import { fulfillmentBillingDetailsActions } from '@detox/actions';
import { BillingPreferencePayload } from '../../types/registrationCheckout';

export interface BillingDetailsState {
  isLoading: boolean;
  isError: boolean;
  billingPreference?: BillingPreferencePayload;
  isOnPaperBill?: boolean;
}

const {
  FETCH_BILLING_PREFERENCE_LOADING,
  FETCH_BILLING_PREFERENCE_SUCCESS,
  FETCH_BILLING_PREFERENCE_ERROR
} = fulfillmentBillingDetailsActions.BILLING_DETAILS;

const initialValues = {
  isLoading: false,
  isError: false
};

export const billingPreference = (
  state: BillingDetailsState = initialValues,
  action
): BillingDetailsState => {
  switch (action.type) {
    case FETCH_BILLING_PREFERENCE_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case FETCH_BILLING_PREFERENCE_SUCCESS: {
      return {
        ...state,
        billingPreference: action.value,
        isLoading: false,
        isError: false,
        isOnPaperBill: !!action.value
      };
    }
    case FETCH_BILLING_PREFERENCE_ERROR: {
      return {
        isError: true,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default billingPreference;
