import React from 'react';
import { Modal, Text, withTheme } from '@dls/web';
import styled, { css } from 'styled-components';
import { Upload, Message } from '@dls/assets/dist/icons/web';
import WhatsAppLogo from '@dls/assets/dist/images/logo_whatsapp.png';
import FacebookLogo from '@dls/assets/dist/images/social_facebook.png';
import LineLogo from '../../assets/images/line_icon.png';
import TelegramLogo from '../../assets/images/telegram_icon.png';
import { trans as t } from '../../helpers/localisation';
import CONSTANTS from '../../constants/common';
import LINKS from '../../config/links';

// Styled Components
const Container = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    @media (max-width: ${coreTheme.brk_md}) {
      gap: 24px;
    }
  `}
`);

const IconGrid = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const IconWrapper = withTheme(styled.div`
  ${({ coreTheme }) => css`
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #f7f7f7;
    overflow: hidden;
    cursor: pointer;
    @media (min-width: ${coreTheme.brk_md}) {
      width: 80px;
      height: 80px;
    }
  `}
`);

const StyledUploadIcon = styled(Upload)`
  width: 32px;
  height: 32px;
`;

const StyledImg = styled.img`
  width: 32px;
  height: 32px;
`;

const StyledMessageIcon = styled(Message)`
  width: 32px;
  height: 32px;
`;

type ShareModelProps = {
  showModal: boolean;
  onClose: () => void;
};

const getCurrentPageUrl = () => {
  if (typeof window !== 'undefined') {
    return encodeURIComponent(window.location.href);
  }
  return '';
};

export const ShareModal = ({ showModal, onClose }: ShareModelProps) => {
  const shareIcons = process.env.GATSBY_SHARE_ICONS;
  const shareIconsList = shareIcons
    ? shareIcons.split('|').map(icon => icon.trim())
    : [];

  const currentPageUrl = getCurrentPageUrl();

  const shareLinks = {
    [CONSTANTS.SHARE_ICON
      .FACEBOOK]: `${LINKS.facebookShareLink}${currentPageUrl}`,
    [CONSTANTS.SHARE_ICON
      .TELEGRAM]: `${LINKS.telegramShareLink}${currentPageUrl}`,
    [CONSTANTS.SHARE_ICON
      .WHATSAPP]: `${LINKS.whatsappShareLink}${currentPageUrl}`,
    [CONSTANTS.SHARE_ICON.LINE]: `${LINKS.lineShareLink}${currentPageUrl}`
  };

  const handleShareAction = (type: string) => {
    try {
      if (type === CONSTANTS.SHARE_ICON.COPY) {
        navigator.clipboard.writeText(window.location.href);
      } else if (
        [
          CONSTANTS.SHARE_ICON.WHATSAPP,
          CONSTANTS.SHARE_ICON.TELEGRAM,
          CONSTANTS.SHARE_ICON.FACEBOOK,
          CONSTANTS.SHARE_ICON.LINE
        ].includes(type)
      ) {
        navigator.clipboard.writeText(window.location.href);
        window.open(shareLinks[type], '_blank');
      } else if (type === CONSTANTS.SHARE_ICON.MESSAGE) {
        window.location.href = `${LINKS.messageShareLink}${currentPageUrl}`;
      }
      onClose();
    } catch (e) {}
  };

  const renderIcon = (type: string, src?: string) => {
    switch (type) {
      case CONSTANTS.SHARE_ICON.COPY:
        return (
          <StyledUploadIcon
            data-testid="copy-link-icon"
            onClick={() => handleShareAction(type)}
          ></StyledUploadIcon>
        );
      case CONSTANTS.SHARE_ICON.FACEBOOK:
      case CONSTANTS.SHARE_ICON.LINE:
      case CONSTANTS.SHARE_ICON.TELEGRAM:
      case CONSTANTS.SHARE_ICON.WHATSAPP:
        return (
          <StyledImg
            data-testid={`${type}-icon`}
            src={src}
            onClick={() => handleShareAction(type)}
          />
        );
      case CONSTANTS.SHARE_ICON.MESSAGE:
        return (
          <StyledMessageIcon
            data-testid="msg-icon"
            onClick={() => handleShareAction(type)}
          />
        );
      default:
        return null;
    }
  };

  const getImgSrc = type => {
    switch (type) {
      case CONSTANTS.SHARE_ICON.WHATSAPP:
        return WhatsAppLogo;
      case CONSTANTS.SHARE_ICON.TELEGRAM:
        return TelegramLogo;
      case CONSTANTS.SHARE_ICON.FACEBOOK:
        return FacebookLogo;
      case CONSTANTS.SHARE_ICON.LINE:
        return LineLogo;
      default:
        return '';
    }
  };

  const renderShareMethod = (type: string) => {
    return (
      <IconGrid>
        <IconWrapper>{renderIcon(type, getImgSrc(type))}</IconWrapper>
        <Text tag="h5" type="smallBody">
          {type}
        </Text>
      </IconGrid>
    );
  };

  return (
    <Modal
      visible={showModal}
      title={t('SHARE_MODAL_TITLE')}
      onClose={onClose}
      scrollable={false}
    >
      <Modal.Content>
        <Container>
          {shareIconsList.map(item => {
            return renderShareMethod(CONSTANTS.SHARE_ICON[item]);
          })}
        </Container>
      </Modal.Content>
    </Modal>
  );
};

export default ShareModal;
