import React, {
  ReactElement,
  useRef,
  useEffect,
  useReducer,
  useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Spacing } from '@dls/web';
import { Form } from '@wec-core/form-engine';
import CONSTANTS from '../../../constants/checkout';
import useTranslate from '../../../hooks/useTranslation';

import {
  getPostalCode,
  getAddNewPostalSubmit,
  getFormDataMapping,
  FORM_MAPPING_ACTIONS,
  getFloorAndUnitField,
  floorValidation,
  unitValidation,
  handleOnFloorNumberChange
} from '../helper';
import { getResetPostalValues } from './helpers';
import {
  TObject,
  Address,
  AddressStatus,
  SelectionObject
} from '../../../types/registrationCheckout';
import {
  checkoutFlowReducer as addNewAddressReducer,
  checkoutInitialState as addNewAddressInitialState
} from '../checkoutFlowState';
import { usePostalCodeHelper } from '../hooks/usePostalCodeHelper';
import { KeyValue } from '../../../types/common.types';
import { confirmSavedNewAddress } from '../../../reducers/fulfillment/addressInfo';

interface TProps {
  isOpen: boolean;
  closeAddressModal: () => void;
  keepAddress: boolean;
}

const AddNewAddress: React.FC<TProps> = ({
  isOpen,
  closeAddressModal,
  keepAddress
}): ReactElement => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const postalCode = getPostalCode(t);
  const additionalAfterPostal = {
    submit: getAddNewPostalSubmit(t, keepAddress)
  };
  const { addressInfo, addNewAddressData } = useSelector((state: TObject) => ({
    addressInfo: state.fulfillment?.addressInfo,
    addNewAddressData: state.checkout?.addNewAddressData
  }));
  const previousPostal = useRef<KeyValue>(addressInfo?.address?.postcode);
  const enableFormReinitialize = useRef<boolean>(true);
  const loadSavedAddress = useRef<boolean>(keepAddress);
  const [addAddressFormState, setAddAddressFormState] = useState<KeyValue>();

  const [formState, formDispatch] = useReducer(
    addNewAddressReducer,
    addNewAddressInitialState
  );
  const {
    setPostalCodeGroup,
    postalSearchValidation,
    handlePostalSearchEvent,
    togglePostalAddressDetail
  } = usePostalCodeHelper(t, formDispatch, dispatch);

  const formFieldsData = {
    postal: postalCode,
    postalAddress: null,
    submit: null
  };
  const initialValues = {
    postalCode: '',
    floorNumber: '',
    unitNumber: '',
    addressCheck: false
  };

  const onAddressCheckChange = (changedData: SelectionObject): void => {
    togglePostalAddressDetail(changedData.value, addressInfo.address);
  };

  const closeAndResetAddress = (): void => {
    if (!keepAddress) {
      dispatch({ type: CONSTANTS.RESET_ADDRESS_DATA });
    }

    closeAddressModal();
  };

  const modalConfig = {
    visible: isOpen,
    onClose: closeAndResetAddress,
    bgColor: 'white',
    backdropClosable: false
  };

  const formattedFormFieldsData = getFormDataMapping(
    formState.formInputsMapping
  );

  const handlePostalSearch = (postalCode: string) => {
    handlePostalSearchEvent(postalCode, addressInfo, false);
  };

  const setPostalCodeGroupData = (
    address: Address,
    status: AddressStatus
  ): void => {
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
      payload: []
    });

    if (address) {
      setPostalCodeGroup(
        address,
        status,
        addNewAddressData,
        initialValues,
        additionalAfterPostal,
        enableFormReinitialize,
        getResetPostalValues()
      );
      if (previousPostal.current !== addressInfo?.address?.postcode) {
        previousPostal.current = addressInfo?.address?.postcode;
        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND,
          payload: getFloorAndUnitField()
        });
      }
    }
  };

  const postalCodeValidation = (code = '') => {
    const preValidation = postalSearchValidation(
      code,
      addressInfo,
      enableFormReinitialize,
      !keepAddress
    );

    return preValidation;
  };

  const setOverAllFormState = (): void => {
    const payload = {
      formInputsMapping: { ...formFieldsData },
      formValues: { ...initialValues, ...addNewAddressData }
    };
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE,
      payload
    });
  };

  useEffect(() => {
    setOverAllFormState();
  }, []);

  useEffect(() => {
    const { address, savedAddress, status } = addressInfo;
    const loadedAddress = loadSavedAddress.current ? savedAddress : address;
    const loadedStatus: AddressStatus = loadSavedAddress.current
      ? 'SUCCEEDED'
      : status;

    setPostalCodeGroupData(loadedAddress, loadedStatus);
    loadSavedAddress.current = false;
  }, [addressInfo]);

  const addressFloorValidation = (floor: string) => {
    const floorValue = floor.length === 1 ? '0' + floor : floor;
    return floorValidation(floorValue, addressInfo?.validFloors);
  };

  const addressUnitValidation = (unit: string) => {
    const floorNumber = addAddressFormState?.['floorNumber'] || '';
    const floorValue =
      floorNumber.length === 1 ? '0' + floorNumber : floorNumber;
    const unitValue = unit.length === 1 ? '0' + unit : unit;
    return unitValidation(unitValue, addressInfo?.validFloors, floorValue);
  };

  const _callBacks = {
    onFloorNumberChange: handleOnFloorNumberChange,
    floorValidation: addressFloorValidation,
    unitValidation: addressUnitValidation,
    postalCodeValidation: postalCodeValidation,
    rightIconLinkClicked: handlePostalSearch,
    onAddressCheckChange
  };

  const _onValueChanged = (values: KeyValue): void => {
    setAddAddressFormState(values);
  };

  const _onSubmit = values => {
    const {
      postcode,
      streetName,
      blockOrHouseNum,
      buildingName
    } = addressInfo.address;
    const { floorNumber, unitNumber } = values;
    const unitDetail =
      floorNumber || unitNumber ? `#${floorNumber}-${unitNumber}` : '';

    const physicalAddress = {
      floorNumber,
      originalUnitNumber: unitNumber,
      blockOrHouseNum,
      buildingName,
      streetName,
      unitNumber: unitDetail,
      postcode,
      newAddressSubTitle: t('CO_EN_ADD_ADDRESS_NEW')
    };

    dispatch({
      type: CONSTANTS.SET_ADDRESS_FORM_DATA,
      payload: values
    });
    dispatch({
      type: CONSTANTS.UPDATE_DELIVERY_ADDRESSES,
      payload: { physicalAddress }
    });

    dispatch({
      type: CONSTANTS.UPDATE_ADDRESS_INFO,
      payload: { physicalAddress }
    });
    confirmSavedNewAddress();
    closeAddressModal();
  };

  const formData = {};
  return (
    <Modal title={t('CO_EN_ADD_ADDRESS_TITLE')} {...modalConfig}>
      <Modal.Content>
        {formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
          <Spacing top={2}>
            <Form
              stackSpacing={2}
              enableReinitialize={formState.enableReinitialize}
              formFieldsConfig={formattedFormFieldsData}
              valuesChangeOnDemand={formState.valuesChangeOnDemand}
              initialValues={formState.formValues}
              data={formData}
              callbacks={_callBacks}
              onValueChange={_onValueChanged}
              onSubmit={_onSubmit}
            />
          </Spacing>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

export default AddNewAddress;
