import React from 'react';
import { Modal, Button, Text, Spacing } from '@dls/web';
import useTranslate from '../../hooks/useTranslation';
import { SessionWarningModalData } from '../../types/fulfilment.types';

const SessionWarningModal = ({
  showModal,
  closeSessionWarning,
  extendSessionWarning
}: SessionWarningModalData) => {
  const { t } = useTranslate();
  return (
    <Modal
      data-testid={`session-timeout-modal`}
      visible={showModal}
      title={t('IDLE_TITLE')}
      onClose={closeSessionWarning}
    >
      <Modal.Content>
        <Spacing bottom={4}>
          <Text type="smallBody">{t('IDLE_CONTENT')}</Text>
        </Spacing>
        <Button
          data-testid="extend-time-button"
          text={t('IDLE_BUTTON')}
          onClick={extendSessionWarning}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SessionWarningModal;
