import { User } from '../../types/user.types';
import { useEffect, useState } from 'react';
import { getUserMyInfo, isCisMyInfoAvailable } from '../../helpers/user';

export const useCisMyInfo = (user: Partial<User>) => {
  const cisInformation = user?.cis?.information;
  const [cisMyInfoData, setCisMyInfoData] = useState(null);
  useEffect(() => {
    if (isCisMyInfoAvailable(cisInformation)) {
      setCisMyInfoData(getUserMyInfo(cisInformation.cusMyInfo));
    }
  }, [user?.cis?.information?.cusMyInfo]);
  return {
    cisMyInfoData
  };
};
