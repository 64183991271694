import { getLocale, getLocaleData, translate } from '@wec-core/helpers';

import enUS from '../hooks/useTranslation/en_us.json';
import rrpEnUS from '../hooks/useTranslation/rrp/en_us.json';

const localisation = {
  en: { ...enUS, ...rrpEnUS }
};

export let messages = {};

export const updateMessages = (values: {
  [key: string]: string | number;
}): void => {
  messages = { ...messages, ...values };
};

export const localStorageKey = 'lang';

export const trans = (
  id: string,
  replaceMap?: { [key: string]: string | number }
) => {
  const localeData = getLocaleData(localisation, getLocale(localStorageKey));

  return translate({ ...localeData, ...messages }[id] || id, replaceMap);
};
