import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import RRPOrderSummary from '../../../components/RRPOrderSummary';

import '../../../fragments/rrp-product';

const RRPOrderSummaryPage = props => {
  const { data } = props;

  return (
    <div className="fs-unmask">
      <SEO title="RRP Order Summary" />
      <RRPOrderSummary data={data} />
    </div>
  );
};

export const query = graphql`
  query RRPOrderSummaryPageQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
  }
`;

export default RRPOrderSummaryPage;
