import { ReactElement } from 'react';
import {
  Discount,
  SelectedPlan,
  TradeIn,
  ShoppingCartVoucher,
  MCSSVoucher,
  SelectedProduct,
  Upfront,
  VoucherTradeInStatus
} from '../types/shoppingCart.types';
import { SelectedAccessory } from '../types/accessory.types';
import isSimOnlyPlan from './is-sim-only-plan';
import isMobileSharePlan from './is-mobile-share-plan';
import { trans as t } from '../helpers/localisation';
import { CART } from '../constants';
import ORDER_CONSTANTS from '../constants/order';
import isFeatureFlagEnabled from './feature-flags';
import {
  FEATURE_FLAG_ENABLE_BIB,
  FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP
} from '../types/featureFlag.types';

const { VOUCHER_REDEMPTION_TYPE, VOUCHER_TYPE } = CART;

export const parseNumber = (number: string | number): number => {
  if (typeof number === 'number') {
    return number;
  }
  if (typeof number === 'string') {
    return parseFloat(number);
  }
  return 0;
};

export const isMobileShareOrSimOnlyPlan = (
  selectedPlan: SelectedPlan
): boolean => {
  return isSimOnlyPlan(selectedPlan) || isMobileSharePlan(selectedPlan);
};

export const isSimOnlyWithoutDevice = (
  selectedPlan: SelectedPlan,
  selectedProduct: SelectedProduct
): boolean => {
  return isSimOnlyPlan(selectedPlan) && !selectedProduct;
};

export const isSimOnlyOrMobileShareWithoutDeviceAndAccessories = (
  selectedPlan: SelectedPlan,
  selectedProduct: SelectedProduct,
  selectedAccessories: SelectedAccessory[]
): boolean => {
  return (
    isMobileShareOrSimOnlyPlan(selectedPlan) &&
    !selectedProduct &&
    (!selectedAccessories || selectedAccessories.length === 0)
  );
};

export const hasSelectedVoucher = (vouchers: ShoppingCartVoucher[] = []) => {
  return vouchers?.some(val => val.selected);
};

export const hasSelectedTradeIn = (tradeIn: TradeIn) => {
  return !!tradeIn && parseNumber(tradeIn.tradeinPrice) > 0;
};

export const getSelectedVoucherAmount = (
  vouchers: ShoppingCartVoucher[] = []
): number => {
  return vouchers.reduce((result, voucher) => {
    if (voucher.selected) {
      result += parseNumber(voucher.price);
    }
    return result;
  }, 0);
};

export const getUpfront = ({
  upfrontPriceCanBeOffset,
  tradeIn,
  discount,
  upfrontPriceCanBeOffsetForTradeIn,
  vouchers
}: {
  upfrontPriceCanBeOffset: number | string;
  tradeIn?: TradeIn;
  discount?: Discount;
  upfrontPriceCanBeOffsetForTradeIn?: string | number;
  vouchers?: ShoppingCartVoucher[];
}): Upfront => {
  const voucherAmount = getSelectedVoucherAmount(vouchers);
  const upfrontAmount = parseNumber(upfrontPriceCanBeOffset);
  const discountAmount = parseNumber(discount?.totalAmount);
  const tradeInAmount = parseNumber(tradeIn?.tradeinPrice);
  const discountWithoutTradeIn = discountAmount - tradeInAmount;
  const totalAfterDiscount = upfrontAmount - discountAmount;
  return {
    totalAfterOffset: totalAfterDiscount,
    totalForTradeIn:
      parseNumber(upfrontPriceCanBeOffsetForTradeIn) - discountWithoutTradeIn,
    hasVoucher: voucherAmount > 0,
    hasTradeIn: tradeInAmount > 0
  };
};

export const validateVoucher = ({
  vouchers,
  upfront
}: {
  vouchers?: ShoppingCartVoucher[];
  upfront: Upfront;
}): VoucherTradeInStatus => {
  let message: string | ReactElement = '';
  let available = true;
  const voucherSelected = hasSelectedVoucher(vouchers);
  const hasRebateVoucher = vouchers.some(
    voucher => voucher.redemptionType === VOUCHER_REDEMPTION_TYPE.REBATE
  );
  switch (true) {
    case vouchers.length === 0:
      message = t('DONT_HAVE_VOUCHER');
      break;

    case upfront.totalAfterOffset <= 0 && !hasRebateVoucher:
      message = t('VOUCHER_UPFRONT_USE_ONLY');
      available = false;
      break;

    case voucherSelected && upfront.totalAfterOffset < 0:
      message =
        vouchers.length > 1
          ? t('VOUCHER_EXCEEDED_MULTIPLE_VOUCHERS')
          : t('VOUCHER_EXCEEDED_SINGLE_VOUCHER');
      break;

    case voucherSelected && upfront.totalAfterOffset === 0:
      message =
        vouchers.length > 1
          ? t('VOUCHER_APPLIED_EQUAL_UPFRONT_FOR_MULTIPLE_VOUCHER')
          : t('VOUCHER_APPLIED_EQUAL_UPFRONT_FOR_SINGLE_VOUCHER');
      break;

    case !voucherSelected && upfront.totalAfterOffset < 0 && upfront.hasTradeIn:
      message = t('VOUCHER_UNAVAILABLE_WHEN_TRADE_IN_APPLIED_EXCEED_UPFRONT');
      available = false;
      break;

    case !voucherSelected &&
      upfront.totalAfterOffset === 0 &&
      upfront.hasTradeIn:
      message = t('VOUCHER_UNAVAILABLE_WHEN_TRADE_IN_APPLIED_EQUAL_UPFRONT');
      available = false;
      break;
    case !voucherSelected && upfront.totalAfterOffset <= 0:
      message = t('VOUCHER_UPFRONT_USE_ONLY');
      available = false;
      break;
    default:
      message = ' ';
      break;
  }

  return { message, available };
};

export const isBibAvailable = ({
  plan,
  flowType,
  selectedProduct,
  eligibleBibGroupIds = ''
}: {
  flowType: string;
  selectedProduct: Partial<SelectedProduct>;
  plan: SelectedPlan;
  eligibleBibGroupIds: string;
}): boolean => {
  const hasDevice = !!selectedProduct?.groupId;
  const eligibleBibGroupIdList = eligibleBibGroupIds?.split(',');
  const isSelectedGroupIdEligible = eligibleBibGroupIdList.some(
    groupId =>
      groupId.trim().toUpperCase() === selectedProduct?.groupId?.toUpperCase()
  );
  return !!(
    isSelectedGroupIdEligible &&
    isSopTradeInAvailable({
      plan,
      hasDevice
    }) &&
    flowType === ORDER_CONSTANTS.TYPE.RECON &&
    isFeatureFlagEnabled(FEATURE_FLAG_ENABLE_BIB)
  );
};

export const isSopTradeInAvailable = ({
  plan,
  hasDevice
}: {
  hasDevice: boolean;
  plan: SelectedPlan;
}): boolean => {
  const isSopTradeInEnable = isFeatureFlagEnabled(
    FEATURE_FLAG_ENABLE_TRADEIN_FOR_SOP
  );
  return !!(isSimOnlyPlan(plan) && isSopTradeInEnable && hasDevice);
};

export const validateTradeIn = ({
  tradeIn,
  isHomeDeliveryAvailable = true,
  upfront,
  plan,
  hasBibTradeIn,
  hasDevice
}: {
  tradeIn?: TradeIn;
  isHomeDeliveryAvailable?: boolean;
  upfront: Upfront;
  plan?: SelectedPlan;
  hasBibTradeIn: boolean;
  hasDevice: boolean;
}): VoucherTradeInStatus => {
  let message: string | ReactElement = '';
  let available = true;
  const tradeInAmount = parseNumber(tradeIn?.tradeinPrice);
  const tradeInSelected = hasSelectedTradeIn(tradeIn);

  const isSopTradingAvailable = isSopTradeInAvailable({ hasDevice, plan });
  switch (true) {
    case hasBibTradeIn: {
      message = t('TRADE_IN_UNAVAILABLE_BIB_APPLIED');
      available = false;
      break;
    }
    case tradeInSelected &&
      upfront.totalForTradeIn > 0 &&
      tradeInAmount - upfront.totalForTradeIn > 0:
      message = t('TRADE_IN_APPLIED_EXCEED_UPFRONT');
      break;

    case !tradeInSelected &&
      upfront.hasVoucher &&
      upfront.totalAfterOffset === 0:
      message = t('TRADE_IN_UNAVAILABLE_WHEN_VOUCHER_APPLIED_EQUAL_UPFRONT');
      available = false;
      break;

    case !tradeInSelected && upfront.hasVoucher && upfront.totalAfterOffset < 0:
      message = t('TRADE_IN_UNAVAILABLE_WHEN_VOUCHER_APPLIED_EXCEEDED_UPFRONT');
      available = false;
      break;

    case upfront.totalForTradeIn <= 0 && !isSopTradingAvailable:
      message = t('TRADE_IN_UNAVAILABLE_NO_UPFRONT');
      available = false;
      break;

    case !isHomeDeliveryAvailable:
      message = t('TRADE_IN_NO_HOME_DELIVERY_MESSAGE');
      available = false;
      break;
    default:
      message = ' ';
      break;
  }

  return { message, available };
};

export interface CreateShoppingCartDataModelVoucherOption {
  vouchers: MCSSVoucher[];
  isCheckoutPriceOffset: boolean;
  cisUser?: boolean;
}

export interface CreateShoppingCartDataModelVoucherReturnValue {
  dataSource: ShoppingCartVoucher[];
  exceeded: boolean;
}

export const createShoppingCartDataModelVoucher = ({
  vouchers,
  isCheckoutPriceOffset,
  cisUser
}: CreateShoppingCartDataModelVoucherOption): CreateShoppingCartDataModelVoucherReturnValue => {
  const redeemedVoucher = vouchers.find(voucher => voucher.redemptionDateX8);

  const dataSource: ShoppingCartVoucher[] = vouchers.map(voucher => {
    const { finalizedNameX8, voucherTypeX8, redemptionType } = voucher;

    let name = finalizedNameX8 as string;
    let disabled =
      isCheckoutPriceOffset && voucher.redemptionDateX8 === undefined;

    if (voucherTypeX8 == VOUCHER_TYPE.RECON_VOUCHER && cisUser) {
      disabled = true;
    } else if (voucherTypeX8 == VOUCHER_TYPE.RED_VOUCHER) {
      if (redemptionType == VOUCHER_REDEMPTION_TYPE.REBATE) {
        disabled = false;
        name = t('REBATE_VOUCHER_PREFIX', {
          voucherName: finalizedNameX8
        }) as string;
      } else if (redemptionType == VOUCHER_REDEMPTION_TYPE.NA) disabled = true;
    }

    if (redeemedVoucher && redeemedVoucher.redemptionType !== redemptionType) {
      // check this only when there is redeemed voucher, DO NOT use optional chaining
      disabled = true;
    }

    return {
      id: voucher.idX8,
      type: voucher.voucherTypeX8,
      name,
      price: voucher.amountX8,
      expiryDate: voucher.expiryDateX8,
      selected: Boolean(voucher.redemptionDateX8),
      redemptionType: voucher.redemptionType,
      disabled
    };
  });

  return {
    dataSource,
    exceeded: isCheckoutPriceOffset && Boolean(redeemedVoucher)
  };
};
