import React from 'react';
import { Button, Modal, Spacing, Text } from '@dls/web';
import useTranslate from '../../../hooks/useTranslation';
import { navigate } from 'gatsby';
import { ErrorScenarioType } from '../../../types/registrationCheckout';

interface CPModalProps {
  errorData: ErrorScenarioType;
  navigateTo?: string;
}

const CPModal: React.FC<CPModalProps> = (props: CPModalProps) => {
  const { t } = useTranslate();
  const { errorData, navigateTo } = props;
  const { title, primaryButtonText } = errorData;

  const [isCPModalOpen, setIsCPModalOpen] = React.useState(true);

  const handleProceed = async () => {
    if (navigateTo) {
      navigate(`/${navigateTo}`);
    }
  };

  const onModalClose = () => {
    setIsCPModalOpen(false);
  };

  return (
    <Modal
      data-testid="cpModal"
      visible={isCPModalOpen}
      title={title}
      onClose={onModalClose}
    >
      <Modal.Content>
        <Spacing bottom={3}>
          <Text type="body">{t('CREDIT_LIMIT_CHECK_BODY')}</Text>
        </Spacing>
        <Button primary fullWidth onClick={handleProceed}>
          {primaryButtonText}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default CPModal;
