/**
 * Sort the array of months in an instalment plan
 *
 * @param {Array<string|number>} months - An array of monthly terms
 * @param {'ASC'|'DESC'} direction - Sets the direction of the sort (e.g. Ascending or descending)
 * @returns {*}
 */
const sortMonth = (months, direction = 'DESC') => {
  switch (direction) {
    case 'ASC':
      return months.sort((a, b) => a - b);
    case 'DESC':
    default:
      return months.sort((a, b) => b - a);
  }
};

export default sortMonth;
