import { FULFILLMENT } from '../../constants';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  isLoading: false
};

const mtposStockReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case 'GET_MTPOS_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        hasError: false,
        errorCode: undefined,
        isLoading: true
      };
    }
    case 'GET_MTPOS_SUCCESS': {
      return {
        ...state,
        data: actions.payload,
        status: REQUEST_STATUS.SUCCEEDED,
        isLoading: false
      };
    }
    case 'GET_MTPOS_ERROR': {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        hasError: true,
        errorCode: actions.payload,
        data: [],
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default mtposStockReducer;
