/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useCallback,
  useReducer,
  useRef,
  ReactElement
} from 'react';
import { Form } from '@wec-core/form-engine';
import { Button, Modal, Spacing, Text, BulletList } from '@dls/web';
import LoadingOverlayComponent from '../LoadingOverlay';
import { trans as t } from '../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { OtpVerification } from '@common-modules/registration-checkout';
import { iphoneActions } from '@detox/actions';
import slugify from '../../helpers/slugify';
import {
  cceNRIC,
  cceNRICTitle,
  cisUserCheck,
  ccePassType,
  ccePromptReducer,
  ccetInitialState,
  FORM_MAPPING_ACTIONS,
  CISEMailGroup,
  ccePromptSubmit,
  getFormDataMapping,
  getPassTypeList,
  nricValidation
} from './cceFormConfigAndState';
import { useSelector, useDispatch } from 'react-redux';
import { IphoneState } from '../../reducers/iPhone';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../../constants/actions';
import { COMMON } from '../../constants';

interface OOSModalProps {
  cceModalToggle: boolean;
  setCCEModalToggle: (isNextBtnClick?: boolean, navigatePath?) => void;
  allPlans: APP_TYPE_ANY[];
  onChangeVariant: APP_TYPE_ANY;
  data: APP_TYPE_ANY;
  roiToken: string;
  isROIPhone: boolean;
}

export const CCEPrompt = ({
  cceModalToggle,
  setCCEModalToggle,
  allPlans,
  onChangeVariant,
  data,
  roiToken,
  isROIPhone
}: OOSModalProps): ReactElement => {
  const otpEntered = useRef(false);
  const dispatch = useDispatch();
  const { iphone } = useSelector<{ iphone: IphoneState }, KeyValue>(state => ({
    iphone: state?.iphone
  }));

  const initialFormValues = {
    nric: '',
    passType: '',
    isCisUser: false,
    cisEmail: ''
  };

  const [formState, formDispatch] = useReducer(
    ccePromptReducer,
    ccetInitialState
  );

  const passTypeAttr = nric => {
    const nricValue = ('' + nric)
      .trim()
      .charAt(0)
      .toLowerCase();
    const includesFGM = ['f', 'g', 'm'].includes(nricValue);
    return { passType: !nric || !includesFGM ? null : ccePassType };
  };

  const formFieldsData = {
    title: cceNRICTitle,
    nric: cceNRIC,
    passType: null,
    isCisUser: cisUserCheck,
    cisEmail: null,
    submit: ccePromptSubmit
  };

  const formData = {
    passType: getPassTypeList,
    cisEmail: ''
  };

  useEffect(() => {
    dispatch({ type: SHOP_ACTION_TYPES.IPHONE.RESET_DATA_EXCLUDE_ROITOKEN });
  }, []);

  const { validateSkuData, skuCheckValid, otpStatus, isLoading } = iphone;
  const sku = validateSkuData?.product?.stockCode;

  useEffect(() => {
    if (otpStatus) {
      handleStockCode();
    }
  }, [otpStatus]);

  useEffect(() => {
    if (sku && iphone.formData?.cisEmail) {
      dispatch({
        type: SHOP_ACTION_TYPES.IPHONE.SET_OTP_VISIBLE,
        data: true
      });
    } else {
      handleStockCode();
    }
  }, [iphone.validateSkuData]);

  const handleStockCode = () => {
    const skuVar = data.skuVariants.find(x => x.sku === (sku || ''));

    if (sku && skuCheckValid && skuVar) {
      const skuVar = data.skuVariants.find(x => x.sku === sku);
      const { colour, size, monthlyTerm } = skuVar || {};
      const colorCode = slugify(colour);
      const memorySize = slugify(size);
      const plan = allPlans.find(plan => plan.mecId === skuVar.planId);
      const planName = plan ? slugify(plan?.planName) : '';
      onChangeVariant(
        { ...skuVar, colorCode, memorySize, planName, monthlyTerm },
        '?'
      );
      setCCEModalToggle(true);
    } else if (validateSkuData && (!skuCheckValid || !skuVar)) {
      dispatch({ type: SHOP_ACTION_TYPES.IPHONE.SET_VALIDATE_ERROR });
    }
  };

  const onFormSubmit = (values: KeyValue) => {
    const formattedNRIC = '' + values?.nric?.trim().toUpperCase();

    dispatch(
      iphoneActions.validateGetSKU({
        campaignId: data?.groupCampaignId,
        emailId: values.cisEmail,
        token: roiToken,
        nric: formattedNRIC
      })
    );
  };

  const setFormState = useCallback(() => {
    const payload = {
      formInputsMapping: { ...formFieldsData },
      formValues: initialFormValues
    };
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE,
      payload
    });
  }, []);

  const formCallbacks = {
    onNRICChange: ({ setFieldValue, value }) => {
      setFieldValue('passType', '', false);

      formDispatch({
        type: FORM_MAPPING_ACTIONS.SET_NRIC_PASSTYPE,
        payload: passTypeAttr(value)
      });
    },
    onCISCheckChange: ({ _, value, setFieldValue }) => {
      setFieldValue('cisEmail', '', false);

      formDispatch({
        type: FORM_MAPPING_ACTIONS.SET_CIS_EMAIL,
        payload: { cisEmail: value ? CISEMailGroup : null }
      });
    },
    nricValidation: nricValidation
  };

  useEffect(() => {
    // set initial form state
    setFormState();
  }, []);

  const formattedFormFieldsData = getFormDataMapping(
    formState.formInputsMapping
  );

  const handleOtp = (otpCode: string, emailId: string) => {
    dispatch(iphoneActions.validateOTP({ otpCode, emailId }));
  };

  const handleResendOtp = () => {
    // resend otp Logic
  };

  const renderOTP = () => {
    const otpErrorMsg =
      !otpStatus && otpEntered.current ? (t('INVALID_OTP') as string) : '';

    return (
      <OtpVerification
        data={{
          data: { email: iphone.formData?.cisEmail }
        }}
        onNricVerified={handleResendOtp}
        errorMessage={otpErrorMsg}
        onOtpVerified={(verifiedData: KeyValue = {}) => {
          otpEntered.current = true;
          const { email, otp } = verifiedData;
          handleOtp(otp, email);
        }}
        onePassLoginRequired={false}
        isLoading={iphone.isLoading}
        configs={{
          fullWidth: true
        }}
        resendRequired={false}
      />
    );
  };

  const renderValidateError = () => {
    return (
      <>
        <Spacing bottom={3}>
          <Text type="body">{t('PRE_F_INVALID_ID_MESSAGE_1')}</Text>
          <BulletList>{t('PRE_F_INVALID_ID_MESSAGE_2')}</BulletList>
          <BulletList>{t('PRE_F_INVALID_ID_MESSAGE_3')}</BulletList>
          <Spacing top={2}>
            <Text type="body">{t('PRE_F_INVALID_ID_MESSAGE_4')}</Text>
          </Spacing>
        </Spacing>
        <Button primary fullWidth onClick={() => setCCEModalToggle()}>
          {t('OKAY_GOT_IT')}
        </Button>
      </>
    );
  };

  const onFormValueChange = (values): void => {
    dispatch({ type: SHOP_ACTION_TYPES.IPHONE.SET_FORM_DATA, data: values });
  };

  const getErrorState = () => {
    return (
      iphone.validateError ||
      iphone.isError ||
      (isROIPhone && (!roiToken || roiToken === COMMON.INVALID))
    );
  };

  const renderModalContent = () => {
    if (getErrorState()) {
      return renderValidateError();
    }

    if (iphone.otpVisible) {
      return renderOTP();
    }

    return (
      <Form
        stackSpacing={2}
        enableReinitialize={true}
        initialValues={formState.formValues}
        data={formData}
        onSubmit={onFormSubmit}
        formFieldsConfig={formattedFormFieldsData}
        callbacks={formCallbacks}
        onValueChange={onFormValueChange}
      />
    );
  };

  const preOrderTitle = getErrorState()
    ? t('PRE_F_INVALID_ID_TITLE')
    : t('IPHONE_PRE_ORDER');
  const modalTitle = iphone.otpVisible ? '' : preOrderTitle;

  return formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
    <>
      <Modal
        data-testid="cce-prompt-modal"
        visible={cceModalToggle}
        title={modalTitle}
        backdropClosable={false}
        onClose={() => setCCEModalToggle()}
      >
        <Modal.Content>{renderModalContent()}</Modal.Content>
      </Modal>
      {isLoading && <LoadingOverlayComponent />}
    </>
  ) : null;
};

export default CCEPrompt;
