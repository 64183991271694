import { CISCustomerLoginType, UserStore } from '@detox/actions';

function useAuth(state: {
  user: UserStore;
}): {
  isUserLoggedIn: boolean;
  cisUserLoginType: CISCustomerLoginType;
  cisMyInfoError: string | boolean;
  cisMyInfoLoading: boolean;
} {
  const { user } = state;
  const userInformation = user?.information;
  const cisInformation = user?.cis?.information;
  const cusMyInfo = cisInformation?.cusMyInfo;
  const cisMyInfoError = cusMyInfo?.error;
  const cisMyInfoLoading = cusMyInfo?.loading;
  const hasUserInfo = Boolean(userInformation);
  const isUserLoggedIn = Boolean(
    hasUserInfo && !userInformation?.anonymousUser
  );
  const isUserCis = Boolean(cisInformation?.$myInfo);
  const isCisUserLoginTypeMyInfo =
    isUserCis && Boolean(cisInformation?.$myInfo);
  const cisUserLoginType: CISCustomerLoginType = isCisUserLoginTypeMyInfo
    ? 'MY_INFO'
    : 'EMAIL';

  return {
    isUserLoggedIn,
    cisUserLoginType,
    cisMyInfoError,
    cisMyInfoLoading
  };
}

export default useAuth;
