import { navigation } from '../middlewares/navigation-constants';

export const rrpIppPages = [
  `/${navigation.RRP_IPP_FULFILMENT}`,
  `/${navigation.RRP_IPP_ORDER_SUMMARY}`,
  `/${navigation.RRP_IPP_SHOPPING_CART}`,
  `/${navigation.RRP_IPP_ORDER_CONFIRMATION}`
];

export default [
  `/${navigation.CUSTOMER_REGISTRATION}`,
  `/${navigation.RRP_SHOPPING_CART}`,
  `/${navigation.RRP_FULFILMENT}`,
  `/${navigation.RRP_ORDERSUMMARY}`,
  `/${navigation.RRP_ORDER_CONFIRMATION}`,
  `/${navigation.RRP_PRODUCT_CATALOG}`,
  `/${navigation.RRP_PRODUCT_DETAILS}`
];
