import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import ShoppingCart from '../components/ShoppingCart';

const ShoppingCartPage = props => {
  const { data } = props;

  return (
    <div className="fs-unmask stickyBoundary">
      <SEO title="Shopping Cart" />
      <ShoppingCart data={data} />
    </div>
  );
};

export const query = graphql`
  query ShoppingCartQuery {
    allTradeIn {
      edges {
        node {
          brands {
            title
            value
            models {
              title
              tradeinPrice
              value
            }
          }
        }
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
    allAddonMobileLob {
      edges {
        node {
          addonId
        }
      }
    }
    allAspireBillingOffer {
      edges {
        node {
          boId
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allVoucherRedemption {
      edges {
        node {
          id
          defaultRedemptionType
          footerLiner
          voucherType
          rules {
            validations {
              plansList
              withDevice
              transactionType {
                newSignup
                portIn
                recontract
              }
            }
            redemptionType
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default ShoppingCartPage;
