import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Text, Button, Spacing } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { ACTION_TYPES } from '../../constants/actions';

interface TProps {
  proceedClicked?: () => void;
  isLoggedInUser?: boolean;
}

export const CCEMisMatchError: React.FC<TProps> = ({
  proceedClicked = () => {
    // fail safe
  },
  isLoggedInUser = false
}: TProps): ReactElement => {
  const dispatch = useDispatch();

  const onCloseErrorPopup = () => {
    if (isLoggedInUser) {
      dispatch({ type: ACTION_TYPES.IPHONE.RESET_DATA });
    }
    proceedClicked();
  };

  const titleMessage = isLoggedInUser
    ? t('PRE_F_LOGIN_MISMATCH_TITLE')
    : t('PRE_F_MISMATCH_TITLE');
  const bodyMessage1 = isLoggedInUser
    ? t('PRE_F_LOGIN_MISMATCH_BODY_1')
    : t('PRE_F_MISMATCH_BODY');

  return (
    <Modal
      visible={true}
      title={titleMessage}
      onClose={onCloseErrorPopup}
      backdropClosable={false}
      wide={false}
    >
      <Modal.Content>
        <Text type="body">{bodyMessage1}</Text>

        {isLoggedInUser && (
          <Spacing top={3}>
            <Text type="body">{t('PRE_F_LOGIN_MISMATCH_BODY_2')}</Text>
          </Spacing>
        )}
        <Spacing responsive={false} top={3}>
          <Button primary fullWidth onClick={onCloseErrorPopup}>
            {t('OKAY_GOT_IT')}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default CCEMisMatchError;
