import { FULFILLMENT } from '../../constants';
import { ACTION_TYPES } from '../../constants/actions';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  reserveEquipmentData: []
};

const allocateOrReserve = (state = initialState, actions) => {
  switch (actions.type) {
    case 'ALLOCATE_EQUIPMENT_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        errorCode: undefined,
        isLoading: true
      };
    }
    case 'ALLOCATE_EQUIPMENT_SUCCESS': {
      return {
        ...state,
        data: actions.payload,
        status: REQUEST_STATUS.SUCCEEDED,
        isLoading: false
      };
    }
    case 'ALLOCATE_EQUIPMENT_ERROR': {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        errorCode: actions.payload,
        data: [],
        isLoading: false
      };
    }
    case ACTION_TYPES.FULFILMENT.RESERVE_EQUIPMENT_SUCCESS: {
      return {
        ...state,
        reserveEquipmentData: actions.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default allocateOrReserve;
