import { FieldObject } from '../../../types/registrationCheckout';

export const reconInitialState = {
  formInputsMapping: {},
  formValues: {},
  formConfigs: {}
};

export const getReconFlowNonPerIniValues = (
  isCisFlow: boolean
): FieldObject => {
  let nonPersonalInitialValues: FieldObject = {
    nricFrontUpload: null,
    nricBackUpload: null,
    billingPreference: '',
    termsCheck: ''
  };

  if (isCisFlow) {
    nonPersonalInitialValues = {
      ...nonPersonalInitialValues,
      staffPassUpload: null
    };
  }

  return nonPersonalInitialValues;
};
