import styled, { css } from 'styled-components';
import { Plus as PlusIcon } from '@dls/assets/dist/icons/web';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { withTheme } from '@dls/web';
import theme from '../../theme';

export const StyledImg = withTheme(styled.img`
  ${({ coreTheme }) => css`
    height: 100px;
    margin-bottom: 10px;
    @media (min-width: ${coreTheme.brk_lg}) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  `}
`);

export const StyledPlusIcon = withTheme(styled(PlusIcon)`
  ${({ coreTheme }) => css`
    font-weight: bold;
    height: 40px;
    width: 40px;
    margin: 0 10px;
    @media (min-width: ${coreTheme.brk_md}) {
      margin: 0 40px;
    }
    @media (min-width: ${coreTheme.brk_lg}) {
      margin: 0 50px;
    }
  `}
`);

export const StyledImageCaptionContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    align-items: center;
    max-width: 270px;
    flex-direction: column;
    @media (min-width: ${coreTheme.brk_lg}) {
      flex-direction: row;
      flex: 0 1 auto;
    }
  `}
`);

export const StyledImageListContainer = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${coreTheme.brk_md}) {
      flex-direction: row;
      justify-content: flex-start;
    }
    @media (min-width: ${coreTheme.brk_lg}) {
      flex-direction: row;
      justify-content: flex-start;
    }
  `}
`);

export const StyledBlackArrow = styled.div`
  margin: 6px 5px 0 0;
`;

export const StyledHeroImage = withTheme(styled(Img)`
  ${({ coreTheme }) => css`
    margin-bottom: ${theme.spacing(2)};
    @media (min-width: ${coreTheme.brk_md}) {
      margin-bottom: ${theme.spacing(2)};
    }
  `}
`);
