import React from 'react';
import SEO from '../components/SEO';
import CustomerRegistration from '../components/CustomerRegistration';

const CustomerRegistrationPage = () => {
  return (
    <>
      <SEO title="Customer Registration" />
      <CustomerRegistration />
    </>
  );
};

export default CustomerRegistrationPage;
