import React, { Fragment, ReactNode, useState } from 'react';

import styled, { css } from 'styled-components';
import {
  Text,
  Button,
  Spacing,
  Grid,
  Row,
  Column,
  Modal,
  withTheme,
  useDevice
} from '@dls/web';
import theme from '../../theme';

const StyledBanner = withTheme(styled.div`
  ${({ coreTheme, ...p }) => css`
    position: relative;
    height: 320px;
    @media (min-width: ${coreTheme.brk_md}) {
      text-align: left;
    }
    * {
      ${p.bannerTheme === 'dark' &&
        css`
          color: ${theme.colours.white};
        `};

      ${p.bannerTheme === 'light' &&
        css`
          color: ${theme.colours.grey_900};
        `};
    }
  `}
`);

const StyledGrid = styled(props => <Grid {...props} />)`
  height: 100%;
  position: relative;
`;

const StyledRow = styled(props => <Row {...props} />)`
  height: 100%;
`;

const StyledImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;

  > * {
    height: 100%;
  }
`;

const StyledPreviewImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

interface PromoBannerType {
  bannerTheme?: 'dark' | 'light';
  desktopImg: ReactNode;
  mobileImg: ReactNode;
  title: string;
  description: string;
  enableCta: boolean;
  ctaText: string;
  modalTitle: string;
  modalDescription: string;
  previewMode?: boolean;
  desktopFileReference: string;
  mobileFileReference: string;
}

const PromoBanner = ({
  bannerTheme = 'dark',
  desktopImg,
  mobileImg,
  title,
  description,
  enableCta,
  ctaText,
  modalTitle,
  modalDescription,
  previewMode,
  desktopFileReference,
  mobileFileReference
}: PromoBannerType): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile } = useDevice();
  return (
    <Fragment>
      <StyledBanner
        bannerTheme={bannerTheme}
        title={title}
        description={description}
      >
        <Row>
          <Column sm={12}>
            <StyledImageWrapper>
              {!previewMode && isMobile ? mobileImg : desktopImg}
              {previewMode && (
                <StyledPreviewImage
                  src={isMobile ? mobileFileReference : desktopFileReference}
                />
              )}
            </StyledImageWrapper>
          </Column>
        </Row>
        <StyledGrid>
          <StyledRow middle="md">
            <Column xs={7}>
              <Spacing bottom={1} bottomMd={4}>
                <Spacing bottom={0.5} bottomMd={3}>
                  <Text type="bannerTitle" tag="h2">
                    {title}
                  </Text>
                </Spacing>
                <Text type="body">{description}</Text>
              </Spacing>
              {enableCta && (
                <Button onClick={() => setModalOpen(true)}>{ctaText}</Button>
              )}
            </Column>
          </StyledRow>
        </StyledGrid>
      </StyledBanner>
      <Modal
        visible={modalOpen}
        title={modalTitle}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Content>
          <Text type="body">
            <p dangerouslySetInnerHTML={{ __html: modalDescription }} />
          </Text>
        </Modal.Content>
      </Modal>
    </Fragment>
  );
};

export default PromoBanner;
