export const USER = {
  ID_TYPES: {
    NRIC: 'National Registration ID Card (NRIC)',
    STAFF: 'Staff',
    DIPLOMAT: 'Diplomat',
    FOREIGNER: 'Foreigner Employment Pass'
  },
  IDS: {
    'National Registration ID Card (NRIC)': 'NRIC',
    'Foreigner Employment Pass': '"FEP"',
    Staff: 'STAFF',
    Diplomat: 'DIPLOMAT'
  },
  BILLING_PREF: {
    E_BILL: 'METH_NON_PAPER',
    PAPER_BILL: 'METH_PAPER'
  }
};
