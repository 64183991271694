import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { getUrlParams, setUrlParams as setUrlParamFn } from '@lux/helpers';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { RRP_FLOW } from '../types/featureFlag.types';
import SEO from '../components/SEO';
import { PhoneCatalog } from '../components/PhoneCatalog/PhoneCatalog';
import '../fragments/catalog-phone-detail';
import '../fragments/plan-detail';
import '../fragments/aem-components';
import { ACTION_TYPES } from '../constants/actions';
import { CatalogSkeletonLoader } from '../components/PhoneCatalog/SkeletonLoader';
import { useGatsbyInit } from '../hooks/useGatsbyInit';
import useTranslate from '../hooks/useTranslation';

const IndexPage = ({ data, cisPlanRates }) => {
  const { t } = useTranslate();
  let _urlParams = null;
  const rrpFlowEnabled = isFeatureFlagEnabled(RRP_FLOW);
  const locationData = useLocation();
  const dispatch = useDispatch();
  const { isInit } = useGatsbyInit();
  if (cisPlanRates) {
    navigate(setUrlParamFn('/cis-phones', getUrlParams()));
  }

  const [urlParams, setUrlParams] = useState({});
  useEffect(() => {
    _urlParams = getUrlParams();
    setUrlParams(_urlParams);
    dispatch({ type: ACTION_TYPES.IPHONE.RESET_DATA });
  }, []);

  const renderPhoneCatalog = () => {
    if (!isInit) return <CatalogSkeletonLoader />;
    return (
      <PhoneCatalog
        data={data}
        previewMode={urlParams.preview === 'true'}
        location={locationData}
      />
    );
  };
  // The word 'withoutplan' will be amended when business gives the actual RRP URL.
  if (_urlParams && rrpFlowEnabled && _urlParams?.withoutplan) {
    return navigate('/rrp-product-catalog');
  }

  return (
    <div className="fs-unmask">
      <SEO
        title="Phones"
        description={t('SEO_INDEX_DESCRIPTION')}
        pageTitle={t('SEO_INDEX_PAGE_TITLE')}
        pageDescription={t('SEO_INDEX_PAGE_DESCRIPTION')}
        keywords={t('SEO_INDEX_PAGE_KEYWORDS')}
      />
      {renderPhoneCatalog()}
    </div>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object,
  cisPlanRates: PropTypes.arrayOf(
    PropTypes.shape({
      planID: PropTypes.string,
      discountedPrice: PropTypes.string
    })
  )
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { user } = state;

  return {
    cisPlanRates: user.cis.information && user.cis.information.rates
  };
};

export default connect(mapStateToProps)(IndexPage);

export const query = graphql`
  {
    allPhone {
      edges {
        node {
          ...CatalogPhoneDetailFragment
        }
      }
    }
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allPlanGroup(filter: { segment: { in: "res" } }) {
      edges {
        node {
          defaultPlan
          groupName
        }
      }
    }
    allPhoneBrand {
      edges {
        node {
          text
          value
        }
      }
    }
    allPhoneCatalogCategory {
      edges {
        node {
          text
          value
        }
      }
    }
    allInstalmentOptions {
      edges {
        node {
          text
          value
        }
      }
    }
    allPhoneBanner {
      edges {
        node {
          id
          flowType
          enablePromoBanner
          enableCTA
          ctaText
          ctaLink
          description
          desktopFileReference
          mobileFileReference
          sectionDescription
          sectionTitle
          theme
          title
        }
      }
    }
    allAemPages(filter: { pageUrl: { eq: "/eshop/mobile/res/phones.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
  }
`;
