import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import logo from '../../assets/logo.png';

function SEO({
  description = '',
  title,
  image = logo,
  pathname = '/',
  microdata = {},
  keywords = '',
  pageTitle = '',
  pageDescription = '',
  additionalMeta = null,
  hasDefaultTitle = true
}) {
  const pixelEventTrackingId = microdata?.sku;

  useEffect(() => {
    if (pixelEventTrackingId && window.fbq) {
      // eslint-disable-next-line no-undef
      fbq('track', 'ViewContent', {
        content_ids: [microdata.sku],
        content_type: 'product'
      });
    }
  }, [pixelEventTrackingId]);

  return (
    <StaticQuery
      query={detailsQuery}
      render={({
        site: {
          siteMetadata: { defaultTitle, siteUrl, version, buildTime }
        }
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultTitle,
          image: `${siteUrl}${image}`,
          url: `${siteUrl}${pathname}`,
          keywords: keywords || '',
          pageTitle: pageTitle || '',
          pageDescription: pageDescription || ''
        };
        const { metaTitle, metaDescription, metaKeywords } =
          additionalMeta || {};

        return (
          <Helmet
            title={seo.title}
            titleTemplate={`%s${hasDefaultTitle ? ` | ${defaultTitle}` : ''}`}
          >
            {microdata &&
              renderMicrodata({
                '@context': 'https://schema.org',
                '@type': 'Product',
                name: seo.title,
                description: metaDescription || seo.description,
                image: `${siteUrl}${image || logo}`,
                url: `${siteUrl}${pathname || '/'}`,
                ...microdata
              })}
            <meta name="image" content={seo.image} />
            {metaTitle && <meta name="pageTitle" content={metaTitle} />}
            {!metaTitle && seo.pageTitle && (
              <meta name="pageTitle" content={seo.pageTitle} />
            )}
            {metaDescription && (
              <meta name="description" content={metaDescription} />
            )}
            {!metaDescription && seo.pageDescription && (
              <meta name="description" content={seo.pageDescription} />
            )}

            {metaKeywords && <meta name="keywords" content={metaKeywords} />}
            {!metaKeywords && seo.keywords && (
              <meta name="keywords" content={seo.keywords} />
            )}
            <meta property="og:url" content={seo.url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={seo.title} />
            <meta
              property="og:description"
              content={metaDescription || seo.description}
            />
            <meta property="og:image" content={seo.image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="Singtel" />
            <meta name="twitter:title" content={seo.title} />
            <meta
              name="twitter:description"
              content={metaDescription || seo.description}
            />
            <meta name="twitter:image" content={seo.image} />
            <meta name="detox:version" content={version} />
            <meta name="detox:build-time" content={buildTime} />
          </Helmet>
        );
      }}
    />
  );
}

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        version
        buildTime
      }
    }
  }
`;

const renderMicrodata = microdata => {
  if (!microdata) return null;
  return (
    <script type="application/ld+json">
      {JSON.stringify(microdata, null, 2)}
    </script>
  );
};
