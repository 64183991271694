import { ACTION_TYPES } from '../constants/actions';
import { APP_TYPE_ANY, KeyValue } from '../types/common.types';

interface CreditLimitReviewState {
  flow: string;
}
export const initialState: CreditLimitReviewState = {
  flow: ''
};

type CreditLimitReviewActions = {
  payload?: APP_TYPE_ANY;
  type: string;
};

export const creditLimitReviewReducer = (
  state: CreditLimitReviewState = initialState,
  action: CreditLimitReviewActions
): KeyValue => {
  switch (action.type) {
    case ACTION_TYPES.CREDIT_LIMIT_REVIEW.UPDATE_FLOW_TYPE: {
      return {
        ...state,
        flow: action.payload
      };
    }
  }
  return state;
};
