import { connect } from 'react-redux';
import {
  authActions,
  CIS_GET_MYINFO_MISMATCH_ERROR,
  userActions
} from '@detox/actions';
import { Grid } from '@dls/web';
import useAuth from '../../hooks/useAuth';
import React, { useEffect, useState } from 'react';
import ErrorPanel from '../Error/ErrorPanel';
import notifications from '../../config/notifications';
import { navigation } from '../../middlewares/navigation-constants';

export const NumberSelectionAuthGate = ({
  getUserInformation,
  getMyInfo,
  ...props
}) => {
  const { user } = props;
  const {
    cisUserLoginType,
    isUserLoggedIn,
    cisMyInfoError,
    cisMyInfoLoading
  } = useAuth({ user: user });
  const [isForceAuthenticated, setForceAuthenticated] = useState(false);
  const [isMyInfoCheckOk, setMyInfoCheckOk] = useState(false);
  const [authStatus, setAuthStatus] = useState('inactive');
  const isCisUserLoginTypeMyInfo = cisUserLoginType === 'MY_INFO';

  useEffect(() => {
    if (!isCisUserLoginTypeMyInfo) return;
    if (!isForceAuthenticated) {
      getUserInformation().then(() => setForceAuthenticated(true));
    } else if (isForceAuthenticated && isUserLoggedIn) {
      getMyInfo({
        sessionToken: user.cis.information.$myInfo.accessToken
      }).then(() => setMyInfoCheckOk(true));
    } else {
      setAuthStatus('ok');
    }
  }, [isForceAuthenticated]);

  useEffect(() => {
    if (!isCisUserLoginTypeMyInfo) {
      setAuthStatus('ok');
    }
    if (!isForceAuthenticated || !isMyInfoCheckOk || cisMyInfoLoading) return;
    setAuthStatus(cisMyInfoError ? 'error' : 'ok');
  }, [
    isCisUserLoginTypeMyInfo,
    isForceAuthenticated,
    cisMyInfoLoading,
    isMyInfoCheckOk,
    cisMyInfoError
  ]);

  const renderError = ({ errorCode }) => {
    const errorNotificationProps =
      ErrorNotifications[errorCode] || ErrorNotifications.default;
    return (
      <Grid>
        <ErrorPanel data-testid="error-panel" {...errorNotificationProps} />
      </Grid>
    );
  };

  switch (authStatus) {
    case 'error':
      return renderError({ errorCode: cisMyInfoError });
    case 'ok':
      return props.children;
    case 'inactive':
    default:
      return null;
  }
};

const NumberSelectionPageAuth = connect(state => state, {
  getMyInfo: authActions.getCISMyInfo,
  getUserInformation: userActions.getUserInformation
})(NumberSelectionAuthGate);

export default NumberSelectionPageAuth;

const ErrorNotifications = {
  [CIS_GET_MYINFO_MISMATCH_ERROR]: {
    pageTitle: notifications.CIS_MYINFO_MISMATCHED_NOTIFICATION.title,
    errorNote: notifications.CIS_MYINFO_MISMATCHED_NOTIFICATION.text,
    actionButtonText: 'Try Again',
    onActionClick: () =>
      (window.location.href = `${process.env.GATSBY_AEM_URL}${navigation.SPRINGD_MYINFO_PAGE_PATH}`)
  },
  default: {
    pageTitle: 'Unable to proceed with the request',
    errorNote: 'MyInfo validation has failed.',
    actionButtonText: 'Try Again',
    onActionClick: () =>
      (window.location.href = `${process.env.GATSBY_AEM_URL}${navigation.SPRINGD_MYINFO_PAGE_PATH}`)
  }
};
