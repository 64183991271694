import React from 'react';
import { Row, Column, Grid, Spacing, useDevice } from '@dls/web';
import { ListSkeletonLoader } from '../SkeletonLoader';

export const CatalogSkeletonLoader = () => {
  const { isMobile } = useDevice();
  return (
    <Grid>
      <Row>
        <Column xs={12} sm={3}>
          <Spacing right={isMobile ? 0 : 1}>
            <ListSkeletonLoader numberOfItem={3} />
          </Spacing>
        </Column>
        <Column xs={12} sm={9}>
          <Row>
            <Column xs={12} md={4}>
              <ListSkeletonLoader itemType="Card" numberOfItem={3} />
            </Column>
            <Column xs={12} md={4}>
              <ListSkeletonLoader itemType="Card" numberOfItem={3} />
            </Column>
            <Column xs={12} md={4}>
              <ListSkeletonLoader itemType="Card" numberOfItem={3} />
            </Column>
          </Row>
        </Column>
      </Row>
    </Grid>
  );
};
