import React from 'react';
import { Grid, Row, Column, Spacing, Text, useDevice } from '@dls/web';
import { CONFIGS } from '../config/common';

const NotFoundPage = ({ location }) => {
  const { isMobile } = useDevice();
  const custom_404_slug = process.env.GATSBY_CUSTOM_404_SLUG;
  if (custom_404_slug && location?.href?.includes(custom_404_slug)) {
    return (
      <div data-testid="custom-404">
        <img
          src={
            isMobile
              ? CONFIGS.CUSTOM_404_IMAGES.MOBILE
              : CONFIGS.CUSTOM_404_IMAGES.DESKTOP
          }
          width={'100%'}
          alt="not-found"
        />
      </div>
    );
  }
  return (
    <Grid>
      <Row center="sm" middle="sm">
        <Column>
          <Text type="bannerTitle">Oops! Page Not Found</Text>
          <Spacing top={2}>
            <Text type="smallBody">
              The page you requested could not be found.
            </Text>
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

export default NotFoundPage;
