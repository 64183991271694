import React from 'react';
import useTranslate from '../../hooks/useTranslation';
import { Button, Modal, Spacing, Text, Row, Column } from '@dls/web';
import { HasNoStock } from '@common-modules/product-catalogue';

interface Props {
  isModalOpen: boolean;
  type: HasNoStock;
  onClose: () => void;
  onProceedClick: () => void;
}

export const OutOfStockDialog: React.FC<Props> = props => {
  const { t } = useTranslate();
  const { isModalOpen, type, onClose, onProceedClick } = props;

  const labelsAndCta = {
    COLOUR: {
      title: t('OOS_TITLE_COLOUR'),
      body: t('OOS_BODY_TEXT_COLOUR'),
      cta: t('OKAY_GOT_IT'),
      ctaEvent: onClose
    },
    PRODUCT: {
      title: t('OOS_TITLE'),
      body: t('OOS_BODY_TEXT'),
      cta: t('OOS_CTA_TEXT'),
      ctaEvent: onProceedClick
    }
  };

  if (!type) {
    return null;
  }

  const { title, body, cta, ctaEvent } = labelsAndCta[type];

  return (
    <Modal
      data-testid="outOfStockModal"
      visible={isModalOpen}
      title={title}
      onClose={onClose}
      wide={false}
      backdropClosable={false}
    >
      <Modal.Content>
        <Spacing bottom={3}>
          <Text type="body">{body}</Text>
        </Spacing>

        <Row>
          <Column noGutter xs={12} sm={6}>
            <Button
              fullWidth
              secondary
              onClick={ctaEvent}
              data-testid="oos-proceed-btn"
            >
              {cta}
            </Button>
          </Column>
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default OutOfStockDialog;
