import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { noop } from '@lux/helpers';
import useTranslate from '../../hooks/useTranslation';
import useThirdPartyIndicator from '../../hooks/useThirdPartyIndicator';
import { TOMO_INDICATORS } from '../../constants/third-party';
import { Button } from '@dls/web';
import SelectionModal from '../SelectionModal';
import { gaSendEvent, dataLayerPush } from '../../helpers/common';
import { ButtonWrapper, GomoButton, GomoButtonImage } from './styles';
import gomoBurgerSelfieSrc from '../../assets/images/gomo-burger-selfie.png';
import { getProductDetailSubmitDataLayer } from '../../helpers/dataLayerHelpers';
import { useSelector } from 'react-redux';
import { BaseState } from '../../types/state.types';
import { FlowType } from '../../types/dataLayer.type';

export const TransactionDialog = (props: PropTypes) => {
  const { t } = useTranslate();
  const { hasIndicator: hasTomoIndicator } = useThirdPartyIndicator(
    TOMO_INDICATORS
  );

  const { accessory, plan, product } = useSelector<
    BaseState,
    Partial<BaseState>
  >(state => state);

  const { open, isGomoToSingtelEnabled, onClose } = props;

  const pushEcommerceDataLayer = (flow: FlowType) => {
    dataLayerPush(
      getProductDetailSubmitDataLayer({
        selectedPlan: plan?.selectedPlan,
        selectedVariant: product?.selectedProduct,
        accessories: accessory?.selectedAccessories,
        flow,
        location: window.location
      }),
      true
    );
  };

  const handleRecontractClick = () => {
    gaSendEvent({
      eventCategory: 'Mobile/phone-detail/Recontract',
      eventAction: 'Button',
      eventLabel: 'Recontract - Clicked on recontract option'
    });

    pushEcommerceDataLayer(FlowType.RECON);

    dataLayerPush({
      event: 'Button Click - Transaction Modal',
      transaction: 'recontract'
    });

    navigate('/choose-your-number');
  };

  const handleNewNumberClick = () => {
    gaSendEvent({
      eventCategory: 'Mobile/phone-detail/New',
      eventAction: 'Button',
      eventLabel: 'New - Clicked on new number option'
    });

    pushEcommerceDataLayer(FlowType.NEW);
    dataLayerPush({
      event: 'Button Click - Transaction Modal',
      transaction: 'new'
    });

    navigate('/number-selection#new-number');
  };

  const handlePortinClick = () => {
    gaSendEvent({
      eventCategory: 'Mobile/phone-detail/Port-in',
      eventAction: 'Button',
      eventLabel: 'Port-in - Clicked on port-in option'
    });

    pushEcommerceDataLayer(FlowType.PORT_IN);

    dataLayerPush({
      event: 'Button Click - Transaction Modal',
      transaction: 'port-in'
    });

    navigate('/number-selection#port-in');
  };

  const handleGomoButton = () => {
    gaSendEvent({
      eventCategory: 'Mobile/phone-detail/Gomo',
      eventAction: 'Button',
      eventLabel: 'Gomo - Clicked on gomo option'
    });

    pushEcommerceDataLayer(FlowType.GOMO);

    dataLayerPush({
      event: 'Button Click - Transaction Modal',
      transaction: 'gomo'
    });

    window.location.assign(
      `${process.env.GATSBY_AEM_URL}/personal/products-services/mobile/eform/gomotopostpaid-preorder`
    );
  };

  const CustomGomoButton = () => {
    if (!isGomoToSingtelEnabled) {
      return null;
    }

    return (
      <ButtonWrapper>
        <Button fullWidth onClick={handleGomoButton}>
          <GomoButton>
            {t('BUTTON_NUMBER_FROM_GOMO')}
            <GomoButtonImage
              src={gomoBurgerSelfieSrc}
              alt="Button to keep number from GOMO"
            />
          </GomoButton>
        </Button>
      </ButtonWrapper>
    );
  };

  const data = {
    ...(!hasTomoIndicator && {
      lhs: {
        title: t('TRANSACTION_OPTION_CHOOSE_EXISTING_LINE'),
        buttons: [
          {
            buttonText: t('BUTTON_RECONTRACT'),
            onButtonClick: handleRecontractClick
          }
        ]
      }
    }),
    rhs: {
      title: t('TRANSACTION_OPTION_CHOOSE_NEW_LINE'),
      buttons: [
        {
          buttonText: t('BUTTON_NEW_NUMBER'),
          onButtonClick: handleNewNumberClick
        },
        {
          title: t('TRANSACTION_OPTION_CHOOSE_NEW_LINE'),
          buttonText: t('BUTTON_NUMBER_FROM_OTHER_TELCO'),
          onButtonClick: handlePortinClick
        }
      ],
      customButtons: [CustomGomoButton]
    }
  };

  return <SelectionModal open={open} onClose={onClose} data={data} />;
};

type PropTypes = {
  open: boolean;
  isGomoToSingtelEnabled: boolean;
  onClose: () => void;
  onClickGomoPortIn?: () => void;
};

TransactionDialog.defaultProps = {
  onClose: noop,
  onClickGomoPortIn: noop
};

export default TransactionDialog;
