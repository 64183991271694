export default {
  LOGGED_IN: 'LOGGED_IN',
  WORRY_FREE_LOGIN: 'WORRY_FREE_LOGIN',
  WORRY_FREE_OTP: 'WORRY_FREE_OTP',
  MYINFO_MISMATCH: 'MYINFO_MISMATCH',
  MYINFO_FAILED: 'MYINFO_FAILED',
  NRIC: 'NRIC',
  OTP_GENERIC_ERROR: 'OTP_GENERIC_ERROR',
  OTP_LIMIT_EXCEEDED: 'OTP_LIMIT_EXCEEDED',
  ONE_PASS_LOGIN: 'ONE_PASS_LOGIN',
  WORRY_FREE_NRIC: 'WORRY_FREE_NRIC',
  OTP_MISMATCH_ERROR: 'OTP_MISMATCH_ERROR'
};
