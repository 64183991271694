import React from 'react';
import { ThankYou } from '../components/ThankYou';
import SEO from '../components/SEO';
import { useLocation } from '@reach/router';
export const ThankYouPage = () => {
  return (
    <>
      <SEO description="" title="Thank you" />
      <ThankYou location={useLocation()} />
    </>
  );
};

export default ThankYouPage;
