import React, { ReactElement } from 'react';
import {
  Column,
  Grid,
  Row,
  SkeletonLoader,
  Spacing,
  useDevice
} from '@dls/web';
import { SkeletonOverlay, SkeletonWrapper, GridWrapper } from './styles';

export type ItemType = 'Card' | 'ListItem';
export enum SkeletonType {
  grid,
  list,
  page,
  sideBar
}
export interface PropTypes {
  numberOfItem?: number;
  itemType?: ItemType;
  secondaryItem?: ItemType;
  children?: ReactElement;
  isLoading?: boolean;
  responsive?: boolean;
  column?: {
    xs?: number;
    sm?: number;
  };
  position?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

interface GridProps {
  numberOfColumn?: 1 | 2 | 3 | 4 | 6;
  itemType?: ItemType;
  secondaryItem?: ItemType;
  numberOfRow?: number;
  withOverlay?: boolean;
  children?: ReactElement;
  isLoading?: boolean;
  position?: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
}

const getItemType = (
  itemType: ItemType,
  index: number,
  listLength: number,
  secondaryItem?: ItemType
) => {
  if (!secondaryItem) return itemType;
  if (index !== listLength - 2) {
    return itemType;
  }
  return secondaryItem;
};

export const ListSkeletonLoader = ({
  numberOfItem = 3,
  itemType = 'ListItem',
  children,
  isLoading = true,
  responsive = true,
  secondaryItem,
  column
}: PropTypes): ReactElement => {
  const skeletonLoader = (
    <>
      {isLoading &&
        Array(numberOfItem)
          .fill(null)
          .map((_, index) => {
            return (
              <Spacing
                key={`skeleton-${index}`}
                topBottom={1}
                responsive={responsive}
                data-testid="skeleton-item"
              >
                <SkeletonLoader
                  preset={getItemType(
                    itemType,
                    index,
                    numberOfItem,
                    secondaryItem
                  )}
                  loading={true}
                />
              </Spacing>
            );
          })}
    </>
  );
  if (!isLoading) return children;
  if (column) {
    return (
      <Grid>
        <Column {...column}>{skeletonLoader}</Column>
      </Grid>
    );
  }

  return skeletonLoader;
};

export const SkeletonGrid = ({
  numberOfColumn = 3,
  itemType = 'ListItem',
  numberOfRow = 3,
  withOverlay = false,
  children,
  isLoading = true,
  position = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}: GridProps) => {
  if (!isLoading) {
    return children;
  }
  return (
    <GridWrapper
      top={position.top}
      left={position.left}
      right={position.right}
      bottom={position.bottom}
      responsive={false}
    >
      {Array(numberOfColumn)
        .fill(null)
        .map((_, index) => {
          return (
            <Column
              noGutter
              xs={numberOfColumn > 1 ? 6 : 12}
              sm={12 / numberOfColumn}
            >
              <Spacing left={1} right={1} responsive={false}>
                <ListSkeletonLoader
                  responsive={false}
                  itemType={itemType}
                  numberOfItem={numberOfRow}
                />
              </Spacing>
            </Column>
          );
        })}
      {withOverlay && <SkeletonOverlay />}
    </GridWrapper>
  );
};

export const PageSkeletonLoader = ({
  numberOfItem = 3,
  column = { xs: 12, sm: 12 },
  secondaryItem,
  position = {
    top: 0
  }
}: PropTypes) => {
  return (
    <SkeletonWrapper>
      <Spacing top={position.top}>
        <Column {...column}>
          <ListSkeletonLoader
            secondaryItem={secondaryItem}
            numberOfItem={numberOfItem}
          />
        </Column>
      </Spacing>
    </SkeletonWrapper>
  );
};

export const SkeletonWithSideBar = ({
  isLoading = true,
  children
}: {
  isLoading?: boolean;
  children?: ReactElement;
}) => {
  const { isMobile } = useDevice();
  if (!isLoading) return children;
  return (
    <Grid>
      <Row>
        <Column xs={12} sm={7}>
          <ListSkeletonLoader numberOfItem={3} secondaryItem={'Card'} />
        </Column>
        {!isMobile && (
          <Column xs={12} sm={5}>
            <Spacing left={12} top={1}>
              <SkeletonLoader preset="Card" loading={true} />
            </Spacing>
          </Column>
        )}
      </Row>
    </Grid>
  );
};
