import { KeyValue } from '../types/common.types';

//Find 5g VAS
const is5GVasAddon = (availableAddons: KeyValue): Array<string> => {
  //Find occurrence of "5G "
  const is5GRegex = new RegExp(/\s?5G\s{1}/gi);
  return (
    availableAddons &&
    Object.keys(availableAddons)
      .map(key => {
        return {
          ...availableAddons[key],
          is5GID: key
        };
      })
      .filter(addon => {
        return is5GRegex.test(addon.productTitle);
      })
      .map(key => key.is5GID)
  );
};

export default is5GVasAddon;
