import { arrayUnique, getDisplayUnitNumber } from './common';
import CONSTANTS from '../constants/common';
import {
  DeliveryAddress,
  MCSSCollectionAddress,
  MCSSPopstationAddress,
  MCSSRrpFullpriceDeliveryAddress
} from '../types/orderSummary.types';

export const getAddressInformation = (addresses = [], blockOrHouseNumber) =>
  addresses.find(address => address.blockOrHouseNum === blockOrHouseNumber);

export const getBlockOrHouseNumbers = (addresses = []) => {
  return arrayUnique(addresses.map(address => address.blockOrHouseNum));
};

export const getAddressLines = (
  address:
    | MCSSCollectionAddress
    | DeliveryAddress
    | MCSSPopstationAddress
    | MCSSRrpFullpriceDeliveryAddress
) => {
  function isCollectionAddress(address: MCSSCollectionAddress) {
    return address.collectionAddressType !== undefined;
  }

  function isPopStation(address: MCSSPopstationAddress) {
    return address.POPStationName !== undefined;
  }

  function isRrpFullpriceDeliveryAddress(
    address: MCSSRrpFullpriceDeliveryAddress
  ) {
    return address.block !== undefined && address.country !== undefined;
  }

  let addressLines: string[] = [];

  if (isCollectionAddress(address as MCSSCollectionAddress)) {
    const collectionAddress = address as MCSSCollectionAddress;

    addressLines[0] = `${collectionAddress.apartment} ${collectionAddress.streetName} ${collectionAddress.buildingName}`;
    addressLines[1] = `${CONSTANTS.SINGAPORE} ${collectionAddress.postcode}`;
  } else if (isPopStation(address as MCSSPopstationAddress)) {
    const popstationAddress = address as MCSSPopstationAddress;

    addressLines[0] = popstationAddress.POPStationName;
    addressLines[1] = `${popstationAddress.HouseBlockNumber} ${
      popstationAddress.StreetName
    } ${popstationAddress.UnitNumber || ''}`;
    addressLines[2] = `${
      popstationAddress.BuildingName
    } ${popstationAddress.Storey || ''}`;
    addressLines[3] = `${CONSTANTS.SINGAPORE} ${popstationAddress.ZipCode}`;
  } else if (
    isRrpFullpriceDeliveryAddress(address as MCSSRrpFullpriceDeliveryAddress)
  ) {
    const deliveryAddress = address as MCSSRrpFullpriceDeliveryAddress;

    addressLines[0] = `${deliveryAddress.blockOrHouseNum} ${
      deliveryAddress.streetName
    } ${deliveryAddress?.buildingName || ''}`;
    addressLines[1] = getDisplayUnitNumber({
      floor: deliveryAddress.floor,
      unitNumber: deliveryAddress.unitNumber
    });
    addressLines[2] = `${CONSTANTS.SINGAPORE} ${deliveryAddress.postcode}`;
  } else {
    const deliveryAddress = address as DeliveryAddress;

    addressLines[0] = `${deliveryAddress.blockNumber ||
      deliveryAddress.blockOrHouseNum} ${
      deliveryAddress.streetName
    } ${deliveryAddress.buildingName || ''} ${deliveryAddress.unitNumber ||
      ''}`;
    addressLines[1] = `${CONSTANTS.SINGAPORE} ${deliveryAddress.postalCode ||
      deliveryAddress.postcode}`;
  }

  return addressLines.map(removeExtraWhiteSpace);
};

const removeExtraWhiteSpace = (string: string) =>
  string.replace(/^\s+|\s+$/g, '');
