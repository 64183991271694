import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import Checkout from '../components/Checkout';

export const CheckoutPage = props => {
  const { data } = props;

  return (
    <div className="fs-unmask">
      <SEO title="Checkout" />
      <Checkout data={data} />
    </div>
  );
};

export default CheckoutPage;

export const query = graphql`
  query CheckoutQuery {
    allBillingPreference {
      edges {
        node {
          billingPreferenceKey
          billingPreferenceDesc
          billingPreferencePrice
          billingPreferenceTitle
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
