import { FORM_FIELD_TYPES, FORM_VALIDATION_TYPES } from '@wec-core/form-engine';
import * as Yup from 'yup';
import { isBefore, parse, format } from 'date-fns';
import CONSTANTS from '../../constants/checkout';
import COMMON_CONSTANTS from '../../constants/common';
import {
  BillingAccountDetail,
  FileUploadValidation,
  CallbackValidation,
  TranslateFn,
  IFormFieldConfig,
  FieldObject,
  BillingPrefType,
  FormFlowConfig,
  FormFlowConfigResult,
  TFile,
  UploadedDocument,
  FormConfig,
  BaseAddress,
  CustomerInfoPayLoad,
  CustomerInfo,
  Address,
  ValidFloor,
  UpdateCustomerPayload,
  CreateCustomerPayload,
  TranslateHelperFn
} from '../../types/registrationCheckout';
import {
  filterBillingAddress,
  getAccountBillingInfo,
  getDisplayAddress
} from '../../helpers/common';
import { checkoutActions, config } from '@detox/actions';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { USER } from '../../constants';
import { navigate } from 'gatsby';
import { navigation } from '../../middlewares/navigation-constants';
import { trans as t } from '../../helpers/localisation';
import { Checkout, CustomerInfoResponse } from '../../types/checkout.types';
import { DeliveryState, Fulfilment } from '../../types/fulfilment.types';
import { UserInformation } from '../../types/user.types';
import { simCardType } from '../../config/simcard-types';
import CHECKOUT_CONSTANTS from '../../constants/checkout';

export const FORM_MAPPING_ACTIONS = {
  SET_POSTAL_LOADING: 'SET_POSTAL_LOADING',
  SET_POSTAL_CODE_ADDRESS: 'SET_POSTAL_CODE_ADDRESS',
  REMOVE_POSTAL_CODE_ADDRESS: 'REMOVE_POSTAL_CODE_ADDRESS',
  SET_POSTAL_AND_REMAINING: 'SET_POSTAL_AND_REMAINING',
  SET_DOCUMENTS_GROUP: 'SET_DOCUMENTS_GROUP',
  SET_STAFF_DOC_GROUP: 'SET_STAFF_DOC_GROUP',
  SET_PROOF_DOC_GROUP: 'SET_PROOF_DOC_GROUP',
  SET_PROOF_GROUP: 'SET_PROOF_GROUP',
  SET_KYC_DOC_GROUP: 'SET_KYC_DOC_GROUP',
  SET_FORM_VALUES: 'SET_FORM_VALUES',
  SET_FORM_INPUT_MAPPING: 'SET_FORM_INPUT_MAPPING',
  SET_BILLING: 'SET_BILLING',
  SET_OVERALL_FORM_STATE: 'SET_OVERALL_FORM_STATE',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  TOGGLE_ADD_NEW_ADDRESS_MODAL: 'TOGGLE_ADD_NEW_ADDRESS_MODAL',
  SET_VALUES_CHANGE_ON_DEMAND: 'SET_VALUES_CHANGE_ON_DEMAND'
};

export const MODAL_TYPES = {
  ID_CARD_NRIC: 'ID_CARD_NRIC',
  ID_CARD_NRIC_STAFF: 'ID_CARD_NRIC_STAFF',
  ID_CARD_STAFF: 'ID_CARD_STAFF',
  RES_DOCUMENT: 'RES_DOCUMENT',
  KYC: 'KYC'
};

export const FILE_UPLOAD_VALIDATION = {
  INVALID_TYPE: 'INVALID_TYPE',
  INVALID_SIZE: 'INVALID_SIZE'
};

export const columnProps = {
  xs: 12,
  sm: 6,
  md: 6
};

export const subBtnColumnProps = {
  xs: 12,
  sm: 6,
  md: 6
};

export const FileUploadStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  LOADING: 'loading'
};

export const fileUploadFields = [
  'nricFrontUpload',
  'nricBackUpload',
  'staffPassUpload',
  'proofUpload',
  'kycUpload'
];

export const allowedMIMEType = /image\/(png|jpg|jpeg)/i;
export const allowedFileSize = 5000000;

export const commonFileUploadProps = {
  component: FORM_FIELD_TYPES.FILE_UPLOAD,
  columnProps: columnProps,
  isSingleUploadMode: true,
  inputProps: {
    accept: 'image/png, image/jpeg, image/jpg'
  }
};

export const commonFileUploadValidations = [
  {
    type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
    params: {
      name: 'documentTypeValidation',
      promise: false
    }
  },
  {
    type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
    params: {
      name: 'documentSizeValidation',
      promise: false
    }
  }
];

export const convertToSGNumber = (contactNumber: string): string => {
  const formattedNumber = ('' + contactNumber).trim();
  if (!formattedNumber || formattedNumber.length !== 8) {
    return '';
  }

  return `+65 ${formattedNumber.slice(0, 4)} ${formattedNumber.slice(4)}`;
};

export const getUpdatedValues = <Tdata>(values: Tdata): Tdata => {
  let updatedValues = values;

  fileUploadFields.forEach(field => {
    const fieldData = values && values[field];

    if (fieldData) {
      updatedValues = {
        ...updatedValues,
        [field]: null
      };
    }
  });
  return updatedValues;
};

export const getUpdatedMyInfoValues = <Tdata>(
  values: Tdata,
  customerInfoResponse?: CustomerInfoResponse
): Tdata => {
  let updatedValues = values;
  updatedValues = {
    ...updatedValues,
    fullName: customerInfoResponse?.fullName,
    gender: customerInfoResponse?.gender,
    birthday: getFormattedDate(customerInfoResponse?.dateOfBirth),
    contactNumber:
      customerInfoResponse?.contactNumberInTicket ||
      customerInfoResponse?.contactNumberMobile,
    floorNumber: customerInfoResponse?.mailingAddrFloor,
    unitNumber: customerInfoResponse?.mailingAddrUnit,
    postalCode: customerInfoResponse?.postalCode
  };

  return updatedValues;
};

export interface UploadedFile extends File {
  src?: string;
}

export const fileUploadDocumentValidation = (
  type: string,
  message: string,
  file: UploadedFile
): FileUploadValidation => {
  const validFile = { valid: true };
  const invalidFile = { valid: false, message };

  switch (type) {
    case FILE_UPLOAD_VALIDATION.INVALID_TYPE:
      return !file.type.match(allowedMIMEType) ? invalidFile : validFile;
    case FILE_UPLOAD_VALIDATION.INVALID_SIZE: {
      const preString = `data:${file?.type};base64,`;

      if (file?.src.length === preString.length) {
        return {
          ...invalidFile,
          message: t('FILE_UPLOAD_INVALID_FORMAT') as string
        };
      }

      return file.size > allowedFileSize ? invalidFile : validFile;
    }

    default:
      return validFile;
  }
};

export const documentTypeValidation = (
  file: UploadedFile,
  t: TranslateFn
): FileUploadValidation => {
  const typeMessage = t('FILE_UPLOAD_INVALID_TYPE');

  return fileUploadDocumentValidation(
    FILE_UPLOAD_VALIDATION.INVALID_TYPE,
    typeMessage,
    file
  );
};

export const documentSizeValidation = (
  file: UploadedFile,
  t: TranslateFn
): FileUploadValidation => {
  const sizeMessage = t('FILE_UPLOAD_INVALID_SIZE', [file.name]);

  return fileUploadDocumentValidation(
    FILE_UPLOAD_VALIDATION.INVALID_SIZE,
    sizeMessage,
    file
  );
};

export const floorValidation = (
  floorValue: string,
  validFloors: ValidFloor[]
): CallbackValidation => {
  const floorAvailability = validFloors.find(vf => vf.floorNo === floorValue);
  const validity = !floorAvailability
    ? { valid: false, message: t('CC_E_API_POSTAL_FLOOR') as string }
    : { valid: true };

  return validity;
};

export const unitValidation = (
  unitValue: string,
  validFloors: ValidFloor[] = [],
  floorValue: string
): CallbackValidation => {
  if (!floorValue) {
    return { valid: true };
  }

  const unitAvailability = validFloors.find(
    vf => vf.unitNo === unitValue && vf.floorNo === floorValue
  );
  const validity = !unitAvailability
    ? { valid: false, message: t('CC_E_API_POSTAL_UNIT') as string }
    : { valid: true };

  return validity;
};

export const getUpdatedFileUploadGroup = (
  docGroup: IFormFieldConfig,
  fieldName: string,
  updatedProperty: FieldObject
): IFormFieldConfig => {
  if (!docGroup) {
    return null;
  }
  const updatedGroupElements = docGroup.groupElements.map(groupElem => {
    if (groupElem.name === fieldName) {
      return {
        ...groupElem,
        ...updatedProperty
      };
    }

    return groupElem;
  });
  return { ...docGroup, groupElements: updatedGroupElements };
};

export const getReadOnlyGroup = (
  names: string[],
  labels: string[],
  valueLabels: string[]
): Array<IFormFieldConfig> => {
  const commonConfig = {
    component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
    columnProps: columnProps
  };

  return [
    {
      ...commonConfig,
      label: labels[0],
      valueLabel: valueLabels[0],
      name: names[0]
    },
    {
      ...commonConfig,
      label: labels[1],
      valueLabel: valueLabels[1],
      name: names[1]
    },
    ...(labels.length > 2
      ? [
          {
            ...commonConfig,
            label: labels[2],
            valueLabel: valueLabels[2],
            name: names[2]
          }
        ]
      : [])
  ];
};

export const getStepperTilesData = (formConfig?: FormConfig): Array<string> => {
  let firstStepper;
  switch (true) {
    case formConfig?.hasPersonalInfo:
    case formConfig?.hasUploadID && formConfig.hasBillingPref: {
      firstStepper = t('CO_NN_STEPPER_ONE');
      break;
    }
    case formConfig?.hasUploadID: {
      firstStepper = t('CO_NN_STEPPER_UPLOAD_ID');
      break;
    }

    case formConfig?.hasBillingPref: {
      firstStepper = t('CO_NN_STEPPER_BILLING_PREF');
      break;
    }
    default: {
      firstStepper = t('CO_NN_STEPPER_ONE');
    }
  }
  if (formConfig?.byPassFulfilment) {
    return [firstStepper, t('CO_NN_STEPPER_THREE')];
  }

  if (formConfig?.byPassDocUpload) {
    return [
      t('CO_NN_STEPPER_TWO') as string,
      t('CO_NN_STEPPER_THREE') as string
    ];
  }
  return [firstStepper, t('CO_NN_STEPPER_TWO'), t('CO_NN_STEPPER_THREE')];
};

export const genderListData = (
  t: TranslateFn | TranslateHelperFn
): Array<Partial<IFormFieldConfig>> => [
  { label: t('CO_NN_GENDER_MALE'), value: 'M' },
  { label: t('CO_NN_GENDER_FEMALE'), value: 'F' }
];

export const getSalutationListData = (
  t: TranslateFn
): Array<Partial<IFormFieldConfig>> => [
  { text: t('CO_NN_SALUTATION_MR'), value: 'MR' },
  { text: t('CO_NN_SALUTATION_MRS'), value: 'MRS' },
  { text: t('CO_NN_SALUTATION_MISS'), value: 'MS' },
  { text: t('CO_NN_SALUTATION_MS'), value: 'MS' },
  { text: t('CO_NN_SALUTATION_DR'), value: 'DR' }
];

export const getNRIC = (
  t: TranslateFn,
  { id }: { id: string }
): Partial<IFormFieldConfig> => {
  return {
    component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
    name: 'nric',
    label: t('CO_NN_NRIC_FIN'),
    valueLabel: id
  };
};

export const getNRICGroup = (
  t: TranslateFn,
  { id, passTypeText }: { id: string; passTypeText: string }
): Partial<IFormFieldConfig> => {
  if (!passTypeText) {
    return null;
  }

  return {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    groupElements: getReadOnlyGroup(
      ['nric', 'passType'],
      [t('CO_NN_NRIC_FIN'), t('CO_NN_PASS_TYPE')],
      [id, passTypeText]
    )
  };
};

export const getTitleAndInfoGroup = (
  t: TranslateFn,
  nric?: IFormFieldConfig
): Array<IFormFieldConfig> => [
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'formTitle',
    title: t('CO_NN_PERSONAL_INFORMATION'),
    subTitle: t('CO_NN_SUB_PERSONAL_INFORMATION')
  },
  {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    name: 'fullnameGroup',
    groupElements: [
      {
        component: FORM_FIELD_TYPES.DROPDOWN_INPUT,
        name: 'salutation',
        label: t('CO_NN_SALUTATION'),
        bgColor: 'haze',
        dataSource: 'salutationList',
        columnProps: {
          ...columnProps,
          noGutterLeft: true
        },
        validations: [
          'string',
          {
            type: 'required',
            params: { message: t('CC_E_TITLE') }
          }
        ]
      },
      {
        component: FORM_FIELD_TYPES.TEXT_INPUT,
        name: 'fullName',
        label: t('CO_NN_FULLNAME'),
        bgColor: 'haze',
        placeholder: t('CO_NN_FULLNAME_PLACEHOLDER'),
        columnProps: {
          ...columnProps,
          noGutterRight: true
        },
        validations: [
          'string',
          {
            type: 'required',
            params: { message: t('CC_E_FULLNAME') }
          },
          {
            type: FORM_VALIDATION_TYPES.PATTERN_VALIDATION,
            params: {
              invalidMessage: t('CC_E_IV_FULLNAME'),
              regPattern: /^[aA-zZ\s]+$/
            }
          }
        ]
      }
    ]
  },
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'birthday',
    label: t('CO_NN_DOB'),
    bgColor: 'haze',
    placeholder: 'DD/MM/YYYY',
    displayFormat: 'date',
    disabledDays: { after: new Date() },
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_DOB') }
      },
      {
        type: FORM_VALIDATION_TYPES.DOB_VALIDATION,
        params: {
          message: t('CC_E_IV_DOB'),
          limit: 1901,
          limitAtToday: true
        }
      },
      {
        type: FORM_VALIDATION_TYPES.DOB_VALIDATION,
        params: {
          message: t('CC_E_IV_DOB_UNDERAGE'),
          limitUnderage: nric ? 18 : 21
        }
      }
    ]
  },
  {
    component: FORM_FIELD_TYPES.BINARY_SELECTOR_INPUT,
    name: 'gender',
    label: t('CO_NN_GENDER'),
    dataSource: 'genderList',
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_GENDER') }
      }
    ]
  }
];

export const getContactGroup = (t: TranslateFn): Array<IFormFieldConfig> => [
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'contactNumber',
    label: t('CO_NN_CONTACT_NUMBER'),
    displayFormat: 'mobile-sg',
    bgColor: 'haze',
    placeholder: t('CO_NN_CONTACT_NUMBER_PLACEHOLDER'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_CONTACT') }
      },
      {
        type: FORM_VALIDATION_TYPES.MOBILE_NUM_VALIDATION,
        params: {
          message: t('CC_E_IV_CONTACT'),
          inValidLengthMsg: t('CC_E_IVL_CONTACT')
        }
      }
    ]
  },
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'email',
    label: t('CO_NN_EMAIL'),
    bgColor: 'haze',
    placeholder: t('CO_NN_EMAIL_PLACEHOLDER'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_EMAIL') }
      },
      {
        type: 'email',
        params: { message: t('CC_E_IV_EMAIL') }
      }
    ]
  }
];

export const getPostalCode = (t: TranslateFn): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'postalCode',
    label: t('CO_NN_POSTALCODE'),
    bgColor: 'haze',
    maxLength: 6,
    loading: false,
    readOnly: false,
    validateOnChange: true,
    placeholder: t('CO_NN_POSTALCODE_PLACEHOLDER'),
    rightIconLink: {
      iconName: 'search',
      iconSize: 26,
      events: {
        onClick: 'rightIconLinkClicked'
      }
    },
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_POSTAL') }
      },
      {
        type: FORM_VALIDATION_TYPES.PATTERN_VALIDATION,
        params: {
          invalidMessage: t('CC_E_IV_POSTAL'),
          regPattern: /^\d{6}$/
        }
      },
      {
        type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
        params: {
          message: t('CC_E_IV_POSTAL'),
          name: 'postalCodeValidation',
          promise: true
        }
      }
    ]
  };
};

export const getEmptyField = (name: string): IFormFieldConfig => ({
  name,
  component: FORM_FIELD_TYPES.EMPTY_FIELD
});

export const getPostalGroup = (
  t: TranslateFn,
  address: Address,
  isAddressChecked = false
): Array<IFormFieldConfig> => {
  const { blockOrHouseNum, streetName, buildingName } = address;

  const addressDetail = isAddressChecked
    ? [
        {
          component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
          name: 'addressDetail',
          groupElements: [
            getEmptyField('floorNumber'),
            getEmptyField('unitNumber')
          ]
        }
      ]
    : [
        {
          component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
          name: 'addressDetail',
          groupElements:
            address?.floorNumber || address?.unitNumber
              ? [
                  {
                    component: FORM_FIELD_TYPES.TEXT_INPUT,
                    name: 'floorNumber',
                    label: t('CO_NN_FLOOR'),
                    bgColor: 'haze',
                    columnProps: columnProps,
                    validations: [
                      'string',
                      {
                        type: 'required',
                        params: { message: t('CC_E_POSTAL_FLOOR') }
                      },
                      {
                        type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
                        params: {
                          name: 'floorValidation',
                          promise: false
                        }
                      }
                    ],
                    events: {
                      onValueChange: 'onFloorNumberChange'
                    }
                  },
                  {
                    component: FORM_FIELD_TYPES.TEXT_INPUT,
                    name: 'unitNumber',
                    label: t('CO_NN_UNIT_NO'),
                    bgColor: 'haze',
                    columnProps: columnProps,
                    validations: [
                      'string',
                      {
                        type: 'required',
                        params: { message: t('CC_E_POSTAL_UNIT') }
                      },
                      {
                        type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
                        params: {
                          name: 'unitValidation',
                          promise: false
                        }
                      }
                    ]
                  }
                ]
              : []
        }
      ];

  return [
    {
      component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
      name: 'addressGroup',
      groupElements: getReadOnlyGroup(
        [
          'blockOrHouseNum',
          'streetName',
          ...(buildingName ? ['buildingName'] : [])
        ],
        [
          t('CO_NN_BLOCK_NO'),
          t('CO_NN_STREET_ADDRESS'),
          ...(buildingName ? [t('CO_NN_BUILDING_NAME')] : [])
        ],
        [blockOrHouseNum, streetName, ...(buildingName ? [buildingName] : [])]
      )
    },
    ...addressDetail
  ];
};

export const getDocumentTitleGroup = (
  t: TranslateFn,
  hasDivider = true
): Array<IFormFieldConfig> => [
  ...(hasDivider
    ? [
        {
          component: FORM_FIELD_TYPES.FORM_DIVIDER,
          name: 'postalGroupSectionDivider'
        }
      ]
    : []),
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'formTitle',
    title: t('CO_NN_UPLOAD_ID'),
    subTitle: t('CO_NN_UPLOAD_COPIES_INFO')
  },
  {
    component: FORM_FIELD_TYPES.FORM_TEXT_LINK,
    name: 'idTypeLink',
    label: t('CO_NN_UPLOAD_COPIES'),
    events: {
      onClick: 'idCardLinkClicked'
    }
  }
];

export const getDocumentsUploadGroup = (
  t: TranslateFn,
  preNricFront: boolean,
  preNricBack: boolean
): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    name: 'nricFilesGroup',
    groupElements: [
      {
        ...commonFileUploadProps,
        name: 'nricFrontUpload',
        label: t('CO_NN_NRIC_FRONT'),
        singleUploadStatus: preNricFront
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.LOADING,
        isError: '',
        errorMessage: t('CO_NN_UPLOAD_FAILED'),
        labelText: t('CO_NN_ADD_FILE'),
        hintText: t('CO_NN_MAX_FILE_SIZE'),
        isPreloaded: preNricFront,
        events: {
          onDrop: 'nricFrontDropped',
          singleModeOnDelete: 'nricFrontDeleted'
        },
        validations: [
          'mixed',
          {
            type: 'required',
            params: { message: t('CO_NRIC_FRONT_REQUIRED') }
          },
          ...commonFileUploadValidations
        ]
      },
      {
        ...commonFileUploadProps,
        name: 'nricBackUpload',
        label: t('CO_NN_NRIC_BACK'),
        singleUploadStatus: preNricBack
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.LOADING,
        isError: '',
        errorMessage: t('CO_NN_UPLOAD_FAILED'),
        labelText: t('CO_NN_ADD_FILE'),
        hintText: t('CO_NN_MAX_FILE_SIZE'),
        isPreloaded: preNricBack,
        events: {
          onDrop: 'nricBackDropped',
          singleModeOnDelete: 'nricBackDeleted'
        },
        validations: [
          'mixed',
          {
            type: 'required',
            params: { message: t('CO_NRIC_BACK_REQUIRED') }
          },
          ...commonFileUploadValidations
        ]
      }
    ]
  };
};

export const getStaffPassUploadGroup = (
  t: TranslateFn,
  preStaff: boolean
): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    name: 'staffPassFilesGroup',
    groupElements: [
      {
        ...commonFileUploadProps,
        name: 'staffPassUpload',
        label: t('CO_NN_STAFF_PASS'),
        singleUploadStatus: preStaff
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.LOADING,
        isError: '',
        errorMessage: t('CO_NN_UPLOAD_FAILED'),
        labelText: t('CO_NN_ADD_FILE'),
        hintText: t('CO_NN_MAX_FILE_SIZE'),
        isPreloaded: preStaff,
        events: {
          onDrop: 'staffPassDropped',
          singleModeOnDelete: 'staffPassDeleted'
        },
        validations: [
          'mixed',
          {
            type: 'required',
            params: { message: t('CO_STAFF_PASS_REQUIRED') }
          },
          ...commonFileUploadValidations
        ]
      }
    ]
  };
};

export const getKycUploadTitleGroup = (
  t: TranslateFn
): Array<IFormFieldConfig> => [
  {
    component: FORM_FIELD_TYPES.FORM_DIVIDER,
    name: 'proofSectionDivider'
  },
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'kycTitle',
    title: t('KYC_TITLE'),
    subTitle: t('KYC_SUBTITLE')
  },
  {
    component: FORM_FIELD_TYPES.FORM_TEXT_LINK,
    name: 'kycLink',
    label: t('KYC_LINK'),
    events: {
      onClick: 'kycLinkClicked'
    }
  }
];

export const getKycUploadGroup = (
  t: TranslateFn,
  preKyc: boolean
): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    name: 'docKycFilesGroup',
    groupElements: [
      {
        ...commonFileUploadProps,
        name: 'kycUpload',
        label: t('KYC_UPLOAD_TITLE'),
        singleUploadStatus: preKyc
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.LOADING,
        isError: '',
        errorMessage: t('CO_NN_UPLOAD_FAILED'),
        labelText: t('KYC_ADD_FILE'),
        hintText: t('KYC_MAX_FILE_SIZE'),
        isPreloaded: preKyc,
        events: {
          onDrop: 'kycDropped',
          singleModeOnDelete: 'kycDeleted'
        },
        validations: [
          'mixed',
          {
            type: 'required',
            params: { message: t('CO_KYC_DOC_REQUIRED') }
          },
          ...commonFileUploadValidations
        ]
      }
    ]
  };
};

export const getProofUploadTitleGroup = (
  t: TranslateFn
): Array<IFormFieldConfig> => [
  {
    component: FORM_FIELD_TYPES.FORM_DIVIDER,
    name: 'proofSectionDivider'
  },
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'formTitle',
    title: t('PROOF_TITLE'),
    subTitle: t('PROOF_SUBTITLE')
  },
  {
    component: FORM_FIELD_TYPES.FORM_TEXT_LINK,
    name: 'proofLink',
    label: t('PROOF_LINK'),
    events: {
      onClick: 'proofLinkClicked'
    }
  }
];

export const getProofUploadGroup = (
  t: TranslateFn,
  preProof: boolean
): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
    name: 'docProofFilesGroup',
    groupElements: [
      {
        ...commonFileUploadProps,
        name: 'proofUpload',
        label: t('PROOF_UPLOAD_TITLE'),
        singleUploadStatus: preProof
          ? FileUploadStatus.SUCCESS
          : FileUploadStatus.LOADING,
        isError: '',
        errorMessage: t('CO_NN_UPLOAD_FAILED'),
        labelText: t('PROOF_ADD_FILE'),
        hintText: t('PROOF_MAX_FILE_SIZE'),
        isPreloaded: preProof,
        events: {
          onDrop: 'proofDropped',
          singleModeOnDelete: 'proofDeleted'
        },
        validations: [
          'mixed',
          {
            type: 'required',
            params: { message: t('CO_ADDRESS_PROOF_REQUIRED') }
          },
          ...commonFileUploadValidations
        ]
      }
    ]
  };
};

export const getPersSectionDivider = (): IFormFieldConfig => ({
  component: FORM_FIELD_TYPES.FORM_DIVIDER,
  name: 'personalSectionDivider'
});

export const getFormSpacing = (sizing = 1): IFormFieldConfig => ({
  component: FORM_FIELD_TYPES.FORM_SPACING,
  name: 'personalSectionDivider',
  sizing
});

export const getDisplayFaId = (faId: string | number): string => {
  if (!faId) {
    return '';
  }
  if (typeof faId === 'number') {
    faId = (faId as number).toString();
  }
  if (faId.length <= 4) {
    return `****${faId}`;
  }
  return faId;
};

export const getBillingGroup = (
  t: TranslateFn,
  billPrefData: BillingPrefType[],
  billingAccountDetails: BillingAccountDetail[] = [],
  showBillingPref = true,
  showAddressChanger = true,
  newBillingAccountDetails: BillingAccountDetail[] = []
): Array<IFormFieldConfig> => {
  const accDetails = filterBillingAddress(billingAccountDetails);
  const addressFields = billingAccountDetails?.length
    ? [
        {
          component: FORM_FIELD_TYPES.FORM_TITLE,
          hasSectionNumbering: true,
          name: 'formTitle',
          title: t('SELECTOR_GROUP_TITLE'),
          subTitle: t('SELECTOR_GROUP_BILLING_ADDRESS')
        },
        {
          component: FORM_FIELD_TYPES.FORM_GROUP_SELECTORS,
          name: 'billingAddress',
          selectorsList: accDetails
            ?.slice(0, CONSTANTS.NUMBER_OF_BILLING_ADDRESS_DISPLAYED)
            .map((billingAccountDetail: BillingAccountDetail) => {
              const address = billingAccountDetail.physicalAddress;
              const subtitle = `${t('ACCOUNT_NUMBER')} ${getDisplayFaId(
                billingAccountDetail.faId
              )}`;

              return {
                title: getDisplayAddress(address),
                subtitle
              };
            }),
          columnProps: columnProps,
          events: {
            onSelectionChange: 'onBillPrefAddressChange'
          },
          validations: [
            'string',
            {
              type: 'required',
              params: { message: t('CC_E_BILL_PREF') }
            }
          ]
        },
        ...(showAddressChanger
          ? [
              {
                component: FORM_FIELD_TYPES.FORM_TEXT_LINK,
                name: 'addAddressLink',
                label: t('CO_EN_ADD_ADDRESS_COPIES'),
                events: {
                  onClick: 'addAddressLinkClicked'
                }
              }
            ]
          : []),
        ...(newBillingAccountDetails?.length
          ? [
              {
                component: FORM_FIELD_TYPES.FORM_TITLE,
                title: '',
                name: 'newAddressTitle',
                subTitle: t('CO_EN_ADD_ADDRESS_NEW')
              },
              {
                component: FORM_FIELD_TYPES.FORM_GROUP_SELECTORS,
                name: 'newBillingAddress',
                selectorsList: newBillingAccountDetails.map(
                  (billingAccountDetail: BillingAccountDetail) => {
                    const address = billingAccountDetail.physicalAddress;

                    return {
                      title: getDisplayAddress(address),
                      subtitle: ''
                    };
                  }
                ),
                columnProps: columnProps,
                events: {
                  onSelectionChange: 'onBillPrefAddressChange'
                },
                validations: [
                  'string',
                  {
                    type: 'required',
                    params: { message: t('CC_E_BILL_PREF') }
                  }
                ]
              },
              {
                component: FORM_FIELD_TYPES.FORM_TEXT_LINK,
                name: 'addAddressLink',
                label: t('CO_EN_ADD_ADDRESS_CHANGE'),
                events: {
                  onClick: 'addAddressLinkClicked'
                }
              }
            ]
          : []),
        ...(billingAccountDetails?.length && showBillingPref
          ? [
              {
                component: FORM_FIELD_TYPES.FORM_DIVIDER,
                name: 'postalGroupSectionDivider'
              },
              {
                component: FORM_FIELD_TYPES.FORM_TITLE,
                title: '',
                name: 'formTitle',
                subTitle: t('SELECTOR_GROUP_SUBTITLE')
              }
            ]
          : [])
      ]
    : [];

  const billingPref = showBillingPref
    ? [
        ...(!billingAccountDetails?.length && showBillingPref
          ? [
              {
                component: FORM_FIELD_TYPES.FORM_TITLE,
                hasSectionNumbering: true,
                name: 'formTitle',
                title: t('SELECTOR_GROUP_TITLE'),
                subTitle: t('SELECTOR_GROUP_SUBTITLE')
              }
            ]
          : []),
        {
          component: FORM_FIELD_TYPES.FORM_GROUP_SELECTORS,
          name: 'billingPreference',
          selectorsList: billPrefData.map(d => {
            return {
              title: d.billingPreferenceTitle,
              subtitle: d.billingPreferenceDesc,
              endContent:
                d.billingPreferencePrice === '0'
                  ? t('SELECTOR_GROUP_EBILL_ENDCONTENT')
                  : `$${d.billingPreferencePrice}/mth`
            };
          }),
          columnProps: columnProps,
          validations: [
            'string',
            {
              type: 'required',
              params: { message: t('CC_E_BILL_PREF') }
            }
          ]
        }
      ]
    : [];

  return [...addressFields, ...billingPref];
};

export const getSubmitGroup = (
  t: TranslateFn,
  hasTnc = true,
  hasDivider = true,
  defaultButtonText?: string
): Array<IFormFieldConfig> => [
  ...(hasDivider
    ? [
        {
          component: FORM_FIELD_TYPES.FORM_DIVIDER,
          name: 'personalSectionDivider'
        }
      ]
    : []),
  ...(hasTnc
    ? [
        {
          component: FORM_FIELD_TYPES.FORM_TITLE,
          name: 'termsTitle',
          title: t('CO_NN_TERMS')
        },
        {
          component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
          name: 'termsCheck',
          label: t('CO_NN_TERMS_ACK'),
          showError: true,
          validations: [
            'string',
            {
              type: 'required',
              params: { message: t('CC_E_TERMS') }
            }
          ]
        }
      ]
    : []),
  {
    component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
    name: 'submitButton',
    label: defaultButtonText || t('CO_NN_PROCEED_BTN'),
    columnProps: subBtnColumnProps,
    sizing: 3,
    btnProps: {
      fullWidth: true
    }
  }
];

export const isValidPostalCode = (postalCode: string): boolean =>
  /[0-9]{6}$/.test(postalCode);

export const genderSalutationSchema = (gender?: string) => {
  const femaleSalutations = [
    COMMON_CONSTANTS.SALUTATION.MS,
    COMMON_CONSTANTS.SALUTATION.MRS,
    COMMON_CONSTANTS.SALUTATION.DR
  ];
  const maleSalutations = [
    COMMON_CONSTANTS.SALUTATION.MR,
    COMMON_CONSTANTS.SALUTATION.DR
  ];
  if (gender) {
    return {
      salutation: Yup.string().oneOf(
        gender === COMMON_CONSTANTS.GENDER.FEMALE
          ? femaleSalutations
          : maleSalutations,
        t('CC_E_SELECTION') as string
      )
    };
  }

  return {
    salutation: Yup.string().when('gender', {
      is: gender => gender === COMMON_CONSTANTS.GENDER.FEMALE,
      then: Yup.string().oneOf(
        femaleSalutations,
        t('CC_E_SELECTION') as string
      ),
      otherwise: Yup.string().when('gender', {
        is: COMMON_CONSTANTS.GENDER.MALE,
        then: Yup.string().oneOf(maleSalutations, t('CC_E_SELECTION') as string)
      })
    })
  };
};

export const getValidationSchema = (t: TranslateFn): Yup.AnySchema => {
  return Yup.object().shape({
    termsCheck: Yup.string().oneOf(['true'], t('CC_E_TERMS')),
    ...genderSalutationSchema()
  });
};

export const getFormDataMapping = <Tdata>(formValues: Tdata): Tdata[] => {
  return Object.values(formValues)
    .filter(Boolean)
    .reduce((acc: [], val: never) => acc.concat(val), []);
};

export const getNewNewIniValues = (mobileNumber = ''): FieldObject => {
  const initialValues = {
    salutation: '',
    fullName: '',
    birthday: '',
    gender: '',
    contactNumber: mobileNumber,
    email: '',
    postalCode: ''
  };

  return initialValues;
};

export const getResetKycValues = (): FieldObject => {
  return {
    postalAddress: null,
    documentsTitle: null,
    documents: null,
    staffPass: null,
    proofTitle: null,
    proof: null,
    kyc: null,
    kycTitle: null,
    persSectionDivider: null,
    billingPref: null,
    submit: null
  };
};

export const getNewNewFlowNonPerIniValues = (
  isCisFlow: boolean
): FieldObject => {
  let nonPersonalInitialValues: FieldObject = {
    floorNumber: '',
    unitNumber: '',
    addressCheck: '',
    nricFrontUpload: null,
    nricBackUpload: null,
    proofUpload: null,
    kycUpload: null,
    billingPreference: '',
    termsCheck: ''
  };

  if (isCisFlow) {
    nonPersonalInitialValues = {
      ...nonPersonalInitialValues,
      staffPassUpload: null
    };
  }

  return nonPersonalInitialValues;
};

export const UPLOAD_UPDATE_ACTIONS = {
  documents: FORM_MAPPING_ACTIONS.SET_DOCUMENTS_GROUP,
  staffPass: FORM_MAPPING_ACTIONS.SET_STAFF_DOC_GROUP,
  proof: FORM_MAPPING_ACTIONS.SET_PROOF_GROUP,
  kyc: FORM_MAPPING_ACTIONS.SET_KYC_DOC_GROUP
};

export const getFormConfigForNewNewFlow = ({
  device,
  isCis,
  isNric,
  isNricSilver = false,
  simType,
  hasAccessories
}: Partial<FormFlowConfig>): FormFlowConfigResult => {
  const showStaffUploadDoc = isCis;
  const showKYCUploadDoc = !device;
  const showNricFIN = true;
  const showBillingPref = !isNricSilver;
  const showAddressProofUploadDoc = !isNric && !isNricSilver;
  const byPassFulfilment =
    !device && simType === simCardType.simTypeESim && !hasAccessories;
  return {
    showStaffUploadDocument: showStaffUploadDoc,
    showKYCUploadDocument: showKYCUploadDoc,
    showAddressProofUploadDoc,
    showNricFIN,
    showBillingPref,
    byPassFulfilment
  };
};

export const checkNricSilver = (
  dateOfBirth: string | number,
  isNric = false
): boolean => {
  if (!isNric || !dateOfBirth) return false;
  const dateFormat = 'dd/MM/yyyy';
  if (typeof dateOfBirth === 'number') {
    dateOfBirth = format(dateOfBirth, dateFormat);
  }
  const currentYear = new Date().getFullYear();
  const dateToCompare = new Date(currentYear, 11, 31, 23, 59, 59);
  dateToCompare.setFullYear(
    dateToCompare.getFullYear() - CONSTANTS.NRIC_SILVER_AGE
  );
  return isBefore(
    parse(dateOfBirth.replace(/ /g, ''), dateFormat, new Date()),
    dateToCompare
  );
};

export const fileUploaded = async ({
  uploadedFile,
  fieldName,
  fieldsGroupName,
  docGroup,
  hasDocumentErrors,
  productOrderReferenceNumber,
  uploadDocument,
  dispatch,
  formDispatch,
  t
}: {
  uploadedFile: TFile;
  fieldName: string;
  fieldsGroupName: string;
  docGroup: IFormFieldConfig;
  hasDocumentErrors;
  productOrderReferenceNumber;
  uploadDocument;
  dispatch;
  formDispatch;
  t;
}): Promise<void> => {
  const isValidFile = {
    ...documentTypeValidation(uploadedFile, t),
    ...documentSizeValidation(uploadedFile, t)
  };
  if (!isValidFile.valid || isValidFile.message) {
    return;
  }

  const options = {
    productOrderReferenceNumber,
    blob: uploadedFile,
    fileName: uploadedFile.name,
    fieldName
  };
  const { name = '', type = 'image/jpeg' } = uploadedFile;

  try {
    await dispatch(uploadDocument(options));

    const updatedFileFields = getUpdatedFileUploadGroup(docGroup, fieldName, {
      singleUploadStatus: FileUploadStatus.SUCCESS,
      isError: false
    });
    formDispatch({
      type: UPLOAD_UPDATE_ACTIONS[fieldsGroupName],
      payload: updatedFileFields
    });

    dispatch({
      type: CONSTANTS.SET_CHECKOUT_FORM_DOCUMENT_DATA,
      payload: {
        [fieldName]: {
          name,
          type,
          src: ''
        }
      }
    });

    const { [fieldName]: _, ...otherData } = hasDocumentErrors.current;
    hasDocumentErrors.current = otherData;
  } catch (_) {
    const updatedFileFields = getUpdatedFileUploadGroup(docGroup, fieldName, {
      singleUploadStatus: FileUploadStatus.FAILURE,
      isError: true
    });

    formDispatch({
      type: UPLOAD_UPDATE_ACTIONS[fieldsGroupName],
      payload: updatedFileFields
    });

    hasDocumentErrors.current = {
      ...hasDocumentErrors.current,
      [fieldName]: t('CO_NN_UPLOAD_FAILED')
    };
  }
};

export const uploadedFileDeleted = async ({
  fileName,
  fieldName,
  fieldsGroupName,
  docGroup,
  productOrderReferenceNumber,
  formDispatch,
  dispatch,
  documentUpload,
  deleteUploadedDocument
}: {
  fileName: string;
  fieldName: string;
  fieldsGroupName: string;
  docGroup: IFormFieldConfig;
  productOrderReferenceNumber;
  formDispatch;
  dispatch;
  documentUpload;
  deleteUploadedDocument;
}): Promise<void> => {
  const uploadedDocumentIDs = documentUpload
    .filter(
      (doc: UploadedDocument) =>
        doc.fileName === fileName && doc.fieldName === fieldName
    )
    .map(doc => doc.documentId);

  const options = {
    productOrderReferenceNumber,
    documentId: uploadedDocumentIDs
  };

  const updatedGroupData = getUpdatedFileUploadGroup(docGroup, fieldName, {
    singleUploadStatus: FileUploadStatus.LOADING,
    isError: false,
    isPreloaded: false
  });
  formDispatch({
    type: UPLOAD_UPDATE_ACTIONS[fieldsGroupName],
    payload: updatedGroupData
  });
  dispatch({
    type: CONSTANTS.DELETE_DOCUMENT,
    payload: { fieldName, fileName }
  });

  try {
    await dispatch(deleteUploadedDocument(options));
  } catch (_) {
    // to be handled later once the API issue is fixed
  }
};

export const getAddNewPostalSubmit = (
  t: TranslateFn,
  isUpdate: boolean
): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
    name: 'addNewAddressButton',
    label: isUpdate
      ? t('CO_EN_ADD_ADDRESS_CTA_CONFIRM')
      : t('CO_EN_ADD_ADDRESS_CTA'),
    columnProps: subBtnColumnProps,
    sizing: 1,
    btnProps: {
      fullWidth: true
    }
  };
};

export const getFloorAndUnitField = (): KeyValue => {
  return [
    {
      field: 'floorNumber',
      value: '',
      shouldValidate: false
    },
    {
      field: 'unitNumber',
      value: '',
      shouldValidate: false
    }
  ];
};

const getBillingPrefValue = (
  billingPrefData: BillingPrefType[],
  selectedIndex = 0
): string => {
  return USER.BILLING_PREF[billingPrefData[selectedIndex].billingPreferenceKey];
};

export const getCustomerPayload = ({
  customerInfo,
  address,
  isUpdate = false,
  isEKYCFlow = false
}: {
  customerInfo: CustomerInfo;
  address: BaseAddress;
  isUpdate?: boolean;
  isEKYCFlow?: boolean;
}): CustomerInfoPayLoad => {
  const { passType, passId, name } = customerInfo;
  let contactVipCodeX9 = null;

  switch (true) {
    case passType?.includes('Foreigner'): {
      contactVipCodeX9 = 'Foreigner';
      break;
    }
    case passType === USER.ID_TYPES.NRIC: {
      contactVipCodeX9 = 'Normal';
      break;
    }
    case passType === USER.ID_TYPES.STAFF:
    case passType === USER.ID_TYPES.DIPLOMAT: {
      contactVipCodeX9 = passType;
    }
  }

  const payload = {
    salutation: customerInfo.salutation,
    birthDate: customerInfo.birthDate?.replace(/ /g, ''),
    phone: customerInfo.phone,
    // hardcode fields
    nationality: 'Singaporean',
    selectedOption: isEKYCFlow ? 'EKYC' : 'BAU',
    address: {
      ...address,
      apartment: address.unitNumber,
      room: address.unitNumber,
      houseNumber: address.blockOrHouseNum,
      buildingClassification: address.buildingClassification || 'HDB',
      //hardcode fields
      city: 'Singapore',
      countryCode: 'SGP',
      addressType: 'Standard',
      state: 'SNG',
      country: 'SGP'
    }
  };

  if (isUpdate) {
    return {
      ...payload,
      emailId: customerInfo.email,
      personGender: customerInfo.gender,
      name: customerInfo.name
    };
  }
  return {
    ...payload,
    email: customerInfo.email,
    gender: customerInfo.gender,
    givenName: name,
    familyName: name,
    personIdentificationDetails: {
      type: passType,
      docNumber: passId
    },
    contactVipCodeX9,
    // hardcode fields
    billingAddressSame: true,
    custType: 'D',
    custSubType: 'N',
    race: 'Others',
    customerOwningEntity: 'Singapore',
    customerAccountCategory: 'Non In-house',
    timeZone: 'GMT+8',
    language: 'zh-TW',
    contactId: null
  };
};

export const updateCustomer = async ({
  formValues,
  passType,
  passId,
  addressInfo,
  dispatch,
  contactId,
  customerId,
  isSimOnlyIPP,
  productOrder,
  addedToContact,
  portIn,
  selectedPlan,
  billingPrefData,
  myInfoDataComplete
}: UpdateCustomerPayload): Promise<void> => {
  const customerInfo = getCustomerInfoFromFrom(formValues, passType, passId);
  const data = getCustomerPayload({
    customerInfo,
    address: getAddressFromForm(formValues, addressInfo),
    isUpdate: true,
    isEKYCFlow: myInfoDataComplete
  });

  const billingPref = getBillingPrefValue(
    billingPrefData,
    formValues.billingPreference
  );

  await dispatch(
    checkoutActions.updateCustomer({
      contactId,
      data,
      customerId,
      productOrder,
      isSimOnlyIPP,
      addedToContact,
      customerInfo,
      portIn,
      recalculateBlock: true,
      selectedPlan,
      billingPref
    })
  );
};

export const getRawPhoneNumber = (phone: string): string => {
  if (!phone) return '';
  phone = phone.replace(/ /g, '');
  if (phone.includes(COMMON_CONSTANTS.PHONE_CODE)) {
    return phone.slice(3);
  }
  return phone;
};

const getCustomerInfoFromFrom = (
  formValues: KeyValue,
  passType: string,
  passId: string
) => {
  return {
    name: formValues.fullName,
    birthDate: formValues.birthday,
    phone: getRawPhoneNumber(formValues.contactNumber),
    salutation: formValues.salutation,
    gender: formValues.gender,
    email: formValues.email,
    passType,
    passId
  };
};

const getFloorWithPreZero = (floorOrUnit = '') => {
  return floorOrUnit?.length === 1 ? '0' + floorOrUnit : floorOrUnit;
};

const getAddressFromForm = (formValues: KeyValue, addressInfo: BaseAddress) => {
  const house =
    formValues.floorNumber && formValues.unitNumber
      ? `#${getFloorWithPreZero(formValues.floorNumber)}-${getFloorWithPreZero(
          formValues.unitNumber
        )}`
      : `${getFloorWithPreZero(addressInfo?.floor)}`;

  return {
    ...addressInfo,
    floor: house,
    room: house,
    unitNumber: house
  };
};

export const createCustomer = async ({
  productOrder,
  formValues,
  isSimOnlyIPP,
  addressInfo,
  passType,
  passId,
  billingPrefData,
  dispatch,
  addedToContact,
  portIn,
  identityCheckResult,
  selectedPlan,
  myInfoDataComplete
}: CreateCustomerPayload): Promise<void> => {
  const customerInfo = getCustomerInfoFromFrom(formValues, passType, passId);
  let customerInfoPayLoad = getCustomerPayload({
    customerInfo,
    address: getAddressFromForm(formValues, addressInfo),
    isEKYCFlow: myInfoDataComplete
  });

  const { contactExists, contactId } = identityCheckResult || {};
  if (contactExists && contactId) {
    customerInfoPayLoad = {
      ...customerInfoPayLoad,
      contactExists,
      contactId
    };
  }

  const billingPref = getBillingPrefValue(
    billingPrefData,
    formValues.billingPreference
  );

  await dispatch(
    checkoutActions.createCustomer({
      productOrder,
      isSimOnlyIPP,
      customerInfo: customerInfoPayLoad,
      billingPref,
      addedToContact,
      portIn,
      recalculateBlock: true,
      selectedPlan
    })
  );
};

export const navigateToOrderSummary = (): void | Promise<void> => {
  return navigate(`/${navigation.ORDER_SUMMARY}`);
};

export const getRegisterNewBillingAddressRequest = ({
  checkout,
  delivery,
  userInformation,
  addressInfo
}: {
  checkout: Checkout;
  delivery: DeliveryState;
  userInformation: UserInformation;
  addressInfo: BaseAddress;
}): KeyValue | APP_TYPE_ANY => {
  const userContact = userInformation?.clientContext?.contact;
  const billingInfo = getAccountBillingInfo({
    checkout,
    delivery,
    userInformation
  });
  const formValues = checkout.checkoutFormData;
  const passType =
    userContact?.indentType || checkout.verificationData?.passType;
  const passId = userContact?.indentValue || checkout.verificationData?.id;
  return {
    contactId: userContact?.contactId,
    customerId: userInformation?.clientContext?.customers?.[0]?.customerId,
    customerInfo: getCustomerPayload({
      customerInfo: getCustomerInfoFromFrom(formValues, passType, passId),
      address: getAddressFromForm(formValues, addressInfo)
    }),
    deliveryMethod: billingInfo.billingMethod
  };
};

export const handleOnFloorNumberChange = ({
  _,
  setFieldValue
}: KeyValue): void => {
  setFieldValue('unitNumber', '', false);
};

export const getGenderLabel = (
  t: TranslateFn | TranslateHelperFn,
  gender: string
): string => {
  const result = genderListData(t).find(item => item.value === gender);
  return result?.label;
};

export const getGenderValue = (
  t: TranslateFn | TranslateHelperFn,
  gender: string
): string => {
  const result = genderListData(t).find(item => item.label === gender);
  return result?.value || gender;
};

export const getFormattedDate = (
  dateOfBirth: string,
  splitWith = '/'
): string => {
  if (!dateOfBirth) return '';
  return (
    dateOfBirth &&
    dateOfBirth
      .split(splitWith)
      .reverse()
      .join('/')
  );
};

export const isInCompleteSingpassData = (
  customerInfoResponse?: CustomerInfoResponse
): boolean => {
  return !!(
    customerInfoResponse?.fullName &&
    customerInfoResponse?.gender &&
    customerInfoResponse?.dateOfBirth &&
    customerInfoResponse?.postalCode &&
    customerInfoResponse?.mailingAddrBlock &&
    customerInfoResponse?.mailingAddrStreet &&
    customerInfoResponse?.mailingAddrFloor &&
    customerInfoResponse?.mailingAddrUnit &&
    customerInfoResponse?.passType &&
    customerInfoResponse?.uniqueId &&
    (customerInfoResponse?.contactNumberInTicket ||
      customerInfoResponse?.contactNumberMobile)
  );
};

export const handleBillingAddress = ({
  checkout,
  userInformation,
  delivery,
  fulfilmentState,
  callback
}: {
  checkout: Checkout;
  userInformation: UserInformation;
  delivery: DeliveryState;
  fulfilmentState: Fulfilment;
  callback: (barId?: string) => void;
}) => async dispatch => {
  const hasNewBillingAddress =
    checkout?.checkoutFormData?.billingAddress ===
    CHECKOUT_CONSTANTS.NEW_BILLING_ADDRESS_ID;
  let newBarId;
  if (hasNewBillingAddress) {
    const billingRequestData = getRegisterNewBillingAddressRequest({
      checkout,
      userInformation,
      delivery,
      addressInfo: fulfilmentState?.addressInfo?.savedAddress
    });
    if (checkout.billingAccountId && checkout.billingAddress) {
      const billingAddressToUpdate = checkout.billingAddress.find(
        ba => ba.barId === checkout.billingAccountId
      );

      if (billingAddressToUpdate) {
        await dispatch(
          checkoutActions.updateBillingAddressActions({
            billingArrangementId: checkout.billingAccountId,
            contactRoleName: billingAddressToUpdate.contactRole,
            billingAddress: billingRequestData.customerInfo?.address,
            idType:
              billingRequestData.customerInfo?.personIdentificationDetails
                ?.type,
            idValue:
              billingRequestData.customerInfo?.personIdentificationDetails
                ?.docNumber
          })
        );
      }
    } else {
      const billingAddressRes = await dispatch(
        checkoutActions.createBillingAddressAction(
          billingRequestData
        ) as KeyValue
      );
      newBarId = billingAddressRes?.billingAccountRes?.billingArangmentId;
    }

    callback(newBarId);
  } else {
    callback();
  }
};
