import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';

import ChooseYourNumber from '../components/ChooseYourNumber';

import Auth from '../components/Auth';

import { authActions, userActions } from '@detox/actions';

import { ctSendEvent } from '../helpers/clicktale';
import { dtIdentifyUser } from '../helpers/dynatrace';
import getAemComponentByName from '../helpers/getAemComponentByName';
import isMobileSharePlan from '../helpers/is-mobile-share-plan';

import '../fragments/plan-detail';
import '../fragments/aem-components';

export const ChooseYourNumberPage = props => {
  const {
    data,
    authStatus,
    userInformation,
    cisInformation,
    setAuthStatus,
    selectedPlan,
    getCISMyInfo,
    getUserInformation,
    resetUser,
    userMyInfo
  } = props;

  const { allAemPages } = data;
  const [myInfoData, setMyInfoData] = useState(null);
  const isMyInfoLoading = Boolean(userMyInfo?.loading);
  const isUserInfoLoading = Boolean(userInformation?.loading);

  const siteConfigurations = getAemComponentByName(
    allAemPages,
    'SiteConfigurations'
  );

  const isMobileShareFlow = isMobileSharePlan(selectedPlan);

  const isWorryFreeEnabled =
    siteConfigurations && siteConfigurations.switches.DETOX_WORRY_FREE;

  const hasUserInfo = Boolean(userInformation);
  const isUserLoggedIn = Boolean(
    hasUserInfo && !userInformation?.anonymousUser
  );
  const isUserCis = Boolean(cisInformation?.$myInfo);
  const isCisUserLoginTypeMyInfo =
    isUserCis && Boolean(cisInformation?.$myInfo);

  /* Clear user data on first load. */
  useEffect(() => {
    resetUser();
  }, []);

  useEffect(() => {
    if (!userMyInfo) return;
    setMyInfoData({
      idNumber: userMyInfo.customerId,
      mobileNumber: userMyInfo.contactNumber,
      passType: userMyInfo.passType
    });
  }, [userMyInfo]);

  // CIS Customer session flow
  useEffect(() => {
    const cisUserInfo = cisInformation;

    // If logged in, send a Clicktale event
    if (userInformation && userInformation.clientContext) {
      userInformation.clientContext.customers.forEach(customer => {
        ctSendEvent('CustomerId', customer.customerId);
        dtIdentifyUser(customer.customerId);
      });
    }

    if (isCisUserLoginTypeMyInfo) {
      // CIS flow - Call myinfo to validate onepass account matched with myinfo details
      getCISMyInfo({
        sessionToken: cisUserInfo.$myInfo.accessToken
      });
    }
  }, [isCisUserLoginTypeMyInfo]);

  const isLoading = isUserInfoLoading || isMyInfoLoading;

  // Wait util myinfo validation is success before proceed to source selection.
  const chooseYourNumberOk = isCisUserLoginTypeMyInfo
    ? Boolean(myInfoData && !isLoading)
    : true;

  return (
    <div className="fs-unmask">
      <Auth
        loading={isLoading}
        key={JSON.stringify(myInfoData)}
        isAuthenticated={isUserLoggedIn}
        authStatus={authStatus}
        setAuthStatus={setAuthStatus}
        isWorryFreeEnabled={!isMobileShareFlow && isWorryFreeEnabled}
        worryFreeInitialFormData={myInfoData}
      >
        {chooseYourNumberOk ? <ChooseYourNumber data={data} /> : null}
      </Auth>
    </div>
  );
};

ChooseYourNumberPage.propTypes = {
  authStatus: PropTypes.string,
  userInformation: PropTypes.object,
  setAuthStatus: PropTypes.func,
  getCISMyInfo: PropTypes.func
};

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    authStatus: state.auth.status,
    userInformation: state.user.information,
    cisInformation: state.user.cis.information,
    userMyInfo: state.user.cis.information?.cusMyInfo,
    selectedPlan: state.plan.selectedPlan,
    userLoading: state.user?.loading
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { setAuthStatus, getCISMyInfo } = authActions;
  const { getUserInformation, resetUser } = userActions;
  return {
    setAuthStatus: status => dispatch(setAuthStatus(status)),
    getCISMyInfo: params => dispatch(getCISMyInfo(params)),
    resetUser: () => dispatch(resetUser()),
    getUserInformation: options => dispatch(getUserInformation(options))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseYourNumberPage);

export const query = graphql`
  {
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allAemPages(filter: { pageUrl: { eq: "/eshop/admin.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
    allAddonPreTick {
      edges {
        node {
          boIds
          id
          phoneList
          phoneSpecific
          planIds
          segment
          spsIds
          transactionBoIds {
            gaBoIds
            portinBoIds
            reconBoIds
          }
          transactionSpsIds {
            gaSpsIds
            portinSpsIds
            reconSpsIds
          }
          transactionSpecific
          type
        }
      }
    }
    allChooseSimOptions {
      edges {
        node {
          descWithDevice
          descWithoutDevice
          notAvailable
          footerWithDevice
          footerWithoutDevice
          footerNotAvailable
          simPrice {
            newSignup
            portIn
            recontract
          }
          simTitle
          simTypeId
          transactionType {
            newSignup
            portIn
            recontract
          }
        }
      }
    }
  }
`;
