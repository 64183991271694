import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { voucherActions } from '@detox/actions';
import { REQUEST_STATUS } from '../../constants';

const { resetReservedVouchers, getVouchers } = voucherActions;

export const useResetVoucher = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const allVouchers = useSelector(state => state.voucher?.vouchers);
  const userClientContext = useSelector(
    state => state.user?.information?.clientContext
  );
  const customerId = userClientContext?.customers?.[0]?.customerId;
  const contactId = userClientContext?.contact?.contactId;

  const getResetVoucherIds = () => {
    const cartVouchers = cart?.cartOrder?.vouchers;
    let resetVoucherIds = allVouchers
      .filter(vc => vc.redemptionDateX8)
      .map(item => item.idX8);

    if (cartVouchers?.length > 0) {
      resetVoucherIds = resetVoucherIds.filter(
        id => cartVouchers.findIndex(cv => cv?.idX8 === id) === -1
      );
    }

    return resetVoucherIds;
  };

  const manageVouchers = async resetIds => {
    try {
      await dispatch(
        resetReservedVouchers({
          customerId,
          contactId,
          voucherIds: resetIds
        })
      ).then(() => {
        dispatch(getVouchers({ customerId, contactId }));
      });
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const resetVoucher = async resetIds => {
      await manageVouchers(resetIds);
    };

    if (cart.status === REQUEST_STATUS.SUCCEEDED && allVouchers.length) {
      const resetIds = getResetVoucherIds();
      if (resetIds.length) {
        resetVoucher(resetIds);
      }
    }
  }, [cart.status, allVouchers.length]);
};

export default useResetVoucher;
