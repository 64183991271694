import { AuthState } from '../types/auth.types';
import { ACTION_TYPES } from '@detox/actions';

export const initialState: AuthState = {
  worryFree: {},
  headers: {}
};

const authReducers = (state = initialState, action): AuthState => {
  if (action.type === 'RESET_AUTH') {
    return initialState;
  }

  if (action.type === 'SET_UXF_AUTH_TOKEN') {
    return {
      ...state,
      sessionToken: action.payload
    };
  }

  if (action.type === 'SET_AUTH_STATUS') {
    return {
      ...state,
      status: action.payload
    };
  }

  if (action.type === 'SET_WORRY_FREE_STATUS') {
    return {
      ...state,
      worryFree: {
        ...state.worryFree,
        status: action.payload
      },
      isLoading: false
    };
  }

  if (action.type === 'RESET_WORRY_FREE_STATUS') {
    return {
      ...state,
      worryFree: {
        ...state.worryFree,
        status: null
      },
      isLoading: false
    };
  }

  if (action.type === 'SET_WORRY_FREE_DETAILS') {
    return {
      ...state,
      worryFree: {
        ...state.worryFree,
        ...action.payload,
        otpStartTime: action.payload.otpStartTime
      }
    };
  }

  if (action.type === 'RESET_WORRY_FREE') {
    return {
      sessionToken: state.sessionToken,
      worryFree: {}
    };
  }

  if (action.type === 'SET_WORRY_FREE_LOADING') {
    return {
      ...state,
      isLoading: action.payload
    };
  }

  if (action.type === ACTION_TYPES.AUTH.GET_ONEPASS_HEADER_LOADING) {
    return {
      ...state,
      isLoading: true
    };
  }
  if (action.type === ACTION_TYPES.AUTH.GET_ONEPASS_HEADER_FAILED) {
    return {
      ...state,
      isLoading: false
    };
  }
  if (action.type === ACTION_TYPES.AUTH.GET_ONEPASS_HEADER_SUCCESS) {
    return {
      ...state,
      headers: action.payload,
      isLoading: false
    };
  }

  return state;
};

export default authReducers;
