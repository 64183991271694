import {
  BridgeState,
  BridgeAction,
  RrpIppParam,
  RrpQueryParam
} from '../types/bridge.types';
import { REQUEST_STATUS, ORDER } from '../constants';
import { bridgeActions } from '@detox/actions';
const actionTypes = bridgeActions.BRIDGE_ACTIONS;
const { TYPE } = ORDER;
export const initialState: BridgeState = {
  loading: false,
  error: false
};
export const bridgeReducer = (state = initialState, action: BridgeAction) => {
  switch (action.type) {
    case actionTypes.SAVE_BRIDGE_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        status: REQUEST_STATUS.FAILED
      };
    }
    case actionTypes.SAVE_BRIDGE_DATA_LOADING: {
      return {
        ...state,
        loading: true,
        error: false,
        status: REQUEST_STATUS.PENDING
      };
    }
    case actionTypes.SAVE_BRIDGE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        status: REQUEST_STATUS.SUCCEEDED
      };
    }
    case actionTypes.RETRIEVE_BRIDGE_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        data: {}
      };
    }
    case actionTypes.RETRIEVE_BRIDGE_DATA_LOADING: {
      return {
        ...state,
        loading: true,
        error: false,
        data: {}
      };
    }
    case actionTypes.RETRIEVE_BRIDGE_DATA_SUCCESS: {
      function isRrpIppOrder(params: RrpQueryParam): params is RrpIppParam {
        return (params as RrpIppParam).productOrderID !== undefined;
      }

      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload,
          type: isRrpIppOrder(action.payload) ? TYPE.RRPIPP : undefined
        }
      };
    }
  }
  return state;
};
