import React, { useEffect, useRef } from 'react';
import { navigation } from '../../middlewares/navigation-constants';
import { navigate } from 'gatsby';
import { APP_TYPE_ANY } from '../../types/common.types';
export let isBackPressed = false;

export const setBackPress = (backPress: boolean): void => {
  isBackPressed = backPress;
};

export const useFlowCheck = ({
  flowData,
  triggerOnDependencyChanged = false,
  navigateUrl
}: {
  flowData: APP_TYPE_ANY[];
  triggerOnDependencyChanged?: boolean;
  navigateUrl?: string;
}): void => {
  const hasTriggered = useRef(false);

  useEffect(() => {
    if (hasTriggered.current && !triggerOnDependencyChanged) return;
    const hasData = flowData.every(data => {
      if (Array.isArray(data)) return data.length;
      if (data && typeof data === 'object') return Object.keys(data).length > 0;
      return Boolean(data);
    });
    hasTriggered.current = true;
    if (!hasData) {
      navigate(`/${navigation.NO_ACCESS}`, {
        replace: true,
        state: {
          url: navigateUrl
        }
      });
    }
  }, flowData);
};
