/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@wec-core/form-engine';
import { fulFillmentActions, authActions } from '@detox/actions';

import { trans as t } from '../../../helpers/localisation';
import {
  BillingPrefType,
  ErrorScenarioType,
  FormConfig,
  TObject
} from '../../../types/registrationCheckout';

import { getBillingGroup, getFormDataMapping, getSubmitGroup } from '../helper';

import {
  MyInfoFormState,
  getCISMyInfoReadOnlyPersonalData,
  getContactGroup,
  getFilteredExistingValues,
  getSalutationField,
  getSalutationListData,
  getTitleField,
  getValidationSchema,
  myInfoReadOnlyFields,
  getInitialValues,
  getUpdatedCISMyInfoValues
} from '../myinfoHelper';

import LoadingOverlayComponent from '../../LoadingOverlay';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useCheckOutHelper } from '../hooks/useCheckOutHelper';
import { getIfESimOrder } from '../../../selectors';

interface TProps {
  onValuesChange?: (values: TObject) => void;
  billingPrefData: BillingPrefType[];
  errorScenariosData?: ErrorScenarioType[];
  onErrorScenarioFound?: (values: TObject) => void;
  onFormSubmit?: (values: TObject, navigateTo: string) => void;
  goToOrderSummary: () => void;
  onGettingFormConfigs: (formConfigs: FormConfig) => void;
  handleCheckoutHistoryState?: (needPushState?: boolean) => void;
}

const titleField = getTitleField();
const salutationField = getSalutationField();
const submitGroup = getSubmitGroup(t as never);

const formData = {
  salutationList: getSalutationListData()
};

const CISMyInfoFlow: React.FC<TProps> = ({
  onValuesChange,
  billingPrefData,
  errorScenariosData,
  onErrorScenarioFound,
  onFormSubmit,
  goToOrderSummary,
  onGettingFormConfigs,
  handleCheckoutHistoryState
}): ReactElement => {
  const dispatch = useDispatch();
  const { getCISMyInfo } = authActions;
  const {
    addressInfo,
    checkout,
    cisInformation,
    fulfillment,
    orderSummary
  } = useSelector((state: TObject) => ({
    order: state.order,
    addressInfo: state.fulfillment?.addressInfo,
    checkout: state.checkout,
    cisInformation: state.user?.cis?.information,
    fulfillment: state.fulfillment,
    orderSummary: state.orderSummary
  }));
  const { renderError } = useErrorHandler({
    states: [checkout]
  });
  const { handleOnFormSubmit } = useCheckOutHelper({
    onErrorScenarioFound,
    errorScenariosData,
    billingPrefData,
    onFormSubmit,
    goToOrderSummary,
    onGettingFormConfigs
  });

  const [myInfoFormState, setMyInfoFormState] = useState<MyInfoFormState>({
    formValues: {},
    formInputsMapping: []
  });

  const { isLoading: addressLoading, address } = addressInfo || {};
  const { checkoutFormData = {}, isLoading } = checkout;
  const fulfilmentLoading = fulfillment?.delivery?.isLoading || false;
  const orderSummaryLoading = orderSummary?.isShowSkeletonLoader || false;
  const userMyInfo = cisInformation?.cusMyInfo;
  const isCISFlow = Boolean(cisInformation?.rates);
  const isMyInfoLoading = Boolean(userMyInfo?.loading);
  const isCisUserLoginTypeMyInfo = Boolean(cisInformation?.$myInfo);
  const billingPrefGroup = getBillingGroup(t as never, billingPrefData);

  const postalCode = userMyInfo?.address?.postal;
  const { contactNumber, email, userData } = getCISMyInfoReadOnlyPersonalData(
    userMyInfo
  );

  const setOverAllFormState = ({ contactNumber, email, userData }): void => {
    const infoReadOnlyFields = myInfoReadOnlyFields(userData);
    const formFieldsData = {
      title: titleField,
      salutation: salutationField,
      readOnlyFields: infoReadOnlyFields,
      contactGroup: getContactGroup({}),
      billingPref: billingPrefGroup,
      submitGroup: submitGroup
    };
    const filteredExistingValues = getFilteredExistingValues(checkoutFormData);
    const initialFormValues = {
      ...getInitialValues({
        contactNumber,
        email,
        confirmEmailRequired: true
      }),
      ...filteredExistingValues
    };

    setMyInfoFormState({
      formInputsMapping: formFieldsData,
      formValues: initialFormValues
    });
  };

  const getAddressInfoState = async (postalCode: string) => {
    await dispatch(fulFillmentActions.getAddressInfo(postalCode, true));
  };

  useEffect(() => {
    if (!userMyInfo && !userMyInfo?.customerId) {
      dispatch(
        getCISMyInfo({
          sessionToken: cisInformation?.$myInfo?.accessToken,
          isCIS: true
        })
      );
    }
  }, [isCisUserLoginTypeMyInfo, isCISFlow]);

  useEffect(() => {
    if (handleCheckoutHistoryState) {
      handleCheckoutHistoryState();
    }
    if (postalCode) {
      getAddressInfoState(postalCode);
    }
  }, []);

  useEffect(() => {
    if (userMyInfo && address?.postcode === postalCode) {
      setOverAllFormState({ contactNumber, email, userData });
    }
  }, [userMyInfo, address]);

  if (!postalCode) {
    return null;
  }

  // before rendering the form
  if (addressLoading || isMyInfoLoading) {
    return <LoadingOverlayComponent />;
  }

  const formattedFormFieldsData = getFormDataMapping(
    myInfoFormState?.formInputsMapping
  );

  return formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
    <div className="fs-mask">
      <Form
        enableReinitialize={false}
        formFieldsConfig={formattedFormFieldsData}
        initialValues={myInfoFormState.formValues}
        data={formData}
        callbacks={{}}
        onSubmit={values => {
          onValuesChange(values);

          const updatedValues = getUpdatedCISMyInfoValues(
            values,
            email,
            userMyInfo
          );
          handleOnFormSubmit(updatedValues, {
            passType: userData.passType,
            id: userData.passValue
          });
        }}
        validationSchema={getValidationSchema(userData.genderValue)}
        enableInputErrorFocus
      />

      {renderError()}

      {(isLoading || fulfilmentLoading || orderSummaryLoading) && (
        <LoadingOverlayComponent />
      )}
    </div>
  ) : null;
};
export default CISMyInfoFlow;
