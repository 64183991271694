import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Modal,
  Text,
  Spacing,
  Row,
  Column,
  useDevice
} from '@dls/web';

const SelectionModal = props => {
  const { open, onClose, data, footer } = props;
  const { isMobile } = useDevice();
  const { lhs, rhs } = data;
  const isBothHandSides = lhs !== undefined && rhs !== undefined;

  const handleButton = data => {
    return (
      <div className="fs-unmask">
        <Spacing bottom={2}>
          <Text type="header">{data.title}</Text>
        </Spacing>
        {data.buttons.map((item, index) => {
          return (
            <Spacing bottom={2}>
              <Button secondary fullWidth onClick={item.onButtonClick}>
                {item.buttonText}
              </Button>
            </Spacing>
          );
        })}
        {data.customButtons &&
          data.customButtons.map((E, index) => <E key={index} />)}
      </div>
    );
  };

  const renderModalContent = () => {
    if (isBothHandSides) {
      return (
        <Row>
          <Column sm={12}>{handleButton(lhs)}</Column>
          {!isMobile ? (
            <Spacing leftRight={2}>
              <Divider type="vertical" sm={false} md={1} />
            </Spacing>
          ) : (
            <Spacing top={2}>
              <Divider />
            </Spacing>
          )}
          <Column sm={12}>
            {handleButton(rhs)}
            {footer && <Spacing top={4}>{footer}</Spacing>}
          </Column>
        </Row>
      );
    }

    return (
      <Row>
        <Column sm={12} md={true}>
          {handleButton(lhs || rhs)}
          {footer && <Spacing top={4}>{footer}</Spacing>}
        </Column>
      </Row>
    );
  };

  return (
    <Modal visible={open} onClose={onClose} wide={isBothHandSides}>
      <Modal.Content>{renderModalContent()}</Modal.Content>
    </Modal>
  );
};

SelectionModal.defaultProps = {
  data: {
    lhs: {
      title: '',
      buttons: [],
      customButtons: []
    },
    rhs: {
      title: '',
      buttons: [],
      customButtons: []
    }
  }
};

const sideShape = PropTypes.shape({
  title: PropTypes.element || PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonText: PropTypes.element || PropTypes.string,
      onButtonClick: PropTypes.func
    })
  ),
  customButtons: PropTypes.arrayOf(PropTypes.func)
});

SelectionModal.propTypes = {
  data: PropTypes.shape({
    lhs: sideShape,
    rhs: sideShape
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default SelectionModal;
