import { useState, useEffect } from 'react';
import useInterval from '../useInterval';

const nearestThousand = t => Math.round(t / 1000) * 1000;
const reduceTime = t => t - 1000;

const calcTimeLeft = (durationInMs, startTimeInMs) => {
  const diffInMs = durationInMs - (Date.now() - startTimeInMs);
  return nearestThousand(Math.max(0, diffInMs));
};

/**
 * Accepts a duration for the timer to countdown
 * Returns the time left in the timer in milliseconds.
 *
 * @param {Number} durationInMs number of milliseconds for timer to count down
 * @param {Number} startTimeInMs number of milliseconds elapsed since January 1, 1970 00:00:00 UTC.
 */

const useTimer = (durationInMs, startTimeInMs) => {
  // if there is a startTime provided, calculate time left from that time,
  // else count down from the duration provided
  let initialDuration = startTimeInMs
    ? calcTimeLeft(durationInMs, startTimeInMs)
    : durationInMs;

  const [timeLeftInMs, setTimeLeftInMs] = useState(initialDuration);
  const delay = timeLeftInMs > 0 ? 1000 : null;

  useEffect(() => {
    if (startTimeInMs) {
      const timeLeft = calcTimeLeft(durationInMs, startTimeInMs);
      setTimeLeftInMs(timeLeft);
    }
  }, [durationInMs, startTimeInMs]);

  useInterval(() => {
    const newTimeLeftInMs = reduceTime(timeLeftInMs);
    if (newTimeLeftInMs >= 0) {
      setTimeLeftInMs(newTimeLeftInMs);
    }
  }, delay);

  return timeLeftInMs;
};

export default useTimer;
