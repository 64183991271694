import { format } from 'date-fns';
import { FULFILLMENT } from '../../constants';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

export const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: [],
  selectedTimeSlot: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LOGISTIC_APPOINTMENTS_SLOTS_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING
      };
    }
    case 'GET_LOGISTIC_APPOINTMENTS_SLOTS_SUCCESS': {
      const deliveryTimeslot = action.payload.slots.reduce((acc, cur) => {
        const slotDate = format(new Date(cur.slotStartDate), 'MM/dd/yyyy');

        if (slotDate in acc) {
          acc = { ...acc, [slotDate]: [...acc[slotDate], cur.timeDescription] };
        } else {
          acc = { ...acc, [slotDate]: [cur.timeDescription] };
        }

        return acc;
      }, {});

      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: deliveryTimeslot
      };
    }
    case 'SELECT_APPOINTMENTS_TIME_SLOT': {
      return {
        ...state,
        selectedTimeSlot: action.payload
      };
    }
    case 'SET_DELIVERY_MODE':
    case 'RESET_SELECTED_LOGISTIC_TIME_SLOT': {
      return {
        ...state,
        selectedTimeSlot: undefined
      };
    }
    default: {
      return state;
    }
  }
};
