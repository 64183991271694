import React from 'react';
import { ProductCatalogue as ProductCatalogueModule } from '@common-modules/product-catalogue';
import SEO from '../components/SEO';

export const RRPProductCatalog = () => {
  return (
    <>
      <SEO description="" title="Product Catalog" />
      <ProductCatalogueModule />
    </>
  );
};

export default RRPProductCatalog;
