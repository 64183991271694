/**
 * Check if a quantity is unlimited
 * @param {string} quantity
 * @returns {boolean}
 */
export const isUnlimited = quantity =>
  new RegExp(/[U|u]nlimited/).test(quantity);

/**
 *
 * @param {object} talktime
 * @param {object} sms
 * @returns {string} Unlimited Talktime & SMS
 */
const unlimitedTalktimeSms = ({ talktime, sms }) => {
  if (isUnlimited(talktime.quantity) && isUnlimited(sms.quantity)) {
    return `Unlimited Talktime & SMS`;
  }

  return `${talktime.quantity}${talktime.unit} Talktime & ${sms.quantity}${sms.unit} SMS`;
};

export default unlimitedTalktimeSms;
