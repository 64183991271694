import { FieldObject } from '../../../types/registrationCheckout';

export const existingNewInitialState = {
  formInputsMapping: {},
  formValues: {},
  addNewAddressModalOpen: false,
  formConfigs: {},
  valuesChangeOnDemand: []
};

export const getExistingNewFlowNonPerIniValues = (
  isCisFlow: boolean
): FieldObject => {
  let nonPersonalInitialValues: FieldObject = {
    nricFrontUpload: null,
    nricBackUpload: null,
    proofUpload: null,
    kycUpload: null,
    billingPreference: '',
    termsCheck: '',
    billingAddress: 0,
    newBillingAddress: ''
  };

  if (isCisFlow) {
    nonPersonalInitialValues = {
      ...nonPersonalInitialValues,
      staffPassUpload: null
    };
  }

  return nonPersonalInitialValues;
};
