import { useEffect, useState } from 'react';

export const useGatsbyInit = (): { isInit: boolean } => {
  const [isInit, setInit] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 300);
  }, []);
  return { isInit };
};
