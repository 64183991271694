import React, { ReactElement } from 'react';
import SEO from '../components/SEO';
import { Fulfillment } from '../components/Fulfilment';
import { graphql } from 'gatsby';
import { TObject } from '../types/registrationCheckout';

interface TProps {
  data: TObject;
}

export const OrderFulfillment: React.FC<TProps> = ({ data }): ReactElement => {
  return (
    <>
      <SEO title="Fulfillment" description={null} />
      <Fulfillment data={data} />
    </>
  );
};

export const query = graphql`
  query OrderFulfillment {
    fulfilment {
      id
      mobileFulfilmentSlotType
      backOrderLinerForFulfilment
      backOrderLinerForProductDetails
      preOrderLinerForFulfilment
      preOrderLinerForProductDetails
      endEApptTimeInHours
      leadDays {
        sku
        firstDeliveryDate
        lastDeliveryDate
        deliveryPeriod
      }
      leadDaysForCollectAtStore {
        sku
        firstDeliveryDate
        lastDeliveryDate
        deliveryPeriod
      }
      fulfilmentList {
        fulfilmentId
        tags
        mobileFulfilment {
          title
          desc
          price
          ribbon
          liner
          icon
          deliveryLinerWithDevice
          deliveryLinerWithoutDevice
          offsetWithDocument
          offsetWithoutDocument
          maxAllowedItems
          enabledForBackOrder
          enabledForPickupType
          enableUploadDocument
          enabledForTradeIn
          enabledForAccessories
          enabledForFreeGifts
          enabledForHandset
          enabledForSIM
          transactionType {
            newNewNumber
            newNewPortin
            existingNewNumber
            existingNewPortin
            recon
          }
        }
      }
    }
    allStoreIDs {
      edges {
        node {
          name
          mtposId
          storeType
          postalCode
          country
          zoneId
          nearestMrt
          weekdays
          weekends
          qnomyServiceId
          shopType
          id
          street
          building
          zone
          lat
          lon
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
  }
`;

export default OrderFulfillment;
