import { APP_TYPE_ANY, KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '@detox/actions';
import { REQUEST_STATUS } from '../constants';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';

const {
  RRP_ORDER_SUMMARY_LOADING,
  RRP_ORDER_SUMMARY_SUCCESS,
  RRP_ORDER_SUMMARY_ERROR,
  RRP_PROMO_CODE_LOADING,
  RRP_PROMO_CODE_SUCCESS,
  RRP_PROMO_CODE_ERROR,
  RRP_PAYMENT_REQUEST_LOADING,
  RRP_PAYMENT_REQUEST_SUCCESS,
  RRP_PAYMENT_REQUEST_ERROR
} = ACTION_TYPES.RRP_ORDER_SUMMARY;

type RrpOrderSummaryState = {
  status: string;
  orderSummary?: APP_TYPE_ANY;
  promoCodeResult?: APP_TYPE_ANY;
  paymentRequestResult?: APP_TYPE_ANY;
  skeletonLoading: boolean;
};

type RrpOrderSummaryAction = {
  type: string;
  value?: KeyValue;
  payload?: KeyValue;
};

const initialState: RrpOrderSummaryState = {
  status: REQUEST_STATUS.IDLE,
  orderSummary: undefined,
  promoCodeResult: undefined,
  paymentRequestResult: undefined,
  skeletonLoading: false
};

export const rrpOrderSummaryReducer = (
  state: RrpOrderSummaryState = initialState,
  action: RrpOrderSummaryAction
) => {
  switch (action.type) {
    case RRP_ORDER_SUMMARY_LOADING: {
      return {
        ...state,
        skeletonLoading: true
      };
    }
    case RRP_PAYMENT_REQUEST_LOADING:
    case RRP_PROMO_CODE_LOADING:
      return {
        ...state,
        status: REQUEST_STATUS.PENDING
      };

    case RRP_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        orderSummary: action.value,
        skeletonLoading: false
      };

    case RRP_PROMO_CODE_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        promoCodeResult: action.value
      };

    case 'RESET_RRP_PROMO_CODE':
      return {
        ...state,
        promoCodeResult: undefined
      };

    case RRP_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        paymentRequestResult: action.value
      };

    case RRP_PAYMENT_REQUEST_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        paymentRequestResult: undefined
      };
    case RRP_ORDER_SUMMARY_ERROR:
      return {
        ...initialState,
        status: REQUEST_STATUS.FAILED,
        skeletonLoading: false
      };

    case RRP_PROMO_CODE_ERROR: {
      const { promoCode } = action.value;

      if (promoCode === 'CLEAR') {
        action.value.promoCode = state.promoCodeResult.promoCode;
      }

      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        promoCodeResult: action.value
      };
    }

    case SHOP_ACTION_TYPES.RRP.RRP_CLEAR_ORDER_DATA:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
