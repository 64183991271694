import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Grid, Row, Button, Layout, Text, Divider } from '@dls/web';
import Sticky from 'react-sticky-el';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY } from '../../../types/common.types';
import {
  StickyImageContainer,
  StyledBackgroundDiv,
  StyledStickyButtonLayout,
  StyledStickyColumns,
  StyledStickyProductLayout
} from '../addonStyles';

interface Props {
  productImage: APP_TYPE_ANY;
  productIcon: APP_TYPE_ANY;
  productPrimaryText: string;
  productSecondaryText: string;
  ctaText: string;
  preOrderText: string;
  onCtaClick: () => void;
}

const StyledSticky = styled(props => <Sticky {...props} mode="top" />)`
  position: relative;
  z-index: 9;
`;

const StickyCartSummary = (props: Props): ReactElement => {
  const {
    productImage,
    productIcon: ProductIcon,
    productPrimaryText,
    productSecondaryText,
    ctaText,
    onCtaClick,
    preOrderText = ''
  } = props;

  return (
    <StyledSticky>
      <StyledBackgroundDiv>
        <Grid>
          <Row>
            <StyledStickyColumns xs={12} sm={6} md={6}>
              <StyledStickyProductLayout
                alignY="center"
                align="center"
                space={1}
              >
                <StickyImageContainer>
                  {productImage && (
                    <Img
                      image={productImage.childImageSharp.gatsbyImageData}
                      alt={productPrimaryText}
                    />
                  )}

                  {!productImage && ProductIcon && (
                    <ProductIcon width={55} height={55} />
                  )}
                </StickyImageContainer>
                <Layout.Stack alignY="top" align="left" space={0}>
                  {preOrderText && (
                    <Text type="smallBody">{t('PRE-ORDER')}</Text>
                  )}

                  {productPrimaryText && (
                    <Text type="smallBody">{productPrimaryText}</Text>
                  )}
                  {productSecondaryText && (
                    <Text type="smallBody">{productSecondaryText}</Text>
                  )}
                </Layout.Stack>
              </StyledStickyProductLayout>
            </StyledStickyColumns>
            <StyledStickyColumns xs={12} sm={6} md={6}>
              <StyledStickyButtonLayout align="center" space={2}>
                {ctaText ? (
                  <Button
                    onClick={onCtaClick}
                    data-testid="sticky-proceed-to-cart"
                  >
                    {ctaText}
                  </Button>
                ) : null}
              </StyledStickyButtonLayout>
            </StyledStickyColumns>
          </Row>
        </Grid>
      </StyledBackgroundDiv>
      <Divider />
    </StyledSticky>
  );
};

export default StickyCartSummary;
