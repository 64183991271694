import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Column } from '@dls/web';
import { rrpCartActions } from '@detox/actions';

import { KeyValue } from '../../../types/common.types';
import { ACTION_TYPES } from '../../../constants/actions';

import Delivery from '../../../components/RRPFulfilment/Delivery';

import '../../../fragments/rrp-product';

const RrpFulfilment = (props: { data: KeyValue }): React.ReactElement => {
  const dispatch = useDispatch();
  const { retrieveRrpMasterOrderDetails } = rrpCartActions;
  const { data } = props;
  const { rrpCheckout, orderDetails } = useSelector<
    {
      cart: KeyValue;
      rrpCheckout: KeyValue;
    },
    KeyValue
  >(state => ({
    rrpCheckout: state?.rrpCheckout,
    orderDetails: state?.cart?.rrpOrder
  }));

  useEffect(() => {
    dispatch({
      type: ACTION_TYPES.RRP.RESET_FULFILMENT_STATE
    });
    dispatch(
      retrieveRrpMasterOrderDetails({
        rrpMasterOrderId: rrpCheckout.rrpMasterOrderId
      })
    );
  }, []);

  return (
    <>
      {orderDetails && (
        <Row>
          <Column>
            <Delivery data={data} orderDetails={orderDetails} />
          </Column>
        </Row>
      )}
    </>
  );
};

export const query = graphql`
  query MyQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
    rrpFulfilment {
      id
      backOrderLinerForFulfilment
      backOrderLinerForProductDetails
      preOrderLinerForFulfilment
      preOrderLinerForProductDetails
      endEApptTimeInHours
      leadDays {
        sku
        firstDeliveryDate
        lastDeliveryDate
        deliveryPeriod
      }
      fulfilmentList {
        fulfilmentId
        tags
        fulfilment {
          title
          desc
          price
          ribbon
          liner
          icon
          deliveryLinerWithDevice
          deliveryLinerWithoutDevice
          offsetWithDocument
          offsetWithoutDocument
          maxAllowedItems
          enabledForBackOrder
          enabledForPickupType
          enableUploadDocument
          enabledForTradeIn
          enabledForAccessories
          enabledForFreeGifts
          enabledForHandset
          enabledForSIM
        }
      }
    }
  }
`;

export default RrpFulfilment;
