declare type MaskerType = (value: string) => string;

export function mask(str: string, maskerFn?: MaskerType): string {
  return maskerFn ? maskerFn(str) : defaultMasker(str);
}

function defaultMasker(str: string) {
  // First digit should be 8 or 9.
  // followed by 7 digits
  // Mask first 4 digits.
  return str.replace(/([8,9]\d{7})/g, (substring, ...args) => {
    return '****' + substring.substr(4);
  });
}
