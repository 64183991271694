import {
  getBillingGroup,
  getDocumentsUploadGroup,
  getDocumentTitleGroup,
  getFormSpacing,
  getNRICGroup,
  getPersSectionDivider,
  getStaffPassUploadGroup,
  getSubmitGroup,
  getTitleAndInfoGroup
} from '../helper';
import { getFormConfigForReconFlow } from './helpers';
export const getFormData = ({
  t,
  checkoutFormDocumentData,
  billingPrefData,
  verificationData,
  isCisFlow,
  isCISMyInfoFlow,
  device,
  hasSimCard = false,
  hasAccessories = false
}) => {
  const preNricFront =
    checkoutFormDocumentData && checkoutFormDocumentData['nricFrontUpload'];
  const preNricBack =
    checkoutFormDocumentData && checkoutFormDocumentData['nricBackUpload'];
  const preStaff =
    checkoutFormDocumentData && checkoutFormDocumentData['staffPassUpload'];
  const titleAndInfoGroup = getTitleAndInfoGroup(t);
  const documentsTitleGroup = getDocumentTitleGroup(t, false);
  // file upload groups
  const documentsUploadGroup = getDocumentsUploadGroup(
    t,
    !!preNricFront,
    !!preNricBack
  );
  const staffPassUploadGroup = getStaffPassUploadGroup(t, !!preStaff);
  const persSectionDivider = getPersSectionDivider();
  const nricGroup = getNRICGroup(t, verificationData);
  const formData = {};
  const getNonPersonalGroup = ({
    isEBill,
    isNricSilver = false,
    isNric = false,
    simType
  }: {
    isEBill: boolean;
    isNricSilver?: boolean;
    isNric?: boolean;
    simType: string;
  }) => {
    const flowConfigs = getFormConfigForReconFlow({
      device,
      isCis: isCisFlow,
      isCISMyInfoFlow,
      isNric,
      eBill: isEBill,
      hasSimCard,
      isNricSilver,
      hasAccessories,
      simType
    });
    const billingPrefGroup = flowConfigs.showBillingPref
      ? getBillingGroup(t, billingPrefData, [], !isEBill)
      : null;
    const buttonText = flowConfigs.byPassFulfilment
      ? t('CO_NN_PROCEED_ORDER_SUMMARY_BTN')
      : t('CO_NN_PROCEED_BTN');
    const submitGroup = getSubmitGroup(t, false, false, buttonText);
    return {
      groups: {
        documentsTitle:
          flowConfigs.showNricFIN || flowConfigs.showStaffUploadDocument
            ? documentsTitleGroup
            : null,
        documents: flowConfigs.showNricFIN ? documentsUploadGroup : null,
        staffPass: flowConfigs.showStaffUploadDocument
          ? staffPassUploadGroup
          : null,
        persSectionDivider:
          (flowConfigs.showNricFIN || flowConfigs.showStaffUploadDocument) &&
          flowConfigs.showBillingPref
            ? persSectionDivider
            : null,
        billingPref: billingPrefGroup,
        submit: submitGroup
      },
      groupConfigs: {
        byPassDocUpload: flowConfigs.byPassDocUpload,
        byPassFulfilment: flowConfigs.byPassFulfilment,
        showNricFIN: flowConfigs.showNricFIN,
        showBillingPref: flowConfigs.showBillingPref,
        showStaffUploadDocument: flowConfigs.showStaffUploadDocument
      }
    };
  };

  return {
    getNonPersonalGroup,
    titleAndInfoGroup,
    nricGroup,
    documentsUploadGroup,
    staffPassUploadGroup,
    formData
  };
};
