export const replaceString = (
  text: string,
  injectStrings: string[] = []
): string => {
  if (!text || !injectStrings || !injectStrings.length) return text;
  return injectStrings.reduce((result: string, injectString, index) => {
    result = result.replace(`{${index}}`, injectString);
    return result;
  }, text);
};

export const getParamsStringFromParamsObject = (paramsObject?: {
  [key: string]: string;
}): string => {
  if (!paramsObject) return '';
  return Object.entries(paramsObject)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};

export const ucfirst = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
