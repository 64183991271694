import React from 'react';
import { Row, Column, Modal, Button, Spacing, Text } from '@dls/web';

type TProps = {
  header: string;
  message: string;
  visible: boolean;
  ctaText: string;
  onClose(): void;
  onButtonClick(): void;
};

export const CollectAtStoreModal: React.FC<TProps> = ({
  header,
  message,
  visible,
  ctaText,
  onClose,
  onButtonClick
}) => {
  return (
    <Modal
      visible={visible}
      title={header}
      onClose={onClose}
      bgColor="white"
      wide={true}
    >
      <Modal.Content>
        <Row>
          <Column sm={12} md={12}>
            <Spacing bottom={3}>
              <Text
                type="bannerTitle"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            </Spacing>
          </Column>
        </Row>
        {ctaText && <Button onClick={onButtonClick}>{ctaText}</Button>}
      </Modal.Content>
    </Modal>
  );
};
