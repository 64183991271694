import { SkuVariant } from './product.types';
import { SelectedPlan } from './shoppingCart.types';
import { KeyValue } from './common.types';
import { Order, ProductOrder } from './order';
import { SelectedAccessory } from './accessory.types';
import { OrderItemDetail } from '@detox/actions';

export interface DataLayer extends KeyValue {
  event: string;
  event_name: string;
  event_category: string;
  event_label: string;
  event_action: EventActions;
  page_url_without_query_string_parameters: string;
  sim_card_type: string;
  lob: string;
  network_line_type: FlowType;
  ecommerce: Ecommerce;
}

interface Ecommerce {
  currency: string;
  value: number;
  units: number;
  bag: number;
  item: EcommerceItem[];
}
export enum Category {
  ACCESSORY = 'accessory',
  NA = 'NA'
}

interface EcommerceItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  item_category?: string;
  item_category2?: DATA_LAYER_FLOWS;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  sim_card_type?: SimCartType;
  item_variant: string;
  item_colour: string;
  item_size: string;
  price: number;
  quantity: number;
  item_plan_selected?: string;
}

export enum FlowType {
  RECON = 'recontract',
  NEW = 'new-number',
  PORT_IN = 'port-in',
  GOMO = 'gomo',
  RRP = 'rrp',
  RRP_IPP = 'rrp-ipp',
  FULL_PRICE = 'full-price',
  IPP = 'ipp'
}

export enum Event {
  ADD_TO_CART = 'add-to-cart',
  CHECKOUT = 'checkout',
  PROCEED_TO_DELIVER = 'proceed-to-delivery',
  ORDER_FULFILMENT = 'order-fulfilment',
  ORDER_SUMMARY = 'order-summary',
  PURCHASE = 'purchase',
  RRP_CUSTOMER_REGISTRATION = 'rrp-customer-registration',
  ORDER_CONFIRMATION = 'order-confirmation'
}

export enum EventLabel {
  BUY_NOW = 'buy-now',
  ADD_TO_CART = 'add-to-cart',
  CHECKOUT = 'checkout',
  GOT_IT = 'ok-got-it',
  REVIEW_ORDER = 'review-order',
  PROCEED = 'proceed',
  ORDER_RECEIVED = 'order-received'
}

export enum EventName {
  ORDER_INITIATE = 'order-initiate',
  ORDER_COMPLETE = 'order-complete',
  SELECT_NETWORK_LINE_TYPE = 'select-network-line-type',
  CHECKOUT = 'checkout',
  RRP_CUSTOMER_REGISTRATION = 'rrp-customer-registration',
  ORDER_FULFILMENT = 'order-fulfilment',
  ORDER_SUMMARY = 'order-summary',
  ORDER_CONFIRMATION = 'order-confirmation'
}

export enum EventActions {
  BUTTON_CLICK = 'button-click'
}

export enum EventCategory {
  CONTENT_CLICK = 'content-click',
  HEADER_CLICK = 'header-click',
  PAGE_LOAD = 'page-load'
}

export enum SimCartType {
  ESIM = 'esim',
  PHYSICAL_SIM = 'physical-sim-card',
  NO_NEED = 'i-dont-need-a-physical-sim-car-or-esim'
}

export enum DATA_LAYER_FLOWS {
  PHONE_PLAN_WITH_DEVICE = 'mobile-device-with-phone-plans',
  SOP_PLAN_DEVICE = 'mobile-device-with-sop-plans',
  ON_LY_DEVICE = 'mobile-device-without-plans',
  NA = 'NA'
}

export interface DataLayerBaseParams {
  selectedVariant?: Partial<SkuVariant>;
  location: Partial<Location>;
  selectedPlan?: Partial<SelectedPlan>;
  order?: Partial<ProductOrder>;
  accessories?: Partial<SelectedAccessory>[];
  flow?: FlowType;
  rrpFlow?: boolean;
  cartItems?: OrderItemDetail[];
  ippDetails?: IppDetails;
  orderModel?: OrderModel[];
  delivery?: {
    type: string;
  };
}

export interface IppDetails {
  selectedPaymentTerm: { description: string };
}

export interface DataLayerFlowParams {
  event?: string;
  event_name?: string;
  event_label?: string;
  event_category?: EventCategory;
  event_action?: string;
}

export interface OrderModel {
  methods: {
    pickupOptions: [{ type: string }];
  };
  items: {
    list: [{ id: string }];
  };
}
