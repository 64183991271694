import { CisInformation, CusMyInfo } from '../types/user.types';
import idTypes from '../config/id-types.json';
import { convertToSGNumber } from '../components/Checkout/helper';

export const isCisMyInfoAvailable = (cisInformation: CisInformation) => {
  const isCISFlow = Boolean(cisInformation?.rates);
  const isCisUserLoginTypeMyInfo = Boolean(cisInformation?.$myInfo);
  return (
    isCISFlow && isCisUserLoginTypeMyInfo && Boolean(cisInformation?.cusMyInfo)
  );
};

export const getUserMyInfo = (userMyInfo: CusMyInfo) => {
  const { customerId, passType, contactNumber } = userMyInfo;
  const idPrefix = ('' + customerId)[0];
  const filteredPassType = idTypes
    .filter(({ prefixes }) => prefixes.includes(idPrefix))
    .find(idType => idType?.passTypeCode?.includes(passType));
  return {
    id: customerId,
    passType: filteredPassType?.value || '',
    mobileNumber: convertToSGNumber(contactNumber),
    mobileNumberWithoutFormat: contactNumber
  };
};
