import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUrlParams } from '@lux/helpers';
import { ACTION_TYPES } from '../../constants/actions';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { TOMO_PHASE_TWO } from '../../types/featureFlag.types';
import { APP_TYPE_ANY } from '../../types/common.types';

const { UPDATE_INDICATOR, UPDATE_INDICATOR_SUCCESS } = ACTION_TYPES.THIRD_PARTY;

export const useThirdPartyIndicator = (indicators: string[]) => {
  const dispatch = useDispatch();
  const thirdParty = useSelector(
    (state: { [key: string]: APP_TYPE_ANY }) => state.thirdParty
  );
  const { indicator: thirdPartyInd } = thirdParty || {};

  useEffect(() => {
    if (isFeatureFlagEnabled(TOMO_PHASE_TWO)) {
      const params = getUrlParams(window.location.search);

      let temp: { [key: string]: APP_TYPE_ANY } = indicators.reduce(
        (acc: APP_TYPE_ANY, cur: string) => ({
          ...acc,
          [cur]: thirdPartyInd[cur] || undefined
        }),
        {}
      );

      indicators?.forEach((key: string) => {
        if (params.hasOwnProperty(key)) {
          temp[key] = params[key];
        }
      });

      if (Object.keys(temp).length) {
        dispatch({
          type: UPDATE_INDICATOR,
          payload: temp
        });
      }
    }
    const params = getUrlParams(window.location.search);

    let temp: { [key: string]: APP_TYPE_ANY } = indicators.reduce(
      (acc: APP_TYPE_ANY, cur: string) => ({
        ...acc,
        [cur]: thirdPartyInd[cur] || undefined
      }),
      {}
    );

    indicators?.forEach((key: string) => {
      if (params.hasOwnProperty(key)) {
        temp[key] = params[key];
      }
    });

    if (Object.keys(temp).length) {
      dispatch({
        type: UPDATE_INDICATOR,
        payload: temp
      });
    }

    /** Reset the Tomo Error in the store */
    dispatch({
      type: UPDATE_INDICATOR_SUCCESS
    });
  }, []);

  const hasIndicator = indicators?.every(ind => thirdPartyInd[ind]);

  return { hasIndicator, indicator: thirdPartyInd };
};

export default useThirdPartyIndicator;
