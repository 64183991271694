import React from 'react';
import { graphql } from 'gatsby';
import GenericError from '../components/ShoppingCart/GenericError';
import { getErrorMessage, flattenNodes } from '../helpers/common';
import { ERROR_CODES } from '../types/common.types';

export const Error = ({ data }) => {
  const allErrorMessagesFlattened = flattenNodes(data.allErrorMessage);
  const genericErrorMessage = getErrorMessage(
    ERROR_CODES.GENERIC,
    allErrorMessagesFlattened
  );
  if (!genericErrorMessage) return null;
  return (
    <GenericError
      title={genericErrorMessage.title}
      errorText={genericErrorMessage.description}
      primaryButtonText={genericErrorMessage.ctaText}
      onPrimaryClick={() => {
        window.location.replace(genericErrorMessage.ctaURL);
      }}
      footerList={genericErrorMessage.footerLinks}
    />
  );
};

export const query = graphql`
  query ErrorQuery {
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
  }
`;

export default Error;
