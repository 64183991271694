import React, { ReactElement } from 'react';
import {
  Spacing,
  Text,
  Button,
  TextLink,
  Layout,
  Card,
  Ribbon,
  Divider
} from '@dls/web';
import { trans as t } from '../../../helpers/localisation';
import {
  getAddonPriceText,
  getAddonTestId,
  getAddonThumbnailConfig,
  getPromotionText,
  getSmallestPrice
} from '../addon-helper';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';

import {
  StyledCardContent,
  StyledDiv,
  StyledSpacing,
  StyledStackSpacing,
  StyledTruncatedDiv
} from '../addonStyles';
import { useToggleReadMore } from '../useToggleReadMore';

interface TProps extends KeyValue {
  group: KeyValue;
  groupInfo: KeyValue;
}

const renderPromoRibbon = ({ promoDescription, promotionText, atomic }) => {
  const promoRibbonText = getPromotionText({
    promoDescription,
    promotionText,
    atomic
  });

  if (!promoRibbonText) {
    return null;
  }

  return (
    <Spacing bottom={1}>
      <Ribbon.Variant1>{promoRibbonText}</Ribbon.Variant1>
    </Spacing>
  );
};

const AddonsGroupListItem = (props: TProps): ReactElement => {
  const { toggleReadMore, refItem } = useToggleReadMore();
  const { group, groupInfo, onGroupAdd, onRemove, onLearnMore } = props;

  const { shortDescription = '' } = groupInfo || {};
  const { addons = [], groupName = '' } = group || {};
  const groupAddonAdded = addons.find(({ disabled }) => disabled);
  const addedAddon = groupAddonAdded
    ? addons.find(({ disabled }) => !disabled)
    : null;
  const {
    price,
    onetimePrice,
    contract,
    promoDescription,
    promotionText,
    atomic
  } = addedAddon || {};
  const smallestValue = getSmallestPrice(addons);
  const contractTxt = getAddonPriceText({ price, onetimePrice });

  // TODO: remove the type casting - use FnArgumentType
  const thumbnailConfig = getAddonThumbnailConfig({
    groupInfo
  } as APP_TYPE_ANY);

  const dataTestID = getAddonTestId(groupName);
  const btnProps = groupAddonAdded?.disabled
    ? {
        secondary: false,
        loading: 'success',
        text: 'Success',
        onClickEvent: () => {
          onRemove(addedAddon);
        },
        'data-testid': 'remove-' + dataTestID
      }
    : {
        text: t('ADD'),
        onClickEvent: () => {
          onGroupAdd({ group, groupInfo });
        },
        'data-testid': 'add-' + dataTestID
      };

  return (
    <Card shadow="sm" radius="sm" padding="sm">
      <StyledCardContent>
        <StyledSpacing responsive={false}>
          <StyledDiv>
            {renderPromoRibbon({ promoDescription, promotionText, atomic })}
            <Card.Title {...thumbnailConfig}>
              <Text type="header">{groupName}</Text>
              {!groupAddonAdded && (
                <Text type="smallBody">
                  {t('ADDON_MULTIPLE_CONTRACT') as string}
                </Text>
              )}
            </Card.Title>

            <Spacing top={1}>
              <>
                <StyledTruncatedDiv ref={refItem}>
                  <Text type="body">{shortDescription}</Text>
                </StyledTruncatedDiv>

                {toggleReadMore && (
                  <Spacing top={1}>
                    <TextLink
                      inline
                      onClick={() => {
                        onLearnMore({ group, groupInfo }, true);
                      }}
                      data-testid={'learn-' + dataTestID}
                    >
                      {t('ADDON_LEARN_MORE')}
                    </TextLink>
                  </Spacing>
                )}
              </>
            </Spacing>
          </StyledDiv>

          <Spacing top={2}>
            <Divider />
            <Card.Footer
              rightContent={
                <div onClick={btnProps.onClickEvent}>
                  <Button secondary {...btnProps} />
                </div>
              }
            >
              <StyledStackSpacing>
                <Layout.Stack align="left" alignY="middle" space={0}>
                  {price ? (
                    <Text type="body" data-testid="group-added-contractTxt">
                      {contractTxt}
                    </Text>
                  ) : (
                    <Text type="body" data-testid="group-added-smallestValue">
                      {smallestValue}
                    </Text>
                  )}
                  {groupAddonAdded && contract && contract !== '0' && (
                    <Text
                      type="body"
                      data-testid="group-added-mth-contract"
                    >{`${contract}-mth contract`}</Text>
                  )}
                </Layout.Stack>
              </StyledStackSpacing>
            </Card.Footer>
          </Spacing>
        </StyledSpacing>
      </StyledCardContent>
    </Card>
  );
};

export default AddonsGroupListItem;
