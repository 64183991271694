import React from 'react';
import { Modal, Button, Text, Spacing, Row, Column } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { ADDON } from '../../constants';

interface Props {
  isModalOpen: boolean;
  addon: KeyValue;
  onClose: () => void;
  onProceed: (addon: APP_TYPE_ANY) => void;
}

const messageMappingIDs = {
  [ADDON.REMOVE_ACK_ADDONS.SMS_BARRING]: t('SMS_BARRING_REMOVAL_MSG'),
  [ADDON.REMOVE_ACK_ADDONS.CALL_BARRING]: t('CALL_BARRING_REMOVAL_MSG')
};

export const AddonRemoveAckModal: React.FC<Props> = props => {
  const { isModalOpen, addon, onClose, onProceed } = props;
  const addonsCID = addon?.productSpecPricing?.childPricingSchema?.id || '';

  const message = messageMappingIDs[addonsCID];

  return (
    <Modal
      data-testid={`addon-modal-remove`}
      visible={isModalOpen}
      title={t('ADDON_REMOVAL_TITLE') as string}
      closable={true}
      onClose={onClose}
      wide={true}
    >
      <Modal.Content>
        {message && <Text type="body">{message}</Text>}

        <Spacing top={2}>
          <Row>
            <Column xs={12} sm={4} noGutter>
              <Button
                fullWidth={true}
                primary
                data-testid="remove-okay-button"
                text={t('OKAY_GOT_IT')}
                onClick={() => {
                  onProceed(addon);
                }}
              />
            </Column>
          </Row>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default AddonRemoveAckModal;
