import { useState, useEffect } from 'react';

declare global {
  interface Window {
    ReactNativeWebView: unknown;
  }
}

const useIsWebViewFlow = () => {
  const [isClient, setClient] = useState(false);
  const [isWebView, setWebViewState] = useState(false);
  const flow = isWebView ? 'InAppWebViewFlow' : 'WebFlow';
  useEffect(() => {
    const isWebViewFlow = !!(
      typeof window !== 'undefined' && window?.ReactNativeWebView
    );
    setWebViewState(isWebViewFlow);
    setClient(true);
  }, []);

  return { isWebView, isClient, flow };
};

export default useIsWebViewFlow;
