/* istanbul ignore file */
export default {
  TYPE: {
    NEW: 'NEW',
    RECON: 'RECON',
    PORTIN: 'PORT-IN',
    MS_NEW: 'MS_NEW',
    MS_RECONTRACT: 'MS_RECONTRACT',
    RRPIPP: 'RRPIPP'
  },
  EKYC_FLOW_TYPE: {
    RECON: 'Recon',
    MS_RECONTRACT: 'Recon',
    'PORT-IN': 'PortIn',
    NEW: 'New',
    MS_NEW: 'New'
  },

  EQUIPMENT_TYPES: {
    DEVICE: 'Device',
    ACCESSORY: 'Accessory',
    FREEBIE: 'Freebie'
  },
  ORDER_TYPE: {
    CHANGE: 'Change',
    PROVIDE: 'Provide'
  },
  PAYMENT_TYPES: {
    CASH: 'pay-by-cash',
    ONLINE: 'pay-online',
    NO_PAYMENT: 'no-payment'
  },
  AEM_PAYMENT_TYPES: {
    CASH: 'cod',
    ONLINE: 'payonline'
  },
  PAYMENT_METHOD_TYPES: {
    COD: 'cod',
    ONLINE: 'CC'
  },
  STATUS: {
    SUCCESS: 'success',
    FAILURE: 'failure',
    PENDING: 'pending',
    CREDIT_FAILED: 'credit_failed'
  },
  UPDATE_STATUS: {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILED',
    PENDING: 'PENDING'
  },

  PAYMENT_VERIFY_STATUS: {
    COMPLETED: 'success',
    PENDING: 'pending',
    FAILED: 'failure'
  },

  URL: {
    FAQ: 'https://www.singtel.com/personal/support',
    SINGTEL_APP: 'https://www.singtel.com/personal/support',
    ONE_PASS: 'https://www.singtel.com/personal/support/onepass',
    BILLING:
      'https://www.singtel.com/personal/support/billing/bill-is-higher-than-usual'
  },
  URL_PARAMS: {
    ONEPAY_STATUS: 'pgStatus',
    PAYMENT_TYPE: 'paymentType',
    PAYMENT_TOKEN: 'paymentToken',
    ZERO_UPFRONT: 'zeroUpfront',
    MERCHANT_REF_ID: 'merchantRefNum'
  },
  SERVICE_TYPE: {
    NEW_SERVICE: 'new_service',
    RECON_SERVICE: 'existing_service'
  }
};
