import { useCallback, useEffect } from 'react';
import { authActions, api } from '@detox/actions';
import { AuthState } from '../../types/auth.types';
import { useDispatch } from 'react-redux';

export const useAuthToken = (
  params: {
    auth: Partial<AuthState>;
    shouldCleanWorryFee?: boolean;
    cleanWorryFreeOnePassHeader?: boolean;
  },
  dps: boolean[] = []
): void => {
  const dispatch = useDispatch();
  const {
    auth,
    shouldCleanWorryFee = true,
    cleanWorryFreeOnePassHeader = true
  } = params;
  const shouldTrigger = useCallback(() => {
    // all dps must be true when passed
    return dps.reduce((result, dp) => {
      if (!result) return result;
      return dp;
    }, true);
  }, [dps]);

  useEffect(() => {
    if (shouldTrigger()) {
      const { otpVerified, status } = auth.worryFree || {};
      const cleanWorryFree =
        shouldCleanWorryFee &&
        (otpVerified || api?.mcss?.helpers?.isWorryFreeLoggedIn());

      if (!auth.sessionToken || cleanWorryFree) {
        dispatch(
          authActions.getAuthToken({
            cleanWorryFree,
            cleanWorryFreeOnePassHeader
          })
        );
      } else if (auth.sessionToken && status) {
        dispatch(authActions.resetWorryFree());
      }
    }
  }, [
    auth.sessionToken,
    auth.worryFree?.otpVerified,
    auth.worryFree?.status,
    ...dps
  ]);
};
