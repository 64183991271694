import './fonts.css';

const SystemFonts =
  '-apple-system, BlinkMacSystemFont,Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol';

const fonts = {
  headingDesktop: `Museo-100, ${SystemFonts}`,
  headingMobile: `Museo-300, ${SystemFonts}`,
  primary: `Lato-Regular, ${SystemFonts}`,
  secondary: `Lato-Regular, ${SystemFonts}`,
  tertiary: `Lato-Bold, ${SystemFonts}`
};

export default fonts;
