import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import RrpIppOrderSummary from '../../../components/RrpIppOrderSummary';

import '../../../fragments/rrp-product';

const RrpIppOrderSummaryPage = props => {
  const { data } = props;

  return (
    <div className="fs-unmask">
      <SEO title="RRP IPP Order Summary" />
      <RrpIppOrderSummary data={data} />
    </div>
  );
};

export const query = graphql`
  query RrpIppOrderSummaryPageQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
    config {
      dealerCode
      merchantId
    }
  }
`;

export default RrpIppOrderSummaryPage;
