import styled from 'styled-components';
import theme from '../../theme';

export const ButtonWrapper = styled.div`
  & button {
    background-color: ${theme.colours.cyan} !important;
  }
`;

export const GomoButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: 'blue';
`;

export const GomoButtonImage = styled.img`
  width: 58px;
  height: 40px;
`;
