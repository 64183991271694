import { FULFILLMENT } from '../constants';
import { fulfillmentDeliveryActions } from '@detox/actions';
const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

export const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: {},
  isLoading: false
};

const {
  EXTEND_EQUIPMENT_RESERVATION_LOADING,
  EXTEND_EQUIPMENT_RESERVATION_SUCCESS,
  EXTEND_EQUIPMENT_RESERVATION_ERROR
} = fulfillmentDeliveryActions.EXTEND_EQUIPMENT_RESERVATION_ACTIONS;

export const extendSessionTimeoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case EXTEND_EQUIPMENT_RESERVATION_LOADING:
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        hasError: false,
        isLoading: true
      };
    case EXTEND_EQUIPMENT_RESERVATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: REQUEST_STATUS.SUCCEEDED,
        isLoading: false
      };
    case EXTEND_EQUIPMENT_RESERVATION_ERROR: {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        hasError: true,
        errorCode: action.payload,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
