const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'DISABLE_RUBY':
      return {
        ...state,
        ...action.payload
      };

    case 'ENABLE_RUBY':
      return initialState;

    default:
      return state;
  }
};
