import React from 'react';
import IconCombo from '../assets/svgs/plan-combo.svg';
import IconMobileShare from '../assets/svgs/plan-mobile-share.svg';
import IconXo from '../assets/svgs/plan-xo.svg';
import IconYouth from '../assets/svgs/plan-youth.svg';
import IconSilver from '../assets/svgs/plan-silver.svg';
import IconSimOnly from '../assets/svgs/plan-sim-only.svg';

export default {
  'Combo Plans': {
    icon: <IconCombo size={32} />
  },
  'XO Plans': {
    icon: <IconXo size={32} />
  },
  'Youth Combo Plans': {
    icon: <IconYouth size={32} />
  },
  'Silver Combo Plans': {
    icon: <IconSilver size={32} />
  },
  'Mobile Share': {
    icon: <IconMobileShare size={32} />
  },
  'Sim Only': {
    icon: <IconSimOnly size={32} />
  }
};

export const planTagsConfig = {
  '5g-speed': {
    tagName: 'singtel:eshop/network-speed/5g-speed'
  },
  'sim-only': {
    tagName: 'nxt:Category/All Plans/Mobile Plans/SimOnly'
  }
};

// TODO: Move to AEM config
export const PLAN_GROUP_SD_PLANS = {
  'Sim Only Plus (12 mths)': 'SIMOnlyPlans',
  'XO Plus Plans': 'XOPlusPlans',
  'Combo Plans': 'ComboPlans',
  'Youth Combo Plans': 'YouthComboPlans'
};
