exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessories-fulfilment-index-tsx": () => import("./../../../src/pages/accessories/fulfilment/index.tsx" /* webpackChunkName: "component---src-pages-accessories-fulfilment-index-tsx" */),
  "component---src-pages-accessories-index-tsx": () => import("./../../../src/pages/accessories/index.tsx" /* webpackChunkName: "component---src-pages-accessories-index-tsx" */),
  "component---src-pages-accessories-ipp-fulfilment-index-tsx": () => import("./../../../src/pages/accessories/ipp-fulfilment/index.tsx" /* webpackChunkName: "component---src-pages-accessories-ipp-fulfilment-index-tsx" */),
  "component---src-pages-accessories-order-confirmation-index-tsx": () => import("./../../../src/pages/accessories/order-confirmation/index.tsx" /* webpackChunkName: "component---src-pages-accessories-order-confirmation-index-tsx" */),
  "component---src-pages-accessories-ordersummary-index-js": () => import("./../../../src/pages/accessories/ordersummary/index.js" /* webpackChunkName: "component---src-pages-accessories-ordersummary-index-js" */),
  "component---src-pages-accessories-rrp-ipp-order-confirmation-index-tsx": () => import("./../../../src/pages/accessories/rrp-ipp-order-confirmation/index.tsx" /* webpackChunkName: "component---src-pages-accessories-rrp-ipp-order-confirmation-index-tsx" */),
  "component---src-pages-accessories-rrp-ipp-ordersummary-index-tsx": () => import("./../../../src/pages/accessories/rrp-ipp-ordersummary/index.tsx" /* webpackChunkName: "component---src-pages-accessories-rrp-ipp-ordersummary-index-tsx" */),
  "component---src-pages-accessories-rrp-ipp-shopping-cart-index-tsx": () => import("./../../../src/pages/accessories/rrp-ipp-shopping-cart/index.tsx" /* webpackChunkName: "component---src-pages-accessories-rrp-ipp-shopping-cart-index-tsx" */),
  "component---src-pages-accessories-rrp-select-service-index-tsx": () => import("./../../../src/pages/accessories/rrp-select-service/index.tsx" /* webpackChunkName: "component---src-pages-accessories-rrp-select-service-index-tsx" */),
  "component---src-pages-accessories-rrp-select-service-rrp-login-tsx": () => import("./../../../src/pages/accessories/rrp-select-service/rrp-login.tsx" /* webpackChunkName: "component---src-pages-accessories-rrp-select-service-rrp-login-tsx" */),
  "component---src-pages-accessories-shopping-cart-index-js": () => import("./../../../src/pages/accessories/shopping-cart/index.js" /* webpackChunkName: "component---src-pages-accessories-shopping-cart-index-js" */),
  "component---src-pages-addons-tsx": () => import("./../../../src/pages/addons.tsx" /* webpackChunkName: "component---src-pages-addons-tsx" */),
  "component---src-pages-backend-system-not-available-js": () => import("./../../../src/pages/backend-system-not-available.js" /* webpackChunkName: "component---src-pages-backend-system-not-available-js" */),
  "component---src-pages-benjamin-sheares-tsx": () => import("./../../../src/pages/benjamin-sheares.tsx" /* webpackChunkName: "component---src-pages-benjamin-sheares-tsx" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-choose-your-number-js": () => import("./../../../src/pages/choose-your-number.js" /* webpackChunkName: "component---src-pages-choose-your-number-js" */),
  "component---src-pages-cis-phones-js": () => import("./../../../src/pages/cis-phones.js" /* webpackChunkName: "component---src-pages-cis-phones-js" */),
  "component---src-pages-cis-plans-tsx": () => import("./../../../src/pages/cis-plans.tsx" /* webpackChunkName: "component---src-pages-cis-plans-tsx" */),
  "component---src-pages-credit-limit-review-js": () => import("./../../../src/pages/credit-limit-review.js" /* webpackChunkName: "component---src-pages-credit-limit-review-js" */),
  "component---src-pages-customer-registration-js": () => import("./../../../src/pages/customer-registration.js" /* webpackChunkName: "component---src-pages-customer-registration-js" */),
  "component---src-pages-e-services-eform-register-tsx": () => import("./../../../src/pages/e-services/eform-register.tsx" /* webpackChunkName: "component---src-pages-e-services-eform-register-tsx" */),
  "component---src-pages-empty-shopping-cart-js": () => import("./../../../src/pages/empty-shopping-cart.js" /* webpackChunkName: "component---src-pages-empty-shopping-cart-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-login-guest-checkout-js": () => import("./../../../src/pages/login-guest-checkout.js" /* webpackChunkName: "component---src-pages-login-guest-checkout-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-no-access-tsx": () => import("./../../../src/pages/no-access.tsx" /* webpackChunkName: "component---src-pages-no-access-tsx" */),
  "component---src-pages-number-selection-js": () => import("./../../../src/pages/number-selection.js" /* webpackChunkName: "component---src-pages-number-selection-js" */),
  "component---src-pages-number-verification-js": () => import("./../../../src/pages/number-verification.js" /* webpackChunkName: "component---src-pages-number-verification-js" */),
  "component---src-pages-order-fulfillment-tsx": () => import("./../../../src/pages/order-fulfillment.tsx" /* webpackChunkName: "component---src-pages-order-fulfillment-tsx" */),
  "component---src-pages-order-summary-tsx": () => import("./../../../src/pages/order-summary.tsx" /* webpackChunkName: "component---src-pages-order-summary-tsx" */),
  "component---src-pages-payment-redirection-tsx": () => import("./../../../src/pages/payment-redirection.tsx" /* webpackChunkName: "component---src-pages-payment-redirection-tsx" */),
  "component---src-pages-phones-js": () => import("./../../../src/pages/phones.js" /* webpackChunkName: "component---src-pages-phones-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-promotions-js": () => import("./../../../src/pages/promotions.js" /* webpackChunkName: "component---src-pages-promotions-js" */),
  "component---src-pages-rrp-product-catalog-js": () => import("./../../../src/pages/rrp-product-catalog.js" /* webpackChunkName: "component---src-pages-rrp-product-catalog-js" */),
  "component---src-pages-shopping-cart-js": () => import("./../../../src/pages/shopping-cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-uiam-landing-web-tsx": () => import("./../../../src/pages/uiam-landing-web.tsx" /* webpackChunkName: "component---src-pages-uiam-landing-web-tsx" */),
  "component---src-pages-user-identity-check-js": () => import("./../../../src/pages/user-identity-check.js" /* webpackChunkName: "component---src-pages-user-identity-check-js" */),
  "component---src-templates-accessories-rrp-product-index-js": () => import("./../../../src/templates/accessories/rrp-product/index.js" /* webpackChunkName: "component---src-templates-accessories-rrp-product-index-js" */),
  "component---src-templates-phone-index-js": () => import("./../../../src/templates/phone/index.js" /* webpackChunkName: "component---src-templates-phone-index-js" */)
}

