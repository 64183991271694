import styled, { css } from 'styled-components';
import { Column, Spacing, withTheme } from '@dls/web';

export const StyledModalDiv = withTheme(styled.div`
  ${({ coreTheme }) => css`
  padding: '20px',
  backgroundColor: ${coreTheme.cl_ter_l6}
  `}
`);

export const StyledColumn = styled(Column)`
  text-align: center;
`;

export const ImageContainer = styled(Spacing)`
  text-align: center;
  img {
    width: 100%;
  }
`;
