import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const PersistStatusContext = React.createContext({});

/**
 * This component checks if redux persist has persisted the state
 * and stores it in PersistStatusContext.
 *
 * Users can then use PersistStatusConsumer to retrieve this persisted state
 * and hide the UI when the persisting is still in progresss.
 */

const PersistStatusProvider = props => {
  const { persistor } = props;
  const [persisted, setPersisted] = useState(false);

  useEffect(() => {
    const { bootstrapped } = persistor.getState();
    if (bootstrapped && !persisted) {
      setPersisted(true);
    }
  }, [persisted, persistor]);

  return (
    <PersistStatusContext.Provider value={{ persisted, persistor: persistor }}>
      {props.children}
    </PersistStatusContext.Provider>
  );
};

PersistStatusProvider.propTypes = {
  persistor: PropTypes.object,
  children: PropTypes.element
};

export default PersistStatusProvider;
