export const CREDIT_LIMIT_FLOWTYPE = {
  BB: 'BB',
  TV: 'TV',
  CHANGE_PLAN: 'changePlan',
  MANAGE_PLAN: 'managePlan',
  CHANGE_OWNERSHIP: 'changeOwnership',
  DETOX_MOBILE: 'detoxMobile',
  DEFAULT: 'default'
};

export const ESHOP_FLOW_TYPES = [
  CREDIT_LIMIT_FLOWTYPE.BB,
  CREDIT_LIMIT_FLOWTYPE.TV
];

export const CREDIT_LIMIT_QUERY_PARAMS = {
  creditLimitIndicator: 'creditLimitIndicator',
  notEligibleForCR: 'notEligibleForCR'
};
