import React, { ReactElement } from 'react';
import LoadingOverlayComponent from '../../components/LoadingOverlay';
import { AppState } from '../../types/common.types';
import {
  ListSkeletonLoader,
  PageSkeletonLoader,
  SkeletonType,
  SkeletonWithSideBar,
  PropTypes as SkeletonPropTypes
} from '../../components/SkeletonLoader';

export type PropTypes = {
  states?: AppState[];
  skeletonLoadings?: boolean[];
  loadings?: boolean[];
  options?: Options;
};

interface Options extends SkeletonPropTypes {
  type?: SkeletonType;
}

export const useLoader = ({
  states,
  options = {},
  skeletonLoadings,
  loadings
}: PropTypes): { Loader } => {
  const shouldShowLoader =
    states?.reduce((result, state) => {
      result = result || state.isLoading || state.loading;
      return result;
    }, false) || loadings?.some(loading => !!loading);
  const {
    type,
    column = { xs: 12, sm: 7 },
    numberOfItem = 4,
    secondaryItem = 'Card'
  } = options;
  const shouldShowSkeletonLoader =
    states?.reduce((result, state) => {
      result = result || state.skeletonLoading;
      return result;
    }, false) || skeletonLoadings?.some(loading => !!loading);
  const renderSkeletonLoader = () => {
    if (!shouldShowSkeletonLoader) {
      return null;
    }
    const Skeleton = () => {
      switch (type) {
        case SkeletonType.sideBar: {
          return <SkeletonWithSideBar />;
        }
        case SkeletonType.page: {
          return (
            <PageSkeletonLoader
              position={options.position}
              secondaryItem={secondaryItem}
              numberOfItem={numberOfItem}
              column={column}
            />
          );
        }
        default:
          return (
            <ListSkeletonLoader
              column={column}
              secondaryItem={secondaryItem}
              numberOfItem={numberOfItem}
            />
          );
      }
    };
    return <Skeleton />;
  };

  const Loader = ({
    children,
    hasSkeletonLoader = true
  }: {
    children: ReactElement;
    hasSkeletonLoader?: boolean;
  }) => {
    return (
      <>
        <>
          {hasSkeletonLoader && renderSkeletonLoader()}
          {shouldShowLoader && <LoadingOverlayComponent />}
          {!shouldShowSkeletonLoader && children}
        </>
      </>
    );
  };
  return {
    Loader
  };
};
