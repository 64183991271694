import React from 'react';

import SEO from '../components/SEO';
import EmptyCart from '../components/ShoppingCart/EmptyCart';

const EmptyShoppingCartPage = props => {
  return (
    <div className="fs-unmask">
      <SEO title="Empty Shopping Cart" />
      <EmptyCart shopUrl={props?.location?.state?.shopUrl || '/'} />
    </div>
  );
};

export default EmptyShoppingCartPage;
