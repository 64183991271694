import React from 'react';
import { Text, Column } from '@dls/web';
import styled from 'styled-components';
import { FieldObject } from '../../types/registrationCheckout';
import theme from '../../theme';

const StyledBody = styled(props => <div {...props} />)`
  background-color: ${theme.colours.grey_50};
  padding: 16px;
  border-radius: 8px;
`;

interface Props {
  data: FieldObject;
  isWithDevice: boolean;
}

const Disclaimer = (props: Props): React.ReactElement => {
  const { data, isWithDevice } = props;

  return (
    <StyledBody>
      <Text type="header">{data?.title}</Text>
      <Column xs={12}>
        <span
          dangerouslySetInnerHTML={{
            __html: isWithDevice
              ? data?.footerWithDevice
              : data?.footerWithoutDevice
          }}
        />
      </Column>
    </StyledBody>
  );
};

export default Disclaimer;
