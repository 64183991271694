import { KeyValue } from '../../types/common.types';

export interface FormReducerState {
  selectedTab: number;
  addonModal: {
    appleCareAddon: boolean;
    standaloneAddon: boolean;
    groupAddon: boolean;
    sopGroupAddon: boolean;
  };
  addAddonData: KeyValue;
  showRemoveAck?: KeyValue;
}

export const ADDONS_STATE_ACTIONS = {
  SET_MODAL_DATA: 'SET_MODAL_DATA',
  SET_APPLE_CARE_MODAL_DATA: 'SET_APPLE_CARE_MODAL_DATA',
  RESET_MODAL_DATA: 'RESET_MODAL_DATA',
  SET_SELECTED_TAB: 'SET_SELECTED_TAB',
  SET_REMOVE_ACK_MODAL: 'SET_REMOVE_ACK_MODAL'
};

export const addonsInitialState = {
  selectedTab: 0,
  addAddonData: null,
  addonModal: {
    appleCareAddon: false,
    standaloneAddon: false,
    groupAddon: false,
    sopGroupAddon: false
  },
  showRemoveAck: null
};

export type AddonModalType =
  | 'appleCareAddon'
  | 'standaloneAddon'
  | 'groupAddon'
  | 'sopGroupAddon';

export interface ActionValues {
  type: string;
  payload?:
    | KeyValue
    | {
        addAddonData?: KeyValue;
        modalType?: AddonModalType;
        modalState?: boolean;
        selectedTab?: number;
      };
}

export const addonsStateReducer = (
  state = addonsInitialState,
  action: ActionValues
): FormReducerState => {
  switch (action.type) {
    case ADDONS_STATE_ACTIONS.SET_MODAL_DATA: {
      const { modalType, addAddonData, modalState } = action.payload;

      return {
        ...state,
        addAddonData: modalState ? addAddonData : null,
        addonModal: {
          ...addonsInitialState.addonModal,
          [modalType]: modalState
        }
      };
    }

    case ADDONS_STATE_ACTIONS.SET_APPLE_CARE_MODAL_DATA: {
      return {
        ...state,
        addAddonData: null,
        addonModal: {
          ...addonsInitialState.addonModal,
          appleCareAddon: true
        }
      };
    }

    case ADDONS_STATE_ACTIONS.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload.selectedTab
      };
    }

    case ADDONS_STATE_ACTIONS.SET_REMOVE_ACK_MODAL: {
      return {
        ...state,
        showRemoveAck: action.payload
      };
    }

    case ADDONS_STATE_ACTIONS.RESET_MODAL_DATA: {
      return {
        ...addonsInitialState,
        selectedTab: state.selectedTab
      };
    }

    default:
      return addonsInitialState;
  }
};
