import ORDER_CONSTANTS from '../constants/order';
import { getUrlParams } from '@lux/helpers';
import CONSTANTS from '../constants/common';

interface UiamUrlParams {
  accessToken: string;
  targetUrl: string;
}

export const getUiamUrlParams = (pathName: string): UiamUrlParams => {
  if (!pathName) return null;
  let accessToken, targetUrl;
  const responseQueryParams = getUrlParams(pathName);
  if (responseQueryParams?.accessToken && responseQueryParams?.targetUrl) {
    accessToken = responseQueryParams?.accessToken;
    targetUrl = pathName.includes(
      `${CONSTANTS.URL_PARAMS.NEED_VALIDATE_ONE_PASS_USER}=true`
    )
      ? `${responseQueryParams?.targetUrl}=true`
      : responseQueryParams?.targetUrl;
  }

  return { accessToken, targetUrl };
};
