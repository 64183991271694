import { KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '../constants/actions';

export interface OrderSubmissionState {
  isLoading?: boolean;
  isError?: boolean;
  billingEntity?: KeyValue;
  requestSubmissionSuccess?: boolean;
}

export interface OrderSubmissionAction {
  type: string;
  value?: KeyValue;
}

export const initialState: OrderSubmissionState = {
  isError: false,
  isLoading: false,
  billingEntity: {},
  requestSubmissionSuccess: false
};

export const orderSubmissionReducer = (
  state: OrderSubmissionState = initialState,
  action: OrderSubmissionAction
) => {
  switch (action.type) {
    case ACTION_TYPES.ORDER_SUBMISSION.ORDER_SUBMISSION_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.ORDER_SUBMISSION.ORDER_SUBMISSION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        billingEntity: action.value,
        requestSubmissionSuccess: true
      };
    }
    case ACTION_TYPES.ORDER_SUBMISSION.ORDER_SUBMISSION_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        requestSubmissionSuccess: false
      };
    }

    case ACTION_TYPES.ORDER_SUBMISSION.REST_SUBMISSION: {
      return initialState;
    }
  }
  return state;
};
