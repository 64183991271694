import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { noop, remCalc } from '@lux/helpers';
import { FootnotePrimary } from '../Base';

import IconSuccess from '../../assets/svgs/tick-circle.svg';
import IconDanger from '../../assets/svgs/exclamation-circle.svg';
import theme from '../../theme';

const STATES = ['success', 'danger'];

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  font-family: ${theme.fonts.primary};
  font-weight: normal;
  font-size: ${remCalc(16)};
  margin: 0;
  color: ${theme.colours.grey_600};
  background-color: ${theme.colours.grey_50};
  padding: ${theme.spacing(2)} ${theme.spacing(1)};
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${theme.colours.grey_400};
  outline: none;

  ${p =>
    p.validation &&
    css`
      border-bottom: 1px solid ${theme.states[p.validation].colour};
    `};

  ${p =>
    STATES.includes(p.validation)
      ? css`
          &:hover,
          &:focus {
            border-bottom: 1px solid ${theme.states[p.validation].colour};
          }
        `
      : css`
          &:hover,
          &:focus {
            border-bottom: 1px solid ${theme.colours.link};
          }
        `};

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
    `};
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${theme.spacing(2)} ${theme.spacing(1)};

  > svg,
  path {
    ${p =>
      STATES.includes(p.validation) &&
      css`
        fill: ${theme.states[p.validation].colour};
      `};
  }
`;

const HintMessage = styled(FootnotePrimary)`
  display: block;
  height: ${remCalc(16)};
  ${p =>
    STATES.includes(p.validation) &&
    css`
      color: ${theme.states[p.validation].colour};
    `};
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(2)};
`;

const TextField = props => {
  const {
    disabled,
    hintMessage,
    icon,
    id,
    labelText,
    placeholder,
    validation,
    onClick,
    ...rest
  } = props;

  const icons = {
    success: (
      <IconSuccess width={16} height={16} data-testid={`${validation}-icon`} />
    ),
    danger: (
      <IconDanger width={16} height={16} data-testid={`${validation}-icon`} />
    )
  };

  return (
    <InputWrapper>
      <Label htmlFor={id}>{labelText}</Label>
      <StyledInput
        type="text"
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        validation={validation}
        onClick={e => onClick(e)}
        {...rest}
      />
      <Icon validation={validation}>{icon || icons[validation]}</Icon>
      {hintMessage && (
        <HintMessage validation={validation}>{hintMessage}</HintMessage>
      )}
    </InputWrapper>
  );
};

TextField.defaultProps = {
  disabled: false,
  validation: '',
  onClick: noop
};

TextField.propTypes = {
  /** Sets if the input is disabled */
  disabled: PropTypes.bool,
  /** The message to appear below the input for a success or error state */
  hintMessage: PropTypes.string,
  /** Sets the custom icon to be used. E.g. Credit Card icon for credit card field */
  icon: PropTypes.node,
  /** Sets id to associate the label and input */
  id: PropTypes.string,
  /** Sets the label text */
  labelText: PropTypes.string,
  /** The placeholder text. */
  placeholder: PropTypes.string,
  /** The validation type for the component */
  validation: PropTypes.oneOf(['', 'success', 'danger']),
  /** Sets the onClick event */
  onClick: PropTypes.func
};

export default TextField;
