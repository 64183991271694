import styled, { css } from 'styled-components';
import { Grid, Spacing, withTheme } from '@dls/web';

const fullscreenStyles = css`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const SkeletonWrapper = withTheme(styled(Grid)`
  ${({ coreTheme }) => css`
    position: fixed;
    background: ${coreTheme.cl_white};
    ${fullscreenStyles}
  `}
`);

export const SkeletonOverlay = styled.div`
  position: fixed;
  ${fullscreenStyles};
  opacity: 0;
  z-index: 999;
`;

export const GridWrapper = styled(Spacing)`
  display: flex;
  width: calc(100% + 16px);
  margin-left: -8px;
`;
