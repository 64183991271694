import { getUrlParams } from '@lux/helpers';
import ORDER_CONSTANTS from '../../constants/order';
import { KeyValue } from '../../types/common.types';
import { AllocationStatus } from '../../types/fulfilment.types';
import { config } from '@detox/actions';
import { getQueryData } from '../../helpers/common';
import { ORDER } from '../../constants';

export const getOrderStatus = (location: Partial<Location>) => {
  const queryParams = getUrlParams(location.search);
  const status = queryParams[ORDER_CONSTANTS.URL_PARAMS.ONEPAY_STATUS];
  return status?.toLowerCase();
};

export const isZeroUpfront = (location: Partial<Location>) => {
  return (
    getQueryData(location, ORDER_CONSTANTS.URL_PARAMS.ZERO_UPFRONT) === 'true'
  );
};
export const isOnlinePayment = (location: Partial<Location>): boolean => {
  return !isCashPayment(location) && !isZeroUpfront(location);
};

export const isPaymentSuccessFromUrlParams = (location: Partial<Location>) => {
  return getOrderStatus(location) === ORDER_CONSTANTS.STATUS.SUCCESS;
};

export const isPaymentFailureFromUrlParams = (location: Partial<Location>) => {
  return getOrderStatus(location) === ORDER_CONSTANTS.STATUS.FAILURE;
};

export const getCreateCustomerProblemRequestData = ({
  orderId,
  customerId,
  barId,
  contactId
}: {
  orderId: string;
  customerId: string;
  barId: string;
  contactId: string;
}) => {
  return {
    crmParams: {
      severity: ['CRM_LIST:Problem Severity Level', 'Urgent'],
      priority: ['CRM_LIST:Response Priority Code', 'Urgent'],
      problemTypeLevel: [
        'STANDARD:CASE_TYPE',
        'SpringDigital',
        'Sales',
        'PaymentFailed'
      ],
      additionalCustomerProblemDetails: {
        parentProblemId: '',
        reporterSiteId: '',
        reporterAddressId: '',
        reporterOrganizationId: ''
      },
      reporterIndividualId: contactId,
      reporterCustomerId: customerId,
      name: 'Create Order request',
      description: `Your Detox Order Failed,Order Id :${orderId}`,
      associatedItem: { ID: barId, type: 'CUSTOMER_BILL_SPEC' },
      status: 'Pending',
      saveOption: 'SAVE_AND_DISPATCH',
      queueID: '',
      saveInWorkFolder: null
    },
    updateRequestParams: {
      orderId,
      paymentStatus: ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED
    }
  };
};

export const isCashPayment = (location: Partial<Location>) => {
  return (
    getQueryData(location, ORDER_CONSTANTS.URL_PARAMS.PAYMENT_TYPE) ===
      ORDER_CONSTANTS.PAYMENT_TYPES.CASH && !isZeroUpfront(location)
  );
};

export const isOrderSuccess = (payment: KeyValue) => {
  return payment?.status === ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED;
};

export const isOrderFailed = (payment: KeyValue) => {
  return payment?.status === ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED;
};

export const isOrderPending = (payment: KeyValue) => {
  return payment?.status === ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING;
};

export const isDistributedPromoAvailable = (payment: KeyValue) => {
  return (
    (isOrderSuccess(payment) || isOrderPending(payment)) &&
    payment?.distributePromoCode
  );
};

export const getDeviceResDetails = (
  productOrderItemId: string,
  allocationStatusArray: AllocationStatus[] = []
) => {
  return allocationStatusArray.map(item => {
    return {
      id: item.type,
      storeID: config.dealerId,
      itemCodeX9: item.itemCodeX9,
      orderActionIDX9: productOrderItemId,
      reservationId: item.allocationIDX9
    };
  });
};

export const getCartOrder = (cart, productOrder, skuId) => {
  return {
    deviceDetails: [
      { deviceProductId: cart.order?.mobile?.device?.deviceProductID, skuId }
    ],
    freeGifts: cart.cartOrder?.freebies,
    productOrderItemId: productOrder?.productOrderItemId,
    isNewSimAdded: productOrder?.sim?.hasSim,
    simProductId: productOrder?.sim?.productId
  };
};

export const updatePromotionsObj = (promoOrderDetails, key, value) => {
  return { ...promoOrderDetails, [key]: value };
};

export const getOrderDataForGTM = (dataSet: KeyValue): KeyValue => {
  const { order, orderSummary, fulfillment } = dataSet;
  const { productsList = [], isBuyingWithoutDevice = false } = orderSummary;

  const orderProductsList = (productsList || []).map(
    ({ quantity = '', name = '', price = '' }) => ({
      name,
      price,
      quantity
    })
  );

  return {
    OrderCompleteData: {
      OrderId: order.productOrder?.productOrderId,
      OrderAmount: orderSummary.order?.totalCheckOutPrice,
      OrderCoupon: fulfillment.delivery?.promoCodeInfo?.promoCode || '',
      OrderCurrency: 'SGD',
      OrderType: order.productOrder?.type === ORDER.TYPE.NEW ? 'new' : 'return',
      OrderDate: new Date().toString(),
      OrderItems: orderProductsList,
      OrderReference: order.productOrder?.type || '',
      OrderReference1: isBuyingWithoutDevice ? 'Sim Only' : 'Mobile'
    }
  };
};

export const geteCommerceDataForGTM = (dataSet: KeyValue): KeyValue => {
  const {
    order,
    orderSummary,
    plan,
    cart,
    product,
    addons,
    isMobileShare = false,
    paymentStatus = true
  } = dataSet;
  const orderID = order.productOrder?.productOrderId;
  const finalAmountIncludingTax = orderSummary.order?.totalCheckOutPrice;
  const planName = plan.selectedPlan?.planName;
  const productOrderItemType =
    order.productOrder?.type === ORDER.TYPE.NEW ? 'Provide' : 'Change';
  const isPortin = order.productOrder?.type === ORDER.TYPE.PORTIN;
  const newlyAddedSimDetails = cart.order?.newlyAddedSimDetails?.simDetails;
  const { colour, size: deviceSize, groupId } = product?.selectedProduct || {};
  const deviceName = groupId || cart.cartOrder?.deviceName;
  const mobileContents = (addons?.atomicAddons || []).map(atomicAddon => ({
    name: atomicAddon?.name
  }));
  const addonsData = (addons?.addons5G || []).map(mobileContent => ({
    name: mobileContent?.name
  }));
  const promotions = (
    cart.cartOrder?.discount?.promotions || []
  ).map(promo => ({ name: promo?.label }));
  const freeGifts = (cart.cartOrder?.freebies || []).map(freeGift => ({
    name: freeGift?.name
  }));
  const categoryName = isMobileShare
    ? 'Singtel Mobile  (Mobile Share)'
    : 'Singtel Mobile';
  const deviceDetailsData = deviceName
    ? [
        {
          deviceName,
          deviceSize,
          colour
        }
      ]
    : [];

  const OrderInformation = {
    checkoutPrice: {
      finalAmountIncludingTax: finalAmountIncludingTax
    },
    id: orderID, //
    mobile: [
      {
        planDetails: [
          {
            planName: planName
          }
        ],
        productOrderItemType: productOrderItemType,
        isPortin: isPortin,
        simDetails: {
          simType: newlyAddedSimDetails?.simType
        },
        simRegisteration: {
          discountDetails: 'Service Registration Charge'
        },
        deviceDetails: deviceDetailsData,
        addons: addonsData,
        mobileContents,
        promotions,
        freeGifts,
        categoryName,
        isFreeMBB: false
      }
    ],
    paymentStatus: paymentStatus ? 'paymentSuccess' : 'paymentFailed'
  };

  return { OrderInformation };
};

export const surveyPaymentMethodMapping = {
  cc: 'Credit Card',
  enets: 'eNets',
  cod: 'Cash'
};

export const surveyFulfilmentMapping = {
  'pop-station': 'POPStation Collection',
  'singtel-pop-station': 'POPStation Collection',
  'singtel-store': 'Collect at Singtel Shop',
  'sms-mail': 'Snail Mail',
  courier: 'Courier to home',
  'doorstep-delivery': 'Courier to home'
};
