export const ctSendEvent = (type, value) => {
  if (
    window.ClickTaleEvent &&
    typeof window.ClickTaleEvent === 'function' &&
    window.ClickTaleMonitor &&
    typeof window.ClickTaleMonitor.addPageTag === 'function'
  ) {
    window.ClickTaleEvent(`C | ${type} | ${value}`);
    window.ClickTaleMonitor.addPageTag(50792, `C | ${type} | ${value}`);
  }
};
