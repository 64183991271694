import React from 'react';

import SEO from '../../../components/SEO';
import '../../../fragments/rrp-product';
import { RRPServiceList } from '../../../components/RRPServiceList';

export const RRPSelectService = () => {
  return (
    <>
      <SEO title="RRP Shopping Cart" />
      <RRPServiceList />
    </>
  );
};

export default RRPSelectService;
