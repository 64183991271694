/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@wec-core/form-engine';
import { fulFillmentActions } from '@detox/actions';
import { navigate } from 'gatsby';

import { trans as t } from '../../../helpers/localisation';
import { navigation } from '../../../middlewares/navigation-constants';
import {
  BillingPrefType,
  ErrorScenarioType,
  FormConfig,
  TObject
} from '../../../types/registrationCheckout';

import {
  getBillingGroup,
  getFormDataMapping,
  getSubmitGroup,
  isInCompleteSingpassData
} from '../helper';

import {
  MyInfoFormState,
  getContactGroup,
  getFilteredExistingValues,
  getSalutationField,
  getSalutationListData,
  getTitleField,
  getValidationSchema,
  myInfoReadOnlyFields,
  getInitialValues,
  getRESMyInfoReadOnlyPersonalData,
  getUpdatedRESMyInfoValues
} from '../myinfoHelper';

import LoadingOverlayComponent from '../../LoadingOverlay';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useCheckOutHelper } from '../hooks/useCheckOutHelper';
import MyInfoEKYCModal from '../../MyInfoEKYCModal/MyInfoEKYCModal';
import { getIfESimOrder } from '../../../selectors';

interface TProps {
  onValuesChange?: (values: TObject) => void;
  billingPrefData: BillingPrefType[];
  errorScenariosData?: ErrorScenarioType[];
  onErrorScenarioFound?: (values: TObject) => void;
  onFormSubmit?: (values: TObject, navigateTo: string) => void;
  goToOrderSummary: () => void;
  onGettingFormConfigs: (formConfigs: FormConfig) => void;
}

const titleField = getTitleField();
const salutationField = getSalutationField();
const submitGroup = getSubmitGroup(t as never);

const formData = {
  salutationList: getSalutationListData()
};

const RESMyInfoFlow: React.FC<TProps> = ({
  onValuesChange,
  billingPrefData,
  errorScenariosData,
  onErrorScenarioFound,
  onFormSubmit,
  goToOrderSummary,
  onGettingFormConfigs
}): ReactElement => {
  const dispatch = useDispatch();
  const {
    addressInfo,
    checkout,
    customerMyInfo,
    fulfillment,
    orderSummary
  } = useSelector((state: TObject) => ({
    order: state.order,
    addressInfo: state.fulfillment?.addressInfo,
    fulfillment: state.fulfillment,
    orderSummary: state.orderSummary,
    checkout: state.checkout,
    customerMyInfo: state?.customerMyInfo
  }));
  const { renderError } = useErrorHandler({
    states: [checkout]
  });
  const { handleOnFormSubmit } = useCheckOutHelper({
    onErrorScenarioFound,
    errorScenariosData,
    billingPrefData,
    onFormSubmit,
    goToOrderSummary,
    onGettingFormConfigs
  });

  const customerInfoResponse =
    customerMyInfo?.customerInfoResponse?.ImplCustomerInfoResponse;
  const isCompleteData = isInCompleteSingpassData(customerInfoResponse);

  const [myInfoFormState, setMyInfoFormState] = useState<MyInfoFormState>({
    formValues: {},
    formInputsMapping: []
  });
  const [myInfoDataComplete, setMyInfoDataComplete] = useState(isCompleteData);

  const { isLoading: addressLoading, address } = addressInfo || {};
  const { checkoutFormData = {}, isLoading } = checkout;
  const { isLoading: infoLoading } = customerMyInfo;
  const fulfilmentLoading = fulfillment?.delivery?.isLoading || false;
  const orderSummaryLoading = orderSummary?.isShowSkeletonLoader || false;

  const billingPrefGroup = getBillingGroup(t as never, billingPrefData);

  const postalCode = customerInfoResponse?.postalCode;
  const { contactNumber, email, userData } = getRESMyInfoReadOnlyPersonalData(
    customerInfoResponse
  );

  const setOverAllFormState = ({ contactNumber, email, userData }): void => {
    const infoReadOnlyFields = myInfoReadOnlyFields(userData);
    const formFieldsData = {
      title: titleField,
      salutation: salutationField,
      readOnlyFields: infoReadOnlyFields,
      contactGroup: getContactGroup({
        contactNumber
      }),
      billingPref: billingPrefGroup,
      submitGroup: submitGroup
    };
    const filteredExistingValues = getFilteredExistingValues(checkoutFormData);
    const initialFormValues = {
      ...getInitialValues({
        contactNumber,
        email,
        confirmEmailRequired: true,
        contactNumberRequired: false
      }),
      ...filteredExistingValues
    };

    setMyInfoFormState({
      formInputsMapping: formFieldsData,
      formValues: initialFormValues
    });
  };

  const goToNumSelectionPage = () => {
    setMyInfoDataComplete(false);
    navigate(`/${navigation.NUMBER_SELECTION_PAGE}`, { replace: true });
  };

  const getAddressInfoState = async (postalCode: string) => {
    await dispatch(fulFillmentActions.getAddressInfo(postalCode, true));
  };

  useEffect(() => {
    if (postalCode) {
      getAddressInfoState(postalCode);
    }
  }, [postalCode]);

  useEffect(() => {
    if (customerInfoResponse && address?.postcode === postalCode) {
      setOverAllFormState({ contactNumber, email, userData });
    }
  }, [customerInfoResponse, address]);

  if (!postalCode) {
    return null;
  }

  if (!myInfoDataComplete && !infoLoading && !addressLoading) {
    return (
      <MyInfoEKYCModal
        isModalOpen={true}
        modaltitle={t('EKYC_MYINFO_INCOMPLETE_TITLE') as string}
        modalContent={t('EKYC_MYINFO_INCOMPLETE_DESCRIPTION') as string}
        ctaText={t('EKYC_MYINFO_INCOMPLETE_NAVIGATE_BUTTON') as string}
        secondaryType={true}
        onClose={goToNumSelectionPage}
        onConfirm={goToNumSelectionPage}
      />
    );
  }

  // before rendering the form
  if (addressLoading || infoLoading) {
    return <LoadingOverlayComponent />;
  }

  const formattedFormFieldsData = getFormDataMapping(
    myInfoFormState?.formInputsMapping
  );

  return formattedFormFieldsData && formattedFormFieldsData.length > 0 ? (
    <div className="fs-mask">
      <Form
        enableReinitialize={false}
        formFieldsConfig={formattedFormFieldsData}
        initialValues={myInfoFormState.formValues}
        data={formData}
        callbacks={{}}
        onSubmit={values => {
          onValuesChange(values);

          const updatedValues = getUpdatedRESMyInfoValues(
            values,
            email,
            customerInfoResponse
          );
          handleOnFormSubmit(updatedValues, {
            passType: userData.passType,
            id: userData.passValue
          });
        }}
        validationSchema={getValidationSchema(userData.genderValue)}
        enableInputErrorFocus
      />

      {renderError()}

      {(isLoading || fulfilmentLoading || orderSummaryLoading) && (
        <LoadingOverlayComponent />
      )}
    </div>
  ) : null;
};
export default RESMyInfoFlow;
