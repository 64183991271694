import React, { Fragment, ReactNode, useState } from 'react';
import styled from 'styled-components';
import { trans as t } from '../../helpers/localisation';
import {
  Text,
  Grid,
  Row,
  Column,
  TextLink,
  Modal,
  Spacing,
  useDevice
} from '@dls/web';
import theme from '../../theme';

const StyledPromoStrip = styled.div`
  background-color: ${theme.colours.haze};
`;

interface ModalContent {
  mainTitle: string;
  sectionTitle: string;
  sectionDescription: ReactNode;
  sectionFooter: string;
}

interface PromoStrip {
  enableSecondaryStrip: boolean;
  primaryLiner: string;
  modalContent: ModalContent;
  isCisPage: boolean;
}

const PromoStrip = ({
  enableSecondaryStrip,
  primaryLiner,
  modalContent
}: PromoStrip): JSX.Element => {
  const [openPromoStripModal, setOpenPromoStripModal] = useState(false);
  const { isMobile } = useDevice();
  if (!primaryLiner && !enableSecondaryStrip) {
    return null;
  }
  return (
    <Fragment>
      <StyledPromoStrip enableSecondaryStrip={enableSecondaryStrip}>
        <Grid>
          <Row middle="sm">
            <Column noGutter>
              <Spacing responsive={false} topBottom={isMobile ? 0 : 1}>
                <Text type="smallBody">
                  {primaryLiner}{' '}
                  {enableSecondaryStrip && (
                    <TextLink
                      onClick={() => setOpenPromoStripModal(true)}
                      data-testid="click-to-see-more"
                    >
                      {t('SEE_MORE')}
                    </TextLink>
                  )}
                </Text>
              </Spacing>
            </Column>
          </Row>
        </Grid>
      </StyledPromoStrip>

      {enableSecondaryStrip && (
        <Modal
          data-testid={'promo-strip-modal'}
          visible={openPromoStripModal}
          title={modalContent.mainTitle}
          onClose={() => setOpenPromoStripModal(false)}
        >
          <Modal.Content>
            <Text type="body">{modalContent.sectionTitle}</Text>
            <Text type="body">
              <p
                dangerouslySetInnerHTML={{
                  __html: modalContent.sectionDescription
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{ __html: modalContent.sectionFooter }}
              ></p>
            </Text>
          </Modal.Content>
        </Modal>
      )}
    </Fragment>
  );
};

export default PromoStrip;
