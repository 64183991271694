import { ACTION_TYPES } from '../constants/actions';

const initialState = {
  isLoading: null,
  error: null
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOKEN.RETRIEVE_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ACTION_TYPES.TOKEN.RETRIEVE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case ACTION_TYPES.TOKEN.RETRIEVE_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};

export default tokenReducer;
