interface Addon {
  productSpecContainmentID: string;
  [key: string]: any;
}

interface AvailableAddon {
  [key: string]: Addon;
}

export const getAddonIdsByProductSpecContainmentID = (
  productSpecContainmentID: string,
  availableAddons: AvailableAddon = {}
) => {
  let selectedAddons: string[] = [];
  const temp = Object.entries(availableAddons);

  temp.forEach(([addonId, addon]) => {
    if (productSpecContainmentID === addon.productSpecContainmentID) {
      selectedAddons.push(addonId);
    }
  });

  return selectedAddons;
};

interface Model {
  title: string;
  value: string;
  tradeinPrice: number;
}

interface TradeInData {
  title: string;
  value: string;
  models: Model[];
}

export const getTradeInSelection = (dataSource: TradeInData[] = []) => {
  const brands = dataSource.map(data => ({
    text: data.title,
    value: data.value
  }));
  const modelByBrand = dataSource.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.title]: cur.models.map(model => ({
        text: `${model.title} - $${model.tradeinPrice}`,
        value: model.value,
        tradeinPrice: model.tradeinPrice
      }))
    };
  }, {});

  return {
    brands,
    modelByBrand
  };
};
