export const phoneTagsConfig = {
  '5g-speed': {
    tagName: 'singtel:device-connectivity/5g-compatible'
  },
  'mobile-deal': {
    tagName: 'singtel:all-mobile-deals'
  },
  ipp: {
    tagName: 'singtel:instalment-options'
  },
  'product-category': {
    tagName: 'singtel:product-categories'
  },
  'home-lifestyle': {
    tagName: 'singtel:product-categories/home-lifestyle'
  },
  'no-physical-sim': {
    tagName: 'nxt:Facets/SIM_Card_Type/no-physical-sim'
  }
};
