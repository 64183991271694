import React, { ReactElement } from 'react';
import { Text, TextLink } from '@dls/web';
import styled from 'styled-components';

type PropTypes = {
  handleTncModal: () => void;
  handleDppLink: () => void;
};

const StyledText = styled(Text)`
  font-size: 20px;
  line-height: 1.5;
`;

const Tnc: React.FC<PropTypes> = ({
  handleTncModal,
  handleDppLink
}): ReactElement => {
  return (
    <StyledText type="body">
      By submitting, I have read and agree to the{' '}
      <TextLink
        type="body"
        onClick={() => handleTncModal()}
        data-testid="tnc-test-link"
      >
        Terms & Conditions
      </TextLink>{' '}
      and{' '}
      <TextLink
        type="body"
        onClick={() => handleDppLink()}
        data-testid="dpp-test-link"
      >
        Singtel Data Protection Policy.
      </TextLink>
    </StyledText>
  );
};

export default Tnc;
