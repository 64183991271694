import moment from 'moment';
import { RrpFulfilment } from '../types/fulfilment.types';

const DateFormat = 'DD/MM/YYYY';

export interface AemFulfilment {
  fulfilmentId: string;
  tags?: string[] | null;
  fulfilment: RrpFulfilment;
}
export interface GetAEMFulfilmentById {
  [key: string]: RrpFulfilment;
}

export type AEMFulfilmentKey = 'fulfilment' | 'mobileFulfilment';

export const getAEMFulfilmentById = (
  fulfilmentList: AemFulfilment[],
  key: AEMFulfilmentKey
): GetAEMFulfilmentById => {
  return fulfilmentList.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.fulfilmentId]: cur[key]
    };
  }, {});
};

export interface GetLeadDaysOption {
  fulfilmentData: RrpFulfilment;
  firstDeliveryDate?: string;
  hasDocument?: boolean;
}

export interface LeadDaysData {
  firstDeliveryDate: string;
  lastDeliveryDate: string;
  deliveryPeriod: string;
}

export interface BySku {
  [key: string]: LeadDaysData;
}

export interface PreOrderFulfilment extends LeadDaysData {
  sku: string[];
}

export const getPreOrderFulfilment = (
  skuIds: string[] = [],
  leadDaysData: PreOrderFulfilment[] = []
): LeadDaysData => {
  let preOrderFulfilmentBySku: BySku = {};
  let latestDate = moment(new Date(), DateFormat).startOf('day');

  if (leadDaysData && leadDaysData.length > 0) {
    leadDaysData.forEach((data: PreOrderFulfilment) => {
      const { sku, ...rest } = data;
      sku?.forEach(skuId => {
        preOrderFulfilmentBySku[skuId] = rest;
      });
    });
  }

  if (skuIds.length > 0) {
    skuIds.forEach(sku => {
      if (preOrderFulfilmentBySku[sku]?.firstDeliveryDate) {
        latestDate = moment.max(
          latestDate,
          moment(preOrderFulfilmentBySku[sku].firstDeliveryDate, DateFormat)
        );
      }
    });
  }

  let firstDeliveryDate: string;

  if (!latestDate.isSame(moment(new Date(), DateFormat), 'day')) {
    firstDeliveryDate = latestDate.format(DateFormat);
  }

  return {
    firstDeliveryDate,
    lastDeliveryDate: '',
    deliveryPeriod: ''
  };
};

export const getLeadDays = (options: GetLeadDaysOption): number => {
  const {
    firstDeliveryDate,
    hasDocument = false,
    fulfilmentData: { offsetWithoutDocument, offsetWithDocument }
  } = options;

  let leadDays: number = hasDocument
    ? offsetWithDocument
    : offsetWithoutDocument;

  if (firstDeliveryDate) {
    const futureDate = firstDeliveryDate;
    const today = moment(new Date(), DateFormat).startOf('day');
    const future = moment(futureDate, DateFormat);

    const differenceInDays = future.diff(today, 'days');

    if (differenceInDays > leadDays) {
      leadDays = differenceInDays;
    }
  }

  return leadDays;
};
