/* istanbul ignore file */
export default {
  CHECKOUT_VERIFY_ID: 'WORRY_FREE_NRIC',
  CHECKOUT_VERIFY_NUMBER: 'WORRY_FREE_NUMBER',
  CHECKOUT_VERIFY_OTP: 'WORRY_FREE_OTP',
  CHECKOUT_VERIFY_WITH_ONE_PASS: 'ONE_PASS_LOGIN',
  CHECKOUT_NEW_NEW_CUSTOMER: 'NEW_NEW_CUSTOMER',
  CHECKOUT_EXISTING_NEW_CUSTOMER: 'CHECKOUT_EXISTING_NEW_CUSTOMER',
  CHECKOUT_RECON: 'CHECKOUT_RECON',
  CHECKOUT_NEW_NUMBER: 'CHECKOUT_NEW_NUMBER',
  CHECKOUT_STATUS_OTP_SENT: 'OTP_SENT',
  CHECKOUT_OTP_RESEND_SUCCESS: 'OTP_RESEND_SUCCESS',
  CHECKOUT_OTP_VERIFIED: 'OTP_VERIFIED',
  CHECKOUT_STATUS_VERIFY_ERROR: 'OTP_VERIFY_ERROR',
  CHECKOUT_ADDITIONAL_VERIFICATION_REQUIRED: 'WORRY_FREE_FA_CHECK',
  CHECKOUT_STATUS_TIMEOUT_ERROR: 'OTP_TIMEOUT_ERROR',
  CHECKOUT_OTP_LIMIT_EXCEEDED: 'OTP_LIMIT_EXCEEDED',
  CHECKOUT_GENERIC_ERROR: 'OTP_GENERIC_ERROR',
  CHECKOUT_RESET_ERROR_SCENARIO_CHECK: 'CHECKOUT_RESET_ERROR_SCENARIO_CHECK',
  CHECKOUT_CLEAR_CHECKOUT_ERROR: 'CLEAR_CHECKOUT_ERROR',
  CHECKOUT_CLEAR_CUSTOMER_MY_INFO: 'CHECKOUT_CLEAR_CUSTOMER_MY_INFO',
  SET_MYINFO_SUCCESS_CODE: 'SET_MYINFO_SUCCESS_CODE',
  WORRY_FREE_ACCOUNT_VERIFIED: 'WORRY_FREE_ACCOUNT_VERIFIED',
  NAVIGATE_TO: 'shopping-cart',
  CHECKOUT_RESET_VERIFICATION_DATA: 'RESET_VERIFICATION_DATA',
  CHECKOUT_RESET_WORRY_FREE: 'RESET_WORRY_FREE',
  RESET_CREATE_CUSTOMER_STATUS: 'RESET_CREATE_CUSTOMER_STATUS',
  ADDITIONAL_VERIFY_ERROR: 'ADDITIONAL_VERIFY_ERROR',
  MAINDATA: {
    PASSTYPESLIST: [
      { text: 'Work Permit', value: 'Foreigner Work Permit' },
      { text: 'Employment Pass', value: 'Foreigner Employment Pass' },
      { text: 'Foreigner S Pass', value: 'Foreigner S Pass' },
      { text: 'Diplomat', value: 'Diplomat' }
    ],
    LEADINGCHARACTER: ['F', 'G', 'M']
  },
  CHECKOUT_LOADING: 'CHECKOUT_LOADING',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  SET_CHECKOUT_FORM_DATA: 'SET_CHECKOUT_FORM_DATA',
  SET_ADDRESS_FORM_DATA: 'SET_ADDRESS_FORM_DATA',
  SET_SAVED_ADDRESS: 'SET_SAVED_ADDRESS',
  RESET_SAVED_ADDRESS: 'RESET_SAVED_ADDRESS',
  UPDATE_DELIVERY_ADDRESSES: 'UPDATE_DELIVERY_ADDRESSES',
  UPDATE_ADDRESS_INFO: 'UPDATE_ADDRESS_INFO',
  CONFIRM_SAVED_NEW_ADDRESS: 'CONFIRM_SAVED_NEW_ADDRESS',
  UPDATE_DELIVERY_ADDRESS_SELECTION: 'UPDATE_DELIVERY_ADDRESS_SELECTION',
  SET_CHECKOUT_FORM_DOCUMENT_DATA: 'SET_CHECKOUT_FORM_DOCUMENT_DATA',
  SET_CHECKOUT_FLOW: 'SET_CHECKOUT_FLOW',
  UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  GET_ADDRESS_INFO_SUCCESS: 'GET_ADDRESS_INFO_SUCCESS',
  GET_ADDRESS_INFO_FAILED: 'GET_ADDRESS_INFO_FAILED',
  RETRIEVE_DOCUMENT_DETAILS_SUCCESS_DATA:
    'RETRIEVE_DOCUMENT_DETAILS_SUCCESS_DATA',
  RESET_CHECKOUT_FORM_DATA: 'RESET_CHECKOUT_FORM_DATA',
  RETRIEVE_ADDRESS_ID_SUCCESS: 'RETRIEVE_ADDRESS_ID_SUCCESS',
  RESET_ADDRESS_DATA: 'RESET_ADDRESS_DATA',
  CONFIRM_NEW_ADDRESS: 'CONFIRMED_NEW_ADDRESS',
  REVERT_NEW_ADDRESS: 'REVERT_NEW_ADDRESS',
  RETRIEVE_DOCUMENT_CONTENT_SUCCESS_DATA:
    'RETRIEVE_DOCUMENT_CONTENT_SUCCESS_DATA',
  SET_VERIFICATION_MOBILE: 'SET_VERIFICATION_MOBILE',
  SET_VERIFICATION_ID_AND_PASS_TYPE: 'SET_VERIFICATION_ID_AND_PASS_TYPE',
  SET_VERIFICATION_ALL_DETAILS: 'SET_VERIFICATION_ALL_DETAILS',
  SET_STEPPER_TITLES: 'SET_STEPPER_TITLES',
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  NRIC_SILVER_AGE: 60,
  FETCH_BILLING_PREFERENCE_LOADING: 'FETCH_BILLING_PREFERENCE_LOADING',
  CALLER_BY_CONTACT_LOADING: 'CALLER_BY_CONTACT_LOADING',
  CALLER_BY_CONTACT_SUCCESS: 'CALLER_BY_CONTACT_SUCCESS',
  CALLER_BY_CONTACT_ERROR: 'CALLER_BY_CONTACT_ERROR',
  SET_COLLECT_AT_STORE_TIME_SLOTS_SUCCESS:
    'SET_COLLECT_AT_STORE_TIME_SLOTS_SUCCESS',
  EAPPOINTMENT_CREATE: 'EAPPOINTMENT_CREATE',
  EAPPOINTMENT_CREATE_EXISTING_APPT: 'EAPPOINTMENT_CREATE_EXISTING_APPT',
  EAPPOINTMENT_CLEAR: 'EAPPOINTMENT_CLEAR',
  NEW_BILLING_ADDRESS_ID: 99,
  NUMBER_OF_BILLING_ADDRESS_DISPLAYED: 3,
  SHOW_CUSTOM_VERIFICATION_FORM: 'SHOW_CUSTOM_VERIFICATION_FORM',
  BLOCK_ERROR_CODES: ['CRM-1001']
};
