/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { OrderSummaryRewamp } from '@common-modules/shopping-cart';
import TermsAndConditions from '../TermsAndConditions';
import { useDispatch, useSelector } from 'react-redux';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import {
  MCSSPopstationAddress,
  PaymentType
} from '../../types/orderSummary.types';
import { Grid, TextLink } from '@dls/web';
import {
  getBillingAddressInfo,
  getOrderAddressInfo,
  getOrderSummaryDataModelRrpIppBill,
  getOrderProductsListInfo,
  getPersonalInfo,
  getOrderSummaryDataModelRrpIppOrderTotal
} from './helper';
import { orderSummaryActions } from '@detox/actions';
import { dppLink } from '../../config/links';
import { navigate } from 'gatsby';
import { navigation } from '../../middlewares/navigation-constants';
import { TObject } from '../../types/registrationCheckout';
import useUser from '../../hooks/useUser';

import LoadingOverlayComponent from '../LoadingOverlay';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { trans as t } from '../../helpers/localisation';
import { getAccountBillingInfo, flattenNodes } from '../../helpers/common';
import { OrderSummaryState } from '../../reducers/orderSummary';
import Fulfillment from '../../constants/fulfillment';
import { ACTION_TYPES } from '../../constants/actions';
import CONSTANTS from '../../constants/common';
import { DELIVERY_METHOD_TO_TEXT } from '../RRPOrderSummary/RrpOrderSummary';
import {
  getPaymentIntentParams,
  getPaymentRequestParams,
  isZeroUpfrontCharges
} from '../OrderSummary/helper';
import { getProductBySku } from '../../helpers/rrp-product';
import usePaymentRedirection from '../../hooks/usePaymentRedirection';
import { getCatalogPath } from '../../helpers/rrpCart';
import { SkeletonType } from '../SkeletonLoader';
import { useLoader } from '../../hooks/useLoader';

const { FULFILMENT_TYPES_AEM, MCSS_DELIVERY_METHOD_TO_AEM } = Fulfillment;

type PropTypes = {
  data: TObject;
};

const RrpIppOrderSummary = (props: PropTypes): ReactElement => {
  const [tncModal, setTncModal] = useState(false);

  const { allRrpProduct, config } = props.data;
  const {
    fulfilmentState,
    checkout,
    userInformation,
    productOrder,
    contactAddress,
    user,
    orderSummary,
    cart,
    auth,
    billingAccountDetails,
    iphone,
    rrpCart
  } = useSelector((state: KeyValue) => ({
    fulfilmentState: state.fulfillment,
    checkout: state.checkout,
    userInformation: state.user?.information,
    productOrder: state.cart?.order?.productOrder || {},
    contactAddress:
      state.fulfillment?.delivery?.deliveryDetails?.contactAddress,
    user: state.user,
    orderSummary: state.orderSummary as OrderSummaryState,
    cart: state.cart,
    auth: state.auth,
    billingAccountDetails:
      state.fulfillment?.delivery?.deliveryDetails?.billingAccountDetails,
    iphone: state.iphone,
    rrpCart: state.rrpCart
  }));
  const dispatch = useDispatch();

  const isLoading = orderSummary.isLoading;
  const isError = orderSummary.isError;
  const { Loader } = useLoader({
    options: { type: SkeletonType.sideBar },
    loadings: [isLoading],
    skeletonLoadings: [user.isLoading, orderSummary.isShowSkeletonLoader]
  });
  const { renderError } = useErrorHandler({
    states: [
      {
        isError,
        redirectUrl: getCatalogPath()
      }
    ]
  });

  const variantBySku = useMemo(
    () => getProductBySku(flattenNodes(allRrpProduct)),
    [allRrpProduct]
  );

  useUser();
  const isOnePass = user.information?.userDetails?.loginId;
  const needMask = user.information?.userDetails && !isOnePass;

  useEffect(() => {
    if (userInformation?.clientContext) {
      dispatch(
        orderSummaryActions.getOrderSummaryData({
          customerId:
            userInformation?.clientContext?.customers?.[0]?.customerId,
          productOrderId: productOrder?.productOrderId,
          productOrderReferenceNumber:
            productOrder?.productOrderReferenceNumber,
          isRrpIppOrder: true
        })
      );
    }
  }, [userInformation?.clientContext]);

  usePaymentRedirection(
    orderSummary,
    {
      thankYouPageUrlSlug: navigation.RRP_IPP_ORDER_CONFIRMATION
    },
    rrpCart,
    fulfilmentState
  );

  const orderSummaryData = useMemo(() => {
    if (Object.keys(orderSummary?.quoteData)?.length) {
      const orderDeliveryMethod = orderSummary.quoteData?.deliveryMethod;
      const orderTotal = getOrderSummaryDataModelRrpIppOrderTotal({
        mobile: orderSummary.quoteData.mobile
      });

      let deliveryAddress;
      if (
        MCSS_DELIVERY_METHOD_TO_AEM[orderDeliveryMethod] ===
          FULFILMENT_TYPES_AEM.POP_STATION &&
        Boolean(orderSummary.quoteData.collectionAddress)
      ) {
        deliveryAddress = fulfilmentState.popstations.data.find(
          (item: MCSSPopstationAddress) =>
            item.PostCode === orderSummary.quoteData.collectionAddress.postcode
        );
      } else {
        deliveryAddress = orderSummary.quoteData.deliveryAddress;
      }

      return {
        infos: [
          // Personal Details
          getPersonalInfo({
            userContact: user.information?.clientContext?.contact,
            needMask
          }),
          // Billing Address
          {
            ...getBillingAddressInfo(orderSummary.quoteData?.billingAddress),
            helperText: t('ORDER_SUMMARY_CHANGE_BILLING_LINER')
          }
        ],
        orders: [
          {
            info: {
              ...getOrderAddressInfo({
                deliveryAddress: deliveryAddress,
                deliveryStartDateAndTime:
                  orderSummary.quoteData.deliveryStartDateAndTime,
                deliveryTimeSlot: orderSummary.quoteData.deliveryTimeSlot
              }),
              title:
                DELIVERY_METHOD_TO_TEXT[
                  MCSS_DELIVERY_METHOD_TO_AEM[orderDeliveryMethod]
                ] || '',
              action: {
                text: 'Change',
                onClick: () => {
                  navigate(`/${navigation.RRP_IPP_FULFILMENT}`);
                }
              }
            },
            productsList: getOrderProductsListInfo({
              accessories: orderSummary.quoteData.accessories,
              skuData: variantBySku
            }),
            total: orderTotal
          }
        ],
        bills: getOrderSummaryDataModelRrpIppBill({
          mobile: orderSummary.quoteData.mobile,
          accessory: orderSummary.quoteData.accessories[0]
        }),
        total: {
          payTotalAmount: orderTotal.finalAmount
        }
      };
    }
  }, [user, orderSummary.quoteData]);

  const handleTermsLinkClick = () => {
    setTncModal(true);
  };

  const handleDataProtectionLinkClick = () => {
    window.open(dppLink, '_blank', 'noopener,noreferrer');
  };

  const handlePlaceOrder = () => {
    window.sessionStorage.setItem('flow', '');
    const isZeroUpfront = isZeroUpfrontCharges(orderSummary?.quoteData);
    const { barId, customerId } = getAccountBillingInfo({
      checkout,
      delivery: fulfilmentState.delivery,
      userInformation,
      productOrder
    });

    const paymentRequestData = getPaymentRequestParams({
      quoteData: orderSummary?.quoteData,
      productOrder,
      cartOrder: cart.order,
      cartItems: cart.cartOrder,
      userInformation,
      allocationStatusArray:
        fulfilmentState?.allocateOrReserve?.data?.allocationStatusX9,
      reservationStatusArray:
        fulfilmentState?.allocateOrReserve?.reserveEquipmentData
          ?.reservationStatusX9,
      customerId: customerId,
      deliveryMode: fulfilmentState.delivery?.selectedDeliveryMode,
      selectedPlan: undefined,
      selectedProduct: undefined,
      checkout,
      billingAccountDetails,
      paymentType: PaymentType.OFFLINE, // online only if there is a payment option popup
      contractList: orderSummary?.contractList,
      authHeaders: auth.headers,
      merchantCode: config?.merchantId,
      checkoutBarId: barId,
      lineOfBusiness: orderSummary?.quoteData?.lineOfBusiness,
      billPayer: auth.headers[CONSTANTS.HEADERS.HTTP_BILL_PAYER],
      barId,
      storeEAppData: fulfilmentState?.delivery?.storeEAppData,
      iphone,
      isRrpIppOrder: true
    });

    dispatch({ type: ACTION_TYPES.THIRD_PARTY.UPDATE_INDICATOR, payload: {} });
    dispatch(
      orderSummaryActions.requestPayment(
        userInformation?.clientContext?.contact?.contactId,
        {
          paymentRequestData,
          paymentIntentRequestData: isZeroUpfront
            ? null
            : getPaymentIntentParams({
                userInformation,
                customerId,
                contactAddress,
                quoteData: orderSummary?.quoteData,
                productOrderReferenceNumber:
                  productOrder?.productOrderReferenceNumber
              }),
          customerId,
          billingAccountId: barId
        }
      )
    );
  };

  return (
    <Grid>
      {renderError()}
      <Loader>
        {orderSummaryData && (
          <OrderSummaryRewamp
            data={orderSummaryData}
            callbacks={{ onPlaceOrder: handlePlaceOrder }}
            configs={{
              placeOrderButton: { loading: orderSummary?.isLoading }
            }}
            localisation={{
              PROCEED: 'Place order',
              ORDER_SUMMARY_TNC_LABEL: t('TERMS_AND_CONDITION', {
                tnc: (
                  <TextLink
                    type="body"
                    data-testid="terms-link"
                    onClick={handleTermsLinkClick}
                  >
                    Terms and Condition
                  </TextLink>
                ) as APP_TYPE_ANY,
                sdpp: (
                  <TextLink
                    type="body"
                    onClick={handleDataProtectionLinkClick}
                    data-testid="data-protection-link"
                  >
                    Singtel Data Protection Policy.
                  </TextLink>
                ) as APP_TYPE_ANY
              })
            }}
          />
        )}
      </Loader>

      <TermsAndConditions isModalOpen={tncModal} setIsModalOpen={setTncModal} />
    </Grid>
  );
};

export default RrpIppOrderSummary;
