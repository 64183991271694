/* istanbul ignore file */
export default {
  MODAL_TYPE: {
    EMPTY_CART: 'EMPTY_CART',
    EXCEED_AMOUNT: 'EXCEED_AMOUNT',
    SESSION_TIMEOUT: 'SESSION_TIMEOUT',
    SESSION_EXPIRED: 'SESSION_EXPIRED'
  },
  ORDER_FAILURE_STATUS: {
    MTPOS_RESERVATION_ID: '0',
    ORDER_ITEM_STATUS: 1180
  },
  QUANTITY_EXCEED_LIMIT_TEXT: {
    SINGLE_ITEM: 'Only 1 item left',
    MAX_ALLOWED_ITEM: 'Maximum quantity for this item reached'
  },
  CART_ITEM_TYPES: {
    FULL_PRICE: 'fullPrice',
    IPP: 'ipp'
  }
};
