import { useEffect, useRef } from 'react';
import { APP_TYPE_ANY } from '../../types/common.types';

export const useInit = (
  callback: () => void,
  dps: APP_TYPE_ANY[],
  byPassCondition?: boolean
): void => {
  const initRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      (dps.every(dependency => Boolean(dependency)) || byPassCondition) &&
      !initRef.current
    ) {
      callback();
      initRef.current = true;
    }
  }, dps);
};
