import { ACTION_TYPES } from '../constants/actions';
import { APP_TYPE_ANY } from '../types/common.types';

const THIRD_PARTY = ACTION_TYPES.THIRD_PARTY;

interface ThirdPartyState {
  indicator: { [key: string]: APP_TYPE_ANY };
  hasError?: boolean;
  errorCode?: string;
}

export const initialState: ThirdPartyState = {
  indicator: {},
  hasError: false,
  errorCode: ''
};

type ThirdPartyActions = {
  payload?: APP_TYPE_ANY;
  type: string;
};

export const thirdPartyReducer = (
  state: ThirdPartyState = initialState,
  action: ThirdPartyActions
) => {
  switch (action.type) {
    case THIRD_PARTY.UPDATE_INDICATOR: {
      return {
        ...state,
        indicator: action.payload
      };
    }
    case THIRD_PARTY.UPDATE_INDICATOR_ERROR: {
      return {
        ...state,
        hasError: true,
        errorCode: action.payload.errorCode
      };
    }
    case THIRD_PARTY.UPDATE_INDICATOR_SUCCESS: {
      return {
        ...state,
        hasError: false,
        errorCode: ''
      };
    }
  }
  return state;
};
