/* istanbul ignore file */
export default {
  CHANGE_PLAN: 'CHANGE_PLAN',
  CLEAR_CART: 'CLEAR_CART',
  SELECTION_MODAL: 'SELECTION_MODAL',
  CHANGE_NUMBER: 'CHANGE_NUMBER',
  DEVICE_IPP_TITLE: 'Device Instalment Plan',
  ACTION_TYPE: {
    CHANGE: 'CH',
    REMOVE: 'RM'
  },
  VOUCHER_TYPE: {
    RECON_VOUCHER: '22',
    RED_VOUCHER: '25'
  },
  FLOW_TYPES: {
    MOBILE_SHARE_PLAN: 'mobileSharePlan',
    SIM_ONLY_PLAN: 'simOnlyPlan',
    DEVICE_FIRST: 'deviceFirst'
  },
  TNC_LINK:
    'https://www.singtel.com/personal/terms/postpaid-mobile-terms#tradein',
  XO_PLUS_PLANS: 'XO Plus Plans',
  SIM_ONLY_PLUS_PLAN: 'SIM Only Plus',
  VOUCHER_REDEMPTION_TYPE: {
    IMMEDIATE: 'Immediate',
    REBATE: 'Rebate',
    NA: 'NA'
  },
  ROAMING_BUNDLE: 'ROAMING BUNDLE'
};
