import { APP_TYPE_ANY, KeyValue } from '../types/common.types';
import { getCartDisplayedData } from '../helpers/cart';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';
import { ACTION_TYPES } from '@detox/actions';
import { Contract, OrderDetails } from '../types/orderSummary.types';

export interface UpdatedContact {
  emailId: string;
  phone: string;
  [key: string]: APP_TYPE_ANY;
}
export interface OrderSummaryState {
  isLoading?: boolean;
  isContactUpdating?: boolean;
  isContactError?: boolean;
  updatedContact?: UpdatedContact;
  isShowSkeletonLoader?: boolean;
  isError?: boolean;
  quoteData?: KeyValue;
  order?: OrderDetails;
  contractList?: Contract[];
  paymentIntent?: KeyValue;
  requestPaymentSuccess?: boolean;
  refId?: string;
  isSuccess?: boolean;
  quoteFetched?: boolean;
  productsList?: KeyValue[];
  isBuyingWithoutDevice?: boolean;
  paymentErrorResponse?: KeyValue;
}

export interface OrderSummaryAction {
  type: string;
  value?: KeyValue;
  payload?: KeyValue;
}

export const initialState: OrderSummaryState = {
  isError: false,
  isLoading: false,
  isContactUpdating: false,
  isContactError: false,
  updatedContact: null,
  quoteData: {},
  order: {},
  contractList: [],
  isShowSkeletonLoader: false,
  requestPaymentSuccess: false,
  isSuccess: false,
  quoteFetched: false,
  productsList: [],
  isBuyingWithoutDevice: false
};

export const orderSummaryReducer = (
  state: OrderSummaryState = initialState,
  action: OrderSummaryAction
) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_ORDER_PRODUCT.UPDATE_ORDER_PRODUCT_LOADING:
    case ACTION_TYPES.ORDER_SUMMARY.FETCH_ORDER_SUMMARY_LOADING: {
      return {
        ...state,
        isShowSkeletonLoader: true,
        isError: false
      };
    }
    case ACTION_TYPES.UPDATE_ORDER_PRODUCT.UPDATE_ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        isShowSkeletonLoader: false
      };
    case ACTION_TYPES.ORDER_SUMMARY.FETCH_ORDER_SUMMARY_SUCCESS: {
      return {
        ...state,
        isShowSkeletonLoader: false,
        isError: false,
        quoteData: action.value,
        order: action.value?.mobile ? getCartDisplayedData(action.value) : {},
        quoteFetched: true
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.GET_CONTRACT_FAILED:
    case ACTION_TYPES.UPDATE_ORDER_PRODUCT.UPDATE_ORDER_PRODUCT_ERROR:
    case ACTION_TYPES.ORDER_SUMMARY.FETCH_ORDER_SUMMARY_ERROR: {
      return {
        ...state,
        isShowSkeletonLoader: false,
        isError: true
      };
    }

    case ACTION_TYPES.ORDER_SUMMARY.REQUEST_PAYMENT_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.REQUEST_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        paymentIntent: action.value?.paymentIntent,
        refId: action.value?.refId,
        requestPaymentSuccess: true
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.REQUEST_PAYMENT_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        paymentErrorResponse: action.value
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.GET_CONTRACT_LOADING: {
      return {
        ...state,
        isShowSkeletonLoader: true
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.GET_CONTRACT_SUCCESS: {
      return {
        ...state,
        isShowSkeletonLoader: false,
        contractList: action.value
      };
    }
    case SHOP_ACTION_TYPES.ORDER.SET_PRODUCTS_LIST: {
      const { productsList, isBuyingWithoutDevice } = action.value;
      return {
        ...state,
        productsList,
        isBuyingWithoutDevice
      };
    }
    case 'SUBMIT_ORDER_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    }
    case 'SUBMIT_ORDER_SUCCESS': {
      return {
        ...state,
        submitOrderData: action.value,
        isSuccess: true,
        isLoading: false,
        error: false
      };
    }
    case 'SUBMIT_ORDER_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: true,
        isSuccess: false
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.CUSTOMER_CONTACT_UPDATE_LOADING: {
      return {
        ...state,
        isContactUpdating: true,
        isContactError: false,
        updatedContact: null
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.CUSTOMER_CONTACT_UPDATE_SUCCESS: {
      const {
        implContactPerson: { emailId = '', phone = '' }
      } = action.value || {};

      return {
        ...state,
        isContactUpdating: false,
        isContactError: false,
        updatedContact: { emailId, phone }
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.CUSTOMER_CONTACT_UPDATE_FAILURE: {
      return {
        ...state,
        isContactUpdating: false,
        isContactError: true,
        updatedContact: null
      };
    }
    case SHOP_ACTION_TYPES.ORDER_SUMMARY.CLEAR_PAYMENT_INTENT: {
      return {
        ...state,
        paymentIntent: null,
        requestPaymentSuccess: false
      };
    }
    case SHOP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return {
        ...initialState,
        quoteFetched: state.quoteFetched,
        refId: state.refId
      };
    }
    case SHOP_ACTION_TYPES.ORDER.VALIDATE_REQUEST_PAYMENT_FAILED: {
      return {
        ...state,
        isError: true
      };
    }
  }
  return state;
};
