import React, { ReactElement } from 'react';
import { MODAL_TYPES } from '../helper';
import { Row, Text, Modal, BulletList, Spacing } from '@dls/web';
import { ImageContainer, StyledColumn, StyledModalDiv } from '../styles';
import useTranslate from '../../../hooks/useTranslation';
import nricFrontImg from '../../../assets/images/checkout/nric-front.png';
import nricBackImg from '../../../assets/images/checkout/nric-back.png';
import wpFrontImg from '../../../assets/images/checkout/wp-front.png';
import wpBackImg from '../../../assets/images/checkout/wp-back.png';
import kycInstruction from '../../../assets/images/checkout/kyc_instruction.png';
import staffPassImg1 from '../../../assets/images/checkout/staffpass-1.png';
import staffPassImg2 from '../../../assets/images/checkout/staffpass-2.png';

interface IModalInterface {
  type: string;
  showModal: boolean;
  hideModal: () => void;
}

const InformationModal: React.FC<IModalInterface> = ({
  showModal,
  hideModal,
  type
}): ReactElement => {
  const { t } = useTranslate();
  const modalConfig = {
    visible: showModal,
    onClose: hideModal,
    bgColor: 'white'
  };

  switch (type) {
    case MODAL_TYPES.RES_DOCUMENT:
      return (
        <Modal title={t('CO_NN_DOCUMENT_COPIES')} {...modalConfig}>
          <Modal.Content>
            <Text type="body">{t('CO_NN_DOCUMENT_DESCLAIMER')}</Text>
            <StyledModalDiv>
              {t('CO_NN_DOCUMENT_LIST').map(documentType => (
                <BulletList>{documentType}</BulletList>
              ))}
            </StyledModalDiv>
          </Modal.Content>
        </Modal>
      );
    case MODAL_TYPES.ID_CARD_NRIC:
    case MODAL_TYPES.ID_CARD_STAFF:
    case MODAL_TYPES.ID_CARD_NRIC_STAFF:
      return (
        <Modal title={t('CO_NN_UPLOAD_COPIES')} {...modalConfig}>
          <Modal.Content>
            {type !== MODAL_TYPES.ID_CARD_STAFF && (
              <>
                <Spacing bottom={2} top={1}>
                  <Text type="boldBody">{t('CO_NN_UPLOAD_ID_NRIC')}</Text>
                </Spacing>
                <StyledModalDiv>
                  <Row>
                    <StyledColumn>
                      <img src={nricFrontImg} alt={t('CO_NN_UPLOAD_ID_NRIC')} />
                    </StyledColumn>
                    <StyledColumn>
                      <img src={nricBackImg} alt={t('CO_NN_UPLOAD_ID_NRIC')} />
                    </StyledColumn>
                  </Row>
                </StyledModalDiv>
                <Spacing topBottom={2}>
                  <Text type="boldBody">{t('CO_NN_UPLOAD_ID_EPASS')}</Text>
                  <Text type="body">{t('CO_NN_UPLOAD_ID_EPASS_SUB')}</Text>
                </Spacing>
                <StyledModalDiv>
                  <Row>
                    <StyledColumn>
                      <img src={wpFrontImg} alt={t('CO_NN_UPLOAD_ID_EPASS')} />
                    </StyledColumn>
                    <StyledColumn>
                      <img src={wpBackImg} alt={t('CO_NN_UPLOAD_ID_EPASS')} />
                    </StyledColumn>
                  </Row>
                </StyledModalDiv>
              </>
            )}

            {type === MODAL_TYPES.ID_CARD_NRIC_STAFF ||
            type === MODAL_TYPES.ID_CARD_STAFF ? (
              <>
                <Spacing top={3} bottom={2}>
                  <Text type="boldBody">{t('CO_NN_UPLOAD_ID_STAFF_PASS')}</Text>
                </Spacing>
                <StyledModalDiv>
                  <Row>
                    <StyledColumn sm={12} md={6}>
                      <img
                        src={staffPassImg1}
                        alt={t('CO_NN_UPLOAD_ID_STAFF_PASS')}
                      />
                    </StyledColumn>
                    <StyledColumn sm={12} md={6}>
                      <img
                        src={staffPassImg2}
                        alt={t('CO_NN_UPLOAD_ID_STAFF_PASS')}
                      />
                    </StyledColumn>
                  </Row>
                </StyledModalDiv>
              </>
            ) : null}
          </Modal.Content>
        </Modal>
      );
    case MODAL_TYPES.KYC: {
      return (
        <Modal title={t('CO_NN_KYC_MODAL_TITLE')} {...modalConfig}>
          <Modal.Content>
            <Spacing top={1}>
              <Text type="body">{t('CO_NN_KYC_MODAL_SUB_TITLE')}</Text>
            </Spacing>
            <ImageContainer top={2} leftRight={3}>
              <img src={kycInstruction} alt={t('CO_NN_KYC_MODAL_TITLE')} />
            </ImageContainer>
          </Modal.Content>
        </Modal>
      );
    }
    default:
      return null;
  }
};

export default InformationModal;
