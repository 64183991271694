export default {
  AEM_COMPONENTS: {
    SITE_CONFIGURATIONS: 'SiteConfigurations'
  },
  UXF_SESSION_TOKEN: 'UXF_SessionToken',
  DETOX_LIGHTSABER_SESSION_TOKEN: 'Detox_LightsaberSessionToken',
  Detox_MasterOrderId: 'Detox_MasterOrderId',
  Detox_ProductOrderId: 'Detox_ProductOrderId',
  RrpData: 'RrpData',
  UIAM_ACCESS_TOKEN: 'online_usertoken',
  APIGEE_AUTH_TOKEN: 'ApigeeAuthToken',
  REVOKE_SUCCESS: 'REVOKE_SUCCESS',
  AUTH_UNAUTHORIZED: 401,
  IDPF_VERIFY_ERRORS: [
    'TOKEN_EXPIRED',
    'INVALID_INPUT',
    'TOKEN_VALIDATION_FAILED',
    'INTERNAL_SERVER_ERROR'
  ],
  AUTH_FAULT_ERRORS: ['INVALID_ACCESS_TOKEN', 'ACCESS_TOKEN_EXPIRED'],
  MS_MAINLINE_PLANS: ['SIMONLYPLUS55', 'COMBO', 'XO'],
  SINGAPORE: 'Singapore',
  AEM_MS_MAINLINE_GROUP: 'MOBILESHARE',
  // hardcode all customer support data for now, may change in future.
  THANK_YOU_PAGE_CUSTOMER_SUPPORT: {
    domesticNumber: '1609',
    domesticNumberText: `Call 1609`,
    availableTime: '9:00AM - 5:30PM daily',
    whatsapp: '6590181688',
    whatsappAvailableTime: '24-hours daily'
  },
  HEADERS: {
    HTTP_BILL_PAYER: 'HTTP_BILL_PAYER'
  },
  URL_PARAMS: {
    NEED_VALIDATE_ONE_PASS_USER: 'needValidateOnePassUser',
    INFO_TITLE: 'PENDING_ORDER_TITLE',
    INFO_MESSAGE: 'PENDING_ORDER_MESSAGE',
    INFO_CTA_TEXT: 'PENDING_ORDER_CTA_TEXT',
    INFO_CALLBACK_URL: 'INFO_CALLBACK_URL',
    INFO_TYPE: 'INFO_TYPE',
    CHAT_WITH_US: 'CHAT_WITH_US',
    PLAN: 'plan',
    DEAL: 'deal',
    PAYMENT_OPTION: 'payment_option',
    SORT_TYPE: 'sort_type',
    PRICE_RANGE: 'price_range'
  },
  URL_PATHS: {
    PHONE: 'phones',
    RRP_PHONE: 'accessories/rrp-products'
  },
  PHONE_CODE: '+65',
  INVALID: 'invalid',
  PAYMENT_REDIRECT_FLOW: {
    RRP: 'RRP'
  },
  ORDER_CONFIRMATION_SECTIONS: {
    YOU_MAY_NEED_TO_KNOW: 'YouMayNeedToKnow',
    WHAT_NEXT: 'WhatNext',
    HELP_BOX: 'HelpBox',
    BACK_HOME: 'BackHome',
    FEEDBACK_CARD: 'FeedBackCard',
    PROMOTION_CARD: 'PromotionCard',
    NEXT_STEP: 'NextStep',
    ORDER_INFO: 'OrderInfo',
    ORDER_STATUS: 'OrderStatus',
    INFO: 'Info',
    PROMO_CODE: 'PromoCode',
    HELP_LINKS: 'HelpLinks',
    LI_HOOK_INSURANCE: 'LiHookInsurance',
    IMPORTANT_NOTE: 'ImportantNote',
    BULLET_LIST: 'BulletList'
  } /**TODO- CONFIRMATION SECTIONS to be exported from common module */,
  AMENDED_IN_OTHER_FLOW_ERROR: 'amended in other flow',
  DEFAULT_CURRENCY: 'SGD',
  SALUTATION: {
    MR: 'MR',
    MRS: 'MRS',
    MS: 'MS',
    DR: 'DR'
  },
  GENDER: {
    FEMALE: 'F',
    MALE: 'M'
  },
  TRANSACTION_TYPE_VALUE: {
    RECONTRACT: 'recontract',
    NEW_SIGN_UP: 'newSignup'
  },
  SHARE_ICON: {
    COPY: 'Copy Link',
    WHATSAPP: 'Whatsapp',
    TELEGRAM: 'Telegram',
    FACEBOOK: 'Facebook',
    LINE: 'Line',
    MESSAGE: 'Message',
    NONE: 'none'
  }
};
