import { ORDER } from '../constants';
import { RED_PHASE } from '../types/featureFlag.types';
import { MCSSVoucher } from '../types/shoppingCart.types';
import { AemVoucherMatrix } from '../types/voucher.types';
import isFeatureFlagEnabled from './feature-flags';

interface VoucherByServiceId {
  [key: string]: MCSSVoucher[];
}

export const voucherGroupBy = (
  data: MCSSVoucher[] = [],
  needle: string = 'serviceNoX8'
): VoucherByServiceId => {
  const other = 'other';
  const voucherByServiceId = data.reduce(
    (acc: VoucherByServiceId | {}, cur) => {
      const key = cur[needle] || other;

      acc[key] = key in acc ? [...acc[key], cur] : [cur];

      return acc;
    },
    {}
  );

  if (voucherByServiceId[other]?.length) {
    Object.keys(voucherByServiceId).forEach(serviceId => {
      if (serviceId !== other) {
        voucherByServiceId[serviceId].push(...voucherByServiceId[other]);
      }
    });
  }

  return voucherByServiceId;
};

interface GetVoucherRedemptionTypeOrder {
  productOrderType: string;
  hasDevice: boolean;
  planId: string;
}

interface GetVoucherRedemptionTypeReturnValue {
  [key: string]: string;
}

export const getRedemptionType = (
  matrix: AemVoucherMatrix,
  { productOrderType, hasDevice, planId }: GetVoucherRedemptionTypeOrder
) => {
  let redemption: GetVoucherRedemptionTypeReturnValue = {};

  if (isFeatureFlagEnabled(RED_PHASE)) {
    for (const type in matrix) {
      const rules = matrix[type].rules;

      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i].validations;
        let mismatch = Object.keys(rules[i].validations).length;

        switch (true) {
          case productOrderType === ORDER.TYPE.NEW &&
            rule.transactionType?.newSignup:
          case productOrderType === ORDER.TYPE.RECON &&
            rule.transactionType?.recontract:
            mismatch -= 1;

            break;

          default:
            break;
        }

        if (rule.withDevice === hasDevice) {
          mismatch -= 1;
        }

        if (rule.plansList?.includes(planId)) {
          mismatch -= 1;
        }

        if (mismatch === 0) {
          redemption[type] = rules[i].redemptionType;
          break;
        }
      }

      if (redemption[type] === undefined) {
        redemption[type] = matrix[type].defaultRedemptionType;
      }
    }
  }

  return redemption;
};
