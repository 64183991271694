import { RIBBON_TEXT, RRPCART } from '../constants';
import formatPrice from '../helpers/formatPrice';
import { trans as t } from '../helpers/localisation';
import { PRODUCT } from '../constants/product';
import CART_CONSTANT from '../constants/cart';
import RRP_CART_CONSTANTS from '../constants/rrpcart';
import { OrderItemDetail, RRPOrderProduct } from '@detox/actions';
import { IVariant } from '@common-modules/product-catalogue';
import { RRPCartState } from '../reducers/rrpCart';
import { navigation } from '../middlewares/navigation-constants';

const { STOCK_STATUS } = PRODUCT;
const { ACTION_TYPE } = CART_CONSTANT;

let outOfStockItems;

const generatePackageOrder = (
  packageOrder,
  rrpStocks,
  orderItemInGroup,
  canIncreaseQuantity
) => {
  const {
    productDetails,
    actualPrice,
    quantity: skuQuantity,
    sku,
    identifier,
    image,
    ribbonText,
    productId
  } = packageOrder;
  const itemStock = rrpStocks.find(stockItem => stockItem.itemCode === sku);
  const isOutOfStock =
    !itemStock ||
    itemStock.availableQty < skuQuantity ||
    itemStock.status === STOCK_STATUS.NOT_AVAILABLE;
  if (isOutOfStock) {
    outOfStockItems = [
      ...outOfStockItems,
      {
        id: sku,
        name: productDetails.title
      }
    ];
  }

  let extraText = '';
  let allowedRemainingQuantity = itemStock?.availableQty;

  const product = orderItemInGroup[productId];
  if (product) {
    if (product.maxAllowedQuantity > 0) {
      allowedRemainingQuantity = product.maxAllowedQuantity - product.quantity;
    }

    if (isOutOfStock || allowedRemainingQuantity <= 0) {
      extraText = RRPCART.QUANTITY_EXCEED_LIMIT_TEXT.MAX_ALLOWED_ITEM;
    }
  }

  return {
    name:
      productDetails.title +
      (productDetails.color && `, ${productDetails.color}`),
    price: actualPrice.afterTax,
    sku,
    identifier,
    quantity: {
      value: skuQuantity,
      min: 1,
      max:
        isOutOfStock || !canIncreaseQuantity
          ? skuQuantity
          : skuQuantity + allowedRemainingQuantity
    },
    extraText,
    action: ACTION_TYPE.REMOVE,
    ...(ribbonText && {
      ribbon: ribbonText
    }),
    ...(image && {
      image
    }),
    heroImage: {
      imageFile: {
        childImageSharp: {
          fluid: {
            base64:
              'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAIEAQMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB7VW1qLQMRCYP/8QAGxABAAICAwAAAAAAAAAAAAAAAQIDEBIAETH/2gAIAQEAAQUCtUjGVm3LPHCdmhj/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAABBQEBAAAAAAAAAAAAAAABABARIXECMf/aAAgBAQAGPwKvUJ6kYw1DWt//xAAdEAEAAQQDAQAAAAAAAAAAAAABABARIUExUXGh/9oACAEBAAE/IdPpCFzw20p8ycvOh2oBRzjtp//aAAwDAQACAAMAAAAQE8A8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBABAQABBQEAAAAAAAAAAAAAAREQACExUXHw/9oACAEBAAE/EKFgIyyuhYi0J5eY+H3lkNKW7MR91EjVSqXvdx//2Q==',
            aspectRatio: 1,
            src:
              '/static/54c5fa1631bcd3a7f187e0310f59ea55/a3df1/default-phone-heroImg.jpg',
            srcSet:
              '/static/54c5fa1631bcd3a7f187e0310f59ea55/894b0/default-phone-heroImg.jpg 250w,\n/static/54c5fa1631bcd3a7f187e0310f59ea55/a3df1/default-phone-heroImg.jpg 380w',
            sizes: '(max-width: 380px) 100vw, 380px'
          }
        }
      },
      altText: null
    }
  };
};

export const getShoppingCartDataModel = (
  rrpOrder,
  rrpStocks,
  variantBySku = {},
  canIncreaseQuantity
) => {
  let dataModel = {};
  let itemQuantity = 0;

  if (!Object.keys(rrpOrder || {}).length) {
    return dataModel;
  }

  if (rrpOrder.ImplRRPOrderDetails.length) {
    const {
      ImplRRPOrderDetails,
      actualPrice,
      saveAmount,
      retailPrice
    } = rrpOrder;
    outOfStockItems = [];
    const rrp = ImplRRPOrderDetails.map(packageItem => {
      const {
        orderItems,
        promotions,
        actualPrice,
        discountedPrice,
        retailPrice,
        orderDeliveryDetails
      } = packageItem;

      let packageItemCount = 0;
      const orderItemInGroup = {};

      const orderItemData = orderItems.map(orderItem => {
        const { quantity, sku } = orderItem;

        itemQuantity += quantity;
        packageItemCount += quantity;

        const variant = variantBySku[sku];

        if (variant) {
          const productId = variant.productId;
          const product = orderItemInGroup[productId];

          orderItem.productId = productId;

          if (product) {
            product.quantity += quantity;
          } else {
            orderItemInGroup[productId] = {
              quantity,
              maxAllowedQuantity: variant?.maxAllowedQuantity
            };
          }

          if (variant.smallImage) {
            orderItem.image = {
              ...variant.smallImage,
              src: process.env.GATSBY_AEM_URL + variant.smallImage.src
            };
          }

          if (variant.ribbonText?.toUpperCase() === RIBBON_TEXT.PRE_ORDER) {
            orderItem.ribbonText = t('PRE-ORDER');
          }
        }

        return orderItem;
      });

      const dataSource = orderItemData.map(item =>
        generatePackageOrder(
          item,
          rrpStocks,
          orderItemInGroup,
          canIncreaseQuantity
        )
      );

      if (orderDeliveryDetails?.deliveryPrice?.afterTax) {
        dataSource.push({
          name: t('DROP_SHIPPING.DELIVERY_FEE.TEXT'),
          price: orderDeliveryDetails.deliveryPrice.afterTax
        });
      }

      let discount = null;
      if (promotions?.length) {
        const discountItems = promotions.map(item => ({
          name: item.promoText,
          price: formatPrice(item?.price?.afterTax || -item?.stockCodeAmount)
        }));
        discount = {
          totalAmount: formatPrice(Math.abs(discountedPrice.afterTax)),
          dataSource: discountItems
        };
      }
      return {
        dataSource: dataSource,
        total: {
          price: actualPrice.afterTax,
          slashPrice:
            actualPrice.afterTax < retailPrice.afterTax
              ? retailPrice.afterTax
              : undefined
        },
        discount
      };
    });

    dataModel = {
      itemQuantity,
      rrp,
      outOfStockItem: {
        dataSource: [...outOfStockItems]
      },
      total: {
        payTotalAmount: actualPrice?.afterTax,
        beforeDiscountAmount:
          actualPrice?.afterTax < retailPrice?.afterTax
            ? retailPrice?.afterTax
            : undefined,
        saveAmount: saveAmount > 0 ? saveAmount : undefined
      }
    };
  }
  return dataModel;
};

export const flattenRRPProductsList = orderDetails => {
  let productsList = [];
  for (const item of orderDetails) {
    const temp = item.orderItems.map(orderItem => ({
      ...orderItem,
      orderId: item.orderId
    }));
    productsList = [...productsList, ...temp];
  }
  return productsList;
};

const getProductCount = productsList => {
  return productsList.reduce((count, product) => {
    count[product.orderId] = (count[product.orderId] || 0) + 1;
    return count;
  }, {});
};

const findOrderItem = (productsList, sku) => {
  return productsList.find(product => product.sku === sku);
};

const determineOrderFlags = (orderItem, productCount) => {
  const isOnlyProductInOrder =
    orderItem && productCount[orderItem.orderId] === 1;
  const isCancelMasterOrder =
    isOnlyProductInOrder && Object.keys(productCount).length === 1;
  return { isOnlyProductInOrder, isCancelMasterOrder };
};

export const getProductOrderItem = (productsList, sku) => {
  const productCount = getProductCount(productsList);
  const orderItem = findOrderItem(productsList, sku);
  const { isOnlyProductInOrder, isCancelMasterOrder } = determineOrderFlags(
    orderItem,
    productCount
  );

  if (orderItem && Array.isArray(orderItem.orderItemDetails)) {
    const orderItemIds = orderItem.orderItemDetails.map(
      itemDetail => itemDetail.orderItemId
    );

    return {
      sku,
      identifier: orderItem.identifier,
      quantity: orderItem.quantity,
      productDetails: orderItem.productDetails,
      orderId: orderItem.orderId,
      orderItemIds,
      isOnlyProductInOrder,
      isCancelMasterOrder
    };
  } else {
    return {
      sku,
      identifier: null,
      quantity: null,
      productDetails: {},
      orderId: null,
      orderItemIds: []
    };
  }
};

export const getOrderItemDetails = ({
  product,
  quantity = 1
}: {
  product: IVariant;
  quantity: number;
}): RRPOrderProduct[] => {
  if (!product) return [];
  const orderItemDetails: RRPOrderProduct[] = new Array(quantity)
    .fill(null)
    .map(() => {
      return {
        sku: product.sku,
        identifier: product.deviceId,
        productDetails: {
          brand: product.brand,
          color: product.colorCode,
          groupId: product.groupId,
          hexCode: product.colorHexCode,
          model: product.rrpModel,
          price: product.price,
          title:
            product.deviceTitle +
            (product.memorySize ? `, ${product.memorySize}` : ''),
          size: product.memorySize
        }
      };
    });
  return orderItemDetails;
};

export const isCartHavingIppItem = (rrpCart: Partial<RRPCartState>) => {
  return rrpCart?.addedItems?.find(
    item => item.itemType === RRP_CART_CONSTANTS.CART_ITEM_TYPES.IPP
  );
};

export const getCatalogPath = () => {
  return `/${navigation.RRP_PRODUCT_CATALOG}`;
};
