import React, { useState } from 'react';
import { Modal, Button, Text, Spacing, Row, Column } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import ChooseSIMType from '../ChooseSIMType';
import {
  CHOOSE_SIM_TYPE,
  ChooseSimType,
  SimTypeId
} from '../../constants/choose-sim-type';
import { FieldObject } from '../../types/registrationCheckout';

interface Props {
  isModalOpen: boolean;
  title: string;
  subTitle: string;
  selectedSimType: SimTypeId;
  onClose: () => void;
  onProceed: (selected4Gto5GSim: SimTypeId) => void;
  chooseSIMOptions: ChooseSimType[];
  selectedProduct: FieldObject;
}

const columnProps = {
  xs: 12,
  md: 6,
  noGutter: false
};

export const SelectSimTypeModal: React.FC<Props> = props => {
  const {
    isModalOpen,
    title,
    subTitle,
    chooseSIMOptions,
    selectedSimType,
    selectedProduct,
    onClose,
    onProceed
  } = props;
  const [selected4Gto5GSim, setSelected4Gto5GSim] = useState<SimTypeId>();
  const filteredSIMOptions = (chooseSIMOptions || []).filter(
    simOptions => simOptions.simTypeId !== CHOOSE_SIM_TYPE.SIM_TYPE_noSIM
  );

  return (
    <Modal
      data-testid={`addon-modal-sim-type`}
      visible={isModalOpen}
      title={title}
      closable={true}
      onClose={onClose}
      wide={true}
    >
      <Modal.Content>
        <Spacing bottom={2}>
          <Text type="body">{subTitle}</Text>
        </Spacing>

        <ChooseSIMType
          data={filteredSIMOptions}
          showSubtitle
          transactionTypeValue="recontract"
          selectedProduct={selectedProduct}
          preSelectedSimType={
            selectedSimType !== CHOOSE_SIM_TYPE.SIM_TYPE_noSIM
              ? selectedSimType
              : null
          }
          onSimTypeOnSelection={simTypeValue => {
            setSelected4Gto5GSim(simTypeValue);
          }}
          updateSimTypeOnSelection
          simTypeDesignConfig={{
            titlesNeeded: false,
            ctaNeeded: false,
            dividerNeeded: false,
            typeHeight: '8rem',
            simTypeColumnProps: columnProps,
            forceCostFree: true
          }}
        />

        <Spacing topBottom={2}>
          <Row>
            <Column xs={12} sm={4} noGutter>
              <Button
                fullWidth={true}
                primary
                data-testid="simtype-proceed-button"
                text={t('PROCEED_TO_CART')}
                onClick={() => onProceed(selected4Gto5GSim)}
              />
            </Column>
          </Row>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default SelectSimTypeModal;
