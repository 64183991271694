/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { configInterceptor } from '@wec-core/interceptor';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { GATEMANAGER } from '../../types/featureFlag.types';
import { useDispatch, useSelector } from 'react-redux';
import {
  apigeeAuthActions,
  cartActions,
  getDataFromErrorRes
} from '@detox/actions';
import {
  getErrorResponseHeaders,
  isApigeeAuthFailure,
  isErrorMatchFound
} from '../../helpers/common';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { navigate } from 'gatsby';
import { CONFIGS } from '../../config/common';
import { navigation } from '../../middlewares/navigation-constants';

const useInterceptor = (): void => {
  const dispatch = useDispatch();
  const isWhiteListedPage = () => {
    return !![`/${navigation.THANK_YOU}`].find(urlSlug =>
      window.location.pathname?.includes(urlSlug)
    );
  };
  const [isTokenRefreshed, setRefreshToken] = useState(false);
  const { productOrder, user } = useSelector((state: KeyValue) => {
    return {
      productOrder: state?.order?.productOrder,
      user: state?.user
    };
  });

  /**
   * Fires the action to empty the cart
   *
   * @returns {Promise<void>}
   */
  const handleEmptyCart = async () => {
    if (productOrder) {
      const { productOrderId, productOrderItemId } = productOrder;
      const customerId =
        user?.information?.clientContext?.customers?.[0]?.customerId;
      await dispatch(
        cartActions.emptyCart({
          customerId,
          productOrderId,
          productOrderItemId
        })
      );
      dispatch({ type: 'CLEAR_DELIVERY' });
      navigate('/empty-shopping-cart');
    }
  };

  useEffect(() => {
    // Success response callbacks with 200 status codes
    const responseCallback = async (
      response: APP_TYPE_ANY,
      responseJson: APP_TYPE_ANY
    ) => {
      // handle idpf verify errors for UIAM
      const idpfVerifyError = await getErrorResponseHeaders(response);
      if (
        idpfVerifyError &&
        isErrorMatchFound(idpfVerifyError) &&
        !isWhiteListedPage()
      ) {
        await handleEmptyCart();
      }

      // handle gate manager redirection for traffic control
      if (
        isFeatureFlagEnabled(GATEMANAGER) &&
        responseJson?.code === CONFIGS.REQUEST_CODES.GATE_REDIRECT_SUCCESS
      ) {
        const waitUrl = JSON.parse(responseJson?.detail);
        window.open(waitUrl?.location, '_self');
      }
    };

    // Error response callbacks with 4**, 5**
    const handleErrorResponse = async (error: APP_TYPE_ANY) => {
      try {
        const errorResponse = await getDataFromErrorRes(error);
        if (isApigeeAuthFailure(errorResponse?.status, errorResponse?.fault)) {
          dispatch(
            apigeeAuthActions.getApigeeAuthToken(() => setRefreshToken(true))
          );
        }
      } catch (error) {
        console.error('Error handling response:', error);
      }
    };
    const errorCallback = async (error: APP_TYPE_ANY) => {
      await handleErrorResponse(error);
    };

    // Interceptor implementation in eshop
    configInterceptor({
      responseCallback,
      errorCallback,
      whiteListedDomains: [
        process.env.GATSBY_MCSS_HOST,
        process.env.GATSBY_AEM_URL
      ]
    });
  }, []);

  useEffect(() => {
    if (isTokenRefreshed) {
      setRefreshToken(false);

      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  }, [isTokenRefreshed]);
};

export default useInterceptor;
