import colours from './colours';

const states = {
  success: {
    background: colours.green_100,
    colour: colours.green_900,
    iconColour: colours.green_900
  },
  danger: {
    background: colours.red_100,
    colour: colours.primaryVariant,
    iconColour: colours.primaryVariant
  },
  info: {
    background: colours.blue_50,
    colour: colours.nearBlack,
    iconColour: colours.blue_1000
  }
};

export default states;
