import React, { ReactElement } from 'react';
import {
  Spacing,
  Text,
  Button,
  TextLink,
  Layout,
  Card,
  Ribbon,
  Divider
} from '@dls/web';
import {
  getAddonActions,
  getAddonPriceText,
  getAddonThumbnailConfig,
  getContactText,
  getPromotionText
} from '../addon-helper';
import { useToggleReadMore } from '../useToggleReadMore';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import {
  StyledCardContent,
  StyledDiv,
  StyledSpacing,
  StyledStackSpacing,
  StyledTruncatedDiv
} from '../addonStyles';

const renderPromoRibbon = ({ promoDescription, promotionText, atomic }) => {
  const promoRibbonText = getPromotionText({
    promoDescription,
    promotionText,
    atomic
  });

  if (!promoRibbonText) {
    return null;
  }

  return (
    <Spacing bottom={1}>
      <Ribbon.Variant1>{promoRibbonText}</Ribbon.Variant1>
    </Spacing>
  );
};

const renderFooterSection = ({
  ADDON_ACTIONS,
  addonAction,
  price,
  onetimePrice,
  addonAdded,
  contract
}) => {
  return (
    <>
      <Divider />
      <Card.Footer
        rightContent={
          <div onClick={ADDON_ACTIONS[addonAction].onClick}>
            <Button
              text={ADDON_ACTIONS[addonAction].text}
              {...ADDON_ACTIONS[addonAction].btnProps}
              data-testid={ADDON_ACTIONS[addonAction].dataTestID || ''}
            />
          </div>
        }
      >
        <StyledStackSpacing>
          <Layout.Stack align="left" alignY="middle" space={0}>
            <Text type="body">
              {getAddonPriceText({ price, onetimePrice })}
            </Text>

            {addonAdded && contract && contract !== '0' && (
              <Text type="body">{`${contract}-mth contract`}</Text>
            )}
          </Layout.Stack>
        </StyledStackSpacing>
      </Card.Footer>
    </>
  );
};

interface TProps extends KeyValue {
  addon: KeyValue;
}

const AddonsListItem = (props: TProps): ReactElement => {
  const { toggleReadMore, refItem } = useToggleReadMore();
  const { addon = {}, onAdd, onRemove, onLearnMore } = props;

  const {
    productTitle = '',
    productDescription = '',
    promoDescription = '',
    promotionText = '',
    atomic = false,
    contract = '',
    price = '',
    onetimePrice = ''
  } = addon || {};

  const { ADDON_ACTIONS, addonAction, addonAdded } = getAddonActions({
    onAdd,
    onLearnMore,
    onRemove,
    addon
  });

  const contractText = getContactText(contract);

  // TODO: remove the type casting - use FnArgumentType
  const thumbnailConfig = getAddonThumbnailConfig({
    addon
  } as APP_TYPE_ANY);

  return (
    <Card shadow="sm" radius="sm" padding="sm">
      <StyledCardContent>
        <StyledSpacing responsive={false}>
          <StyledDiv>
            {renderPromoRibbon({ promoDescription, promotionText, atomic })}
            <Card.Title {...thumbnailConfig}>
              <Text type="header">{productTitle}</Text>
              {!addonAdded && <Text type="smallBody">{contractText}</Text>}
            </Card.Title>

            <Spacing top={1}>
              <>
                <StyledTruncatedDiv ref={refItem}>
                  <Text type="body">{productDescription}</Text>
                </StyledTruncatedDiv>

                {toggleReadMore && (
                  <Spacing top={1}>
                    <TextLink
                      data-testid={ADDON_ACTIONS['LEARN_MORE'].dataTestID || ''}
                      inline
                      onClick={ADDON_ACTIONS['LEARN_MORE'].onClick}
                    >
                      {ADDON_ACTIONS['LEARN_MORE'].text}
                    </TextLink>
                  </Spacing>
                )}
              </>
            </Spacing>
          </StyledDiv>

          <Spacing top={2}>
            {renderFooterSection({
              ADDON_ACTIONS,
              addonAction,
              price,
              onetimePrice,
              addonAdded,
              contract
            })}
          </Spacing>
        </StyledSpacing>
      </StyledCardContent>
    </Card>
  );
};

export default AddonsListItem;
