import React, { ReactElement } from 'react';
import { navigate } from 'gatsby';
import { Modal, Grid, Row, Column, Spacing, Text, Button } from '@dls/web';
import { navigation } from '../../../middlewares/navigation-constants';
import { trans as t } from '../../../helpers/localisation';
import { StyledErrorButtonLayout } from '../addonStyles';

interface TProps {
  inModal?: boolean;
  onErrorModalClose?: () => void;
  isModalOpen?: boolean;
}

const AddonActionErrors = (props: TProps): ReactElement => {
  const { inModal = false, isModalOpen = false, onErrorModalClose } = props;

  if (inModal) {
    return (
      <Modal
        data-testid={`addon-unknown-error`}
        visible={isModalOpen}
        title={t('GENERIC_ERROR_TITLE') as string}
        closable={true}
        onClose={onErrorModalClose}
        wide={true}
      >
        <Modal.Content>
          <Spacing bottom={2}>
            <Text type="body">{t('GENERIC_ERROR_MESSAGE')}</Text>
          </Spacing>

          <Row>
            <Column noGutter xs={12} sm={6} md={6}>
              <Button
                fullWidth
                data-testid="error-addon-refresh"
                onClick={onErrorModalClose}
              >
                {t('OK_GOT_IT')}
              </Button>
            </Column>
          </Row>
        </Modal.Content>
      </Modal>
    );
  }

  return (
    <Spacing topBottom={3} leftRight={2}>
      <Grid>
        <Spacing bottom={2}>
          <Text type="pageTitle">{t('ADDON_ERROR_TITLE')}</Text>
        </Spacing>
        <Spacing bottom={3}>
          <Text type="boldBody">{t('ADDON_PAGE_ERROR_MESSAGE')}</Text>
        </Spacing>

        <Row>
          <Column noGutter xs={12} sm={6} md={6}>
            <StyledErrorButtonLayout>
              <Button
                secondary
                data-testid="error-addon-proceed-to-cart"
                onClick={() => navigate(`/${navigation.SHOPPING_CART_PAGE}`)}
              >
                {t('PROCEED_TO_CART')}
              </Button>
              <Button
                data-testid="error-addon-refresh"
                onClick={() => window.location.reload()}
              >
                {t('REFRESH_PAGE')}
              </Button>
            </StyledErrorButtonLayout>
          </Column>
        </Row>
      </Grid>
    </Spacing>
  );
};

export default AddonActionErrors;
