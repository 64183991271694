import React from 'react';
import { Modal, Button, Text, Spacing, useDevice } from '@dls/web';

const RRPModal = ({
  header,
  message,
  visible,
  ctaText,
  secondary = false,
  onClose,
  onButtonClick
}) => {
  const { isMobile } = useDevice();
  return (
    <Modal visible={visible} title={header} onClose={onClose} bgColor="white">
      <Modal.Content>
        <Spacing bottom={2}>
          <Text type="body">{message}</Text>
        </Spacing>
        {ctaText && isMobile ? (
          <Button secondary={secondary} fullWidth onClick={onButtonClick}>
            {ctaText}
          </Button>
        ) : (
          <Button secondary={secondary} onClick={onButtonClick}>
            {ctaText}
          </Button>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default RRPModal;
