import { useCallback, useEffect } from 'react';
import { FORM_MAPPING_ACTIONS } from '../helper';
import { UploadedDocument } from '../../../types/registrationCheckout';
import { KeyValue } from '../../../types/common.types';

interface DoccumentUploadReturnType {
  fetchUploadedDocuments: () => void;
  setDocumentFormState: (downloadedDocumentData: UploadedDocument[]) => void;
}

export const useDocumentUpload = ({
  dispatch,
  documentUpload,
  productOrderReferenceNumber,
  retrieveDocumentContent,
  checkoutFormDocumentData,
  checkoutFormData,
  formDispatch,
  downloadedDocData
}: KeyValue): DoccumentUploadReturnType => {
  const fetchUploadedDocuments = useCallback(async (): Promise<void> => {
    documentUpload.forEach(async docData => {
      const options = {
        productOrderReferenceNumber,
        documentId: docData.documentId
      };

      try {
        await dispatch(retrieveDocumentContent(options));
      } catch (_) {
        // to handle later
      }
    });
  }, [
    dispatch,
    documentUpload,
    productOrderReferenceNumber,
    retrieveDocumentContent
  ]);

  const setDocumentFormState = useCallback(
    (downloadedDocumentData: UploadedDocument[]) => {
      const updateCheckoutFormDocData = Object.keys(checkoutFormDocumentData)
        .map(formDocData => {
          const downloadedDoc = downloadedDocumentData.find(
            dData =>
              dData.fileName === checkoutFormDocumentData[formDocData].name
          );

          if (downloadedDoc) {
            const fileType = `image/${downloadedDoc.fileName.split('.').pop()}`;
            return {
              [formDocData]: {
                type: fileType,
                src: downloadedDoc.fileContent,
                name: downloadedDoc.fileName
              }
            };
          }
          return null;
        })
        .reduce((obj, item) => ({ ...obj, ...item }), {});

      const payload = {
        ...checkoutFormData,
        ...updateCheckoutFormDocData
      };

      formDispatch({
        type: FORM_MAPPING_ACTIONS.SET_FORM_VALUES,
        payload
      });
    },
    [checkoutFormData, checkoutFormDocumentData, formDispatch]
  );

  useEffect(() => {
    if (downloadedDocData.length > 0) {
      setDocumentFormState(downloadedDocData);
    }
  }, [downloadedDocData, setDocumentFormState]);

  return {
    fetchUploadedDocuments,
    setDocumentFormState
  };
};
