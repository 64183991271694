const getIsRoiMismatch = (custId, roiValidatedData) => {
  if (custId && roiValidatedData?.custId) {
    const roiCustId = (roiValidatedData.custId + '').trim().toUpperCase();
    const userCustId = (custId + '').trim().toUpperCase();

    return userCustId !== roiCustId;
  }
  return false;
};

export default getIsRoiMismatch;
