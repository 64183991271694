import React from 'react';
import { Grid } from '@dls/web';
import { Footer as BaseFooter } from '@singtel-web/widgets';
import useIsWebViewFlow from '../../hooks/useIsWebViewFlow';

const minimalFooterProps = {
  baseUrl: 'https://www.singtel.com',
  links: [
    {
      text: 'DATA PROTECTION',
      link: 'https://www.singtel.com/personal/i/dataprotection'
    },
    { text: 'CAREER', link: 'https://start.singtel.com/' },
    {
      text: 'TERMS OF USE',
      link: 'https://www.singtel.com/standard-agreement'
    },
    {
      text: 'COPYRIGHT NOTICES',
      link: 'https://www.singtel.com/copyright'
    }
  ],
  copyrightText: '© Singtel (CRN: 199201624D) All Rights Reserved'
};

const Footer = () => {
  const { isClient, isWebView, flow } = useIsWebViewFlow();
  if (!isClient || (isClient && isWebView)) return null;
  return (
    <Grid flow={flow}>
      <BaseFooter {...minimalFooterProps} />
    </Grid>
  );
};

export default Footer;
