import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Spacing } from '@dls/web';
import PlanCatalog from '../components/PlanCatalog/PlanCatalog';
import SEO from '../components/SEO';
import useThirdPartyIndicator from '../hooks/useThirdPartyIndicator';

import { connect } from 'react-redux';
import { setUrlParams } from '@lux/helpers';
import { TOMO_INDICATORS } from '../constants/third-party';

export const PlanCatalogPage = ({ data, location, isCis, cisSessionToken }) => {
  useThirdPartyIndicator(TOMO_INDICATORS);

  if (isCis) {
    navigate(
      setUrlParams('/cis-plans', {
        sessionToken: cisSessionToken
      })
    );
    return null;
  }

  return (
    <div className="fs-unmask">
      <SEO
        title="Plans"
        description="No Queuing. Beat the queues and shop from the comfort of your own home. Flexible Collection &amp; Delivery."
        pageTitle="Find Your Perfect Mobile Plan | Singtel"
        pageDescription="Explore a wide variety of Singtel Postpaid Mobile Plans today, including Phone Plans, SIM Only plans and Mobileshare plans, with eSIM options available!"
        keywords="sim only plan, cheap sim only plans, mobile phone plans, best mobile plans, esim plans, esim phone plans, postpaid esim, singtel sim only plan, singtel mobile plan, singtel phone plan, singtel postpaid plan, singtel mobile share, singtel esim plan"
      />
      <Spacing top={-3} topMd={-6}>
        <PlanCatalog data={data} location={location} />
      </Spacing>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = state => {
  const { user } = state;

  return {
    isCis: Boolean(user.cis.information && user.cis.information.rates),
    cisSessionToken: user.cis?.information?.sessionToken
  };
};

export default connect(mapStateToProps)(PlanCatalogPage);

export const query = graphql`
  {
    allPhone {
      edges {
        node {
          ...CatalogPhoneDetailFragment
        }
      }
    }
    allPlan(filter: { enabledOnPlanCatalog: { eq: true } }) {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    allPlanGroup(
      filter: {
        enabledOnPlanCatalog: { eq: true }
        groupName: { nin: "Sim Only Plus" }
        segment: { in: "res" }
      }
    ) {
      edges {
        node {
          groupName
          groupDisplayName
          enabledOnPlanCatalog
          shortDescription
          footerLiner
          enableIppForRES
          enableIppForCIS
        }
      }
    }
  }
`;
