import React from 'react';
import { Grid, Row, Column, Spacing, Text } from '@dls/web';

const MaintenancePage = () => {
  return (
    <Grid>
      <Row center="sm" middle="sm">
        <Column>
          <Text type="bannerTitle" tag="h2">
            We’ll be back soon.
          </Text>
          <Spacing top={2} bottom={2}>
            <Text type="smallBody">
              We are upgrading our website to serve you better. We apologise for
              any inconvenience and appreciate your patience.
            </Text>
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

export default MaintenancePage;
