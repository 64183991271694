import React, { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  TextLink,
  Layout,
  Text,
  Spacing,
  Button,
  Selector
} from '@dls/web';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import formatPrice from '../../../helpers/formatPrice';

interface Props extends KeyValue {
  isAddAddonOpen: boolean;
  addonData: KeyValue;
  onClose: (values?: KeyValue) => void;
  onAdd: (addon) => void;
}

const AddSimOnlyGroupAddonsModal = (props: Props): ReactElement => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const { isAddAddonOpen, onClose, addonData, onAdd = () => null } = props;
  const { groupName, sopDataList = [] } = addonData || {};

  useEffect(() => {
    setSelectedOptionIndex(-1);
    setSelectedAddon(null);
  }, [isAddAddonOpen]);

  return (
    <Modal
      data-testid={`add-sop-addon-modal`}
      visible={isAddAddonOpen}
      title={groupName}
      closable={true}
      onClose={onClose}
      wide={false}
      backdropClosable={false}
    >
      {
        (
          <Modal.Content>
            <Spacing>
              <Text type="boldBody">{t('ADDON_SELECTION')}</Text>
            </Spacing>

            <Spacing top={1} bottom={1}>
              {sopDataList.map((addon, addonIndex) => {
                const { price, quantity } = addon;
                const priceText = `${formatPrice(price)}/mth`;

                return (
                  <Spacing bottom={1} key={quantity}>
                    <Selector
                      alignment="row"
                      endContent={priceText}
                      selected={selectedOptionIndex === addonIndex}
                      onClick={() => {
                        setSelectedAddon(addon);
                        setSelectedOptionIndex(addonIndex);
                      }}
                      data-testid={`selector-opt-${addonIndex}`}
                    >
                      <Selector.Body
                        title={quantity}
                        align={'start'}
                      ></Selector.Body>
                    </Selector>
                  </Spacing>
                );
              })}
            </Spacing>

            <Layout.Stack>
              <Spacing bottom={2}>
                <Button
                  secondary
                  fullWidth
                  onClick={() => {
                    if (selectedAddon) {
                      onAdd(selectedAddon);
                    }
                  }}
                  text={t('ADD')}
                  data-testid="sop-add-button"
                />
              </Spacing>
              <Layout.Stack align="center">
                <TextLink onClick={onClose}>
                  <TextLink.Text>{t('NO_THANKS')}</TextLink.Text>
                </TextLink>
              </Layout.Stack>
            </Layout.Stack>
          </Modal.Content>
        ) as APP_TYPE_ANY
      }
    </Modal>
  );
};

export default AddSimOnlyGroupAddonsModal;
