/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback
} from 'react';
import { navigate } from 'gatsby';
import { OrderSummary as OrderSummaryModule } from '@common-modules/shopping-cart';
import TermsAndConditions from '../TermsAndConditions';
import { getShoppingCartDataModel } from '../../helpers/cart';
import { useDispatch, useSelector } from 'react-redux';
import { KeyValue } from '../../types/common.types';
import {
  flattenNodes,
  getAccountBillingInfo,
  dataLayerPush,
  getAemConfigs
} from '../../helpers/common';
import {
  Grid,
  Stepper,
  Row,
  Column,
  Spacing,
  SkeletonLoader,
  useDevice
} from '@dls/web';
import { ORDER } from '../../constants';
import {
  getBillingAddressInfo,
  getESimDeliveryOptions,
  getFreebieStockData,
  getPaymentIntentParams,
  getPaymentOptions,
  getPaymentRequestParams,
  getPersonalInfo,
  getPromoResult,
  getShowBillingChangeState,
  getUserContactInfo,
  getYuuMemberInfo,
  isZeroUpfrontCharges,
  validatePaymentRequest
} from './helper';
import {
  authActions,
  orderSummaryActions,
  orderActions,
  userActions
} from '@detox/actions';
import CHECKOUT_CONSTANTS from '../../constants/checkout';
import ORDER_CONSTANTS from '../../constants/order';
import CONSTANTS from '../../constants/common';
import { ORDER_SUMMARY_CONSTANTS } from '../../constants/ordersummary';
import { dppLink } from '../../config/links';
import {
  ActionTypes,
  FormattedPaymentOption,
  OrderSummaryDataModel,
  PaymentType,
  PromoCodeEmpty,
  PromoCodeInfo,
  PromoCodeOptions
} from '../../types/orderSummary.types';
import { navigation } from '../../middlewares/navigation-constants';
import { SkeletonWithSideBar } from '../SkeletonLoader';
import { TObject } from '../../types/registrationCheckout';
import Tnc from './Tnc';
import { ACTION_TYPES } from '../../constants/actions';
import { useInit } from '../../hooks/useInit';
import useUser from '../../hooks/useUser';

import LoadingOverlayComponent from '../LoadingOverlay';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { resetPromoData } from '../../reducers/fulfillment/delivery';
import { getFulfilmentProductsList } from '../Fulfilment/helpers';
import { isMobileShareOrSimOnlyPlan } from '../../helpers/shoppingCartHelper';
import { trans as t } from '../../helpers/localisation';
import { OrderSummaryState } from '../../reducers/orderSummary';
import createDescription from '../../helpers/create-description';
import { useFlowCheck } from '../../hooks/useFlowCheck/useFlowCheck';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { YUU_MEMBERSHIP } from '../../types/featureFlag.types';
import { AEM_CONFIG_KEYS, CONFIGS } from '../../config/common';
import ChangeContactDetails from './ChangeContactDetails';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { SkipStepModal } from './MissingStepModal';
import usePaymentRedirection from '../../hooks/usePaymentRedirection';
import {
  CISMyInfoUserData,
  CustomerInfoResponse
} from '../../types/checkout.types';
import { getFormattedDate, isInCompleteSingpassData } from '../Checkout/helper';
import { getOrderSummaryDataLayer } from '../../helpers/dataLayerHelpers';
import {
  getIfCISMyInfoFlow,
  getIfESimOrder,
  getIsEKYCFlow,
  getChangeCISEKYCFlow
} from '../../selectors';
import { useBrowserBack } from '../../hooks/useBrowserBack/useBrowserBack';
const { getUserInformation } = userActions;

type PropTypes = {
  data: TObject;
};

export const OrderSummary = (props: PropTypes): ReactElement => {
  const totalCheckOutPrice = useRef<number>();
  const totalOneTimePrice = useRef<number>();
  const discountAddons = useRef<number>(0);
  const [dataModel, setDataModel] = useState<
    OrderSummaryDataModel | KeyValue
  >();
  const [tncModal, setTncModal] = useState(false);
  const [showMissingStepModal, setShowMissingStepModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const {
    allPlan,
    allAddonMobileLob,
    allAspireBillingOffer,
    config,
    membership,
    allPaymentMethod,
    allVariableConfig
  } = props.data;
  const variableConfigs = flattenNodes(allVariableConfig) || [];
  const [enableChangeCisMyInfo = ''] = getAemConfigs(variableConfigs, [
    AEM_CONFIG_KEYS.DETOX_CIS_MYINFO_CHANGE_SKIP_DOC_UPLOAD
  ]);

  const paymentOptions = flattenNodes(allPaymentMethod);
  const {
    fulfilmentState,
    product,
    cart,
    checkout,
    userInformation,
    productOrder,
    stepperTitles,
    user,
    contactAddress,
    orderSummary,
    selectedPlan,
    billingAccountDetails,
    auth,
    cisUserInfo,
    accessories,
    order,
    iphone,
    thirdParty,
    customerMyInfo,
    cisInformation,
    isESimOrder,
    userCISMyInfoAvailable,
    isEkycFlow,
    changeCISMyInfoFlow
  } = useSelector((state: KeyValue) => ({
    product: state.product,
    cart: state.cart,
    accessories: state.accessory,
    checkout: state.checkout,
    fulfilmentState: state.fulfillment,
    userInformation: state.user?.information,
    productOrder: state.order?.productOrder || {},
    selectedPlan: state.plan?.selectedPlan,
    stepperTitles: state.checkout?.stepperTitles,
    user: state.user,
    contactAddress:
      state.fulfillment?.delivery?.deliveryDetails?.contactAddress,
    orderSummary: state.orderSummary as OrderSummaryState,
    billingAccountDetails:
      state.fulfillment?.delivery?.deliveryDetails?.billingAccountDetails,
    auth: state.auth,
    cisUserInfo: state.user?.cis?.information,
    order: state.order,
    iphone: state.iphone,
    thirdParty: state?.thirdParty,
    customerMyInfo: state?.customerMyInfo,
    cisInformation: state.user?.cis?.information,
    isESimOrder: getIfESimOrder(state),
    userCISMyInfoAvailable: getIfCISMyInfoFlow(state),
    isEkycFlow: getIsEKYCFlow(state),
    changeCISMyInfoFlow: getChangeCISEKYCFlow(state, enableChangeCisMyInfo)
  }));
  const dispatch = useDispatch();
  useBrowserBack();
  useFlowCheck({
    flowData: [
      cart?.cartOrder,
      checkout.stepperTitles?.includes(t('CO_NN_STEPPER_TWO'))
        ? fulfilmentState?.delivery?.selectedDeliveryMode
        : checkout.checkoutFlow
    ]
  });
  const { renderError } = useErrorHandler({
    states: [auth, user, orderSummary]
  });

  const { selectedProduct } = product;
  const { cartOrder } = cart;
  const { selectedAccessories } = accessories;
  const { deliveryOptions } = fulfilmentState.delivery;
  const myInfoCustomerData: CustomerInfoResponse =
    customerMyInfo?.customerInfoResponse?.ImplCustomerInfoResponse;
  const isCompleteMyInfoData = isInCompleteSingpassData(myInfoCustomerData);
  const isShowOverlayLoading = orderSummary?.isLoading;
  const { updatedContact } = orderSummary;
  const userCISMyInfo: CISMyInfoUserData = cisInformation?.cusMyInfo;
  const promoCodeInfo: PromoCodeInfo = fulfilmentState.delivery?.promoCodeInfo;
  const plans = useMemo(() => flattenNodes(allPlan), [allPlan]);
  const isNewNewFlow =
    checkout.checkoutFlow === CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER;
  const hideBillingChange = getShowBillingChangeState({
    isESimOrder,
    isNewNewFlow,
    userCISMyInfoAvailable,
    isCompleteMyInfoData
  });
  const isYuuEnabled =
    isFeatureFlagEnabled(YUU_MEMBERSHIP) &&
    [ORDER.TYPE.NEW, ORDER.TYPE.PORTIN, ORDER.TYPE.MS_NEW].includes(
      order?.productOrder?.type
    ) &&
    !cisUserInfo;

  useUser();

  useEffect(() => {
    // if user tries to go back from thank-you page to order-summary
    // this will check and navigate to home page
    if (!order?.productOrder) {
      navigate('/', { replace: true });
    }
  }, [productOrder]);

  useEffect(() => {
    if (!auth?.headers || !Object.keys(auth.headers)?.length) {
      dispatch(authActions.getOnePassHeaders());
    }
    dispatch({ type: ACTION_TYPES.ORDER_SUMMARY.CLEAR_PAYMENT_INTENT });

    dataLayerPush(
      getOrderSummaryDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: accessories?.selectedAccessories,
        location: window.location,
        order: productOrder
      }),
      true
    );
  }, []);

  const { renderAmendedErrorPopup, tabIsAmended } = useAmendedTab();
  const isBuyingWithoutDevice =
    isMobileShareOrSimOnlyPlan(selectedPlan) && !cartOrder?.device;
  const { productsList } = getFulfilmentProductsList({
    cartOrder,
    selectedProduct,
    fulfillment: fulfilmentState,
    selectedAccessories,
    isBuyingWithoutDevice,
    priceNeeded: true,
    needFreebies: false,
    nounderline: false
  });

  const getOrderSummaryPayload = () => {
    return {
      customerId: productOrder?.service?.customerId || getCustomerId(),
      productOrderId: productOrder?.productOrderId,
      productOrderReferenceNumber: productOrder?.productOrderReferenceNumber,
      technicalVasIds: allAddonMobileLob?.edges.map(edge => edge.node.addonId),
      aspireBillingOffers: allAspireBillingOffer?.edges.map(
        edge => edge.node.boId
      )
    };
  };

  const getOrderSummaryData = async () => {
    dispatch(orderSummaryActions.getOrderSummaryData(getOrderSummaryPayload()));
  };

  useEffect(() => {
    if (userInformation?.clientContext) {
      getOrderSummaryData();
    }
  }, [userInformation?.clientContext]);

  const getOrderSummaryConfigData = () => {
    const paymentOptions = _getPaymentOptions();
    return {
      isZeroUpfront: isZeroUpfrontCharges(orderSummary?.quoteData),
      ignorePaymentMethodPopup: paymentOptions.length === 1,
      defaultPaymentType:
        paymentOptions[0]?.type || ORDER_CONSTANTS.PAYMENT_TYPES.ONLINE
    };
  };

  useEffect(() => {
    if (!fulfilmentState.delivery?.hasConfirmedFulfilment) {
      setShowMissingStepModal(true);
    }
  }, []);
  useEffect(() => {
    const orderSummaryData = getOrderSummaryConfigData();
    if (promoCodeInfo) {
      const { promoCodeResult } = getPromoResult(promoCodeInfo, orderSummary);
      setDataModel(prev => ({
        ...prev,
        promoCodeResult,
        orderSummaryData
      }));

      if (promoCodeInfo.promoCode === PromoCodeEmpty.EMPTY) {
        dispatch(resetPromoData());
      }
    } else {
      dispatch(resetPromoData());
      setDataModel(prev => ({
        ...prev,
        promoCodeResult: {
          errorMessage: undefined,
          appliedPromoCode: '',
          promoText: undefined,
          isSuccess: false,
          keepPromoOpen: false,
          disabled: false,
          isLoading: false
        },
        orderSummaryData
      }));
    }

    const totalPriceFromSummary = orderSummary?.order?.totalCheckOutPrice;
    const totalOneTimePriceFromSummary = orderSummary?.order?.totalOneTimePrice;
    const discountedAddons = orderSummary?.order?.discountAddons?.length || 0;

    if (
      totalCheckOutPrice.current !== totalPriceFromSummary ||
      totalOneTimePrice.current !== totalOneTimePriceFromSummary ||
      discountAddons.current !== discountedAddons
    ) {
      init();
      totalCheckOutPrice.current = totalPriceFromSummary;
      totalOneTimePrice.current = totalOneTimePriceFromSummary;
      discountAddons.current = discountedAddons;
    }
  }, [promoCodeInfo, orderSummary]);

  useEffect(() => {
    init();
  }, [orderSummary?.quoteData]);

  useEffect(() => {
    if (updatedContact) {
      init();
    }
  }, [updatedContact]);

  useInit(() => {
    init();
  }, [orderSummary?.order]);

  useInit(() => {
    init();
  }, [userInformation, orderSummary?.order?.serviceId]);

  useEffect(() => {
    fetchContract();
  }, [orderSummary.quoteFetched]);

  usePaymentRedirection(orderSummary, {
    thankYouPageUrlSlug: navigation.THANK_YOU
  });

  const callbacks = {};
  const isPreviousIpp = cart?.order?.mobile?.existingDevice?.ippDetails;

  const existingOrder = {
    device: isPreviousIpp && cart?.order?.mobile?.existingDevice?.model
  };

  const _getPaymentOptions = (): FormattedPaymentOption[] => {
    return getPaymentOptions({
      paymentOptions,
      flow: order?.productOrder?.type,
      deliveryMethod: fulfilmentState?.delivery?.selectedDeliveryMode,
      isExistingCustomer:
        checkout?.checkoutFlow !== CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER
    });
  };

  const getNewUserFullName = (): string => {
    return (
      checkout.checkoutFormData?.fullName ||
      myInfoCustomerData?.fullName ||
      userCISMyInfo?.name ||
      ''
    );
  };

  const getDateOfBirth = (): string => {
    return (
      checkout.checkoutFormData?.birthday ||
      getFormattedDate(myInfoCustomerData?.dateOfBirth, '/') ||
      getFormattedDate(userCISMyInfo?.dateOfBirth, '-') ||
      ''
    );
  };

  const init = () => {
    const checkoutFormData = checkout.checkoutFormData;
    const userInfoEmail = userInformation?.clientContext?.contact?.email;
    const userContact = isNewNewFlow
      ? {
          firstName: getNewUserFullName(),
          lastName: '',
          indentValue: checkout.verificationData?.id,
          dateOfBirth: getDateOfBirth(),
          phone: checkout.verificationData?.mobileNumber,
          email: checkoutFormData.email
        }
      : getUserContactInfo(
          user.information?.clientContext?.contact,
          updatedContact
        );

    const planDescription = createDescription({
      data: selectedPlan?.data,
      sms: selectedPlan?.sms,
      talktime: selectedPlan?.talktime
    });
    const mobilePlan = cart?.order?.mobile?.plan;
    const orderSummaryData = {
      ...orderSummary?.order,
      selectedService: productOrder?.service,
      freebies: cartOrder?.freebies,
      selectedPlan: {
        name: mobilePlan?.name,
        description: planDescription,
        price: mobilePlan?.price,
        groupName: selectedPlan?.groupName
      }
    };

    const dataObject = {
      cartOrder: orderSummaryData,
      selectedProduct: product?.selectedProduct,
      orderType: productOrder?.type,
      mainLines: user?.mainLines,
      userSelectedServiceId: user?.selectedService?.serviceId,
      plans: plans,
      userProducts: user?.products,
      callbacks: callbacks,
      existingOrder: existingOrder,
      cisUser: cisUserInfo,
      hasAction: false,
      callFromOrderSummary: true
    };

    const dataModel = getShoppingCartDataModel({
      ...dataObject,
      freebiesStockData: getFreebieStockData(cartOrder?.freebies)
    });

    const isOnePass = user.information?.userDetails?.loginId;
    const needMask = user.information?.userDetails && !isOnePass;
    const promoCodeResult = getPromoResult(promoCodeInfo, orderSummary);
    dispatch({
      type: ACTION_TYPES.ORDER.SET_PRODUCTS_LIST,
      value: { productsList, isBuyingWithoutDevice }
    });
    const yuuMembership = getYuuMemberInfo(
      membership?.memberBanner,
      thirdParty?.indicator?.partnerId
    );
    const personalInfoData = getPersonalInfo({
      userContact,
      needMask,
      flow: checkout.checkoutFlow,
      isOnePass
    });
    const combinedData = {
      membershipData: cisUserInfo
        ? {}
        : {
            value: yuuMembership?.value,
            title: yuuMembership?.title,
            subTitle: yuuMembership?.description,
            note: yuuMembership?.footer,
            icon: yuuMembership?.icon,
            isComingFromApp: !!yuuMembership?.value
          },
      ...dataModel,
      productsList,
      eSimDeliveryOptions: getESimDeliveryOptions(
        isESimOrder,
        isNewNewFlow ? checkoutFormData?.email : userInfoEmail
      ),
      deliveryOptions,
      paymentOptions: _getPaymentOptions(),
      personalInfo: personalInfoData,
      billingAddress: getBillingAddressInfo(
        orderSummary.quoteData?.billingAddress,
        checkout.checkoutFlow,
        hideBillingChange
      ),
      orderSummaryData: getOrderSummaryConfigData(),
      ...promoCodeResult
    };
    setDataModel(combinedData);
  };

  const fetchContract = () => {
    if (orderSummary.quoteFetched) {
      const { barId } = getAccountBillingInfo({
        checkout,
        delivery: fulfilmentState.delivery,
        userInformation,
        productOrder
      });

      const { productOrderId } = productOrder;
      dispatch(
        orderSummaryActions.getContract({
          productOrderId,
          billingAccountId: barId
        })
      );
    }
  };

  const getCustomerId = () => {
    const { customerId } = getAccountBillingInfo({
      checkout,
      delivery: fulfilmentState?.delivery,
      userInformation,
      productOrder
    });
    return customerId;
  };

  const applyPromo = (promo: string) => {
    const promoCodeOptions: PromoCodeOptions = {
      productOrderId: productOrder?.productOrderId,
      updateProducts: true,
      productOrder: productOrder,
      userInformation: userInformation,
      orderSummaryPayload: getOrderSummaryPayload(),
      customerId: getCustomerId()
    };

    // Remove Button Handling
    if (!promo || promo === PromoCodeEmpty.CLEAR) {
      dispatch(
        orderActions.addPromoCode({
          ...promoCodeOptions,
          promoString: PromoCodeEmpty.EMPTY,
          promoCheckNeeded: false
        })
      );
      dispatch(resetPromoData());
      return;
    }

    //Add Button Handling
    dispatch(
      orderActions.addPromoCode({
        ...promoCodeOptions,
        promoString: promo?.toUpperCase(),
        promoCheckNeeded: promo !== PromoCodeEmpty.EMPTY
      })
    );
  };

  const renderHeader = () => {
    return (
      <Spacing top={2}>
        <Grid noGutter>
          <Row>
            <Column xs={12} md={8} noGutter>
              <Stepper
                totalSteps={stepperTitles?.length}
                activeStep={stepperTitles?.length}
                titles={stepperTitles}
              />
            </Column>
          </Row>
        </Grid>
      </Spacing>
    );
  };

  const renderChangeContactModal = useCallback(() => {
    if (!showContactModal) {
      return null;
    }

    return (
      <ChangeContactDetails
        isOpen={showContactModal}
        closeContactModal={(callContract = false) => {
          setShowContactModal(false);
          if (callContract) {
            fetchContract();
            dispatch(getUserInformation());
          }
        }}
        personalInfo={dataModel.personalInfo}
      />
    );
  }, [dataModel?.personalInfo, showContactModal]);

  const onSectionClicked = (actionType: ActionTypes) => {
    switch (actionType) {
      case ActionTypes.CHANGE_BILLING_ADDRESS:
      case ActionTypes.CHANGE_PERSONAL_INFO: {
        window.history.go(-(checkout.stepperTitles?.length - 1 || 2));
        return;
      }
      case ActionTypes.CHANGE_STORE_SELECTION:
      case ActionTypes.CHANGE_POPSTATION_ADDRESS:
      case ActionTypes.CHANGE_DELIVERY_ADDRESS: {
        window.history.go(-1);
        return;
      }
      case ActionTypes.CHANGE_PERSONAL_INFO_EXISTING:
      case ActionTypes.CHANGE_PERSONAL_INFO_RECON: {
        setShowContactModal(true);
      }
    }
  };

  const onPlaceOrder = (paymentType, data) => {
    window.sessionStorage.setItem('flow', '');
    const membershipId =
      (dataModel.membershipData?.isComingFromApp ||
        dataModel.membershipData?.isValid) &&
      dataModel.membershipData?.value;
    const isZeroUpfront = isZeroUpfrontCharges(orderSummary?.quoteData);
    const isOnlinePayment =
      paymentType === ORDER_CONSTANTS.PAYMENT_TYPES.ONLINE;
    const { barId, customerId } = getAccountBillingInfo({
      checkout,
      delivery: fulfilmentState.delivery,
      userInformation,
      productOrder
    });
    const paymentIntentRequestData = getPaymentIntentParams({
      userInformation,
      customerId,
      contactAddress,
      quoteData: orderSummary?.quoteData,
      productOrderReferenceNumber: productOrder?.productOrderReferenceNumber
    });
    const paymentRequestData = getPaymentRequestParams({
      quoteData: orderSummary?.quoteData,
      productOrder,
      cartOrder: cart.order,
      cartItems: cart.cartOrder,
      userInformation,
      allocationStatusArray:
        fulfilmentState?.allocateOrReserve?.data?.allocationStatusX9,
      reservationStatusArray:
        fulfilmentState?.allocateOrReserve?.reserveEquipmentData
          ?.reservationStatusX9,
      customerId,
      deliveryMode: fulfilmentState.delivery?.selectedDeliveryMode,
      selectedPlan,
      selectedProduct: product.selectedProduct,
      checkout,
      billingAccountDetails,
      paymentType: isOnlinePayment ? PaymentType.ONLINE : PaymentType.OFFLINE,
      contractList: orderSummary?.contractList,
      authHeaders: auth.headers,
      merchantCode: config?.merchantId,
      checkoutBarId: barId,
      lineOfBusiness: orderSummary?.quoteData?.lineOfBusiness,
      billPayer: auth.headers[CONSTANTS.HEADERS.HTTP_BILL_PAYER],
      barId,
      storeEAppData: fulfilmentState?.delivery?.storeEAppData,
      iphone
    });

    if (!validatePaymentRequest(paymentRequestData)) {
      return dispatch({
        type: ACTION_TYPES.ORDER.VALIDATE_REQUEST_PAYMENT_FAILED
      });
    }
    // clear third party indicator after placing order
    dispatch({ type: ACTION_TYPES.THIRD_PARTY.UPDATE_INDICATOR, payload: {} });
    dispatch(
      orderSummaryActions.requestPayment(
        userInformation?.clientContext?.contact?.contactId,
        {
          membershipCode: dataModel.membershipData?.isComingFromApp
            ? thirdParty?.indicator?.partnerCode
            : CONFIGS.YUU_MEMBERSHIP_CODE,
          membershipId,
          paymentRequestData,
          paymentIntentRequestData:
            isOnlinePayment && !isZeroUpfront ? paymentIntentRequestData : null,
          customerId,
          billingAccountId: barId,
          planName: selectedPlan?.planName,
          flowType: ORDER_CONSTANTS.EKYC_FLOW_TYPE[order?.productOrder?.type],
          isEkyc: isEkycFlow || changeCISMyInfoFlow
        }
      )
    );
  };

  const handleTncModal = () => {
    setTncModal(true);
  };

  const renderSkipStepModal = () => {
    const goBack = () => {
      window.history.go(-1);
    };
    return (
      <SkipStepModal
        isOpen={showMissingStepModal}
        onClose={goBack}
        onCtaClick={goBack}
      />
    );
  };

  const handleDppLink = () => {
    const url = '' + dppLink;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (user.isLoading || orderSummary.isShowSkeletonLoader || auth.isLoading) {
    return (
      <>
        {renderHeader()}
        <SkeletonWithSideBar />
      </>
    );
  }

  if (!dataModel) return null;

  return (
    <>
      <OrderSummaryModule
        data={dataModel}
        ui={{
          Tnc: (
            <Tnc
              handleTncModal={handleTncModal}
              handleDppLink={handleDppLink}
            />
          ),
          Stepper: renderHeader()
        }}
        callbacks={{
          onSectionClicked,
          onPlaceOrder,
          applyPromo
        }}
        localisation={{}}
        configs={{
          showOutOfStock: true,
          buyflowType: ORDER_SUMMARY_CONSTANTS.MOBILE,
          membership: {
            show: isYuuEnabled
          },
          stickyBoundaryElement: '.stickyBoundary'
        }}
      />
      {tncModal ? (
        <TermsAndConditions
          isModalOpen={tncModal}
          setIsModalOpen={setTncModal}
        />
      ) : null}
      {isShowOverlayLoading && <LoadingOverlayComponent />}
      {!tabIsAmended && renderError()}
      {renderAmendedErrorPopup()}
      {renderChangeContactModal()}
      {renderSkipStepModal()}
    </>
  );
};
