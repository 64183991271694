import { Layout, useDevice } from '@dls/web';
import React from 'react';

export const ButtonWrapper = ({ children }) => {
  const { isMobile } = useDevice();
  return isMobile ? (
    <Layout.Stack>{children}</Layout.Stack>
  ) : (
    <Layout.Inline alignY={'center'}>{children}</Layout.Inline>
  );
};
