import React, { useEffect } from 'react';
import {
  ProductCatalogue,
  FilterGroup,
  Product
} from '@common-modules/product-catalogue';
import { planActions } from '@detox/actions';
import { Grid, Row, Column, Spacing } from '@dls/web';
import { KeyValue } from '../../types/common.types';
import { flattenNodes } from '../../helpers/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProductCatalogState,
  setFilterData,
  applyFilter as actionApplyFilter,
  clearFilter as actionClearFilter,
  selectFilter as actionSelectFilter,
  resetToPreviousFilter as actionResetToPreviousFilter,
  setProductList,
  setIppFilter,
  setRRPFlow
} from '../../reducers/productCatalogs';
import { FLOW, Plan } from '../../types/plan.types';
import { navigate } from 'gatsby';
import {
  checkIppChanged,
  getDefaultPhonePlan,
  getSelectedPlan,
  getSimOnlyPlans,
  setUrlParamsMapping,
  updateUrlParamFromFilter
} from '../../helpers/productCatalogHelper';
import { getUrlParams } from '@wec-core/helpers';
import {
  getPhoneCatalogBanner,
  getUrlByPlan
} from '../../helpers/catalogue-helpers';
import { CATALOGUE } from '../../constants';
import getAemComponentByName from '../../helpers/getAemComponentByName';
import PromoStrip from '../PromoStrip/PromoStrip';
import PromoBanner from '../PromoBanner';
import { GatsbyImage } from 'gatsby-plugin-image';
import { UserInformation } from '../../types/user.types';
import CisPointOfEntry from '../CisPointOfEntry';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import { trans as t } from '../../helpers/localisation';
import { useInit } from '../../hooks/useInit';
import { BannerList } from '../RrpBanner';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { NEW_PROMO_BANNER } from '../../types/featureFlag.types';

type PropTypes = {
  data: KeyValue;
  isCisPage: boolean;
  location: Location;
  previewMode: boolean;
};

export const PhoneCatalog = (props: PropTypes) => {
  const { data, previewMode, isCisPage } = props;

  const dispatch = useDispatch();

  const {
    productCatalog,
    selectedPlan,
    cisRates,
    cisLoading,
    userSelection
  } = useSelector((state: KeyValue) => {
    return {
      productCatalog: state.productCatalog as ProductCatalogState,
      selectedPlan: state.plan?.selectedPlan as Plan,
      userSelection: state.user?.selection,
      cisRates: (state.user as UserInformation)?.cis?.information?.rates,
      cisLoading: (state.user as UserInformation)?.cis?.loading
    };
  });

  const {
    allPhoneCatalogCategory,
    allPhoneBrand,
    allPhone,
    allPlan,
    allAemPages,
    allInstalmentOptions,
    allPhoneBanner
  } = data;
  const plans = flattenNodes(allPlan) as Plan[];
  const brands = flattenNodes(allPhoneBrand);
  const categories = flattenNodes(allPhoneCatalogCategory);
  const ippOptions = flattenNodes(allInstalmentOptions);
  const products = flattenNodes(allPhone);
  const banners = flattenNodes(allPhoneBanner);
  const getQueryParams = () => {
    return getUrlParams(window.location.search);
  };

  useEffect(() => {
    dispatch(setRRPFlow(false));
    setUrlParamsMapping([categories, ippOptions]);
  }, []);

  useEffect(() => {
    if (!productCatalog.filterData?.length && !selectedPlan) {
      dispatch(setIppFilter(getQueryParams()?.[CATALOGUE.FILTER_TYPES.PLAN]));
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      dispatch(
        setProductList({
          products,
          selectedPlan,
          simOnlyPlans: getSimOnlyPlans(plans),
          queryParams: getQueryParams() as KeyValue,
          filterData: productCatalog.filterData
        })
      );
    }
  }, [selectedPlan]);

  useInit(() => {
    if (
      productCatalog.productList?.length &&
      !productCatalog.filterData.length
    ) {
      dispatch(
        setFilterData({
          brands,
          categories,
          queryParams: getQueryParams() as KeyValue,
          ippOptions,
          selectedPlan
        })
      );
    }
  }, [productCatalog.productList.length]);

  useEffect(() => {
    if (productCatalog.filterData?.length) {
      updateUrlParamFromFilter(
        productCatalog.filterData,
        selectedPlan,
        getQueryParams() as KeyValue
      );
    }
  }, [productCatalog.filterData]);

  useEffect(() => {
    if (productCatalog.isDoneResetFilter) {
      const plan = getDefaultPhonePlan(plans, cisRates);
      selectPlanAndUpdateUrl(plan);
    }
  }, [productCatalog.isDoneResetFilter]);

  useEffect(() => {
    if (productCatalog.hasIppFilter !== null) {
      const plan = getPlanWhenChangeIpp(productCatalog.hasIppFilter);
      selectPlanAndUpdateUrl(plan);
    }
  }, [productCatalog.hasIppFilter]);

  const getPlanWhenChangeIpp = hasIppFilter => {
    const isSimOnlySelected = isSimOnlyPlan(userSelection?.plan);
    if (hasIppFilter) {
      const simOnlyPlan = isSimOnlySelected
        ? userSelection.plan
        : getSimOnlyPlans(plans)[0];
      return {
        ...simOnlyPlan,
        selectedMonthlyTerm:
          selectedPlan?.selectedMonthlyTerm || CATALOGUE.INSTALLMENT_MONTHS[0]
      };
    } else {
      return getSelectedPlan({
        plans,
        location: window.location,
        queryParams: getQueryParams() as KeyValue,
        fromQueryParams: false,
        cisRates,
        selectedPlan: !isSimOnlySelected && userSelection?.plan
      });
    }
  };

  const selectPlanAndUpdateUrl = plan => {
    const url = getUrlByPlan(plan);
    if (url) {
      updateUrlParamFromFilter(
        productCatalog.filterData,
        plan,
        getQueryParams() as KeyValue
      );
      dispatch(planActions.setSelectedPlan(plan));
    }
  };

  const applyFilterOrSelectFilter = (
    filterData: FilterGroup[],
    isApply = true
  ) => {
    const { isIPPChanged, newValue } = checkIppChanged(
      productCatalog.filterData,
      filterData
    );
    let plan = selectedPlan;
    if (isIPPChanged) {
      plan = getPlanWhenChangeIpp(newValue);
    }
    if (isApply) {
      return dispatch(actionApplyFilter({ filterData, selectedPlan: plan }));
    }
    return dispatch(actionSelectFilter({ filterData, selectedPlan: plan }));
  };

  const clearFilter = () => {
    dispatch(actionClearFilter());
  };

  const onCloseFilterModal = () => {
    dispatch(actionResetToPreviousFilter());
  };

  const onProductClick = (product: Product) => {
    navigate(`/phones/${product.slug}`);
  };

  const renderPromoStrip = () => {
    const promoStrip = getAemComponentByName(allAemPages, 'PromoStrip');

    if (!promoStrip?.enablePromoStrip) {
      return null;
    }

    const {
      primaryLiner,
      enableSecondaryStrip,
      mainTitle,
      sectionTitle,
      sectionDescription,
      sectionFooter
    } = promoStrip;

    return (
      <PromoStrip
        primaryLiner={primaryLiner}
        enableSecondaryStrip={enableSecondaryStrip}
        modalContent={{
          mainTitle,
          sectionTitle,
          sectionDescription,
          sectionFooter
        }}
        isCisPage={isCisPage}
      />
    );
  };

  const renderCisPointOfEntry = () => {
    if (cisLoading || cisRates) {
      return null;
    }
    return <CisPointOfEntry />;
  };

  const renderBanner = () => {
    const isNewPromoBannerEnabled = isFeatureFlagEnabled(NEW_PROMO_BANNER);

    if (isNewPromoBannerEnabled && banners) {
      const flowType = isCisPage ? FLOW.CIS : FLOW.RES;
      const promoBanner = getPhoneCatalogBanner(banners, flowType);
      return <BannerList banners={promoBanner} isDeviceFlow={true} />;
    }

    const promoBanner = getAemComponentByName(allAemPages, 'PromoBanner');

    if (!promoBanner?.enablePromoBanner) {
      return null;
    }

    const {
      theme,
      desktopImg,
      desktopImgAlt,
      mobileImg,
      mobileImgAlt,
      title,
      description,
      enableCTA,
      ctaText,
      sectionTitle,
      sectionDescription,
      desktopFileReference,
      mobileFileReference
    } = promoBanner;

    return (
      <PromoBanner
        bannerTheme={theme}
        desktopImg={
          <GatsbyImage
            image={desktopImg?.childImageSharp?.gatsbyImageData}
            alt={desktopImgAlt}
          />
        }
        mobileImg={
          <GatsbyImage
            image={mobileImg?.childImageSharp?.gatsbyImageData}
            alt={mobileImgAlt}
          />
        }
        title={title}
        description={description}
        enableCta={enableCTA}
        ctaText={ctaText}
        modalTitle={sectionTitle}
        modalDescription={sectionDescription}
        desktopFileReference={desktopFileReference}
        mobileFileReference={mobileFileReference}
        previewMode={previewMode}
      />
    );
  };

  const renderCatalog = () => {
    return (
      <Spacing top={0}>
        <Spacing bottom={5}>
          {renderPromoStrip()}
          {renderBanner()}
          {renderCisPointOfEntry()}
          <Grid>
            <Row>
              <Column>
                <ProductCatalogue
                  data={{
                    productList: productCatalog.productList,
                    filterData: productCatalog.filterData,
                    productCatalogTitle: t('PRODUCT_CATALOG_TITLE') as string
                  }}
                  configs={{
                    enableViewMoreBtn: false,
                    enableFilter: true,
                    enableSorter: false,
                    enablePromoStrip: false,
                    enableBanner: false,
                    hasProductCount: true,
                    hideDisabledFilter: true
                  }}
                  localisation={{
                    SEARCH_MODAL_TITLE: t('SEARCH_MODAL_TITLE')
                  }}
                  callbacks={{
                    applyFilter: filterData => {
                      applyFilterOrSelectFilter(filterData);
                    },
                    onProductClick,
                    clearFilter,
                    onSelectFilter: filterData => {
                      applyFilterOrSelectFilter(filterData, false);
                    },
                    onCloseFilterModal
                  }}
                />
              </Column>
            </Row>
          </Grid>
        </Spacing>
      </Spacing>
    );
  };

  return <>{renderCatalog()}</>;
};
