import { ACTION_TYPES } from '@detox/actions';
import { APP_TYPE_ANY, KeyValue } from '../types/common.types';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';

export interface ValidateSKU {
  custId: string;
  product: KeyValue;
  status: string;
  transactionId: string;
}

export interface IPhoneFormState {
  nric: string;
  isCisUser: boolean;
  passType: string;
  cisEmail: string;
}
export interface IphoneState extends KeyValue {
  isLoading: boolean;
  isError: boolean;
  roiToken: string;
  groupCampaignId: string;
  validateSkuData: ValidateSKU;
  sku: string;
  validateOtpData: KeyValue;
  redirectNeeded?: boolean;
  skuCheckValid?: boolean;
  otpStatus?: boolean;
  otpVisible?: boolean;
  validateError?: boolean;
  formData?: IPhoneFormState;
}

interface IphoneAction {
  type: string;
  data?: APP_TYPE_ANY;
}

const initialState: IphoneState = {
  isLoading: false,
  isError: false,
  roiToken: '',
  groupCampaignId: '',
  validateSkuData: null,
  skuCheckValid: null,
  sku: '',
  validateOtpData: null,
  redirectNeeded: false,
  otpVisible: false,
  validateError: false,
  formData: null
};

export const iphoneReducer = (
  state: IphoneState = initialState,
  action: IphoneAction
): IphoneState => {
  switch (action.type) {
    case SHOP_ACTION_TYPES.IPHONE.SET_ROI_TOKEN: {
      return {
        ...initialState,
        roiToken: action.data.roiToken,
        groupCampaignId: action.data.groupCampaignId
      };
    }
    case ACTION_TYPES.IPHONE.VALIDATE_OTP_LOADING:
    case ACTION_TYPES.IPHONE.VALIDATE_GET_SKU_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.IPHONE.VALIDATE_GET_SKU_FAILED: {
      return {
        ...state,
        sku: '',
        isError: true,
        isLoading: false
      };
    }

    case ACTION_TYPES.IPHONE.VALIDATE_GET_SKU_SUCCESS: {
      const { status: skuCheckValid, product } = action.data || {};

      return {
        ...state,
        isError: false,
        isLoading: false,
        skuCheckValid: skuCheckValid === 'true',
        validateSkuData: action.data,
        sku: product?.stockCode
      };
    }

    case ACTION_TYPES.IPHONE.VALIDATE_OTP_FAILED: {
      return {
        ...state,
        isError: true
      };
    }

    case SHOP_ACTION_TYPES.IPHONE.SET_VALIDATE_ERROR: {
      return {
        ...state,
        validateError: true
      };
    }

    case ACTION_TYPES.IPHONE.VALIDATE_OTP_SUCCESS: {
      const otpStatus =
        action.data.resultStatus === true ||
        action.data.resultStatus === 'true';

      return {
        ...state,
        isError: false,
        isLoading: false,
        validateOtpData: action.data,
        otpStatus
      };
    }

    case SHOP_ACTION_TYPES.IPHONE.SET_OTP_VISIBLE: {
      return {
        ...state,
        otpVisible: action.data
      };
    }

    case SHOP_ACTION_TYPES.IPHONE.SET_FORM_DATA: {
      return {
        ...state,
        formData: action.data
      };
    }

    case SHOP_ACTION_TYPES.IPHONE.RESET_FORM_DATA: {
      return {
        ...state,
        formData: null
      };
    }

    case SHOP_ACTION_TYPES.IPHONE.RESET_DATA_EXCLUDE_ROITOKEN: {
      return {
        ...initialState,
        groupCampaignId: state.groupCampaignId,
        roiToken: state.roiToken
      };
    }

    case SHOP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA:
    case SHOP_ACTION_TYPES.IPHONE.RESET_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};
