import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Grid, Row, Column, Text, Spacing, Notification } from '@dls/web';
import { api } from '@detox/actions';
import SingpassLogo from '@dls/assets/dist/images/logo_singpass_full_colour.png';

import { trans as t } from '../../helpers/localisation';
import { CP_FLOW_TYPE } from '../../constants/index';
import SingpassRetrieveMyInfo from '../../assets/svgs/singpass-retrieve-myInfo.svg';

const { apiMyinfoRetrieveUrl } = api.mcss;

type CreditLimitReviewType = {
  flowType: string;
};

const CreditLimitReview = (props: CreditLimitReviewType) => {
  const { flowType } = props;
  const [retrieveErrorMsg, setRetrieveErrorMsg] = useState<boolean>(false);

  const retrieveRedirectUrl = async () => {
    const params = {
      flowType: CP_FLOW_TYPE,
      redirectUrl: `${window.location.origin}${window.location.pathname}`
    };
    const res = await apiMyinfoRetrieveUrl(params);
    if (res && res.url) {
      navigate(res.url);
      setRetrieveErrorMsg(false);
    } else {
      setRetrieveErrorMsg(true);
    }
  };

  return (
    <>
      {retrieveErrorMsg && (
        <Spacing top={2}>
          <Spacing bottom={2}>
            <Notification
              type="alert"
              content={t('CR_RETRIEVE_ERROR')}
              fullWidth
              horizontalPadding={'lg'}
            />
          </Spacing>
        </Spacing>
      )}

      <Grid>
        <Row noGutter>
          <Column xs={12} sm={12} md={6} noGutter>
            <img
              src={SingpassLogo}
              alt="Singpass logo"
              style={{ width: 165 }}
            />
          </Column>
        </Row>
        <Row noGutter>
          <Column xs={12} sm={12} md={8} noGutter>
            <Spacing bottom={3}>
              <Text type="pageTitle" fontWeight="regular">
                {t('SINGTEL_CREDIT_LIMIT_REVIEW')}
              </Text>
            </Spacing>
          </Column>
        </Row>
        <Row>
          <Column xs={12} sm={12} md={8}>
            <Spacing bottom={2}>
              <Text>{t('CR_LOGIN_TO_MY_INFO_MSG')}</Text>
            </Spacing>
          </Column>
        </Row>
        <Row>
          <Column xs={12} sm={12} md={8} middle="md">
            <Spacing bottom={3}>
              <Text>{t('CR_LOGIN_REDIRECTION_INFO')}</Text>
            </Spacing>
          </Column>
        </Row>
        <Spacing bottom={3}>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <SingpassRetrieveMyInfo
                onClick={retrieveRedirectUrl}
                data-testid="retrieve-myinfo-btn"
                width={350}
                height={50}
              />
            </Column>
            <Column xs={12} sm={12} md={4}>
              <Text type="smallBody">{t('CR_CLOSE_THE_BROWSER')}</Text>
            </Column>
          </Row>
        </Spacing>
      </Grid>
    </>
  );
};

export default CreditLimitReview;
