import React, { ReactElement, useEffect } from 'react';
import { getUiamUrlParams } from '../helpers/uiam-helpers';
import setCookie from '../helpers/set-cookie';
import CONSTANT from '../constants/common';
import { getUIAMLoginUrl, sdAccountSummary } from '../config';
import LoadingOverlayComponent from '../components/LoadingOverlay';
import { extractBasePath } from '../helpers/common';
function openTargetUrlAsync(url) {
  setTimeout(() => {
    window.open(url, '_self');
  }, 300);
}

export const UiamLandingRedirection = (): ReactElement => {
  const handleRedirection = () => {
    const pathName = window.location.search;
    const tokens = getUiamUrlParams(pathName);
    if (tokens?.accessToken && tokens?.targetUrl) {
      setCookie(
        CONSTANT.UIAM_ACCESS_TOKEN,
        tokens?.accessToken,
        parseInt(process.env.GATSBY_UIAM_TOKEN_EXPIRY)
      );
      const basePath = extractBasePath(tokens?.targetUrl);
      if (typeof basePath === 'string' && basePath.endsWith('singtel.com')) {
        openTargetUrlAsync(`${tokens?.targetUrl}`);
      } else {
        openTargetUrlAsync(getUIAMLoginUrl(sdAccountSummary, true));
      }
    } else {
      openTargetUrlAsync(getUIAMLoginUrl(sdAccountSummary, true));
    }
  };

  useEffect(() => {
    handleRedirection();
  }, []);

  return <LoadingOverlayComponent />;
};

export default UiamLandingRedirection;
