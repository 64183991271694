import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import LoginGuestCheckout from '../components/LoginGuestCheckout';
import { useLocation } from '@reach/router';

export const LoginGuestCheckoutPage = props => {
  const { data } = props;

  return (
    <>
      <SEO title="Login Guest Checkout" />
      <LoginGuestCheckout location={useLocation()} data={data} />
    </>
  );
};

export default LoginGuestCheckoutPage;

export const query = graphql`
  query LoginGuestCheckoutQuery {
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allAemPages(filter: { pageUrl: { eq: "/eshop/admin.xjson" } }) {
      edges {
        node {
          title
          components {
            ...AemComponentsFragment
          }
        }
      }
    }
  }
`;
