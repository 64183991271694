import React from 'react';
import { Info as InfoComponent } from '../components/Info';
import CONSTANTS from '../constants/common';
import { getUrlParams } from '@lux/helpers';
import { navigate } from 'gatsby';
import { trans as t } from '../helpers/localisation';
export const Info = ({ location }) => {
  const urlParams = getUrlParams(location?.search);

  const title = urlParams[CONSTANTS.URL_PARAMS.INFO_TITLE];
  const message = urlParams[CONSTANTS.URL_PARAMS.INFO_MESSAGE];
  const ctaText = urlParams[CONSTANTS.URL_PARAMS.INFO_CTA_TEXT];
  const callbackUrl = urlParams[CONSTANTS.URL_PARAMS.INFO_CALLBACK_URL];
  const infoType = urlParams[CONSTANTS.URL_PARAMS.INFO_TYPE];
  const chatWithUs = urlParams[CONSTANTS.URL_PARAMS.CHAT_WITH_US];
  const onClick = () => {
    if (callbackUrl) {
      navigate(`/${callbackUrl}`, { replace: true });
    }
  };
  return (
    <InfoComponent
      title={t(title)}
      message={t(message)}
      ctaText={t(ctaText)}
      onclick={onClick}
      type={infoType}
      chatWithUs={chatWithUs}
    />
  );
};

export default Info;
