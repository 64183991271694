import React, { ReactElement } from 'react';
import { Text, Spacing, Button } from '@dls/web';
import NoAccessImg from '../assets/images/no-access.png';
import styled from 'styled-components';

import { navigate } from 'gatsby';
import useTranslate from '../hooks/useTranslation';
const Container = styled(Spacing)`
  text-align: center;
`;
export const NoAccess = (props): ReactElement => {
  const { t } = useTranslate();
  const shopNowClicked = () => {
    const navigateUrl = props?.location?.state?.url || '/';
    navigate(navigateUrl, { replace: true });
  };
  return (
    <>
      <Container>
        <img src={NoAccessImg} alt={'no-access'} />
        <Spacing topBottom={2} responsive={false}>
          <Text type="pageTitle">{t('NO_ACCESS_TITLE')}</Text>
        </Spacing>
        <Text type="body">{t('NO_ACCESS_MESSAGE')}</Text>
        <Spacing topBottom={3} responsive={false}>
          <Button secondary onClick={shopNowClicked}>
            {t('SHOP_NOW')}
          </Button>
        </Spacing>
      </Container>
    </>
  );
};
export default NoAccess;
