import React from 'react';
import { Text, Card, Row, Column, Button, Spacing, useTheme } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import styled from 'styled-components';

const StyledCardsRow = styled(Row)`
  margin: -8px;
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
`;

const PromoCodeText = styled(Text)`
  margin-right: 8px;
`;

const HowToUseSectionContainer = styled.div`
  flex: 1;
`;

const StyledButtonRow = styled(Row)`
  margin-top: 16px;
`;

const ServicePromotions = (props): JSX.Element => {
  const { theme } = useTheme();
  const { data } = props;

  const onCtaClick = clickThroughUrl => {
    window.location.href = clickThroughUrl;
  };

  return (
    <>
      <Text type="header" data-testid="heading">
        {t('CCDE_HERES_WHAT_YOU_CAN_ENJOY')}
      </Text>

      {!!data && data.length > 0 && (
        <StyledCardsRow>
          {data?.map(
            ({
              productName,
              productID,
              sellableEndDate,
              productDescription,
              clickThroughUrl
            }) => (
              <Column xs={12} sm={4} key={productID}>
                <Card>
                  <CardContent>
                    <Text type="header" data-testid="productName">
                      {productName}
                    </Text>

                    {productID && (
                      <Text fontWeight="bold" data-testid="productId">
                        {productID}
                      </Text>
                    )}

                    {sellableEndDate && (
                      <Text
                        type="body"
                        color={theme.cl_ter_l3}
                        data-testid="validDate"
                      >
                        {t('CCDE_VALID_UNITL', {
                          validDate: sellableEndDate
                        })}
                      </Text>
                    )}

                    <HowToUseSectionContainer>
                      <Spacing top={productID && sellableEndDate ? 2 : 0}>
                        <Text type="body" fontWeight="bold">
                          {t('CCDE_HOW_TO_USE')}
                        </Text>
                        {Array.isArray(productDescription) ? (
                          productDescription?.map((info, i) => (
                            <Spacing left={0.1}>
                              <Text type="body" key={i}>
                                {i + 1}. {info}
                              </Text>
                            </Spacing>
                          ))
                        ) : (
                          <Spacing left={0.1}>
                            <Text type="body" data-testid="productDescription">
                              {productDescription}
                            </Text>
                          </Spacing>
                        )}
                      </Spacing>
                    </HowToUseSectionContainer>

                    <StyledButtonRow>
                      <Button
                        secondary
                        onClick={() => onCtaClick(clickThroughUrl)}
                        data-testid="useNowButton"
                      >
                        {t('CCDE_USE_NOW')}
                      </Button>
                    </StyledButtonRow>
                  </CardContent>
                </Card>
              </Column>
            )
          )}
        </StyledCardsRow>
      )}
    </>
  );
};

export default ServicePromotions;
