/* istanbul ignore file */

export const CONTAINMENT_IDS = {
  TRADE_IN: '10642'
};

export const PRODUCT_SPEC_IDS = {
  BIB: '4140405'
};

export default {
  // Extracted from configStrings in OSG
  ALLOWED_ACTIONS: {
    REMOVE: 'RM',
    ADD: 'AD',
    UPDATE: 'UP',
    SUSPEND: 'SU',
    RESUME: 'RS'
  },
  DEFAULT_SHOW_ADDONS_COUNT: 5,
  FAMILYPLAN: 'FAMILYPLAN',
  REMOVE_ACK_ADDONS: { SMS_BARRING: '4456445', CALL_BARRING: '4176275' },
  BUNDLE_ADDON_IDS: ['2175705'], // McAfee bundle handling
  ADDON_BUNDLE_MAP: { '2175705': ['2175815', '2175805'] },
  MCAFEE_BUNDLE_ID: '2175705',
  MICROSOFT_365_PSID: '17498471',
  FORCE_BILLING_COMPONENT_IDS: ['17498471'],
  CONTAINMENT_IDS: [CONTAINMENT_IDS.TRADE_IN]
};
