import React from 'react';
import PropTypes from 'prop-types';
import { Column, Grid, Row, Text, Notification, Spacing } from '@dls/web';
import { trans as t } from '../../helpers/localisation';

const CisError = props => {
  const { title, subTitle, ctaText, ctaUrl } = props;

  return (
    <Grid>
      <Row>
        <Column>
          <Text type="bannerTitle">{t('CIS_ERROR_TITLE')}</Text>
          <Spacing topBottom={5}>
            <Notification
              type="alert"
              title={title}
              content={subTitle}
              action={{
                type: 'button',
                text: ctaText,
                onClick: () => {
                  ctaUrl && window.open(ctaUrl);
                }
              }}
            />
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

CisError.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  subTitle: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string
};

export default CisError;
