import { ACTION_TYPES } from '../constants/actions';

export const selectedProduct = state => state.product?.selectedProduct;

const initialState = {};

const productReducers = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_PRODUCT':
      return initialState;

    case 'SET_SELECTED_PRODUCT': {
      return {
        ...state,
        selectedProduct: action.payload
      };
    }

    case ACTION_TYPES.SIMTYPE.SET_SIMTYPE: {
      return {
        ...state,
        selectedSimType: action.payload
      };
    }

    case ACTION_TYPES.ORDER.RESET_SEARCH_NUMBERS:
    case ACTION_TYPES.SIMTYPE.RESET_SIMTYPE:
    case ACTION_TYPES.ORDER.RESET_ORDER:
    case ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA:
      return {
        ...state,
        selectedSimType: null
      };

    default:
      return state;
  }
};

export default productReducers;
