import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from '@lux/helpers';
import notifications from '../../config/notifications';
import validationMessages from '../../config/validation-messages.json';
import { ChooseSIMType } from '../ChooseSIMType';
import {
  TextInput,
  Text,
  Modal,
  TextLink,
  Spacing,
  Row,
  Column,
  Button,
  useDevice
} from '@dls/web';
import { FieldObject } from '../../types/registrationCheckout';
import { isValidPhoneNumber } from '../../helpers/common';
import { getRawPhoneNumber } from '../Checkout/helper';
import { trans as t } from '../../helpers/localisation';
import { ChooseSimType } from '../../constants/choose-sim-type';

import theme from '../../theme';

const PortInDetails = styled.div`
  p {
    margin-bottom: ${theme.spacing(3)};
  }
`;

interface State {
  hintMessage?: string;
  number?: string;
  termsModalOpen?: boolean;
  errorModalOpen?: boolean;
  showErrorStatus?: boolean;
}

interface PropTypes {
  errorStatus: string | boolean;
  onClick: (number: string, selectedSIMType?: string) => void;
  onModalClose: () => void;
  chooseSIMOptions: ChooseSimType[];
  selectedProduct: FieldObject;
}

const NumberPortIn = (props: PropTypes) => {
  const { isMobile } = useDevice();

  const {
    errorStatus,
    onClick,
    onModalClose,
    chooseSIMOptions,
    selectedProduct
  } = props;

  const [state, _setState] = useState<State>({
    hintMessage: '',
    number: undefined,
    termsModalOpen: false,
    errorModalOpen: false,
    showErrorStatus: false
  });

  const setState = (stateChange: Partial<State>) => {
    _setState({ ...state, ...stateChange });
  };

  const validateInput = useCallback(
    (number: string, selectedSIMType?: string) => {
      setState({ hintMessage: '' });

      if (!isValidPhoneNumber(number)) {
        setState({
          hintMessage: validationMessages.validMobileStartingWithEight
        });
        return false;
      }

      onClick(getRawPhoneNumber(number), selectedSIMType);
    },
    [onClick]
  );

  const handleButtonClick = (selectedSIMType?: string) => {
    validateInput(state.number, selectedSIMType);
    setState({ showErrorStatus: true });
  };

  useEffect(() => {
    if (errorStatus && state.showErrorStatus) {
      setState({ errorModalOpen: true });
    }
  }, [errorStatus, state.showErrorStatus]);

  const errorMessage = errorStatus && notifications[errorStatus];
  const TermsAndConditionsModal = () => (
    <Modal
      visible={state.termsModalOpen}
      title="Port in Details"
      onClose={() => setState({ termsModalOpen: false })}
    >
      <Modal.Content>
        <PortInDetails>
          <Text type="body">Undertaking Agreement</Text>
          <Text type="body">
            1. I hereby confirm the information is true and correct.
          </Text>
          <Text type="body">
            2. I hereby consent to the release of all relevant information to a
            third party central number port service provider and/or Donor for
            purpose of receiving the Port-In services.
          </Text>
          <Text type="body">
            3. I understand that I have to inform Singtel Mobile if I decide to
            opt out of any promotional Add-on Services when they expire.
          </Text>
          <Text type="body">
            4. I acknowledge that the existing contract with the Donor is my
            responsibility and agree to pay to the Donor all outstanding charges
            including early termination charges (ETC), if any until successful
            commencement of the Port-In services with Singtel Mobile.
          </Text>
          <Text type="body">
            5. I agree that should a Port-In service request be rejected for any
            reason, I agree to attend to and resolve the issue(s) with the Donor
            or otherwise within fourteen (14) days from the date of this
            Undertaking.
          </Text>
          <Text type="body">
            6. I agree to pay Singtel Mobile, immediately on demand, the
            applicable Device Agreement ETC or special promotional related costs
            should I fail to resolve the dispute and successfully commence the
            Port-In services with Singtel Mobile.
          </Text>
          <Text type="body">
            7. I acknowledge that the above mentioned required date is a
            tentative start date of Port-In request. The effective start
            date/time will be communicated via SMS.
          </Text>
          <Text type="body">
            8. I agree to subscribe for the Port-In services provided by Singtel
            Mobile Singapore Pte. Ltd. (Singtel Mobile) upon the same terms and
            conditions concurrently agreed and accepted by me/us in relation to
            the application for mobile services.
          </Text>
        </PortInDetails>
      </Modal.Content>
    </Modal>
  );

  const ErrorMessageModal = () => {
    const handleModalClose = () => {
      setState({ number: '', errorModalOpen: false, showErrorStatus: false });
      onModalClose();
    };

    return (
      errorMessage && (
        <Modal
          data-testid="error-modal"
          visible={state.errorModalOpen}
          title={errorMessage && errorMessage.title}
          onClose={handleModalClose}
        >
          <Modal.Content>
            <Spacing bottom={3} bottomMd={5}>
              <Text type="body">{errorMessage.text}</Text>
            </Spacing>
            <Button onClick={handleModalClose}>{errorMessage.ctaText}</Button>
          </Modal.Content>
        </Modal>
      )
    );
  };

  const onNumberChanged = e => {
    const number = e.target.value;
    if (isValidPhoneNumber(number)) {
      return setState({ number, hintMessage: undefined });
    }

    return setState({
      hintMessage: validationMessages.validMobileStartingWithEight,
      number
    });
  };

  return (
    <Fragment>
      <Row>
        <Column sm={12} md={12}>
          <Spacing top={isMobile ? 1 : 0.1} bottom={isMobile ? 3 : 2}>
            <Text type="header">
              {t('PORTIN_TRANSFER_MY_NUMBER_TO_SINGTEL')}
            </Text>
          </Spacing>
          <Spacing bottom={1}>
            <TextInput
              displayFormat="mobile-sg"
              bgColor={'haze'}
              label={t('YOUR_EXISTING_NUMBER')}
              value={state.number}
              maxLength={13}
              onChange={onNumberChanged}
              validation={state.hintMessage && 'danger'}
              error={!!state.hintMessage}
              hintMessage={state.hintMessage}
            />
          </Spacing>
          <Spacing bottom={isMobile ? 1 : 0}>
            <Text type="body">
              {t('PORT_IN_TNC_TEXT')}
              <TextLink
                onClick={() => setState({ termsModalOpen: true })}
                inline
              >
                {t('TNC')}
              </TextLink>
            </Text>
          </Spacing>
        </Column>
      </Row>
      {isValidPhoneNumber(state.number) && (
        <ChooseSIMType
          data={chooseSIMOptions}
          scrollToSimType
          transactionTypeValue="portIn"
          onSimTypeOnSelection={handleButtonClick}
          selectedProduct={selectedProduct}
        />
      )}

      {state.termsModalOpen && TermsAndConditionsModal()}
      {state.errorModalOpen && ErrorMessageModal()}
    </Fragment>
  );
};

NumberPortIn.defaultProps = {
  onClick: noop,
  onModalClose: noop
};

export default NumberPortIn;
