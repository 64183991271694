import React from 'react';
import { Button, Modal, Spacing, Text } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { signUpNewPlanLink, cancelExistingPlanLink } from '../../config/links';

export const ExistingSubscriberModal = props => {
  const { isModalOpen, setIsModalOpen } = props;

  return (
    <Modal
      data-testid="existingModalId"
      visible={isModalOpen}
      title={t('EXISTING_MS_SUBSCRIBER_MODAL_TITLE')}
      onClose={() => setIsModalOpen(false)}
    >
      <Modal.Content>
        <Spacing top={1} bottom={2}>
          <Text>{t('ONLY_COVER_PREVIOUS_REGISTERED_DEVICE')}</Text>
        </Spacing>
        <Text>
          {t('UNSUBSCRIBE_FROM_EXISTING_MOBILESWOP', {
            unsubscribe: (
              <Text
                type="link"
                tag="span"
                onClick={() => window.open(cancelExistingPlanLink, '_blank')}
              >
                unsubscribe from your existing MobileSwop.
              </Text>
            )
          })}
        </Text>
        <Spacing top={3} bottom={1}>
          <Button
            secondary
            fullWidth
            onClick={() => window.open(signUpNewPlanLink, '_blank')}
          >
            {t('GET_ANOTHER_MOBILESWOP_BTN')}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default ExistingSubscriberModal;
