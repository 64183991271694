import React, { ReactElement, useEffect, useState } from 'react';
import { Verify } from '@common-modules/registration-checkout';
import CHECKOUT_CONSTANT from '../../constants/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Grid, Row, Spacing, Text, TextLink } from '@dls/web';
import { BaseState } from '../../types/state.types';
import { WorryFreeData } from '../../types/user.types';
import { trans as t } from '../../helpers/localisation';
import AUTH_CONSTANTS from '../../constants/auth';
import getIsRoiMismatch from '../../helpers/is-roi-mismatch';
import {
  authActions,
  NEW_NEW_CUSTOMER,
  OTP_EXCEED_LIMIT_ERROR,
  OTP_GENERIC_ERROR,
  OTP_RESEND_SUCCESS,
  OTP_SENT,
  OTP_TIMEOUT_ERROR,
  OTP_VERIFIED,
  OTP_VERIFY_ERROR,
  WORRY_FREE_FA_CHECK
} from '@detox/actions';
import { navigation } from '../../middlewares/navigation-constants';
import notifications from '../../config/notifications';
import { getUIAMLoginUrl } from '../../config';
import { useCisMyInfo } from '../../hooks/useMyInfo';
import { ACTION_TYPES } from '../../constants/actions';
import CCEMisMatchError from '../CCEPrompt/CCEMisMatchErrorModal';
import { APP_TYPE_ANY } from '../../types/common.types';

type PropTypes = {
  onePassTargetUrl?: string;
};
export const WorryFreeLogin = (props: PropTypes): ReactElement => {
  const { onePassTargetUrl = navigation.CHOOSE_NUMBER_PAGE } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [authStatus, setAuthStatus] = useState(
    AUTH_CONSTANTS.CHECKOUT_VERIFY_ID
  );
  const [cceMismatch, setCceMismatch] = useState(false);
  const { user, auth, roiValidatedData } = useSelector<
    BaseState,
    Partial<BaseState>
  >(state => {
    return {
      user: state.user,
      auth: state.auth,
      roiValidatedData: state.iphone?.validateSkuData
    };
  });
  const { cisMyInfoData } = useCisMyInfo(user);
  const { worryFreeData } = user;
  const worryFreeStatus = auth?.worryFree?.status;
  const isLoginStage = auth.status === AUTH_CONSTANTS.WORRY_FREE_LOGIN;
  const dispatch = useDispatch();
  useEffect(() => {
    switch (worryFreeStatus) {
      case WORRY_FREE_FA_CHECK:
      case NEW_NEW_CUSTOMER: {
        setErrorMessage(notifications[NEW_NEW_CUSTOMER].text as string);
        break;
      }
      case OTP_SENT:
        setErrorMessage('');
        setAuthStatus(AUTH_CONSTANTS.WORRY_FREE_OTP);
        break;
      case OTP_RESEND_SUCCESS:
        setAuthStatus(AUTH_CONSTANTS.WORRY_FREE_OTP);
        break;
      case OTP_VERIFIED:
        break;
      case OTP_EXCEED_LIMIT_ERROR:
        setAuthStatus(AUTH_CONSTANTS.OTP_LIMIT_EXCEEDED);
        break;
      case AUTH_CONSTANTS.OTP_MISMATCH_ERROR:
      case OTP_GENERIC_ERROR: {
        setErrorMessage(t('WORRY_FREE_GENERIC_ERROR') as string);
        setAuthStatus(AUTH_CONSTANTS.OTP_GENERIC_ERROR);
        break;
      }
      case OTP_VERIFY_ERROR:
        setErrorMessage(t('VERIFICATION_OTP_INVALID_OTP_ERROR_MSG') as string);
        break;
      case OTP_TIMEOUT_ERROR:
        setErrorMessage(t('VERIFICATION_OTP_EXPIRED_OTP_ERROR_MSG') as string);
        break;
    }
  }, [worryFreeStatus]);

  const requestOtp = (data: WorryFreeData) => {
    const worryFreeParams = {
      cleanWorryFree: !data.triggeredResend,
      mobileNumber: data.mobileNumberWithoutFormat,
      indentType: data.passType,
      indentValue: data.id
    };
    const hasRoiDataMismatch = getIsRoiMismatch(data.id, roiValidatedData);
    if (hasRoiDataMismatch) {
      return setCceMismatch(true);
    }

    dispatch(authActions.sendOtp(worryFreeParams as APP_TYPE_ANY));
    dispatch({
      type: ACTION_TYPES.AUTH.SET_WORRY_FREE_DATA,
      data
    });
  };

  const verifyOtp = loginDetails => {
    dispatch(authActions.resetWorryFreeStatus({}));
    setErrorMessage('');
    dispatch(
      authActions.verifyOtp({
        otpCode: loginDetails.otp,
        mobileNumber: loginDetails.mobileNumberWithoutFormat,
        indentType: loginDetails.passType,
        indentValue: loginDetails.id
      })
    );
  };

  const handleOnePassLogin = () => {
    window.open(getUIAMLoginUrl(onePassTargetUrl), '_self');
  };

  const renderGenericError = () => {
    return (
      <>
        <Text type="pageTitle">{t('GENERIC_ERROR_TITLE')}</Text>
        <Spacing topBottom={2} responsive={false}>
          <Text type={'body'}>{t('WORRY_FREE_GENERIC_ERROR_MESSAGE')}</Text>
        </Spacing>
        <TextLink onClick={handleOnePassLogin}>{t('LOGIN_ONE_PASS')}</TextLink>
      </>
    );
  };

  const renderOtpLogin = () => {
    return (
      <Verify
        authStatus={isLoginStage ? AUTH_CONSTANTS.WORRY_FREE_NRIC : authStatus}
        data={{
          mainData: CHECKOUT_CONSTANT.MAINDATA,
          data: cisMyInfoData ? { ...cisMyInfoData } : { ...worryFreeData }
        }}
        errorMessage={errorMessage}
        isLoading={auth.isLoading}
        configs={{
          otpTimeInterval: 30,
          onePassLoginRequired: true,
          disabledFields: cisMyInfoData ? ['passType'] : []
        }}
        callbacks={{
          onNricVerified: requestOtp,
          onOtpVerified: verifyOtp,
          onOnePassLoginClick: handleOnePassLogin
        }}
        localisation={{
          VERIFICATION_TITLE: t('LOGIN_WITH_OTP'),
          VERIFICATION_SUBTITLE: null,
          VERIFICATION_GET_SMS_OTP: t('GET_SMS_OTP'),
          VERIFICATION_REQUIREMENTS: t('WORRY_FREE_LOGIN_LINER'),
          VERIFICATION_OTP_VERIFY_WITH_ONEPASS_TEXT: null,
          VERIFICATION_OTP_VERIFY_WITH_ONEPASS: t('LOGIN_ONE_PASS'),
          VERIFICATION_OTP_LIMIT_EXCEEDED_TITLE: t('OTP_REQUESTS_REACHED')
        }}
      />
    );
  };

  const render = () => {
    switch (true) {
      case !isLoginStage &&
        auth.worryFree?.status === AUTH_CONSTANTS.OTP_GENERIC_ERROR:
        return renderGenericError();
      default:
        return renderOtpLogin();
    }
  };

  return (
    <Spacing bottom={5}>
      <Grid>
        <Row>
          <Column>{render()}</Column>
        </Row>
      </Grid>
      {cceMismatch && (
        <CCEMisMatchError
          proceedClicked={() => {
            setCceMismatch(false);
          }}
        />
      )}
    </Spacing>
  );
};
