import { FULFILLMENT } from '../../constants';
import translate from '../../hooks/useTranslation';
import { ACTION_TYPES } from '../../constants/actions';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

const initialState = {
  postcode: '',
  status: REQUEST_STATUS.IDLE,
  timeSlotsStatus: REQUEST_STATUS.IDLE,
  data: [],
  timeslots: [],
  hasError: false,
  errorCode: undefined,
  selectedPopstation: undefined,
  timeSlots: []
};

export default (state = initialState, actions) => {
  const { t } = translate();
  switch (actions.type) {
    case 'GET_POPSTATIONS_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        data: [],
        selectedPopstation: undefined
      };
    }
    case 'UPDATE_GET_POPSTATIONS_STATUS': {
      return {
        ...state,
        status: actions.payload
      };
    }
    case 'GET_POPSTATIONS_SUCCESS': {
      if (actions?.payload?.implPOPStationDetails) {
        return {
          ...state,
          data: actions?.payload?.implPOPStationDetails || [],
          status: REQUEST_STATUS.SUCCEEDED,
          postcode: actions?.meta?.postcode,
          hasError: false,
          errorCode: undefined
        };
      }
      return {
        ...state,
        data: [],
        hasError: true,
        status: REQUEST_STATUS.SUCCEEDED,
        postcode: actions?.meta?.postcode,
        redirectNeeded: false,
        errorCode: 'EMPTY_POPSTATIONS'
      };
    }
    case 'GET_POPSTATIONS_FAILED': {
      return {
        ...state,
        hasError: true,
        status: REQUEST_STATUS.FAILED,
        data: []
      };
    }

    case 'REMOVE_GET_POPSTATION_ERROR': {
      return {
        ...state,
        hasError: undefined,
        errorCode: undefined,
        status:
          state.status === REQUEST_STATUS.PENDING
            ? REQUEST_STATUS.IDLE
            : state.status
      };
    }
    case 'SELECT_POPSTATION': {
      return {
        ...state,
        selectedPopstation: actions.payload
      };
    }
    case 'SET_DELIVERY_MODE': {
      return {
        ...state,
        hasError: undefined,
        errorCode: undefined,
        status:
          state.status === REQUEST_STATUS.PENDING
            ? REQUEST_STATUS.IDLE
            : state.status,
        postcode: '',
        selectedPopstation: undefined,
        data: []
      };
    }
    case 'RESET_POPSTATION': {
      return {
        ...state,
        postcode: '',
        selectedPopstation: undefined,
        data: []
      };
    }
    case 'GET_SELF_COLLECTION_LOADING': {
      return {
        ...state,
        hasError: false,
        timeSlotsStatus: REQUEST_STATUS.PENDING,
        status: REQUEST_STATUS.PENDING,
        timeslots: []
      };
    }
    case 'GET_SELF_COLLECTION_SUCCESS': {
      if (!actions.payload || !actions.payload.length) {
        return {
          ...state,
          errorTitle: t('TIME_SLOTS_UNAVAILABLE'),
          errorMessage: t('TIME_SLOTS_UNAVAILABLE_MSG_POPSTATION'),
          timeSlots: [],
          redirectNeeded: false,
          hasError: true
        };
      }
      return {
        ...state,
        hasError: false,
        timeSlotsStatus: REQUEST_STATUS.SUCCEEDED,
        status: REQUEST_STATUS.SUCCEEDED,
        timeSlots: actions?.payload || []
      };
    }
    case 'GET_SELF_COLLECTION_FAILED': {
      return {
        ...state,
        hasError: true,
        timeSlotsStatus: REQUEST_STATUS.FAILED,
        status: REQUEST_STATUS.FAILED,
        timeSlots: [],
        redirectNeeded: false,
        errorTitle: t('TIME_SLOTS_UNAVAILABLE'),
        errorMessage: t('TIME_SLOTS_UNAVAILABLE_MSG_POPSTATION')
      };
    }
    case ACTION_TYPES.DELIVERY.RESET_ERROR: {
      return {
        ...state,
        hasError: false
      };
    }
    default: {
      return state;
    }
  }
};

export const resetPopStationData = () => dispatch => {
  dispatch({
    type: 'RESET_POPSTATION'
  });
};
