import { useEffect } from 'react';
import { navigate } from 'gatsby';
import CONSTANT from '../../constants/common';
import { api } from '@detox/actions';
const { getToken } = api.mcss.helpers;

export const isUXFSessionExisted = () => {
  const token = getToken();
  return token && token?.includes(CONSTANT.UXF_SESSION_TOKEN);
};

const useRootNavigation = cart => {
  useEffect(() => {
    if (!isUXFSessionExisted() && !(cart && cart?.cartOrder)) {
      navigate('/');
    }
  });
};

export default useRootNavigation;
