/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import styled from 'styled-components';
import { Stepper, Grid, Row, Column, Spacing } from '@dls/web';
import LoadingOverlayComponent from '../LoadingOverlay';
import {
  Fulfilment as FulfilmentModule,
  FulfilmentValue
} from '@common-modules/shopping-cart';
import {
  fulfillmentDeliveryActions,
  fulFillmentActions,
  config,
  FulfilmentMode,
  PaymentMode,
  cartActions
} from '@detox/actions';
import useStockCheck, { STOCK_CHECK_TYPE } from '../../hooks/useStockCheck';
import useTranslate from '../../hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { KeyValue } from '../../types/common.types';
import {
  getAddressInfo,
  getDeliveryModes,
  getPopstationTimeSlot,
  getFulfilmentProductsList,
  getDeliveryTimeSlot,
  getStoreIdsFromLocations,
  getLocationsFromStoreIds,
  getLeadDatesFromAEM,
  getSelectedPopstation,
  getSelectedPopStationTimeSlot,
  getFulfilmentParams,
  getDealerData,
  getContactData,
  getAddressPayload,
  getDoorStepDeliveryTimeSlot,
  DootStepDelivery,
  formatDate,
  formattedString,
  getRetrieveAddressIdParams,
  getDeliveryNote,
  checkIfOOSAndFulfilment,
  getAddressWithId,
  ErrorStateSteps
} from './helpers';
import {
  confirmNewAddress,
  revertNewAddress
} from '../../reducers/fulfillment/addressInfo';
import { BaseAddress, TObject } from '../../types/registrationCheckout';
import {
  PickupLocation,
  PreSelectDeliveryData
} from '../../types/fulfilment.types';

import { FULFILLMENT } from '../../constants';
import CHECKOUT_CONSTANTS from '../../constants/checkout';
import isSimOnlyPlan from '../../helpers/is-sim-only-plan';
import isSimOnlyCard from '../../helpers/is-sim-only-card';
import {
  isMobileShareOrSimOnlyPlan,
  isSimOnlyWithoutDevice,
  isSimOnlyOrMobileShareWithoutDeviceAndAccessories
} from '../../helpers/shoppingCartHelper';
import { POPSTATION_TIMESLOTS, DELIVERY_SLOTS } from '../../config/fulfilment';
import { navigate } from 'gatsby';
import { navigation } from '../../middlewares/navigation-constants';
import {
  initData,
  setAddress,
  setCollectAtStoreTimeSlots,
  setConfirmAddress,
  setDeliveryMode,
  setDeliveryTimeSlots,
  setExistingAddress,
  setPopstations,
  setPopstationTimeslots,
  setPreSelectDeliveryData,
  setDeliveryOptions,
  showCollectAtStoreSlotError,
  clearPreSelectDeliveryData,
  setValidFloorForAddress,
  eApptClear,
  setConfirmFulfilment,
  resetDeliveryMode
} from '../../reducers/fulfillment/delivery';
import { useInit } from '../../hooks/useInit';
import useUser from '../../hooks/useUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { format } from 'date-fns';
import isMobileSharePlan from '../../helpers/is-mobile-share-plan';
import { ActionTypes } from '../../types/orderSummary.types';
import { handleBillingAddress } from '../Checkout/helper';
import { resetPopStationData } from '../../reducers/fulfillment/popstations';
import { phoneTagsConfig } from '../../config/phone-config';
import {
  getAccountBillingInfo,
  getFirstEntries,
  getFormattedDisplayAddress,
  dataLayerPush,
  isTagged,
  flattenNodes
} from '../../helpers/common';
import { useFlowCheck } from '../../hooks/useFlowCheck/useFlowCheck';
import OOSModal from './OOSModal';
import { ACTION_TYPES } from '../../constants/actions';
import { useAmendedTab } from '../../hooks/useAmendedTab/useAmendedTab';
import { PageSkeletonLoader } from '../SkeletonLoader';
import { FulfilmentWrapper } from './styles';
import {
  getAEMFulfilmentById,
  getPreOrderFulfilment,
  getLeadDays
} from '../../helpers/fulfilment.common';
import { getFulfilmentDataLayer } from '../../helpers/dataLayerHelpers';
import { useBrowserBack } from '../../hooks/useBrowserBack/useBrowserBack';

const {
  FULFILMENT_TYPES_AEM,
  REQUEST_STATUS,
  FULFILMENT_TYPES,
  FULFILMENT_ACTIONS
} = FULFILLMENT;

const HeaderGrid = styled(Grid)`
  padding-bottom: 0;
`;
const GoogleMapKey = process.env.GATSBY_GOOGLE_MAP_API_KEY;

interface TProps {
  data: TObject;
}

export const Fulfillment = (props: TProps): ReactElement => {
  const { t } = useTranslate();

  const {
    fulfilment: {
      preOrderLinerForFulfilment,
      backOrderLinerForFulfilment,
      fulfilmentList,
      mobileFulfilmentSlotType,
      leadDays,
      leadDaysForCollectAtStore,
      endEApptTimeInHours
    },
    allStoreIDs = {},
    allErrorMessage
  } = props.data;
  const dispatch = useDispatch();
  const allStoreIDsFlat = flattenNodes(allStoreIDs);
  const allErrorMessagesFlattened = flattenNodes(allErrorMessage);
  const [pickupType, setPickupType] = useState<string | undefined>();
  const [oosModalToggle, setOOSModalToggle] = useState<boolean>(false);
  const [resetSelection, setResetSelection] = useState<boolean>(false);
  const {
    fulfilmentState,
    product,
    cart,
    accessories,
    checkout,
    userInformation,
    productOrder = {},
    selectedPlan,
    stepperTitles,
    user
  } = useSelector((state: KeyValue) => ({
    product: state.product,
    cart: state.cart,
    accessories: state.accessory,
    checkout: state.checkout,
    fulfilmentState: state.fulfillment,
    userInformation: state.user?.information,
    productOrder: state.order?.productOrder,
    selectedPlan: state.plan?.selectedPlan,
    stepperTitles: state.checkout?.stepperTitles,
    user: state.user
  }));
  const { popstations, delivery } = fulfilmentState;

  const onCloseErrorPopUp = (errorStep: ErrorStateSteps) => {
    if (errorStep === 'delivery_appointment_slot') {
      // clear delivery method selection
      dispatch(resetDeliveryMode());
      setPickupType(null);
      setResetSelection(true);
    }

    dispatch({ type: ACTION_TYPES.DELIVERY.RESET_ERROR });
  };

  const { renderError } = useErrorHandler({
    states: [user, fulfilmentState.delivery, popstations],
    onCloseErrorPopUp,
    configuredErrors: allErrorMessagesFlattened
  });

  const { renderAmendedErrorPopup, tabIsAmended } = useAmendedTab();
  const preSelectedDeliveryMode = delivery?.preselectDeliveryData;
  const { selectedProduct } = product;
  const { order, cartOrder } = cart;
  const { selectedAccessories } = accessories;
  const { checkoutFlow, createdAccountId, identityCheck } = checkout;
  const {
    appointmentSlots,
    collectAtStoreTimeSlots,
    deliveryDetails,
    collectAtStoreAppointments,
    casAppointmentCreated,
    cartCleared
  } = delivery;
  const isBackOrder = selectedProduct?.isBackOrder;
  const isPreOrder = selectedProduct?.isPreOrder;
  const eApptCancelData = casAppointmentCreated
    ? collectAtStoreAppointments?.appointments?.[0]?.apptId
    : null;

  const aemFulfilmentById = getAEMFulfilmentById(
    fulfilmentList,
    'mobileFulfilment'
  );
  const preOrderFulfilment = getPreOrderFulfilment(
    [selectedProduct?.deviceCode],
    leadDays
  );

  const hasFreeGift = cartOrder?.freebies?.length;
  const hasDocument = checkout.documentUpload?.length > 0;
  const hasTradeIn = order?.tradeInDetails;
  const storeIds = getStoreIdsFromLocations(allStoreIDsFlat);
  const isPopstationLoading =
    fulfilmentState?.popstations?.status === REQUEST_STATUS.PENDING;
  const isPopstationTimeslotLoading =
    fulfilmentState?.popstations?.timeSlotsStatus === REQUEST_STATUS.PENDING;
  const userContactId =
    userInformation?.clientContext?.contact?.contactId ||
    createdAccountId?.contactId ||
    identityCheck?.contactId;

  const { isUserLoggedIn } = useUser();

  const {
    stockAvailable: {
      status: hasOfflineStock,
      availableStoreIds,
      isFetched: isOfflineStockFetched
    }
  } = useStockCheck(STOCK_CHECK_TYPE.STORE_MULTIPLE_SKUS, storeIds);

  const isShowOverlayLoading =
    checkout.isLoading ||
    fulfilmentState.addressInfo?.isLoading ||
    fulfilmentState?.delivery?.isLoading ||
    fulfilmentState?.delivery?.isDeliveryLoading ||
    isPopstationTimeslotLoading;

  useBrowserBack();
  useFlowCheck({ flowData: [cart?.cartOrder, checkoutFlow] });
  useEffect(() => {
    dispatch({ type: CHECKOUT_CONSTANTS.CHECKOUT_LOADING, value: false });
    dispatch(setConfirmFulfilment(false));
    dataLayerPush(
      getFulfilmentDataLayer({
        selectedPlan,
        selectedVariant: selectedProduct,
        accessories: accessories?.selectedAccessories,
        location: window.location,
        order: productOrder
      }),
      true
    );
  }, []);

  useEffect(() => {
    if (popstations?.data && !isPopstationLoading) {
      dispatch(setPopstations(popstations.data));
    }
  }, [popstations?.data]);

  useEffect(() => {
    if (popstations?.timeSlots?.length) {
      const popstationTimeslots = getPopstationTimeSlot(popstations.timeSlots);
      dispatch(
        setPopstationTimeslots(
          getFirstEntries(popstationTimeslots, POPSTATION_TIMESLOTS) as string[]
        )
      );
    } else if (popstations?.hasError) {
      dispatch(setPopstationTimeslots([]));
    }
  }, [popstations?.timeSlots]);

  useEffect(() => {
    if (isUserLoggedIn) {
      const { address, validFloors, savedAddress } =
        fulfilmentState?.addressInfo || {};
      if (address && Object.keys(address).length > 0) {
        const addressWithId = getAddressWithId(address, savedAddress);
        dispatch(setConfirmAddress(addressWithId));
        dispatch(setValidFloorForAddress(validFloors));
      } else {
        dispatch(setConfirmAddress(null));
      }
    } else {
      dispatch(setConfirmAddress(null));
      dispatch(setValidFloorForAddress(null));
    }
  }, [fulfilmentState?.addressInfo, isUserLoggedIn]);

  const isBuyingWithoutDevice =
    isMobileShareOrSimOnlyPlan(selectedPlan) && !cartOrder?.device;

  useEffect(() => {
    if (pickupType) {
      dispatch(setDeliveryMode(pickupType));

      if (preSelectedDeliveryMode?.selectedDeliveryMode !== pickupType) {
        dispatch(clearPreSelectDeliveryData());
        dispatch(eApptClear());
      }

      pickupType !== FULFILMENT_TYPES.POP_STATION &&
        dispatch(resetPopStationData());
    }
  }, [pickupType]);

  useEffect(() => {
    if (cartCleared) {
      setOOSModalToggle(false);
      navigate(`/${navigation.EMPTY_SHOPPING_CART}`, { replace: true });
    }
  }, [cartCleared]);

  useEffect(() => {
    const { addresses, addAddressInfo, canAddNewAddress } = getAddressInfo({
      checkoutFlow,
      fulfilmentState,
      t,
      pickupType,
      fulfilmentList,
      isBuyingWithoutDevice,
      newAddressData: {
        floor: checkout?.checkoutFormData?.floorNumber,
        unitNumber: checkout?.checkoutFormData?.unitNumber
      },
      addressId: checkout?.newAddedAddressId
    });

    dispatch(
      setExistingAddress({
        data: addresses,
        information: addAddressInfo,
        canAddNewAddress
      })
    );
  }, [fulfilmentState?.delivery?.deliveryDetails]);

  // get pre-booked slots for collect-at-store
  const updatePreBookedTimeSlots = () => {
    dispatch(
      fulfillmentDeliveryActions.contactView({
        contactId: userContactId,
        customerId: userContactId
      })
    );

    const { productOrderReferenceNumber } = productOrder;

    if (!deliveryDetails?.contactAddress) {
      dispatch(
        fulfillmentDeliveryActions.getDeliveryDetails({
          contactId: userContactId,
          productOrderReferenceNumber
        })
      );
    }
  };

  useEffect(() => {
    if (appointmentSlots?.slots?.length > 0) {
      const deliverySlots = getDeliveryTimeSlot(appointmentSlots?.slots);
      const enabledDays = getFirstEntries(deliverySlots, DELIVERY_SLOTS);
      dispatch(setDeliveryTimeSlots(enabledDays));
    }
  }, [appointmentSlots]);

  const {
    productsList,
    hasAccessories,
    hasTradeIn: tradeInAdded,
    hasFreebies
  } = getFulfilmentProductsList({
    cartOrder,
    selectedProduct,
    fulfillment: fulfilmentState,
    selectedAccessories,
    isBuyingWithoutDevice
  });

  const isSimOnlyCardSelected = isSimOnlyCard(
    selectedPlan,
    cart?.cartOrder?.device,
    hasAccessories,
    hasFreebies
  );

  const isSimOnlyWithAccessories = isSimOnlyCard(
    selectedPlan,
    cart?.cartOrder?.device,
    !hasAccessories,
    hasFreebies
  );

  const isMobileShare = isMobileSharePlan(selectedPlan);
  const simOnlyOrMobileShare = isSimOnlyCardSelected || isMobileShare;

  useInit(
    () => {
      init();
    },
    [isOfflineStockFetched],
    isSimOnlyWithAccessories
  );

  // This applies for SIM card only selection without device, accessories and freebies/mobile share.
  useInit(() => {
    init();
  }, [simOnlyOrMobileShare]);

  const init = () => {
    const availableStoreLocations = getLocationsFromStoreIds(
      availableStoreIds || [],
      allStoreIDsFlat
    );

    const isLifeStyleProduct = isTagged(
      selectedProduct?.tags || [],
      phoneTagsConfig['home-lifestyle'].tagName
    );

    const disableCollectAtStore = hasAccessories || tradeInAdded;
    let pickup = getDeliveryModes({
      deliveryModes: fulfilmentList,
      hasOfflineStock,
      isPopStationAvailable: selectedProduct?.popStationAvailable,
      tags: selectedProduct?.tags,
      flowParams: {
        hasTradeIn,
        hasDocument,
        hasFreeGift,
        flow: checkout.checkoutFlow,
        hasLifeStyleProduct: isLifeStyleProduct,
        hasAccessory: !!cart?.cartOrder?.accessories?.length,
        hasSim: productOrder?.sim?.hasSim,
        isBackOrder: selectedProduct?.isBackOrder,
        isPreOrder: selectedProduct?.isPreOrder,
        hasHandset: cart?.cartOrder?.device,
        isSimOnly: isSimOnlyPlan(selectedPlan),
        isMobileShare: isMobileSharePlan(selectedPlan)
      },
      availableStoreLocations,
      disableCollectAtStore,
      selectedAccessories,
      isBib: !!cart.order?.bib?.tradeInBo
    });

    if (selectedProduct?.deviceCode) {
      pickup = pickup.filter(item => item.type !== FULFILMENT_TYPES.SNAIL_MAIL);
    }

    const note = getDeliveryNote(
      selectedProduct,
      leadDays,
      preOrderLinerForFulfilment,
      backOrderLinerForFulfilment
    );

    dispatch(
      initData({
        note,
        pickup,
        productList: productsList
      })
    );

    const pickupModes = pickup || [];
    const showOOSToggle = checkIfOOSAndFulfilment(pickupModes);

    setOOSModalToggle(showOOSToggle);
  };

  const searchPopstationTimeslot = outlet => {
    dispatch(
      fulfillmentDeliveryActions.getSelfCollectionTimeSlots({
        dealer: config.dealerId,
        outlet,
        documentRequired: hasDocument,
        hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate),
        leadDays: getLeadDays({
          hasDocument,
          firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
          fulfilmentData: aemFulfilmentById[FULFILMENT_TYPES_AEM.POP_STATION]
        })
      })
    );
  };

  const searchPopstation = (postalCode: string) => {
    if (postalCode) {
      dispatch(fulfillmentDeliveryActions.getPopstations(postalCode));
    }
  };

  const searchAddress = (postcode: string) => {
    const address = deliveryDetails?.billingAccountDetails.map(
      billingAccountDetails => billingAccountDetails.physicalAddress
    );

    dispatch(setAddress(address));

    if (postcode) {
      dispatch(fulfillmentDeliveryActions.getAddressInfo(postcode, false));
    }
  };

  const onConfirmNewAddress = (address: Partial<BaseAddress>) => {
    dispatch(confirmNewAddress(address));
  };
  const onCloseAddressModal = () => {
    dispatch(revertNewAddress());
  };

  const retrieveDeliveryTimeslot = (id, pickupType) => {
    const fulfilmentData =
      pickupType === FULFILMENT_TYPES.SNAIL_MAIL
        ? aemFulfilmentById[FULFILMENT_TYPES_AEM.SNAIL_MAIL]
        : aemFulfilmentById[FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY];

    const paymentMode: PaymentMode = 'CASH';
    const options = {
      appointmentOrderId: productOrder?.productOrderId,
      slotType:
        pickupType === FULFILMENT_TYPES.SNAIL_MAIL
          ? config.slotTypes.N
          : mobileFulfilmentSlotType,
      paymentMode: paymentMode,
      dealerCode: config.dealerId,
      documentRequired: hasDocument,
      leadDay: getLeadDays({
        hasDocument,
        firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
        fulfilmentData: fulfilmentData
      }),
      hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate)
    };
    dispatch(fulfillmentDeliveryActions.getLogisticAppointmentsSlots(options));
  };

  // set the delivery information liner

  const onCollectAtStoreSelection = useCallback(
    (selectedStore: PickupLocation) => {
      if (selectedStore?.mtposId && userInformation) {
        const hasPrelaunchDevice =
          selectedProduct?.backOrderText || selectedProduct?.preOrderText;

        const payload = {
          hasPrelaunchDevice: !!hasPrelaunchDevice,
          ...getLeadDatesFromAEM({
            selectedProduct,
            fulfilmentList,
            leadDaysForFulfilmentMode: leadDaysForCollectAtStore ?? [],
            hasDocument,
            endEApptTimeInHours
          }),
          dealerCode: selectedStore?.mtposId,
          contactId: userContactId
        };
        dispatch(
          fulfillmentDeliveryActions?.getCollectAtStoreTimeSlots(payload)
        );
      }
    },
    [
      userInformation,
      dispatch,
      selectedProduct?.backOrderText,
      selectedProduct?.preOrderText
    ]
  );

  useEffect(() => {
    dispatch(setCollectAtStoreTimeSlots(collectAtStoreTimeSlots));
  }, [collectAtStoreTimeSlots]);

  useEffect(() => {
    if (userInformation?.clientContext) {
      updatePreBookedTimeSlots();
    }
  }, [userInformation?.clientContext]);

  useEffect(() => {
    if (fulfilmentState?.delivery?.submitSuccess) {
      dispatch({
        type: FULFILMENT_ACTIONS.RESET_FULFILMENT
      });
      dispatch(setConfirmFulfilment(true));
      navigate(`/${navigation.ORDER_SUMMARY}`);
    }
  }, [fulfilmentState?.delivery?.submitSuccess]);

  const onDeliveryModeSelected = (type: string) => {
    setPickupType(type);
  };

  const getCustomerId = () => {
    const { customerId } = getAccountBillingInfo({
      checkout,
      delivery,
      userInformation,
      productOrder
    });
    return customerId;
  };

  const getBarId = (): string => {
    const { barId } = getAccountBillingInfo({
      checkout,
      delivery,
      userInformation,
      productOrder
    });
    return barId;
  };

  const onPopstationSubmit = (
    addressId: string,
    selectedTimeslot,
    address,
    selectedFulfilmentMode: string
  ) => {
    if (selectedTimeslot && address && user.information && productOrder) {
      const fulfilmentRequestParams = getFulfilmentParams({
        productOrder,
        selectedTimeslot,
        address,
        userInfo: user.information,
        simOnlyPlan: isSimOnlyWithoutDevice(selectedPlan, selectedProduct),
        hasDocument,
        isNewSimAdded: !!order?.newlyAddedSimDetails?.simDetails,
        addressId,
        order,
        selectedFulfilmentMode,
        billingAccountId: getBarId(),
        customerId: getCustomerId(),
        isBackOrder,
        isPreOrder,
        eApptCancelData,
        cartItem: cartOrder,
        fulfillment: fulfilmentState
      });
      dispatch(
        fulFillmentActions.proceedToOrderSummary(fulfilmentRequestParams)
      );
    }
  };

  const onDeliverySubmit = async ({
    deliverySelectedSlot,
    pickupType,
    address,
    newBarId
  }) => {
    if ((address && !address?.addressId) || address?.isNewAddress) {
      address.addressId = await dispatch(
        fulfillmentDeliveryActions.retrieveAddressId(
          getRetrieveAddressIdParams(address),
          FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY as FulfilmentMode
        )
      );
    }

    const selectedFulfilmentMode = DootStepDelivery[pickupType];
    const deliveryTimeSlot = getDoorStepDeliveryTimeSlot({
      slots: appointmentSlots?.slots,
      deliverySelectedSlot,
      pickupType
    });
    const msisdn = cart?.cartOrder?.serviceId;
    const isSnailMail = pickupType === FULFILMENT_TYPES.SNAIL_MAIL;
    const selectedAddressId =
      address?.addressId || deliveryDetails?.contactAddress?.addressId;
    const fulfilmentRequestParams = getFulfilmentParams({
      address: {},
      addressId: selectedAddressId,
      productOrder,
      selectedTimeslot: deliveryTimeSlot,
      userInfo: userInformation,
      simOnlyPlan: isSimOnlyWithoutDevice(selectedPlan, selectedProduct),
      hasDocument,
      isNewSimAdded: !!order?.newlyAddedSimDetails?.simDetails,
      order,
      selectedFulfilmentMode,
      isPayChannelRequired: !!userInformation,
      msisdn,
      isAllocateEquipmentRequired: false,
      isReserveEquipmentRequired: false,
      billingAccountId: newBarId || getBarId(),
      customerId: getCustomerId(),
      isBackOrder,
      isPreOrder,
      eApptCancelData,
      isSnailMail,
      cartItem: cartOrder,
      fulfillment: fulfilmentState
    });
    const { selectedDate, selectedTime } = deliverySelectedSlot || {};
    const { slotStartDate, timeDescription } = deliveryTimeSlot || {};
    const { postcode } = address || {};
    const deliveryDateAndTime = () => {
      return isSimOnlyOrMobileShareWithoutDeviceAndAccessories(
        selectedPlan,
        selectedProduct,
        selectedAccessories
      )
        ? null
        : `${format(
            new Date(slotStartDate),
            'dd MMM yyyy'
          )}, ${timeDescription || ''}`;
    };
    const reserveSlotType = isSnailMail
      ? config.slotTypes.N
      : config.slotTypes.M;
    const deliveryOptions = {
      title: isSnailMail ? t('MAILBOX_DELIVERY') : t('DOOR_STEP_DELIVERY'),
      content: [
        deliveryDateAndTime(),
        getFormattedDisplayAddress(address),
        `${t('SINGAPORE')} ${postcode || ''}`
      ],
      action: {
        text: t('CHANGE'),
        type: ActionTypes.CHANGE_DELIVERY_ADDRESS
      }
    };

    dispatch(setDeliveryOptions(deliveryOptions));

    setPreselectDeliveryData({
      address: selectedAddressId,
      timeslot: selectedDate && formatDate(selectedDate),
      selectedTime
    });
    dispatch(
      fulFillmentActions.proceedToOrderSummary({
        ...fulfilmentRequestParams,
        reserveSlotType
      })
    );
  };

  const onCollectAtStoreSubmit = async ({ fulfilmentValue }) => {
    const {
      collectAtStoreTimeSlot: { selectedTimeSlot },
      collectAtStoreTimeSlotStore
    } = fulfilmentValue;
    const contactData = getContactData(userInformation.clientContext);
    const dealerData = getDealerData(selectedTimeSlot, productOrder);
    const eApptCreateData = { ...contactData, ...dealerData };
    const selectedCollectAtStore = {
      selectedStore: collectAtStoreTimeSlotStore,
      collectionAddress: getAddressPayload(collectAtStoreTimeSlotStore)
    };

    const fulfilmentRequestParams = getFulfilmentParams({
      address: {},
      addressId: '',
      productOrder,
      selectedTimeslot: selectedTimeSlot,
      userInfo: userInformation,
      simOnlyPlan: isSimOnlyWithoutDevice(selectedPlan, selectedProduct),
      hasDocument,
      isNewSimAdded: !!order?.newlyAddedSimDetails?.simDetails,
      order,
      selectedFulfilmentMode: FULFILMENT_TYPES_AEM.COLLECT_AT_STORE,
      isReserveEquipmentRequired: true,
      eApptCreateData,
      eApptCancelData,
      selectedCollectAtStore,
      isPayChannelRequired: true,
      billingAccountId: getBarId(),
      selectedStoreId: dealerData?.dealerCode,
      customerId: getCustomerId(),
      isBackOrder,
      isPreOrder,
      cartItem: cartOrder,
      fulfillment: fulfilmentState
    });
    dispatch(fulFillmentActions.proceedToOrderSummary(fulfilmentRequestParams));
  };

  const onSubmitFulfilment = async (fulfilmentValue: FulfilmentValue) => {
    dispatch(
      handleBillingAddress({
        checkout,
        delivery,
        userInformation,
        fulfilmentState,
        callback: barId => {
          onConfirmDelivery(fulfilmentValue, barId);
        }
      })
    );
  };

  const onConfirmDelivery = async (
    fulfilmentValue: FulfilmentValue,
    newBarId?: string
  ) => {
    switch (pickupType) {
      case FULFILMENT_TYPES.SNAIL_MAIL:
      case FULFILMENT_TYPES.DOOR_STEP_DELIVERY: {
        const { deliverySelectedSlot, address } = fulfilmentValue;
        onDeliverySubmit({
          deliverySelectedSlot,
          pickupType,
          address,
          newBarId
        });
        break;
      }

      case FULFILMENT_TYPES.POP_STATION: {
        const selectedPopStation = getSelectedPopstation(
          fulfilmentValue,
          popstations?.data
        );
        if (selectedPopStation && fulfilmentValue?.popStation?.date) {
          const selectedTimeslot = getSelectedPopStationTimeSlot(
            fulfilmentState?.popstations?.timeSlots,
            fulfilmentValue?.popStation?.date
          );
          const addressId = await dispatch(
            fulfillmentDeliveryActions.retrieveAddressId(
              selectedPopStation,
              FULFILMENT_TYPES_AEM.POP_STATION as FulfilmentMode
            )
          );

          if (addressId) {
            onPopstationSubmit(
              '' + addressId,
              selectedTimeslot,
              selectedPopStation,
              FULFILMENT_TYPES_AEM.POP_STATION
            );
          }
          const { popStation } = fulfilmentValue;
          const {
            POPStationName,
            StreetName,
            BuildingName,
            UnitNumber,
            ZipCode,
            HouseBlockNumber = '',
            Storey = ''
          } = selectedPopStation;
          const deliveryOptions = {
            title: t('POPSTATION'),
            content: [
              `${format(new Date(popStation.date), 'dd MMM yyyy')}`,
              `${POPStationName}`,
              `${formattedString([
                `${HouseBlockNumber} ${StreetName}`,
                UnitNumber
              ])}`,
              `${formattedString([BuildingName, Storey])}`,
              `${t('SINGAPORE')} ${ZipCode}`
            ],
            action: {
              text: t('CHANGE'),
              type: ActionTypes.CHANGE_POPSTATION_ADDRESS
            }
          };
          dispatch(setDeliveryOptions(deliveryOptions));
          setPreselectDeliveryData({
            address: selectedPopStation.KioskId,
            postCode: fulfilmentState.popstations.postcode,
            timeslot: selectedTimeslot.slotDate
          });
        }
        break;
      }

      case FULFILMENT_TYPES.COLLECT_AT_STORE: {
        const {
          collectAtStoreTimeSlotStore,
          collectAtStoreTimeSlot
        } = fulfilmentValue;

        if (
          collectAtStoreTimeSlotStore &&
          collectAtStoreTimeSlot &&
          collectAtStoreTimeSlots?.length > 0
        ) {
          const {
            searchTerm,
            lat,
            lon,
            name,
            building,
            street,
            postalCode
          } = collectAtStoreTimeSlotStore;
          const { selectedTimeSlot } = collectAtStoreTimeSlot;

          const deliveryOptions = {
            title: t('COLLECT_AT_STORE'),
            content: [
              `${format(new Date(selectedTimeSlot.slotDate), 'dd MMM yyyy')}, ${
                selectedTimeSlot.slotDesc
              }`,
              `${name}`,
              `${formattedString([street, building])},`,
              `${postalCode}`
            ],
            action: {
              text: t('CHANGE'),
              type: ActionTypes.CHANGE_STORE_SELECTION
            }
          };
          dispatch(setDeliveryOptions(deliveryOptions));

          setPreselectDeliveryData({
            timeslot: selectedTimeSlot.slotDate,
            selectedTime: selectedTimeSlot.slotDesc,
            mapPreSelection: {
              searchTerm,
              selectedLocation: {
                lat: +lat,
                lng: +lon
              }
            }
          });

          onCollectAtStoreSubmit({
            fulfilmentValue
          });
        } else if (collectAtStoreTimeSlotStore && !collectAtStoreTimeSlot) {
          showCollectAtStoreSlotError();
        }

        break;
      }
    }
  };

  const renderHeader = () => {
    return (
      <HeaderGrid>
        <Row>
          <Column xs={12} md={8}>
            <Stepper
              totalSteps={stepperTitles?.length}
              activeStep={stepperTitles?.length === 3 ? 2 : 1}
              titles={stepperTitles}
            />
          </Column>
        </Row>
      </HeaderGrid>
    );
  };

  const renderSkeleton = () => {
    if (
      !(
        fulfilmentState?.mtposStock?.isLoading ||
        fulfilmentState?.storeStock?.isLoading
      )
    ) {
      return null;
    }
    return (
      <PageSkeletonLoader
        secondaryItem={'Card'}
        numberOfItem={4}
        column={{ xs: 12, sm: 7 }}
      />
    );
  };

  const setPreselectDeliveryData = ({
    postCode,
    address,
    timeslot,
    selectedTime,
    mapPreSelection
  }: PreSelectDeliveryData) => {
    dispatch(
      setPreSelectDeliveryData({
        selectedDeliveryMode: delivery?.selectedDeliveryMode,
        postCode,
        address,
        timeslot,
        selectedTime,
        mapPreSelection
      })
    );
  };

  const closeOOSModalAndClear = async () => {
    const isNewNew =
      checkoutFlow === CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER;
    const { productOrderId, productOrderItemId } = productOrder;

    const userCustomerId =
      userInformation?.clientContext?.customers?.[0]?.customerId;
    const customerId = isNewNew ? {} : { customerId: userCustomerId };

    await dispatch(
      cartActions.emptyCart({
        ...customerId,
        productOrderId,
        productOrderItemId
      })
    );
  };

  return (
    <Spacing top={2}>
      {renderHeader()}
      {!tabIsAmended && (
        <FulfilmentWrapper>
          <FulfilmentModule
            data={{
              ...delivery.deliveryDataModel,
              googleMapKey: GoogleMapKey
            }}
            configs={{
              isPopstationLoading,
              isPopstationTimeslotLoading,
              isCollectAtSoreTimeSlotLoading:
                delivery?.deliveryModeLoading?.collectAtStore,
              showMultiModel: false,
              preSelection: delivery?.preselectDeliveryData,
              doneFetchingAddress:
                fulfilmentState?.addressInfo?.status ===
                  REQUEST_STATUS.SUCCEEDED ||
                fulfilmentState?.addressInfo?.status === REQUEST_STATUS.FAILED,
              resetSelection: resetSelection
            }}
            localisation={{}}
            callbacks={{
              onProceed: (value: FulfilmentValue) => {
                onSubmitFulfilment(value);
              },
              onConfirmNewAddress,
              onSearchAddress: searchAddress,
              onDeliveryModeSelected,
              onRetrieveAppointmentSlot: retrieveDeliveryTimeslot,
              onSearchPopstation: searchPopstation,
              onSearchPopstationTimeslot: searchPopstationTimeslot,
              onCloseAddressModal,
              onCollectAtStoreSelection,
              onResetSelection: setResetSelection
            }}
          />
          {renderSkeleton()}
        </FulfilmentWrapper>
      )}
      {isShowOverlayLoading && <LoadingOverlayComponent />}
      {!isShowOverlayLoading && oosModalToggle && (
        <OOSModal
          oosModalToggle={oosModalToggle}
          setOOSModalToggle={closeOOSModalAndClear}
        />
      )}
      {!tabIsAmended && renderError()}
      {renderAmendedErrorPopup()}
    </Spacing>
  );
};
