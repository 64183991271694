import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import planReducer from './plan';
import productReducer from './product';
import userReducer, { userTransform } from './user';
import authReducer from './auth';
import orderReducer from './order';
import ngnisReducer from './ngnis';
import promotionsReducer from './promotions';
import cartReducer from './cart';
import addonsReducer from './addons';
import fulfillmentReducer from './fulfillment';
import settingReducer from './setting';
import voucherReducer from './voucher';
import accessoryReducer from './accessory';
import { getStorage } from '../helpers/reduxHelper';
import checkoutReducer from './checkout';
import documentsReducer from './documents';
import { bridgeReducer } from './bridge';
import { orderSummaryReducer } from './orderSummary';
import { rrpOrderSummaryReducer } from './rrpOrderSummary';
import rrpCartReducer from './rrpCart';
import { orderSubmissionReducer } from './orderSubmission';
import { paymentReducer } from './payment';
import { createCustomerProblemReducer } from './createCustomerProblem';
import livePersonReducer from './livePerson';
import { extendSessionTimeoutReducer } from './extendSessionTimeout';
import { thirdPartyReducer } from './thirdParty';
import { creditLimitReviewReducer } from './creditLimitReview';
import { iphoneReducer } from './iPhone';
import tokenReducer from './token';
import { rrpCheckoutReducer } from './rrpCheckout';
import { productCatalogReducer } from './productCatalogs';
import customerMyInfoReducer from './customerMyInfo';
import { UserPromotionsReducer } from './userPromotions';
import { apigeeAuthReducer } from './apigeeAuth';

const userPersistConfig = {
  key: 'user',
  storage: getStorage(),
  whitelist: ['cis', 'mainLines', 'selectedService'],
  transforms: [userTransform]
};

const appReducer = combineReducers({
  auth: authReducer,
  ngnis: ngnisReducer,
  order: orderReducer,
  plan: planReducer,
  product: productReducer,
  promotions: promotionsReducer,
  cart: cartReducer,
  rrpCart: rrpCartReducer,
  addons: addonsReducer,
  setting: settingReducer,
  fulfillment: fulfillmentReducer,
  voucher: voucherReducer,
  accessory: accessoryReducer,
  user: persistReducer(userPersistConfig, userReducer),
  checkout: checkoutReducer,
  documents: documentsReducer,
  bridge: bridgeReducer,
  orderSummary: orderSummaryReducer,
  rrpOrderSummary: rrpOrderSummaryReducer,
  orderSubmission: orderSubmissionReducer,
  payment: paymentReducer,
  createCustomerProblem: createCustomerProblemReducer,
  livePerson: livePersonReducer,
  extendSessionTimeout: extendSessionTimeoutReducer,
  creditLimitReview: creditLimitReviewReducer,
  thirdParty: thirdPartyReducer,
  iphone: iphoneReducer,
  rrpCheckout: rrpCheckoutReducer,
  token: tokenReducer,
  productCatalog: productCatalogReducer,
  customerMyInfo: customerMyInfoReducer,
  userPromotions: UserPromotionsReducer,
  apigeeAuth: apigeeAuthReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
