import React, { ReactElement, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
  Column,
  Grid,
  withTheme,
  Row,
  Spacing,
  Text,
  TextLink,
  useDevice
} from '@dls/web';
import SingpassLogo from '@dls/assets/dist/images/logo_singpass_full_colour.png';
import { trans as t } from '../../helpers/localisation';
import { customerMyInfoActions } from '@detox/actions';
import { useDispatch, useSelector } from 'react-redux';
import { navigation } from '../../middlewares/navigation-constants';
import { TObject } from '../../types/registrationCheckout';
import LoadingOverlayComponent from '../LoadingOverlay';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import CHECKOUT_CONSTANT from '../../constants/checkout';
import { ACTION_TYPES } from '../../constants/actions';
import styled, { css } from 'styled-components';

import { useBrowserBack } from '../../hooks/useBrowserBack/useBrowserBack';
import { getIfESimOrder } from '../../selectors';
import isFeatureFlagEnabled from '../../helpers/feature-flags';
import { ENABLE_MANUAL_FLOW } from '../../types/featureFlag.types';
import SingpassRetrieveMyInfo from '../../assets/svgs/singpass-retrieve-myInfo.svg';

const StyledBox = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    background-color: ${coreTheme.cl_ter_l6};
    border-radius: 16px;
    padding-left: 32px;
    @media (max-width: ${coreTheme.brk_sm}) {
      padding-left: 0px;
    }
  `}
`);

const MyInfoRetrieval = (): ReactElement => {
  const [retrieveErrorMsg, setRetrieveErrorMsg] = useState<boolean>(false);
  const dispatch = useDispatch();
  useBrowserBack();
  const { isMobile } = useDevice();
  const manualFormEnabled = isFeatureFlagEnabled(ENABLE_MANUAL_FLOW);
  const { customerMyInfo, productOrder, isESimOrder } = useSelector(
    (state: TObject) => ({
      customerMyInfo: state?.customerMyInfo,
      productOrder: state.order?.productOrder,
      isESimOrder: getIfESimOrder(state)
    })
  );

  const { renderError } = useErrorHandler({
    states: [customerMyInfo]
  });

  const cleanUpCheckoutData = () => {
    dispatch({
      type: CHECKOUT_CONSTANT.CHECKOUT_CLEAR_CUSTOMER_MY_INFO
    });
    dispatch({
      type: CHECKOUT_CONSTANT.RESET_CHECKOUT_FORM_DATA
    });
  };

  useEffect(() => {
    // this will check and navigate to home page if invalid entry
    if (!productOrder) {
      navigate('/', { replace: true });
    }
  }, [productOrder]);

  useEffect(() => {
    // clear existing customer info response from myinfo
    cleanUpCheckoutData();
  }, []);

  useEffect(() => {
    if (customerMyInfo?.retrieveMyInfoUrl) {
      const targetUrl = customerMyInfo?.retrieveMyInfoUrl?.url;
      setRetrieveErrorMsg(false);
      navigate(targetUrl);
      dispatch({
        type: ACTION_TYPES.USER_IDENTITY_CHECK.CLEAR_MY_INFO_URL
      });
    } else {
      setRetrieveErrorMsg(true);
    }
  }, [customerMyInfo]);

  const retrieveRedirectUrl = async () => {
    cleanUpCheckoutData();
    const options = {
      redirectUrl: `${window.location.origin}/${navigation.CHECKOUT}`
    };
    dispatch(customerMyInfoActions?.retrieveMyInfoURL(options));
  };

  const handlePhysicalCard = (goManual = false) => {
    if (goManual) {
      cleanUpCheckoutData();
      navigate(`/${navigation.CHECKOUT}`);
    } else {
      navigate(`/${navigation.NUMBER_SELECTION_PAGE}`);
    }
  };

  const renderContent = () => {
    return (
      <Spacing top={1} bottom={isMobile ? 3 : 2}>
        <Text>{t('SINGPASS_DESCRIPTION')}</Text>
      </Spacing>
    );
  };

  const renderTitle = () => {
    return (
      <Spacing top={2}>
        <img src={SingpassLogo} alt="Singpass logo" style={{ width: 165 }} />
      </Spacing>
    );
  };

  return (
    <>
      {retrieveErrorMsg && renderError()}
      <Grid>
        <Row>
          <Column noGutter xs={12} md={7}>
            <Spacing top={1} bottom={3}>
              <Text type="pageTitle">{t('EKYC_PAGE_TITLE')}</Text>
            </Spacing>
            <StyledBox>
              <Spacing bottom={isMobile ? 2 : 3}>
                <Column>
                  {renderTitle()}
                  {renderContent()}
                  <SingpassRetrieveMyInfo
                    data-testid="retrieveRedirectUrl"
                    onClick={retrieveRedirectUrl}
                    width={320}
                    height={50}
                  />
                </Column>
              </Spacing>
            </StyledBox>

            <Spacing top={3} bottom={3}>
              <Text type="boldBody">{t('IMDA_REGULATION_TEXT')}</Text>
            </Spacing>

            {manualFormEnabled && (
              <Spacing top={3} bottom={3}>
                <Text type="boldBody">{t('NO_SINGPASS_ACCOUNT')}</Text>
                {isESimOrder ? (
                  <Text>
                    Get a{' '}
                    <TextLink
                      data-testid="go-psim"
                      onClick={() => {
                        handlePhysicalCard();
                      }}
                    >
                      physical SIM card
                    </TextLink>{' '}
                    instead.
                  </Text>
                ) : (
                  <Text>
                    <TextLink
                      data-testid="go-manual"
                      onClick={() => {
                        handlePhysicalCard(true);
                      }}
                    >
                      Fill in details manually
                    </TextLink>
                  </Text>
                )}
              </Spacing>
            )}
          </Column>
        </Row>
      </Grid>
      {customerMyInfo?.isLoading && <LoadingOverlayComponent />}
    </>
  );
};

export default MyInfoRetrieval;
