export const initialState = {
  searchNumbers: {
    numbers: [],
    signature: undefined,
    loading: false,
    error: false
  },
  reserveResource: {
    success: true,
    loading: false,
    error: false
  }
};

const ngnisReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET_SEARCH_NUMBERS':
      return {
        ...state,
        searchNumbers: {
          ...initialState.searchNumbers
        }
      };
    case 'SET_SEARCH_NUMBERS_SUCCESS':
      return {
        ...state,
        searchNumbers: {
          ...state.searchNumbers,
          numbers: action.value.numbers,
          signature: action.value.signature,
          loading: false,
          error: false
        }
      };
    case 'SET_SEARCH_NUMBERS_LOADING':
      return {
        ...state,
        searchNumbers: {
          ...state.searchNumbers,
          numbers: [],
          signature: undefined,
          loading: action.value,
          error: false
        }
      };
    case 'SET_SEARCH_NUMBERS_ERROR':
      return {
        ...state,
        searchNumbers: {
          ...state.searchNumbers,
          numbers: [],
          signature: undefined,
          loading: false,
          error: true
        }
      };
    default:
      return state;
  }
};

export default ngnisReducer;
