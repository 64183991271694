import React, { ReactNode } from 'react';
import {
  Button,
  Spacing,
  Text,
  Grid,
  Row,
  Column,
  TextLink,
  useDevice
} from '@dls/web';
import styled from 'styled-components';
import { InProgress, Completed, Alert } from '@dls/assets/dist/icons/web';
import { trans as t } from '../../helpers/localisation';
import CONSTANTS from '../../constants/common';
import { APP_TYPE_ANY } from '../../types/common.types';

type PropTypes = {
  type?: INFO_TYPES;
  title: string;
  message: string;
  onclick?: () => void;
  chatWithUs?: APP_TYPE_ANY;
  ctaText?: string;
};

export enum INFO_TYPES {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO'
}

const Container = styled(Spacing)`
  text-align: center;
`;

const Content = styled.div`
  text-align: ${p => (p.isMobile ? 'left' : 'center')};
`;

export const Info = (props: PropTypes): ReactNode => {
  const { isMobile } = useDevice();
  const { type, message, title, onclick, ctaText, chatWithUs } = props;
  const renderIcon = () => {
    switch (type) {
      case INFO_TYPES.INFO:
        return <Completed size="60" />;
      case INFO_TYPES.ERROR:
        return <Alert size="60" />;
      default:
        return <InProgress size="60" />;
    }
  };
  const renderInfo = () => {
    return (
      <Container>
        <Grid>
          <Row>
            <Column sx={12}>
              <Spacing>
                {renderIcon()}
                <Spacing topBottom={2} responsive={false}>
                  <Text type="pageTitle">{title}</Text>
                </Spacing>
                <Content isMobile={isMobile}>
                  <Text type={'body'}>{message}</Text>
                  {chatWithUs && (
                    <Spacing top={2}>
                      <Text type="body">
                        {t(chatWithUs, {
                          link: (
                            <a
                              target="_blank"
                              href={`https://wa.me/${CONSTANTS.THANK_YOU_PAGE_CUSTOMER_SUPPORT.whatsapp}`}
                            >
                              <TextLink>{t('CHAT_WITH_US')} </TextLink>
                            </a>
                          )
                        })}
                      </Text>
                    </Spacing>
                  )}
                </Content>
                {onclick && ctaText && (
                  <Spacing topBottom={3} responsive={false}>
                    <Button fullWidth={isMobile} secondary onClick={onclick}>
                      {ctaText}
                    </Button>
                  </Spacing>
                )}
              </Spacing>
            </Column>
          </Row>
        </Grid>
      </Container>
    );
  };
  return renderInfo();
};
