import { navigate } from 'gatsby';
import { navigation } from '../middlewares/navigation-constants';

export const RRP_MODAL = {
  CHANGE_PERSONAL_DETAILS: 'CHANGE_PERSONAL_DETAILS'
};

export default {
  [RRP_MODAL.CHANGE_PERSONAL_DETAILS]: {
    templateId: 'change-personal-details',
    title: 'Change your details?',
    text: 'You will need to log in and select your delivery option again.',
    okText: 'Proceed',
    onOk: () => {
      navigate(`/${navigation.CUSTOMER_REGISTRATION}`);
    }
  }
};
