import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import useThirdPartyIndicator from '../../hooks/useThirdPartyIndicator';
import { TOMO_INDICATORS } from '../../constants/third-party';

import imageRuby from '../../assets/images/liveperson-ruby.png';
import theme from '../../theme';
import useIsWebViewFlow from '../../hooks/useIsWebViewFlow';

const StyledButton = styled.div`
  position: fixed;
  bottom: 0;
  right: ${theme.spacing(1)};
  cursor: pointer;
  z-index: 99;
`;

const StyledChatDiv = styled.div`
  left: -500px;
  position: absolute;
`;

export const LivePerson = withTheme(
  ({ trackLivePersonChatTriggered, ...props }) => {
    const { lpDivId, lpEngagementId, lpSection, lpSiteId, user, theme } = props;
    const { hasIndicator: hasTomoIndicator } = useThirdPartyIndicator(
      TOMO_INDICATORS
    );

    const [showRuby, setShowRuby] = useState(false);

    useEffect(() => {
      // Needs to be defined in windows because LP is looking for it globally
      window.lpSiteId = lpSiteId;

      import('./le-mtagconfig.js')
        .then(() => {
          window.lpTag.events.bind({
            eventName: 'ON_READY',
            func: () => setShowRuby(!hasTomoIndicator),
            async: true,
            triggerOnce: true
          });

          window.lpTag.sdes = window.lpTag.sdes || [];
          window.lpTag.section = [lpSection];
        })
        .catch(() => 'An error occurred while loading LivePerson');
    }, [lpSection, lpSiteId, hasTomoIndicator]);

    useEffect(() => {
      const userDetails =
        user && user.information && user.information.userDetails;

      if (showRuby && lpEngagementId) {
        window.lpTag.sdes.send({
          type: 'ctmrinfo',
          info: {
            customerId: userDetails
              ? `G3.OnePass ${userDetails.loginId}`
              : 'Visitor'
          }
        });
      }
    }, [lpEngagementId, user, showRuby]);

    const onChatClick = () => {
      if (showRuby && lpEngagementId) {
        // Fire GA event for LP chat trigger
        trackLivePersonChatTriggered();
        window.lpTag.taglets.rendererStub.click(lpEngagementId);
      }
    };

    return (
      showRuby && (
        <StyledButton
          data-testid="liveperson-chat-button"
          onClick={onChatClick}
        >
          <img src={imageRuby} alt="" data-testid="icon-ruby" />
          <StyledChatDiv id={lpDivId} />
        </StyledButton>
      )
    );
  }
);

LivePerson.propTypes = {
  /** LivePerson placeholder div id */
  lpDivId: PropTypes.string,
  /** LivePerson engagement id */
  lpEngagementId: PropTypes.string,
  /** LivePerson section id */
  lpSection: PropTypes.string,
  /** LivePerson site id */
  lpSiteId: PropTypes.string,
  user: PropTypes.object
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  user: state.user
});

export const trackLivePersonChatTriggered = () => ({
  type: 'GA_TRACKING_LIVEPERSON_TRIGGERED'
});

export const showCampaigns = url => {
  if (window?.lpTag?.newPage) {
    window.lpTag.newPage(url, {
      section: [],
      sdes: [],
      taglets: {
        rendererStub: {
          divIdsToKeep: {
            lpButtonDiv: true
          }
        }
      }
    });
  }
};

export const hideCampaigns = () => {
  if (window?.lpTag?.newPage) {
    // eslint-disable-next-line no-unused-expressions
    window?.lpTag?.newPage('/', {
      section: [],
      sdes: [],
      taglets: {
        rendererStub: {
          divIdsToKeep: {
            lpButtonDiv: false
          }
        }
      }
    });
  }
};

const LiverPersonWrap = ({ ...props }) => {
  const { isClient, isWebView, flow } = useIsWebViewFlow();
  if (!isClient || (isClient && isWebView)) return null;
  return <LivePerson key={flow} {...props} />;
};

export default connect(mapStateToProps, {
  trackLivePersonChatTriggered
})(LiverPersonWrap);
