import styled, { css } from 'styled-components';
import { withTheme } from '@dls/web';
import theme from '../../theme';

type ColorContainerProps = {
  type?: string;
  padding?: string;
};

export const TextLinkWrapper = styled.div`
  padding: ${theme.spacing(3)} 0 0;
  text-align: center;
`;
export const IconWrapper = styled.div`
  margin-right: ${theme.spacing(2)};
  margin-top: 4px;
`;

export const ColorContainer = withTheme(styled.div<ColorContainerProps>`
  ${({ coreTheme, type, padding }) => css`
    padding: ${theme.spacing(3)};
    margin: ${theme.spacing(5)} 0;
  border-radius: 12px;
  display: flex;

  ${type === 'primary' && `background-color: ${coreTheme.cl_pri_l3};`}
  
  ${type === 'secondary' && `background-color: #EFEDFD;`}
  
  ${type === 'tertiary' && `background-color: ${coreTheme.cl_ter_l6};`} 
  ${padding && `padding: ${padding}`}
`}
`);
