import React from 'react';
import { Column, Row, Text, TextLink } from '@dls/web';
import { trans as t } from '../../helpers/localisation';

interface IProps {
  onTextLinkClick: () => void;
}
const PromotionsError: React.FunctionComponent<IProps> = ({
  onTextLinkClick
}) => {
  return (
    <Row>
      <Column noGutter>
        <Text type="header">{t('CCDE_TROUBLE_RETRIEVING_PROMOTIONS')}</Text>
        <Text type="body">{t('CCDE_FULL_LIST_OF_DEALS')}</Text>
        <TextLink onClick={onTextLinkClick} data-testid="errorButton">
          {t('CCDE_SEE_ALL_PROMOTIONS')}
        </TextLink>
      </Column>
    </Row>
  );
};

export default PromotionsError;
