import { ORDER } from '../constants';
import { trans as t } from '../helpers/localisation';

export const ORDER_CONFIRMATION_LEGO = {
  OrderStatus: 'OrderStatus',
  OrderInfo: 'OrderInfo',
  ImportantNote: 'ImportantNote',
  WhatNext: 'WhatNext',
  NextStep: 'NextStep',
  YouMayNeedToKnow: 'YouMayNeedToKnow',
  BackHome: 'BackHome',
  HelpBox: 'HelpBox'
};

interface GetOrderStatusTextOption {
  status?: string;
}

interface OrderStatus {
  title?: string;
  message?: string;
}

export const getOrderStatusText = (
  payment: GetOrderStatusTextOption
): OrderStatus => {
  switch (payment?.status) {
    case ORDER.PAYMENT_VERIFY_STATUS.COMPLETED: {
      return {
        title: t('THANK_YOU_PAGE_STATUS_SUCCESS') as string,
        message: ' '
      };
    }
    case ORDER.PAYMENT_VERIFY_STATUS.FAILED: {
      return {
        title: t('THANK_YOU_PAGE_STATUS_FAILED') as string,
        message: t('THANK_YOU_PAGE_STATUS_FAILED_MESSAGE') as string
      };
    }
    case ORDER.PAYMENT_VERIFY_STATUS.PENDING: {
      return {
        title: t('THANK_YOU_PAGE_STATUS_PENDING') as string,
        message: t('THANK_YOU_PAGE_STATUS_PENDING_MESSAGE') as string
      };
    }
    default: {
      return {};
    }
  }
};
