import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Card,
  Divider,
  Spacing,
  Button,
  Ribbon,
  Row,
  Tag,
  useDevice,
  withTheme
} from '@dls/web';
import { Tick as TickIcon } from '@dls/assets/dist/icons/web';
import styled, { css } from 'styled-components';
import { noop } from '@lux/helpers';
import formatPrice from '../../helpers/formatPrice';
import unlimitedTalktimeSms from '../../helpers/unlimited-talktime-sms';
import { planTagsConfig } from '../../config/plan-group-config';
import theme from '../../theme';

const StyledUnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 0 ${theme.spacing(0)};
`;

const StrikeThroughText = withTheme(styled(Text)`
  ${({ coreTheme, ...props }) => css`
text-decoration: line-through;
display: inline;
margin-left: 8px;
${props.isMobile &&
  `
    display: block;
    margin-left: 0px;
`}
color: ${coreTheme.cl_ter_l4};
`}
`);

const StyledTickDiv = styled.div`
  padding-top: 5px;
`;

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  gap: 5px;
`;

const StyledCardContent = styled.div`
  padding: 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCardContentStretch = styled.div`
  flex: 1;
`;

export const renderPlanTags = (planTags): ReactNode => {
  const tags = [
    planTags.includes(planTagsConfig['5g-speed'].tagName)
      ? {
          text: '5G'
        }
      : {
          text: '4G'
        }
  ];

  return tags.map((tag, index) => {
    return (
      <Tag
        key={index}
        data-testid="plan-tag"
        type="secondary"
        customStyle={{
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}
      >
        {tag.text}
      </Tag>
    );
  });
};

export const renderPrice = (
  price,
  discountPrice,
  discountedLiner,
  isMobile
): ReactNode => {
  const parsedActualPrice = parseFloat(price);
  const parsedDiscountedPrice = parseFloat(discountPrice);
  if (parsedDiscountedPrice >= 0) {
    return (
      <>
        <Text type="header" data-testid="discount-price">
          {`${formatPrice(parsedDiscountedPrice)}/mth`}
          {parsedDiscountedPrice < parsedActualPrice && (
            <StrikeThroughText
              type="smallBody"
              isMobile={isMobile}
              data-testid="price"
            >
              {`${formatPrice(parsedActualPrice)}`}
            </StrikeThroughText>
          )}
        </Text>
        <Text type="smallBody">{discountedLiner}</Text>
      </>
    );
  } else {
    return <Text type="header">{`${formatPrice(parsedActualPrice)}/mth`}</Text>;
  }
};

/**
 * Format the plan data to a standardised format
 *
 * @param {Number} quantity
 * @param {String} unit
 * @returns {string}
 */
export const formatPlanData = (quantity, unit): string => {
  return `${quantity}${unit}`.replace(/\s/, '');
};

const PlanCatalogCard = props => {
  const {
    name,
    ribbon,
    price,
    discountPrice,
    discountedLiner,
    data,
    talktime,
    tags = [],
    sms,
    planDescSubLiner,
    onPlanClick,
    isHideButton,
    upsellRibbon
  } = props;

  const { isMobile } = useDevice();

  const renderPlanContent = planDescSubLiner => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = planDescSubLiner;
    const newArray = Array.from(tempDiv.querySelectorAll('li')).map(
      liElement => liElement.textContent
    );

    return (
      <StyledUnorderedList>
        {newArray.map((text, index) => (
          <StyledRow>
            <StyledTickDiv>
              <TickIcon color="#0CAA8F" />
            </StyledTickDiv>
            <li key={index}>
              <Text type="smallBody">{text}</Text>
            </li>
          </StyledRow>
        ))}
      </StyledUnorderedList>
    );
  };

  return (
    <Card shadow="sm" radius="sm" padding="sm">
      <StyledCardContent>
        <StyledCardContentStretch>
          {ribbon ? (
            <Spacing bottom={0.5}>
              <Ribbon.Variant1>{ribbon}</Ribbon.Variant1>
            </Spacing>
          ) : null}
          {!ribbon && upsellRibbon ? (
            <Spacing bottom={0.5}>
              <Ribbon.Variant1>{upsellRibbon}</Ribbon.Variant1>
            </Spacing>
          ) : null}

          <Spacing bottom={1}>
            <Text>{name}</Text>
          </Spacing>
          <Spacing bottom={0.25}>
            <Row>
              <Text type="sectionTitle02">
                {data?.quantity && formatPlanData(data.quantity, data.unit)}
              </Text>
              <Spacing top={isMobile ? 0.7 : 0.1} left={isMobile ? 1 : 0.1}>
                {tags && renderPlanTags(tags)}
              </Spacing>
            </Row>
          </Spacing>
          <StyledRow>
            <StyledTickDiv>
              <TickIcon color="#0CAA8F" />
            </StyledTickDiv>
            <Spacing top={isMobile ? 0.3 : 0}>
              {talktime && sms ? (
                <Text type="smallBody">
                  {unlimitedTalktimeSms({
                    talktime: talktime,
                    sms: sms
                  })}
                </Text>
              ) : null}
            </Spacing>
          </StyledRow>
          <Spacing bottom={0.25}>{renderPlanContent(planDescSubLiner)}</Spacing>
        </StyledCardContentStretch>

        <div>
          <Spacing top={isMobile ? 2 : 1} bottom={2}>
            <Divider />
          </Spacing>

          <Card.Footer
            rightContent={
              !isHideButton && (
                <Button secondary text="Get" onClick={() => onPlanClick()} />
              )
            }
          >
            {renderPrice(price, discountPrice, discountedLiner, isMobile)}
          </Card.Footer>
        </div>
      </StyledCardContent>
    </Card>
  );
};

PlanCatalogCard.defaultProps = {
  onPlanClick: noop
};

PlanCatalogCard.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  onPlanClick: PropTypes.func,
  planDescSubLiner: PropTypes.any,
  price: PropTypes.string,
  discountPrice: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  discountedLiner: PropTypes.string,
  ribbon: PropTypes.string,
  upsellRibbon: PropTypes.string,
  sms: PropTypes.object,
  tags: PropTypes.array,
  talktime: PropTypes.object,
  isHideButton: PropTypes.bool
};

export default PlanCatalogCard;
