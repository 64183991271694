/**
 * Set cookie on the singtel.com domain
 *
 * We have a simple implementation because @lux/helper's setCookie
 * doesnt let us set the path. Once that is solved, we will use that
 * one instead.
 *
 * @param {*} name Name of cookie
 * @param {*} value Value of cookie
 */

export const deleteCookie = (cookieName: string) => {
  document.cookie =
    cookieName +
    '=' +
    '; expires=Tue, 06 Feb 2023 04:07:42 GMT' +
    '; path=/;domain=.singtel.com';
};

const setCookie = (name: string, value: string, minutes: number) => {
  let expires = '';
  if (minutes) {
    let date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie =
    name + '=' + value + expires + '; path=/;domain=.singtel.com';
};

export default setCookie;
