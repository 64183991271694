const useAEMPage = (allAemPages, pages: AEMPage | Array<AEMPage>) => {
  if (!allAemPages?.edges) return;
  const allPages = [].concat(pages);
  const allPagesData = allAemPages.edges
    .map(edge => edge.node)
    .filter(filterByPageTitle(allPages))
    .map(removeDummyNode);

  if (pages instanceof Array) return allPagesData;
  else return allPagesData[0];
};

const filterByPageTitle = allPages => ({ title }) => {
  return allPages.includes(title);
};

const removeDummyNode = node => {
  return node.components.filter(({ __typename }) => __typename !== 'Dummy');
};

export default useAEMPage;

type AEMPage =
  | 'Admin'
  | 'Maintenance Message'
  | 'cis-plans'
  | 'cis-phones'
  | 'res-phones'
  | 'Gate Manager';
