import styled from 'styled-components';
import Theme from '../../theme';
import { remCalc } from '@lux/helpers';

const Subtitle = styled.span`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(16)};
  line-height: 1.5;
  margin: 0;
`;

export const SubtitleSecondary = styled(Subtitle)`
  font-family: ${Theme.fonts.primary};
`;

const Body = styled.p`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(14)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.25)};
  margin: 0;
`;

export const BodyPrimary = styled(Body)`
  font-family: ${Theme.fonts.secondary};
`;

export const BodySecondary = styled(Body)`
  font-family: ${Theme.fonts.primary};
`;

export const FootnotePrimary = styled.span`
  display: block;
  font-weight: normal;
  font-size: ${remCalc(12)};
  line-height: 1.5;
  letter-spacing: ${remCalc(0.25)};
  margin: 0;
  font-family: ${Theme.fonts.secondary};
`;

export default {
  SubtitleSecondary,
  BodyPrimary,
  BodySecondary,
  FootnotePrimary
};
