import { CP_ERROR_CODES, CREDIT_LIMIT_FLOWTYPE } from '../config/common';
import { navigation } from '../middlewares/navigation-constants';

export const mapNoaData = (
  unmappedNoaData: UnmappedNoaData = {}
): MappedNoaData => {
  const {
    yearofassessment,
    lastupdated,
    amount,
    source,
    classification
  } = unmappedNoaData;
  return {
    yearofassessment: yearofassessment?.value,
    lastupdated: lastupdated,
    assessableincome: amount?.value?.toString(),
    source: source,
    classification: classification
  };
};

export const mapCpfData = (unmappedCpfData: UnmappedCpfData): MappedCpfData => {
  const mappedCpfContributions = unmappedCpfData?.history?.map(contribution => {
    const { date, employer, amount, month } = contribution;
    return {
      date: date.value,
      employer: employer.value,
      amount: amount.value,
      month: month.value
    };
  });

  return mappedCpfContributions;
};

type UnmappedNoaData = {
  yearofassessment?: {
    value: string;
  };
  lastupdated?: string;
  amount?: {
    value: number;
  };
  source?: string;
  classification?: string;
};

type MappedNoaData = {
  yearofassessment?: string;
  lastupdated?: string;
  assessableincome?: string;
  source?: string;
  classification?: string;
};

type UnmappedCpfData = {
  history: {
    date: {
      value: string;
    };
    employer: {
      value: string;
    };
    amount: {
      value: number;
    };
    month: {
      value: string;
    };
  }[];
};

type MappedCpfData = {
  date: string;
  employer: string;
  amount: number;
  month: string;
}[];

export const getCreditLimitErrorProps = errorScenarioMessage => {
  if (
    errorScenarioMessage?.errorCode === CP_ERROR_CODES.CREDIT_LIMIT_UNSUCCESS
  ) {
    return {
      navigateTo: navigation.EMPTY_SHOPPING_CART
    };
  } else {
    return {
      navigateTo: `${navigation.CREDIT_LIMIT_REVIEW}?flow=${CREDIT_LIMIT_FLOWTYPE}`
    };
  }
};
