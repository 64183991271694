import { PICKUP_TYPE_TO_KEY } from '../constants/fulfillment';
import { getDeliveryTimeSlot } from '../components/Fulfilment/helpers';
import { trans as t } from './localisation';
import { RRP_POPSTATION_LIMIT } from '../config/rrp';

export const RRPFulfilment = {
  apiStatus: {
    SUCCESS: 'SUCCESS'
  },
  dealerCode: 'D2519',
  paymentMode: {
    CASH: 'CASH'
  },
  vendor: {
    SINGTEL: 'SINGTEL'
  },
  slotTypes: {
    RRP: 'RRP',
    NonDropShipping: 'R',
    DropShipping: 'D',
    RRP_IPP: 'M'
  },
  deliveryMethodApi: {
    [PICKUP_TYPE_TO_KEY.POPSTATION]: 'pop-station',
    [PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY]: 'courier',
    [PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY]: 'Drop Shipping'
  },
  aemFulfilmentIdMapping: {
    [PICKUP_TYPE_TO_KEY.POPSTATION]: 'pop-station',
    [PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY]: 'courier',
    [PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY]: 'deliveryViaPartners'
  },
  tags: {
    POPSTATION: 'singtel:rrp/pop-station',
    POPSTATION_ONLY: 'singtel:rrp/pop-station-only-delivery'
  },
  slotDateFormat: {
    POPSTATION_TIMESLOT: 'M/dd/yyyy'
  }
};

const checkEligibleFulfilments = bag => {
  let popStationApplicable = false;
  let deliveryApplicable = true;
  let skuTags = {
    popStationDeliveryCount: 0,
    popStationOnlyCount: 0
  };
  const bagLength = bag.length;
  const bagItemsQuantity = (bag || []).reduce(
    (acc, cur) => acc + Number(cur.quantity || '0'),
    0
  );

  if (bagItemsQuantity < RRP_POPSTATION_LIMIT) {
    bag.forEach(product => {
      let hasPopstation = false;
      let hasPopstationOnly = false;
      if (Array.isArray(product.tags)) {
        hasPopstation = product.tags.includes(RRPFulfilment.tags.POPSTATION);
        hasPopstationOnly = product.tags.includes(
          RRPFulfilment.tags.POPSTATION_ONLY
        );
      }
      skuTags = {
        popStationDeliveryCount: hasPopstation
          ? skuTags.popStationDeliveryCount + 1
          : skuTags.popStationDeliveryCount,
        popStationOnlyCount: hasPopstationOnly
          ? skuTags.popStationOnlyCount + 1
          : skuTags.popStationOnlyCount
      };
    });

    if (
      skuTags.popStationOnlyCount + skuTags.popStationDeliveryCount ===
      bagLength
    ) {
      popStationApplicable = true;
    }

    if (skuTags.popStationOnlyCount === bagLength) {
      popStationApplicable = true;
      deliveryApplicable = false;
    }

    if (skuTags.popStationDeliveryCount === bagLength) {
      popStationApplicable = true;
      deliveryApplicable = true;
    }

    return {
      deliveryApplicable,
      popStationApplicable
    };
  } else {
    return {
      deliveryApplicable: true,
      popStationApplicable: false
    };
  }
};

export const getFulfilmentOptions = ({ bag, vendor, fulfilmentData }) => {
  let fulfilmentList = {};
  fulfilmentData.fulfilmentList.map(method => {
    fulfilmentList[method.fulfilmentId] = method.fulfilment;
  });

  let applicableFulfilments = [];
  if (vendor === RRPFulfilment.vendor.SINGTEL) {
    const {
      popStationApplicable,
      deliveryApplicable
    } = checkEligibleFulfilments(bag);
    const popstationFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[PICKUP_TYPE_TO_KEY.POPSTATION]
      ];
    const doorStepDeliveryFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[
          PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY
        ]
      ];

    doorStepDeliveryFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY,
        charges: doorStepDeliveryFulfilment.price,
        disabled: !deliveryApplicable,
        description: !deliveryApplicable
          ? t('FULFILMENT_MODE_NOT_AVAILABLE')
          : ''
      });

    popstationFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.POPSTATION,
        charges: popstationFulfilment.price,
        disabled: !popStationApplicable,
        description: !popStationApplicable
          ? t('FULFILMENT_MODE_NOT_AVAILABLE')
          : '',
        explain: {
          icon: 'message',
          message: t('POP_STATION_SMS_MESSAGE')
        }
      });
  } else {
    const partnerFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[
          PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY
        ]
      ];
    partnerFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY,
        charges: partnerFulfilment.price,
        explain: [
          {
            icon: 'delivery',
            message: t('PARTNER_DELIVERY_LINER')
          }
        ]
      });
  }

  return applicableFulfilments;
};

export const getRrpIppFulfilmentOptions = ({ bag, vendor, fulfilmentData }) => {
  let fulfilmentList = {};
  fulfilmentData.fulfilmentList.map(method => {
    fulfilmentList[method.fulfilmentId] = method.fulfilment;
  });

  let applicableFulfilments = [];
  if (vendor === RRPFulfilment.vendor.SINGTEL) {
    const deliveryApplicable = !bag[0]?.tags?.includes(
      RRPFulfilment.tags.POPSTATION_ONLY
    );

    const popstationFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[PICKUP_TYPE_TO_KEY.POPSTATION]
      ];
    const doorStepDeliveryFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[
          PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY
        ]
      ];

    doorStepDeliveryFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY,
        charges: doorStepDeliveryFulfilment.price,
        disabled: !deliveryApplicable,
        description: !deliveryApplicable
          ? t('FULFILMENT_MODE_NOT_AVAILABLE')
          : ''
      });

    const popStationApplicable = !!bag[0]?.tags?.find(
      x =>
        x === RRPFulfilment.tags.POPSTATION ||
        x === RRPFulfilment.tags.POPSTATION_ONLY
    );

    popstationFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.POPSTATION,
        charges: popstationFulfilment.price,
        disabled: !popStationApplicable,
        description: !popStationApplicable
          ? t('FULFILMENT_MODE_NOT_AVAILABLE')
          : '',
        explain: {
          icon: 'message',
          message: t('POP_STATION_SMS_MESSAGE')
        }
      });
  } else {
    const partnerFulfilment =
      fulfilmentList[
        RRPFulfilment.aemFulfilmentIdMapping[
          PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY
        ]
      ];
    partnerFulfilment &&
      applicableFulfilments.push({
        type: PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY,
        charges: partnerFulfilment.price,
        explain: [
          {
            icon: 'delivery',
            message: t('PARTNER_DELIVERY_LINER')
          }
        ]
      });
  }

  return applicableFulfilments;
};

export const formattedDate = date => {
  const modDate = new Date(date);
  let timestamp = modDate.getTime() - modDate.getTimezoneOffset() * 60000;
  let correctDate = new Date(timestamp);
  return correctDate.toISOString().slice(0, 10); //YYYY-MM-DD
};

export const formatFloorUnitNumber = (floorNo, unitNo) => {
  if (!floorNo || !unitNo) {
    return '';
  } else {
    const floorPad = floorNo.toString().padStart(2, '0');
    const unitPad = unitNo.toString().padStart(2, '0');
    return `#${floorPad}-${unitPad}`;
  }
};

export const unixTimestamp = date => {
  return Math.floor(new Date(date).getTime() / 1000);
};

export const getStartEndDateEpoch = (timeSlot, slotStartDate) => {
  const formattedTimeSlot = timeSlot.split('-').map(time => {
    return time
      .trim()
      .replace('AM', ' AM GMT+0800')
      .replace('PM', ' PM GMT+0800');
  });

  return {
    slotStartDateEpoch: Date.parse(
      `${slotStartDate.replaceAll('-', '/')} ${formattedTimeSlot[0]}`
    ),
    slotEndDateEpoch: Date.parse(
      `${slotStartDate.replaceAll('-', '/')} ${formattedTimeSlot[1]}`
    )
  };
};

export const getFloorUnit = floorNumber => {
  let floor = floorNumber || '';
  let unitNumber = '';
  if (floor && floor.includes('#')) {
    const unitFloor = floor?.split('#')[1].split('-');
    floor = unitFloor?.[0];
    unitNumber = unitFloor?.[1];
  }
  return {
    floor,
    unitNumber
  };
};
