import React, { useState } from 'react';
import { trans as t } from '../../helpers/localisation';
import { Button, Modal, Spacing, Text, Layout, useDevice } from '@dls/web';
import { ButtonWrapper } from '../../components/Common';

export type ModalData = {
  visible?: boolean;
  message?: string;
  title?: string;
  buttonText?: string;
  callback?: () => void;
  onClose?: () => void;
  secondaryButtonText?: string;
  secondaryCallback?: () => void;
};
export const useModal = (callback?: () => void) => {
  const [modalData, setModalData] = useState<ModalData>({});
  const setState = (params: Partial<ModalData>) => {
    setModalData({ ...modalData, ...params });
  };

  const { isMobile } = useDevice();

  const onButtonClicked = () => {
    setState({ visible: false });
    modalData.onClose && modalData.onClose();
    if (callback) {
      return callback();
    }
    modalData.callback && modalData.callback();
  };

  const onSecondaryClicked = () => {
    setState({ visible: false });
    modalData.secondaryCallback && modalData.secondaryCallback();
  };

  const renderedModal = (
    <Modal
      data-testid="use-modal"
      visible={modalData.visible}
      title={modalData.title}
      backdropClosable={false}
      onClose={() => {
        setState({ visible: false });
        modalData.onClose && modalData.onClose();
      }}
    >
      <Modal.Content>
        <Spacing bottom={1}>
          <Text>{modalData.message}</Text>
        </Spacing>
        <ButtonWrapper>
          {modalData.secondaryButtonText && (
            <Spacing top={1}>
              <Button
                fullWidth={isMobile}
                onClick={onSecondaryClicked}
                secondary
              >
                {modalData.secondaryButtonText}
              </Button>
            </Spacing>
          )}
          <Spacing top={1}>
            <Button
              fullWidth={isMobile}
              primary
              onClick={onButtonClicked}
              data-testid="modal-button"
            >
              {modalData.buttonText || t('OK_GOT_IT')}
            </Button>
          </Spacing>
        </ButtonWrapper>
      </Modal.Content>
    </Modal>
  );
  return { setModalData, renderedModal };
};
