import React, { memo } from 'react';
import { Row, Column, Spacing, Text, useDevice } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { ORDER } from '../../constants';

const HelpBox = () => {
  const { isMobile } = useDevice();

  return (
    <Row start="xs">
      <Column xs={12}>
        <Spacing top={isMobile ? 0 : 0.2} bottom={isMobile ? 0 : 2}>
          <Spacing topBottom={1}>
            <Text type="header">{t('THANK_YOU_PAGE_NEED_HELP_TITLE')}</Text>
          </Spacing>

          <Text type="body">
            {t('THANK_YOU_PAGE_NEED_HELP_TEXT')}
            <Text type="link" href={ORDER.URL.FAQ} target="_blank">
              {t('THANK_YOU_PAGE_NEED_HELP_TEXT_SUFFIX')}
            </Text>
          </Text>
        </Spacing>
      </Column>
    </Row>
  );
};

export default memo(HelpBox);
