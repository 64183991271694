import React from 'react';
import { connect } from 'react-redux';
import { Column, Grid, Row, Spacing, Text } from '@dls/web';

export const NumberVerificationPassportHolder = ({ worryFreeDetails }) => {
  const {
    acceptorIndentValue,
    acceptorNewCustomer,
    acceptorMobileSubscriberExist,
    acceptorContactSubscriptionExists
  } = worryFreeDetails;

  return (
    <Grid>
      <Row>
        <Column>
          <Spacing bottom={1}>
            <Text type="bannerTitle" tag="h2">
              An account already exists for {acceptorIndentValue}
            </Text>
          </Spacing>
        </Column>
      </Row>
      <Row>
        <Column>
          <Spacing bottom={1}>
            <p>{`New Customer: ${acceptorNewCustomer}`}</p>
            <p>{`Mobile Subscriber Exist: ${acceptorMobileSubscriberExist}`}</p>
            <p>{`Contact Subscription Exists: ${acceptorContactSubscriptionExists}`}</p>
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    worryFreeDetails: state.auth.worryFree
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberVerificationPassportHolder);
