import React from 'react';
import { Button, Modal, Spacing, Text } from '@dls/web';
import useTranslate from '../../hooks/useTranslation';
import { navigate } from 'gatsby';

const ProceedModal = props => {
  const { t } = useTranslate();
  const {
    isModalOpen,
    setIsModalOpen,
    navigateTo,
    proceedClicked = () => {},
    onClose = () => {}
  } = props;

  const handleProceed = async () => {
    if (navigateTo) {
      navigate(`/${navigateTo}`);
    }
    await proceedClicked();
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <Modal
      data-testid="proceedModal"
      visible={isModalOpen}
      title={t('PROCEED_MODAL_TITLE')}
      onClose={onModalClose}
    >
      <Modal.Content>
        <Spacing bottom={3}>
          <Text type="body">{t('PROCEED_MODAL_SUBTITLE')}</Text>
        </Spacing>
        <Button primary fullWidth onClick={handleProceed}>
          {t('PROCEED_MODAL_BUTTON_TEXT')}
        </Button>
      </Modal.Content>
    </Modal>
  );
};

export default ProceedModal;
