import React from 'react';
import {App as DTCP} from 'dtcp-module';
import Layout from './src/components/Layout';
import PersistStatusConsumer from './src/components/PersistStatusConsumer';
import isFeatureFlagEnabled from './src/helpers/feature-flags';
import {navigation} from './src/middlewares/navigation-constants';

const shouldUsePersistGate = path => {
  return !path.startsWith('/phones/') && path !== '/';
};

const wrapWithPersistGate = ({element, props}) => {
  let renderChild = element;
  if (props.location?.pathname?.includes(navigation.UIAM_LANDING_WEB)) {
    return <>{renderChild}</>;
  }
  if (shouldUsePersistGate(props.location?.pathname)) {
    renderChild = (
      <PersistStatusConsumer usePersistGate>{element}</PersistStatusConsumer>
    );
  }
  return (
    <>
      {isFeatureFlagEnabled('DTCP') && <DTCP appName="detox"/>}
      <Layout {...props} location={props.location}>
        {renderChild}
      </Layout>
    </>
  );
};

export default wrapWithPersistGate;
