export const navigation = {
  ADDONS: 'addons',
  MAIN_PLANS_PAGE: 'plans',
  ORDER_FULFILMENT_PAGE: 'order-fulfillment',
  ORDER_SUMMARY: 'order-summary',
  LOGIN_GUEST_CHECKOUT: 'login-guest-checkout',
  ACCESSORIES_PAGE: 'accessories',
  SHOPPING_CART_PAGE: 'shopping-cart',
  BILLING_DETAILS_PAGE: 'billing-details',
  CHECKOUT: 'checkout',
  THANK_YOU: 'thank-you',
  ERROR: 'error',
  NO_ACCESS: 'no-access',
  EMPTY_SHOPPING_CART: 'empty-shopping-cart',
  CREDIT_LIMIT_REVIEW: 'credit-limit-review',
  PAYMENT_REDIRECT_URL: 'payment-redirection',
  CHOOSE_NUMBER_PAGE: 'choose-your-number',
  UIAM_LANDING_WEB: 'uiam-landing-web',
  INFO: 'info',
  CUSTOMER_REGISTRATION: 'customer-registration',
  RRP_SHOPPING_CART: 'accessories/shopping-cart',
  ESERV_EFORM_REG: 'e-services/eform-register',
  RRP_IPP_SHOPPING_CART: 'accessories/rrp-ipp-shopping-cart',
  RRP_FULFILMENT: 'accessories/fulfilment',
  RRP_ORDERSUMMARY: 'accessories/ordersummary',
  RRP_ORDER_CONFIRMATION: 'accessories/order-confirmation',
  USER_IDENTITY_CHECK: 'user-identity-check',
  NUMBER_SELECTION_PAGE: 'number-selection#new-number',
  RRP_IPP_FULFILMENT: 'accessories/ipp-fulfilment',
  RRP_IPP_ORDER_SUMMARY: 'accessories/rrp-ipp-ordersummary',
  RRP_IPP_ORDER_CONFIRMATION: 'accessories/rrp-ipp-order-confirmation',
  USER_PROMOTIONS: 'promotions',
  SPRINGD_THANKYOU_PAGE_PATH: '/personal/products-services/order/confirmation',
  SPRINGD_RRP_CATALOGUE_PAGE_PATH:
    '/personal/products-services/mobile/accessories',
  SPRINGD_MYINFO_PAGE_PATH: '/personal/products-services/mobile/cis',
  RRP_SERVICE_LIST: 'accessories/rrp-select-service',
  RRP_LOGIN: 'accessories/rrp-select-service/rrp-login',
  RRP_PRODUCT_CATALOG: 'accessories',
  RRP_PRODUCT_DETAILS: 'rrp-products'
};
