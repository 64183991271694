import { FORM_FIELD_TYPES, FORM_VALIDATION_TYPES } from '@wec-core/form-engine';
import * as Yup from 'yup';
import { addDays, startOfDay } from 'date-fns';

import { trans as t } from '../../helpers/localisation';
import idTypes from '../../config/id-types.json';
import {
  FieldObject,
  IFormFieldConfig
} from '../../types/registrationCheckout';
import {
  CISMyInfoUserData,
  CustomerInfoResponse,
  EmptyObj,
  MyInfoDataValues,
  ReadPersonalDataReturnType
} from '../../types/checkout.types';
import {
  convertToSGNumber,
  genderSalutationSchema,
  getFormattedDate,
  getGenderLabel,
  getGenderValue,
  getReadOnlyGroup
} from './helper';
import { KeyValue } from '../../types/common.types';

const columnProps = {
  xs: 12,
  md: 12,
  noGutterLeft: true
};

export interface MyInfoFormState {
  formInputsMapping: FieldObject;
  formValues: FieldObject;
}

export interface MyInfoInitialInput {
  contactNumber: string;
  email: string;
  confirmEmailRequired?: boolean;
  contactNumberRequired?: boolean;
  gender?: string;
}

export interface MyInfoInitialValues {
  salutation: string;
  contactNumber?: string;
  email: string;
  confirmEmail?: string;
  termsCheck: string;
  billingPreference: string;
  gender?: string;
}

export const getSalutationListData = (): Array<Partial<IFormFieldConfig>> => [
  { text: t('CO_NN_SALUTATION_MR'), value: 'MR' },
  { text: t('CO_NN_SALUTATION_MRS'), value: 'MRS' },
  { text: t('CO_NN_SALUTATION_MISS'), value: 'MS' },
  { text: t('CO_NN_SALUTATION_MS'), value: 'MS' },
  { text: t('CO_NN_SALUTATION_DR'), value: 'DR' }
];

export const getTitleField = (): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'formTitle',
    title: t('EKYC_PERSONAL_INFORMATION_TITLE'),
    subTitle: t('EKYC_PERSONAL_INFORMATION_DESC')
  };
};

export const getSalutationField = (): IFormFieldConfig => {
  return {
    component: FORM_FIELD_TYPES.DROPDOWN_INPUT,
    name: 'salutation',
    label: t('CO_NN_SALUTATION') as string,
    bgColor: 'haze',
    dataSource: 'salutationList',
    columnProps: columnProps,
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('CC_E_TITLE') }
      }
    ]
  };
};

export const myInfoReadOnlyFields = (
  myInfoData?: MyInfoDataValues
): IFormFieldConfig[] => {
  const {
    fullName = '-',
    dob = '-',
    gender = '-',
    passValue = '-',
    passType = '-',
    mailingAddrBlock = '-',
    mailingAddrStreet = '-',
    mailingAddrFloor = '-',
    mailingAddrUnit = '-'
  } = myInfoData || {};

  return [
    {
      name: 'fullName',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_FULLNAME'),
      valueLabel: fullName
    },
    {
      name: 'dob',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_DOB'),
      valueLabel: dob
    },
    {
      name: 'gender',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_GENDER'),
      valueLabel: gender
    },
    {
      name: 'nric',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_NRIC_FIN'),
      valueLabel: passValue
    },
    {
      name: 'passType',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_PASS_TYPE'),
      valueLabel: passType
    },
    {
      name: 'houseORBlockNum',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_BLOCK_NO'),
      valueLabel: mailingAddrBlock
    },
    {
      name: 'streetAddress',
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('CO_NN_STREET_ADDRESS'),
      valueLabel: mailingAddrStreet
    },
    {
      component: FORM_FIELD_TYPES.FORM_GROUP_INPUTS,
      groupElements: getReadOnlyGroup(
        ['floor', 'unitNum'],
        [t('CO_NN_FLOOR') as string, t('CO_NN_UNIT_NO') as string],
        [mailingAddrFloor, mailingAddrUnit]
      )
    },
    {
      component: FORM_FIELD_TYPES.FORM_DIVIDER,
      name: 'personalInfoSectionDivider'
    }
  ];
};

export const getContactGroup = ({
  contactNumber = ''
}: {
  confirmEmailRequired?: boolean;
  contactNumber?: string;
}): IFormFieldConfig[] => {
  const contactTitle = {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    hasSectionNumbering: true,
    name: 'formTitle',
    title: t('EKYC_CONTACT_DETAILS_TITLE'),
    subTitle: t('EKYC_CONTACT_DETAILS_DESC')
  };

  const contactNumberField: IFormFieldConfig = contactNumber
    ? {
        name: 'contactNumber',
        component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
        label: t('CO_NN_CONTACT_NUMBER'),
        valueLabel: contactNumber
      }
    : {
        component: FORM_FIELD_TYPES.TEXT_INPUT,
        name: 'contactNumber',
        label: t('CO_NN_CONTACT_NUMBER') as string,
        displayFormat: 'mobile-sg',
        bgColor: 'haze',
        placeholder: t('CO_NN_CONTACT_NUMBER_PLACEHOLDER'),
        validations: [
          'string',
          {
            type: 'required',
            params: { message: t('CC_E_CONTACT') }
          },
          {
            type: FORM_VALIDATION_TYPES.MOBILE_NUM_VALIDATION,
            params: {
              message: t('CC_E_IV_CONTACT'),
              inValidLengthMsg: t('CC_E_IVL_CONTACT')
            }
          }
        ]
      };

  const emailField: IFormFieldConfig = {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'email',
    label: t('CO_NN_EMAIL') as string,
    bgColor: 'haze',
    placeholder: t('CO_NN_EMAIL_PLACEHOLDER')
  };

  const confirmEmailField: IFormFieldConfig = {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'confirmEmail',
    label: t('CO_NN_CONFIRM_EMAIL') as string,
    bgColor: 'haze',
    placeholder: t('CO_NN_CONFIRM_EMAIL')
  };

  const contactDivider = {
    component: FORM_FIELD_TYPES.FORM_DIVIDER,
    name: 'contactDetailsDivider'
  };

  return [
    contactTitle,
    contactNumberField,
    emailField,
    confirmEmailField,
    contactDivider
  ];
};

export const getInitialValues = ({
  contactNumber,
  email,
  confirmEmailRequired = true,
  contactNumberRequired = true
}: MyInfoInitialInput): FieldObject => {
  const emailValue = confirmEmailRequired ? ('' + email).toLowerCase() : email;
  let initialValues: MyInfoInitialValues = {
    salutation: '',
    termsCheck: '',
    billingPreference: '',
    email: emailValue || ''
  };

  if (contactNumberRequired) {
    initialValues = { ...initialValues, contactNumber: contactNumber || '' };
  }

  if (confirmEmailRequired) {
    initialValues = { ...initialValues, confirmEmail: '' };
  }

  return initialValues;
};

export const getValidationSchema = (gender?: string): Yup.AnySchema => {
  return Yup.object().shape({
    termsCheck: Yup.string().oneOf(['true'], t('CC_E_TERMS') as string),
    email: Yup.string()
      .email(t('CC_E_IV_EMAIL') as string)
      .required(t('CC_E_EMAIL') as string),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], t('CC_E_CONFIRM_EMAIL_VALID') as string)
      .required(t('CC_E_EMAIL') as string),
    ...genderSalutationSchema(gender)
  });
};

export const getFilteredExistingValues = (
  existingValues: FieldObject
): FieldObject => {
  if (!existingValues) {
    return {};
  }
  const filteredObj = Object.keys(existingValues).reduce((result, key) => {
    if (existingValues[key] !== '') {
      result[key] = existingValues[key];
    }
    return result;
  }, {});
  return filteredObj;
};

export const isInCompleteMyInfoFilled = (
  prefilledData: KeyValue = {},
  filledCheckoutData: KeyValue = {},
  ignoredProperties = ['floorNumber', 'unitNumber']
): boolean => {
  if (!filledCheckoutData) {
    return false;
  }
  for (const key in prefilledData) {
    if (
      !ignoredProperties.includes(key) &&
      prefilledData[key] === '' &&
      (filledCheckoutData[key] === undefined || filledCheckoutData[key] === '')
    ) {
      return false;
    }
  }
  return true;
};

export const getPreFilledCheckoutData = (
  resMyInfoData: CustomerInfoResponse
): KeyValue | EmptyObj => {
  if (!resMyInfoData) {
    return {};
  }
  const prefilledData = {
    fullName: resMyInfoData.fullName || '',
    birthday: getFormattedDate(resMyInfoData.dateOfBirth, '/') || '',
    gender: resMyInfoData.gender || '',
    contactNumber: resMyInfoData.contactNumberMobile || '',
    email: resMyInfoData.email || '',
    postalCode: resMyInfoData.postalCode || '',
    floorNumber: resMyInfoData.mailingAddrFloor || '',
    unitNumber: resMyInfoData.mailingAddrUnit || ''
  };

  return prefilledData;
};

export const getRESMyInfoReadOnlyPersonalData = (
  resMyInfoData: CustomerInfoResponse
): ReadPersonalDataReturnType | EmptyObj => {
  if (!resMyInfoData) {
    return {};
  }

  const userData = {
    fullName: resMyInfoData.fullName,
    dob: getFormattedDate(resMyInfoData.dateOfBirth, '/'),
    gender: getGenderLabel(t, resMyInfoData.gender),
    genderValue: resMyInfoData.gender,
    passValue: resMyInfoData.uniqueId,
    passType: resMyInfoData.passType,
    mailingAddrBlock: resMyInfoData.mailingAddrBlock,
    mailingAddrStreet: resMyInfoData.mailingAddrStreet,
    mailingAddrFloor: resMyInfoData.mailingAddrFloor,
    mailingAddrUnit: resMyInfoData.mailingAddrUnit
  };

  return {
    contactNumber: convertToSGNumber(
      resMyInfoData.contactNumberInTicket || resMyInfoData.contactNumberMobile
    ),
    email: resMyInfoData.email,
    userData
  };
};

export const getCISMyInfoReadOnlyPersonalData = (
  cisMyInfoData: CISMyInfoUserData
): ReadPersonalDataReturnType | EmptyObj => {
  if (!cisMyInfoData) {
    return {};
  }

  const { customerId, passType, contactNumber } = cisMyInfoData;
  const idPrefix = ('' + customerId)[0];
  const filteredPassType = idTypes
    .filter(({ prefixes }) => prefixes.includes(idPrefix))
    .find(({ passTypeCode }) => passTypeCode.includes(passType));

  const userData = {
    fullName: cisMyInfoData.name,
    dob: getFormattedDate(cisMyInfoData.dateOfBirth, '-'),
    gender: cisMyInfoData.gender,
    passValue: cisMyInfoData.customerId,
    passType: filteredPassType?.value || '',
    mailingAddrBlock: cisMyInfoData.address?.block,
    mailingAddrStreet: cisMyInfoData.address?.street,
    mailingAddrFloor: cisMyInfoData.address?.floor,
    mailingAddrUnit: cisMyInfoData.address?.unit
  };

  return {
    contactNumber: convertToSGNumber(contactNumber),
    email: cisMyInfoData.email,
    userData
  };
};

export const compareAndGetUpdatedEmail = (
  myInfoEmail = '',
  userUpdatedEmail = ''
): string => {
  if (
    ('' + myInfoEmail).trim().toLowerCase() ===
    ('' + userUpdatedEmail).trim().toLowerCase()
  ) {
    return myInfoEmail;
  }

  return userUpdatedEmail;
};

export const getUpdatedRESMyInfoValues = (
  values: KeyValue,
  myInfoEmail: string,
  resMyInfoData?: CustomerInfoResponse
): KeyValue => {
  if (!resMyInfoData) {
    return values;
  }

  return {
    ...values,
    email: compareAndGetUpdatedEmail(myInfoEmail, values?.email),
    fullName: resMyInfoData.fullName,
    gender: getGenderValue(t, resMyInfoData.gender),
    birthday: getFormattedDate(resMyInfoData.dateOfBirth, '/'),
    contactNumber:
      resMyInfoData.contactNumberInTicket || resMyInfoData.contactNumberMobile,
    floorNumber: resMyInfoData.mailingAddrFloor,
    unitNumber: resMyInfoData.mailingAddrUnit,
    postalCode: resMyInfoData.postalCode
  };
};

export const getUpdatedCISMyInfoValues = (
  values: KeyValue,
  myInfoEmail: string,
  cisMyInfoData?: CISMyInfoUserData
): KeyValue => {
  if (!cisMyInfoData) {
    return values;
  }

  return {
    ...values,
    email: compareAndGetUpdatedEmail(myInfoEmail, values?.email),
    fullName: cisMyInfoData.name,
    gender: getGenderValue(t, cisMyInfoData.gender),
    birthday: getFormattedDate(cisMyInfoData.dateOfBirth, '-'),
    contactNumber: cisMyInfoData.contactNumber,
    floorNumber: cisMyInfoData.address?.floor,
    unitNumber: cisMyInfoData.address?.unit,
    postalCode: cisMyInfoData.address?.postal
  };
};

export const getSimOnlyEsimDeliveryPayload = (): KeyValue => {
  // T+0 days as discussed
  const tPlusDate = addDays(startOfDay(new Date()), 0);
  const slotDate = isNaN(+tPlusDate) ? new Date() : +tPlusDate;

  return {
    deliveryTimeSlot: {
      slotStartDate: slotDate,
      slotEndDate: slotDate,
      slotCode: null,
      timeDescription: '00:00AM - 11:59PM'
    }
  };
};
