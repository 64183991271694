import React, { ReactElement } from 'react';
import { Modal, TextLink, Layout, Text, Spacing } from '@dls/web';
import { Form, FORM_FIELD_TYPES } from '@wec-core/form-engine';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';

interface Props extends KeyValue {
  isModalOpen: boolean;
  onClose: (values?: KeyValue) => void | Promise<void>;
  selectionCost?: string;
}

const formFieldsData = [
  {
    component: FORM_FIELD_TYPES.FORM_TITLE,
    name: 'formTitle',
    title: t('CC_E_EMAIL'),
    subTitle: t('ADDON_APPLECARE_MSG')
  },
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'email',
    label: t('EMAIL'),
    bgColor: 'haze',
    placeholder: t('EMAIL'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      },
      {
        type: 'email',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      }
    ]
  },
  {
    component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
    name: 'submitButton',
    label: t('ADD'),
    sizing: 3,
    btnProps: {
      fullWidth: true
    }
  }
];

const AppleCareAddonModal = (props: Props): ReactElement => {
  const { isModalOpen, onClose, selectionCost } = props;
  const initialFormValues = {
    email: ''
  };

  return (
    <Modal
      data-testid={`applecase-addon-modal`}
      visible={isModalOpen}
      title={t('ADDON_APPLECARE') as string}
      closable={true}
      onClose={onClose}
      wide={false}
      backdropClosable={false}
    >
      {
        (
          <Modal.Content>
            {selectionCost && (
              <Spacing topBottom={2}>
                <Text type="body">{selectionCost}</Text>
              </Spacing>
            )}
            <Form
              stackSpacing={2}
              enableReinitialize={true}
              initialValues={initialFormValues}
              data={{}}
              onSubmit={values => {
                onClose(values);
              }}
              formFieldsConfig={formFieldsData}
              callbacks={{}}
            />

            <Layout.Stack align="center">
              <TextLink onClick={onClose}>
                <TextLink.Text>{t('NO_THANKS')}</TextLink.Text>
              </TextLink>
            </Layout.Stack>
          </Modal.Content>
        ) as APP_TYPE_ANY
      }
    </Modal>
  );
};

export default AppleCareAddonModal;
