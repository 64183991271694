import { getUrlParams, setUrlParams } from '@lux/helpers';
import { navigate } from 'gatsby';
import slugify from './slugify';
import isSimOnlyPlan from './is-sim-only-plan';
import { CATALOGUE } from '../constants';
import formatPrice from './formatPrice';
import { includes } from './common';

export const sortByRankingAndTitle = (a, b) =>
  a.ranking - b.ranking || a.title.localeCompare(b.title);

export const updateQueryFilters = (location, { plan }) => {
  const queryParams = getUrlParams(location.search);
  let urlParams;
  const queryParamsList = CATALOGUE.QUERY_PARAMS.filter(param => {
    return queryParams.hasOwnProperty(param);
  });

  if (queryParamsList && queryParamsList.length > 0) {
    urlParams = {
      ...queryParams,
      plan: plan || queryParams.plan
    };
  } else if (
    queryParamsList &&
    queryParamsList.length === 0 &&
    plan.includes(CATALOGUE.QUERY_PLANS.SIM_ONLY_PLUS) &&
    queryParams.plan
  ) {
    plan = queryParams.plan;
    urlParams = {
      ...queryParams,
      plan
    };
  } else {
    urlParams = {
      plan
    };
  }

  return navigate(setUrlParams(location.pathname, urlParams), {
    replace: true
  });
};

export const getFiltersFromQueryParams = (
  plans,
  allSimOnlyPlusPlan,
  queryString
) => {
  const { plan: planSluglified, ipp: ippText } = getUrlParams(queryString);
  if (!planSluglified) return {};
  let plan;
  if (
    planSluglified.includes(CATALOGUE.QUERY_PLANS.SIM_ONLY_PLUS) &&
    ippText &&
    ippText === CATALOGUE.QUERY_PLANS.IPP_TEXT
  ) {
    const splittedString = planSluglified.split('-');
    const simOnlyPlan = allSimOnlyPlusPlan.filter(item =>
      item.planName.includes(splittedString[3])
    );
    if (simOnlyPlan.length > 0) {
      const planname =
        CATALOGUE.QUERY_PLANS.SIM_ONLY_PLUS + '-' + splittedString[3];
      plan = plans.find(p => planname === slugify(p.planName));
      plan.selectedMonthlyTerm =
        splittedString.length === 5 &&
        CATALOGUE.INSTALLMENT_MONTHS.includes(splittedString[4])
          ? splittedString[4]
          : CATALOGUE.INSTALLMENT_MONTHS[0];
    }
  } else {
    plan = plans.find(p => planSluglified === slugify(p.planName));
  }
  return { plan };
};

export const getUrlByPlan = plan => {
  if (plan && plan.planName) {
    const newPath = setUrlParams('', {
      plan: slugify(plan.planName)
    });
    const pathWithMonth =
      isSimOnlyPlan(plan) && plan.selectedMonthlyTerm
        ? newPath + '-' + plan.selectedMonthlyTerm
        : newPath;
    return pathWithMonth;
  }
};

// The price on each phone has to change based on the plan selected.
// This function returns a list of phones with the updated price whenever the plan changes.
export const getPhonesWithPrice = (phones, selectedPlan) => {
  const selectedMecId = selectedPlan.mecId;
  return phones
    .map(phone => {
      // gets the public price of the selected plan
      const pricesArr = phone.prices
        .filter(plan => plan.planId === selectedMecId)
        .map(plan => plan.publicPrice); // Price displayed on the card should show the lowest of all the sku prices for that phone model
      const phoneVariantWithMatchingPlan = phone.variants.find(
        ({ planId, monthlyTerm }) =>
          planId === selectedMecId &&
          monthlyTerm === selectedPlan?.selectedMonthlyTerm
      );
      const partialUpfrontPriceArr = phone.variants
        .filter(({ planId }) => planId === selectedMecId)
        .map(({ partialUpfrontAmount }) => partialUpfrontAmount);

      const lowestSkuPrice = Math.min.apply(null, pricesArr);
      const lowestPartialUpfrontPrice = Math.min.apply(
        null,
        partialUpfrontPriceArr
      );

      return {
        ...phone,
        monthlyTerm: phoneVariantWithMatchingPlan?.monthlyTerm,
        installmentPrice: phoneVariantWithMatchingPlan?.installmentPrice,
        partialUpfrontAmount: lowestPartialUpfrontPrice,
        publicPrice: lowestSkuPrice
      };
    })
    .filter(phone => isFinite(phone.publicPrice) && phone.publicPrice >= 0);
};

export const setPriceText = phone => {
  // Default price text
  let priceText = `From ${formatPrice(phone.publicPrice, 0)}`;

  // Installment price text with monthly term duration
  let installmentPriceText = `From ${formatPrice(
    phone.installmentPrice
  )}/mth with PayLater`;

  if (phone.partialUpfrontAmount >= 0 && phone.installmentPrice >= 0) {
    priceText = installmentPriceText;
  }

  if (!phone.partialUpfrontAmount && phone.installmentPrice >= 0) {
    priceText = installmentPriceText;
  }

  return priceText;
};

export const constructProductUrl = (colour, size, planName, monthlyTerm) => {
  const colourNoSpace = colour?.replace(/ |\//g, '-');
  const sizeNoSpace = size?.replace(/ |\//g, '-');
  const planNameNoSpace = planName?.replace(/ |\//g, '-');
  const monthlyTermNoSpace = monthlyTerm?.replace(/ |\//g, '-');
  return [colourNoSpace, sizeNoSpace, planNameNoSpace, monthlyTermNoSpace]
    .filter(Boolean)
    .join('-')
    .toLowerCase();
};

export const updateUtmUrl = locationStr => {
  const queryParams = getUrlParams(locationStr);
  let urlParams;
  const queryParamsList = CATALOGUE.QUERY_PARAMS.filter(param => {
    return queryParams.hasOwnProperty(param);
  });

  if (queryParamsList && queryParamsList.length > 0) {
    urlParams = { ...queryParams };
  }
  return urlParams;
};

export const getRibbon = (ribbons = [], selectedPlan) => {
  return (
    Array.isArray(ribbons) &&
    ribbons.find(ribbon => {
      return includes(ribbon?.planGroups, selectedPlan?.groupName);
    })
  );
};

// Filter the banner list for phone catlogue based on the Flow Type
export const getPhoneCatalogBanner = (allPhoneBanners, flowType) => {
  return (
    Array.isArray(allPhoneBanners) &&
    allPhoneBanners.filter(
      banner =>
        banner?.flowType?.includes(flowType) && banner?.enablePromoBanner
    )
  );
};
