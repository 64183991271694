import React, { ReactElement, useEffect, useReducer } from 'react';
import { navigate } from 'gatsby';

import { getUrlParams } from '@lux/helpers';
import { api } from '@detox/actions';
import { Column, Grid, Row, Spacing, Text, Button, Modal } from '@dls/web';
import SingpassLogo from '@dls/assets/dist/images/logo_singpass_full_colour.png';

import LoadingOverlayComponent from '../LoadingOverlay/LoadingOverlay';

import { ACTION_TYPES } from '../../constants/actions';
import { trans as t } from '../../helpers/localisation';
import { navigation } from '../../middlewares/navigation-constants';
import MyInfoEKYCModal from '../MyInfoEKYCModal/MyInfoEKYCModal';
import ERegister from './ERegister';
import {
  EREG_STATE_ACTIONS,
  eRegisterInitialState,
  eRegisterStateReducer,
  fetchAndRespond,
  StyledSingPassImg,
  submitSRB
} from './helper';
import SingpassRetrieveMyInfo from '../../assets/svgs/singpass-retrieve-myInfo.svg';

const successURL =
  'https://www.singtel.com/personal/products-services/devices-and-gadgets/shop-phones-tablets?utm_source=webpage&utm_medium=link&utm_campaign=try-and-buy';
const contactLink =
  'https://api.whatsapp.com/send?phone=6590181688&text=WAMOBSOP30ESIM_CS';

const {
  apiMyinfoRetrieveUrl,
  apiMyinfoCustomerInfo,
  apiRetrieveAddress
} = api.mcss;

export const EFormRegister = (): ReactElement => {
  const [eRegState, eRegDispatch] = useReducer(
    eRegisterStateReducer,
    eRegisterInitialState
  );
  const {
    showRegForm = false,
    singpassCancel = false,
    apiLoading = false,
    toggleAckModal = false,
    customerMyInfo = null,
    addressData = null,
    modalData
  } = eRegState;

  const pathName = window.location.search;
  const isFromMyInfo = pathName.includes(
    ACTION_TYPES.USER_IDENTITY_CHECK.SINGPASS_SUCCESS_CODE
  );
  const isFromMyInfoWithError =
    pathName.includes(ACTION_TYPES.USER_IDENTITY_CHECK.SINGPASS_ERROR_CODE) ||
    false;

  const retrieveFailure = () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.SET_RETRIEVE_FAILURE
    });
  };

  const setApiLoading = () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.API_LOADING
    });
  };

  const resetLoading = () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.RESET_LOADING
    });
  };

  const clearAndReset = () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.CLEAR_AND_RESET
    });
    if (customerMyInfo) {
      navigate('/' + navigation.ESERV_EFORM_REG, { replace: true });
    }
  };

  const handleAPIstate = (resCallback, res, error) => {
    resetLoading();
    if (error) {
      retrieveFailure();
    } else if (res) {
      resCallback();
    }
  };

  const retrieveRedirectUrl = async () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.SET_RETRIEVE_INITIATE
    });

    const options = {
      redirectUrl: `${window.location.origin}/${navigation.ESERV_EFORM_REG}`
    };

    const [res, error] = await fetchAndRespond(apiMyinfoRetrieveUrl, options);

    handleAPIstate(
      () => {
        navigate(res.url);
      },
      res,
      error
    );
  };

  const retrieveCustomerData = async () => {
    const urlParams = getUrlParams();

    if (urlParams?.code) {
      eRegDispatch({
        type: EREG_STATE_ACTIONS.SET_RETRIEVE_INITIATE
      });
      const options = {
        redirectUrl: `${window.location.origin}/${navigation.ESERV_EFORM_REG}`,
        data: urlParams?.code,
        status: urlParams?.state
      };

      const [res, error] = await fetchAndRespond(
        apiMyinfoCustomerInfo,
        options
      );

      handleAPIstate(
        () => {
          eRegDispatch({
            type: EREG_STATE_ACTIONS.SET_CUS_INFO_SUCCESS,
            payload: res
          });
        },
        res,
        error
      );
    }
  };

  const retrieveAddressData = async (postalCode: string) => {
    setApiLoading();
    const [res, error] = await fetchAndRespond(apiRetrieveAddress, postalCode);

    handleAPIstate(
      () => {
        const address = res?.find(d => d.unitNumber || d.floor) || res[0];
        eRegDispatch({
          type: EREG_STATE_ACTIONS.SET_ADDRESS_DATA_SUCCESS,
          payload: address
        });
      },
      res,
      error
    );
  };

  const submitData = async (data = {}) => {
    setApiLoading();
    const [res, error] = await submitSRB(data);

    handleAPIstate(
      () => {
        eRegDispatch({
          type: EREG_STATE_ACTIONS.SUBMIT_SRB_SUCCESS,
          payload: res
        });
      },
      res,
      error
    );
  };

  const handleBackToPage = () => {
    eRegDispatch({
      type: EREG_STATE_ACTIONS.HANDLE_CANCEL_ACK,
      payload: false
    });
    navigate('/' + navigation.ESERV_EFORM_REG, { replace: true });
  };

  useEffect(() => {
    if (isFromMyInfo) {
      retrieveCustomerData();
    } else if (isFromMyInfoWithError) {
      eRegDispatch({
        type: EREG_STATE_ACTIONS.HANDLE_CANCEL_ACK,
        payload: true
      });
    }
  }, [pathName]);

  useEffect(() => {
    const showRegForm =
      isFromMyInfo && !isFromMyInfoWithError && customerMyInfo && addressData;

    const postCode = customerMyInfo?.ImplCustomerInfoResponse?.postalCode;
    if (postCode && addressData?.postcode !== postCode) {
      retrieveAddressData(postCode);
    }

    eRegDispatch({
      type: EREG_STATE_ACTIONS.SET_SHOW_REG_FORM,
      payload: showRegForm
    });
  }, [pathName, customerMyInfo, addressData]);

  const renderRegistrationForm = () => {
    return (
      <ERegister
        customerInfoResponse={customerMyInfo}
        address={addressData}
        submitData={submitData}
      />
    );
  };

  const renderSingpassSection = () => {
    return (
      <>
        <Spacing top={1} bottom={3}>
          <Text type="pageTitle">{t('EKYC_REG_TITLE')}</Text>
        </Spacing>
        <Column noGutter>
          <Spacing top={2}>
            <StyledSingPassImg src={SingpassLogo} alt="Singpass logo" />
          </Spacing>

          <Spacing top={1} bottom={2}>
            <Text>{t('SINGPASS_DESCRIPTION')}</Text>
          </Spacing>

          <SingpassRetrieveMyInfo
            data-testid="retrieveRedirectUrl"
            onClick={retrieveRedirectUrl}
            width={350}
            height={50}
          />
        </Column>
      </>
    );
  };

  const renderAckModal = () => {
    const { title, message, clearState } = modalData || {};

    const handleCloseAck = () => {
      if (clearState) {
        clearAndReset();
      } else {
        navigate(successURL, { replace: true });
      }
    };

    return (
      <Modal
        visible={toggleAckModal}
        title={title}
        onClose={handleCloseAck}
        backdropClosable={false}
      >
        <Modal.Content>
          <Text type="body">{message}</Text>

          {title !== t('GENERIC_ERROR_TITLE') ? (
            <>
              <Spacing responsive={false} top={3}>
                <Button
                  data-testid="close-error-popup"
                  secondary
                  onClick={handleCloseAck}
                >
                  {t('OK_GOT_IT')}
                </Button>
              </Spacing>
            </>
          ) : (
            <>
              <Spacing top={2}>
                <Button
                  data-testid="open-contact-popup"
                  fullWidth
                  onClick={() => {
                    window.open(contactLink, '_blank', 'noopener,noreferrer');
                  }}
                >
                  {t('CO_CONTACT_US')}
                </Button>
              </Spacing>
            </>
          )}
        </Modal.Content>
      </Modal>
    );
  };

  return (
    <>
      <Grid>
        <Row>
          <Column xs={12}>
            {showRegForm ? renderRegistrationForm() : renderSingpassSection()}
          </Column>
        </Row>
      </Grid>

      <MyInfoEKYCModal
        isModalOpen={singpassCancel}
        modaltitle={t('EKYC_MYINFO_MISMATCH_TITLE') as string}
        modalContent={t('EKYC_CANCEL_DESCRIPTION') as string}
        ctaText={t('EKYC_CANCEL_BUTTON') as string}
        secondaryType={true}
        onClose={handleBackToPage}
        onConfirm={handleBackToPage}
      />

      {apiLoading && <LoadingOverlayComponent />}

      {renderAckModal()}
    </>
  );
};

export default EFormRegister;
