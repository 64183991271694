import { ACTION_TYPES } from '../constants/actions';

interface ApigeeAuthState {
  isLoading?: boolean;
  isError?: boolean;
  apigeeToken?: string;
}

const initialState: ApigeeAuthState = {
  isError: false,
  isLoading: false,
  apigeeToken: ''
};

type ApigeeAuthActions = {
  type: string;
  payload?: string;
};

export const apigeeAuthReducer = (
  state: ApigeeAuthState = initialState,
  action: ApigeeAuthActions
) => {
  switch (action.type) {
    case ACTION_TYPES.APIGEE_AUTH.GET_APIGEE_TOKEN_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.APIGEE_AUTH.GET_APIGEE_TOKEN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        products: action.payload
      };
    }
    case ACTION_TYPES.APIGEE_AUTH.GET_APIGEE_TOKEN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }
  }
  return state;
};
