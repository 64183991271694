import React, { ReactElement } from 'react';
import { Modal, Spacing, Text, Button } from '@dls/web';
import useTranslate from '../../../hooks/useTranslation';

interface TProps {
  isOpen: boolean;
  onClose: () => void;
  onCtaClick: () => void;
}

export const SkipStepModal: React.FC<TProps> = ({
  isOpen,
  onClose,
  onCtaClick
}): ReactElement => {
  const { t } = useTranslate();
  return (
    <Modal
      data-testid="skip-step-modal"
      visible={isOpen}
      title={t('MISSING_STEP_TITLE')}
      backdropClosable={false}
      onClose={onClose}
    >
      <Modal.Content>
        <Text>{t('MISSING_STEP_MESSAGE')}</Text>
        <Spacing top={2}>
          <Button data-testid={'back-to-fulfilment-btn'} onClick={onCtaClick}>
            {t('BACK_TO_DELIVERY')}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};
