import React from 'react';
import { Grid, Row, Column, Text, Spacing } from '@dls/web';

const BackendNotAvailablePage = () => {
  return (
    <Grid>
      <Row center="sm" middle="sm">
        <Column>
          <Text type="bannerTitle" tag="h2">
            Upgrading in progress
          </Text>
          <Spacing top={2} bottom={2}>
            <Text type="smallBody">
              We're busy pushing out some new enhancements and tweaks.
            </Text>
            <Text type="smallBody">
              We'll be back up and running again in a few hours.
            </Text>
            <Text type="smallBody">Thanks for your patience!</Text>
          </Spacing>
        </Column>
      </Row>
    </Grid>
  );
};

export default BackendNotAvailablePage;
