import React, { ReactElement } from 'react';
import { OrderSummary } from '../components/OrderSummary';
import { graphql } from 'gatsby';
import { TObject } from '../types/registrationCheckout';
import SEO from '../components/SEO';

interface TProps {
  data: TObject;
}

export const OrderSummaryPage: React.FC<TProps> = ({ data }): ReactElement => {
  return (
    <>
      <SEO description="" title="Order Summary" />
      <OrderSummary data={data} />
    </>
  );
};

export const query = graphql`
  query OrderSummaryQuery {
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allAddonMobileLob {
      edges {
        node {
          addonId
        }
      }
    }
    allAspireBillingOffer {
      edges {
        node {
          boId
        }
      }
    }
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    config {
      dealerCode
      merchantId
    }
    membership {
      memberBanner {
        member
        icon
        footer
        title
        description
      }
    }
    allPaymentMethod {
      edges {
        node {
          paymentMethodKey
          paymentTitle
          paymentDesc
          deliveryOption {
            popStation
            courier
            snailMail
            storePickUp
            collectNow
          }
          deliveryOptionKeys
          transactionType {
            newNewNumber
            newNewPortin
            existingNewNumber
            existingNewPortin
            recon
          }
        }
      }
    }
    allVariableConfig {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;

export default OrderSummaryPage;
