import React from 'react';

import SEO from '../components/SEO';
import MyInfoRetrieval from '../components/MyInfoRetrieval';

export const UserIndentityPage = props => {
  const { data } = props;

  return (
    <div className="fs-unmask">
      <SEO title="User Indentity" />
      <MyInfoRetrieval data={data} />
    </div>
  );
};

export default UserIndentityPage;
