import { navigate } from 'gatsby';
import { PLAN_GROUP_SD_PLANS } from '../config/plan-group-config';
import slugify from './slugify';
import { PlanState } from '../types/plan.types';
import { CisInformationStore } from '../types/user.types';
import { constructProductUrl } from './catalogue-helpers';

export function navigateToSpringDPlans({
  groupName
}: {
  groupName: string;
}): void {
  const sdPlanGroupPathHash = PLAN_GROUP_SD_PLANS[groupName] || '';
  window.location.assign(
    `${process.env.GATSBY_AEM_URL}/personal/products-services/mobile/postpaid-plans#${sdPlanGroupPathHash}`
  );
}

export function navigateToPlansPage(
  selectedPlan: PlanState,
  cisUserInfo: CisInformationStore
) {
  if (cisUserInfo) {
    return navigate(
      `/cis-plans?sessionToken=${cisUserInfo.sessionToken}&group=${slugify(
        selectedPlan.groupName
      )}`
    );
  }
  return navigate(`/plans/?group=${slugify(selectedPlan.groupName)}`);
}

export function navigateToProductDetailsPage(
  selectedProduct: PlanState,
  selectedPlan: PlanState,
  cisUserInfo: CisInformationStore
) {
  if (selectedProduct) {
    const slug = slugify(selectedProduct?.title);
    const url = constructProductUrl(
      selectedProduct.colour,
      selectedProduct.size,
      selectedPlan.planName,
      selectedProduct.monthlyTerm
    );

    return navigate(`/phones/${slug}/${url}`, {
      state: {
        isChangePlan: true
      }
    });
  } else {
    if (cisUserInfo) {
      return navigate(
        `/cis-plans?sessionToken=${cisUserInfo.sessionToken}&group=${slugify(
          selectedPlan.groupName
        )}`
      );
    }

    return navigate(`/plans/?group=${slugify(selectedPlan.groupName)}`);
  }
}
