import React from 'react';
import { Column, Row, Text, TextLink } from '@dls/web';
import { trans as t } from '../../helpers/localisation';

interface IProps {
  product: string;
  onTextLinkClick: () => void;
}
const PromotionsEmpty: React.FunctionComponent<IProps> = props => {
  const { product, onTextLinkClick } = props;

  return (
    <Row>
      <Column noGutter>
        <Text type="header">{t('CCDE_NO_PROMOTIONS_TITLE', { product })}</Text>
        <Text type="body">{t('CCDE_NO_PROMOTIONS_SUBTITLE')}</Text>
        <TextLink onClick={onTextLinkClick} data-testid="errorButton">
          {t('CCDE_SEE_ALL_PROMOTIONS')}
        </TextLink>
      </Column>
    </Row>
  );
};

export default PromotionsEmpty;
