import React from 'react';
import { Modal, Text, Button, Spacing } from '@dls/web';
import { trans as t } from '../../helpers/localisation';
import { springDPromotionsPageUrl } from '../../config/links';

const PromotionsNotFoundModal = (): JSX.Element => {
  const onCloseErrorPopup = () => {
    window.location.href = springDPromotionsPageUrl;
  };

  return (
    <Modal
      visible={true}
      title={t('CCDE_ERROR_MODAL_TITLE')}
      onClose={onCloseErrorPopup}
      backdropClosable={false}
      wide={false}
    >
      <Modal.Content>
        <Text type="body">{t('CCDE_ERROR_MODAL_SUBTITLE')}</Text>

        <Spacing responsive={false} top={3}>
          <Button
            primary
            fullWidth
            onClick={onCloseErrorPopup}
            data-testid="seeAllPromotionsButton"
          >
            {t('CCDE_ERROR_MODAL_CTA_TEXT')}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default PromotionsNotFoundModal;
