export const initialState = {
  exclusiveValidation: {
    show: true
  },
  maintenanceMessage: {
    show: false
  }
};

const settingReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case 'DETOX_MAINTENANCE_SHOW':
      return {
        ...state,
        maintenanceMessage: {
          show: true
        }
      };
    case 'DETOX_MAINTENANCE_HIDE':
      return {
        ...state,
        maintenanceMessage: {
          show: false
        }
      };
    case 'EXCLUSIVE_VALIDATION_SHOW':
      return {
        ...state,
        exclusiveValidation: {
          show: true
        }
      };
    case 'EXCLUSIVE_VALIDATION_HIDE':
      return {
        ...state,
        exclusiveValidation: {
          show: false
        }
      };
    default:
      return state;
  }
};

export default settingReducer;
