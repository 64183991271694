import { simCardType } from '../config/simcard-types';
import { CHOOSE_SIM_TYPE } from '../constants/choose-sim-type';
import { isSimOnlyWithoutDevice } from '../helpers/shoppingCartHelper';
import { KeyValue } from '../types/common.types';
import CHECKOUT_CONSTANTS from '../constants/checkout';
import { ORDER } from '../constants';
import CONSTANT from '../constants/common';
import isSimOnlyPlan from '../helpers/is-sim-only-plan';
import isMobileSharePlan from '../helpers/is-mobile-share-plan';

const getPlanAndProduct = (state: KeyValue): KeyValue => {
  const selectedPlan = state.plan?.selectedPlan;
  const selectedProduct = state.product?.selectedProduct;

  return {
    selectedPlan,
    selectedProduct
  };
};

export const getIfSimOnly = (state: KeyValue): boolean => {
  const { selectedPlan, selectedProduct } = getPlanAndProduct(state);
  return selectedPlan && isSimOnlyPlan(selectedPlan) && !selectedProduct;
};

export const getIfMobileShare = (state: KeyValue): boolean => {
  const { selectedPlan } = getPlanAndProduct(state);
  return selectedPlan && isMobileSharePlan(selectedPlan);
};

export const getIfESimOrder = (state: KeyValue): boolean => {
  const productOrder = state?.order?.productOrder;
  const cartOrder = state?.cart?.order;

  return (
    cartOrder?.hasSim &&
    (productOrder?.sim?.selectedSimType === CHOOSE_SIM_TYPE.SIM_TYPE_ESIM ||
      cartOrder?.newlyAddedSimDetails?.simDetails?.simType ===
        simCardType.simTypeESim)
  );
};

export const getIfCISMyInfoFlow = (state: KeyValue): boolean => {
  const cisInformation = state.user?.cis?.information;
  if (!cisInformation) {
    return false;
  }

  const isCisUserLoginTypeMyInfo = Boolean(cisInformation?.$myInfo);
  const isCISFlow = Boolean(cisInformation?.rates);
  const userCISMyInfo = Boolean(cisInformation?.cusMyInfo);

  return isCISFlow && isCisUserLoginTypeMyInfo && userCISMyInfo;
};

export const getIfsimOnlyeSIMOrder = (state: KeyValue): boolean => {
  const isESimOrder = getIfESimOrder(state);
  const isSimOnlyPlan = isSimOnlyWithoutDevice(
    state.plan?.selectedPlan,
    state.product?.selectedProduct
  );

  return isESimOrder && isSimOnlyPlan;
};

export const getIfSimOnlyESIMPortInOrder = (state: KeyValue): boolean => {
  const isPortin = state.order?.productOrder?.type === ORDER.TYPE.PORTIN;
  const simOnlyeSIMOrder = getIfsimOnlyeSIMOrder(state);

  return isPortin && simOnlyeSIMOrder;
};

export const getIsEKYCFlow = (state: KeyValue): boolean => {
  const isNewNewFlow =
    state.checkout?.checkoutFlow ===
    CHECKOUT_CONSTANTS.CHECKOUT_NEW_NEW_CUSTOMER;
  const userCISMyInfoAvailable = getIfCISMyInfoFlow(state);
  const isCompleteMyInfoData = state.customerMyInfo?.isCompleteMyInfoData;

  return isNewNewFlow && (userCISMyInfoAvailable || isCompleteMyInfoData);
};

// CHANGE is the term for recon/existing new buy flows
export const getChangeCISEKYCFlow = (
  state: KeyValue,
  enableCISMyInfoeKYC = 'false'
): boolean => {
  const userCISMyInfoAvailable = getIfCISMyInfoFlow(state);
  return enableCISMyInfoeKYC === 'true' && userCISMyInfoAvailable;
};

export const getUIAMEnabledWithToken = (state: KeyValue): boolean => {
  if (typeof window === 'undefined') {
    return true;
  }

  const apigeeToken = state?.apigeeAuth?.apigeeToken;
  return !!(
    apigeeToken || window.sessionStorage.getItem(CONSTANT.APIGEE_AUTH_TOKEN)
  );
};

export const getTradeInDetails = (state: KeyValue): KeyValue => {
  const tradeInDetails = state.cart.cartOrder?.tradeInDetails;
  const tradeInCID =
    tradeInDetails?.tradeInBo?.productSpecPricing?.childPricingSchema?.id;

  return { tradeInDetails, tradeInCID };
};
