/**
 * Format the price to the given locale correctly.
 *
 * Defaults to "en-SG" locale and "SGD" currency denomination
 *
 * @param {number|string} price
 * @param {number} decimal
 * @returns {string}
 */
const formatPrice = (price, decimal = 2, currency = '$') => {
  // Typecasting the price to a Number
  price = Number(price);

  const rootValue = Math.abs(price).toFixed(decimal);
  return price < 0 ? `-${currency}${rootValue}` : `${currency}${rootValue}`;
};

export default formatPrice;
