import React, { ReactElement, useEffect, useState } from 'react';
import { Text, Spacing } from '@dls/web';
import { Form } from '@wec-core/form-engine';

import { trans as t } from '../../helpers/localisation';
import {
  MyInfoFormState,
  getRESMyInfoReadOnlyPersonalData,
  getTitleField
} from '../Checkout/myinfoHelper';
import { getContactGroup, getFormDataMapping } from '../Checkout/helper';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import {
  eRegReadOnlyFields,
  getSubmitButton,
  eRegisterInitialValues,
  getTransactionType,
  getTransactionList
} from './helper';

interface TProps {
  customerInfoResponse: APP_TYPE_ANY;
  address: KeyValue;
  submitData: (data: APP_TYPE_ANY) => Promise<void>;
}

const submitGroup = getSubmitButton();
const transactionType = getTransactionType();
const transactionList = getTransactionList();
const formData = {
  transactionList
};

const ERegister = (props: TProps): ReactElement => {
  const { customerInfoResponse, address, submitData } = props;

  const [myInfoFormState, setMyInfoFormState] = useState<MyInfoFormState>({
    formValues: {},
    formInputsMapping: []
  });

  const { contactNumber, email, userData } = getRESMyInfoReadOnlyPersonalData(
    customerInfoResponse?.ImplCustomerInfoResponse
  );

  const setOverAllFormState = ({ contactNumber, email, userData }): void => {
    const infoReadOnlyFields = eRegReadOnlyFields(userData);
    const formFieldsData = {
      title: getTitleField,
      transactionType: transactionType,
      readOnlyFields: infoReadOnlyFields,
      contactGroup: getContactGroup(t as APP_TYPE_ANY),
      submitGroup: submitGroup
    };

    const initialFormValues = {
      ...eRegisterInitialValues({
        contactNumber,
        email
      })
    };

    setMyInfoFormState({
      formInputsMapping: formFieldsData,
      formValues: initialFormValues
    });
  };

  useEffect(() => {
    if (customerInfoResponse) {
      setOverAllFormState({ contactNumber, email, userData });
    }
  }, [customerInfoResponse, address]);

  const formattedFormFieldsData = getFormDataMapping(
    myInfoFormState?.formInputsMapping
  );

  return (
    <div className="fs-mask">
      <Spacing topBottom={2}>
        <Text type="pageTitle">{t('CHECK_OUT_NN_FORM_TITLE')}</Text>
      </Spacing>
      <Form
        enableReinitialize={false}
        formFieldsConfig={formattedFormFieldsData}
        initialValues={myInfoFormState.formValues}
        data={formData}
        callbacks={{}}
        onSubmit={values => {
          submitData({ ...values, ...userData });
        }}
        enableInputErrorFocus
      />
    </div>
  );
};

export default ERegister;
