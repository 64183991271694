import styled, { css } from 'styled-components';
import { withTheme } from '@dls/web';

export const LoginCheckoutWrapper = withTheme(styled.div`
  ${({ coreTheme }) => css`
    @media (max-width: ${coreTheme.brk_sm}) {
      padding: 0px 16px;
    }
  `}
`);
