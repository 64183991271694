import { useCallback } from 'react';
import { Dispatch } from 'redux';
import { api, fulFillmentActions } from '@detox/actions';
import {
  FORM_MAPPING_ACTIONS,
  getPostalGroup,
  isValidPostalCode
} from '../helper';

import CONSTANTS from '../../../constants/checkout';
import {
  FormDispatch,
  FieldValidation,
  AddressInfo,
  Address,
  AddressStatus,
  TranslateFn,
  TObject,
  FieldObject,
  IFormFieldConfig
} from '../../../types/registrationCheckout';
import { APP_TYPE_ANY } from '../../../types/common.types';

const { getAddressInfo } = fulFillmentActions;
const { apiRetrieveAddress } = api.mcss;

interface HelperFunctions {
  handlePostalSearchEvent: (
    postalCode: string,
    addressInfo: AddressInfo,
    saveNewAddressInfo?: boolean
  ) => Promise<void>;
  postalSearchValidation: (
    code: string,
    addressInfo: AddressInfo,
    enableFormReinitialize?: { current: boolean },
    saveNewAddressInfo?: boolean
  ) => Promise<FieldValidation>;
  setPostalCodeGroup: (
    address: Address,
    status: AddressStatus,
    existingFormData: TObject,
    initialValues: FieldObject,
    additionalAfterPostal: IFormFieldConfig,
    enableFormReinitialize?: { current: boolean },
    valuesToReset?: FieldObject
  ) => void;
  togglePostalAddressDetail: (
    addressChecked: boolean,
    address: Address,
    enableFormReinitialize?: { current: boolean }
  ) => void;
}

export const usePostalCodeHelper = (
  t: TranslateFn,
  formDispatch: React.Dispatch<FormDispatch>,
  dispatch: Dispatch<APP_TYPE_ANY>
): HelperFunctions => {
  const togglePostalAddressDetail = (
    addressChecked: boolean,
    address: Address
  ): void => {
    const postalGroup = getPostalGroup(t, address, addressChecked);

    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_POSTAL_CODE_ADDRESS,
      payload: postalGroup
    });
  };

  const setPostalCodeGroup = useCallback(
    (
      address: Address,
      status: AddressStatus,
      existingFormData: TObject,
      initialValues: FieldObject,
      additionalAfterPostal: IFormFieldConfig,
      enableFormReinitialize?: { current: boolean },
      valuesToReset?: FieldObject
    ): void => {
      switch (status) {
        case 'FAILED': {
          formDispatch({
            type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
            payload: false
          });

          formDispatch({
            type: FORM_MAPPING_ACTIONS.SET_FORM_INPUT_MAPPING,
            payload: valuesToReset
          });

          break;
        }
        case 'SUCCEEDED': {
          formDispatch({
            type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
            payload: false
          });
          const existingFormDataValues = existingFormData
            ? existingFormData
            : {};
          const addressChecked = existingFormDataValues.addressCheck as unknown;
          const postalGroup = getPostalGroup(
            t,
            address,
            addressChecked as boolean
          );

          const payload = {
            formValues: { ...initialValues, ...existingFormDataValues },
            formInputsMapping: {
              ...additionalAfterPostal,
              postalAddress: postalGroup
            },
            enableReinitialize: enableFormReinitialize.current
          };

          formDispatch({
            type: FORM_MAPPING_ACTIONS.SET_POSTAL_AND_REMAINING,
            payload
          });

          break;
        }
        default:
          break;
      }
    },
    [formDispatch, t]
  );

  const getPostalAddressEvent = useCallback(
    async (
      postcode: string,
      enableFormReinitialize: { current: boolean },
      saveNewAddressInfo
    ): Promise<FieldValidation> => {
      formDispatch({
        type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
        payload: true
      });

      try {
        const result = await apiRetrieveAddress(postcode);
        enableFormReinitialize.current = false;
        dispatch({
          type: CONSTANTS.GET_ADDRESS_INFO_SUCCESS,
          payload: result,
          meta: { postcode, saveNewAddressInfo }
        });

        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
          payload: false
        });

        return { valid: true };
      } catch (error) {
        dispatch({
          type: CONSTANTS.GET_ADDRESS_INFO_FAILED,
          error,
          meta: { postcode }
        });

        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
          payload: false
        });
        return { valid: false };
      }
    },
    [dispatch, formDispatch]
  );

  const postalSearchValidation = useCallback(
    async (
      code = '',
      addressInfo: AddressInfo,
      enableFormReinitialize?: { current: boolean },
      saveNewAddressInfo = true
    ): Promise<FieldValidation> => {
      if (addressInfo.hasInvalid && addressInfo.previousSearch === code) {
        return { valid: false };
      }
      const isNotPresent =
        addressInfo.previousSearch !== code &&
        (!addressInfo.address || addressInfo.address.postcode !== code);

      if (isValidPostalCode(code) && isNotPresent) {
        const result = await getPostalAddressEvent(
          code,
          enableFormReinitialize,
          saveNewAddressInfo
        );

        return result;
      }

      return { valid: true };
    },
    [getPostalAddressEvent]
  );

  const handlePostalSearchEvent = useCallback(
    async (
      postalCode: string,
      addressInfo: AddressInfo,
      saveNewAddressInfo = true
    ): Promise<void> => {
      const { address } = addressInfo;

      if (postalCode && (!address || address.postcode !== postalCode)) {
        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING,
          payload: true
        });
        await dispatch(getAddressInfo(postalCode, saveNewAddressInfo));
      }
    },
    [formDispatch, dispatch]
  );

  return {
    handlePostalSearchEvent,
    postalSearchValidation,
    setPostalCodeGroup,
    togglePostalAddressDetail
  };
};
