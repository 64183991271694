import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Theme from '../../theme';

const LoadingOverlayComponent = () => (
  <LoadingOverlay
    styles={{
      wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 9999 // Should be greater than modal z-index
      },
      overlay: base => ({
        ...base,
        background: `rgba(255, 255, 255, 0.6)`
      }),
      spinner: base => ({
        ...base,
        /* As per package documentation. https://github.com/derrickpelletier/react-loading-overlay#custom-styling-nail_care */
        '& svg circle': {
          // The theme from ThemeProvider is not exposed here, thus directly importing the theme from base
          stroke: Theme.colours.primary
        }
      })
    }}
    active={true}
    spinner={true}
  />
);

export default LoadingOverlayComponent;
