import popstations from './popstations';
import mtposStock from './mtposStock';
import lampStock from './lampStock';
import allocateOrReserve from './allocateOrReserve';
import addressInfo from './addressInfo';
import logisticAppointmentSlots from './logisticAppointmentSlots';
import { deliveryReducer as delivery } from './delivery';
import { combineReducers } from 'redux';
import billingPreference from './billingPreference';
import uploadDocumentNeeded from './uploadDocumentNeeded';
import { storeStockReducer as storeStock } from './storeStock';

export default combineReducers({
  popstations,
  mtposStock,
  storeStock,
  lampStock,
  allocateOrReserve,
  addressInfo,
  delivery,
  billingPreference,
  uploadDocumentNeeded,
  logisticAppointmentSlots
});
