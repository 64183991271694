import React, { ReactElement, useEffect } from 'react';
import isFeatureFlagEnabled from '../helpers/feature-flags';
import { SHOP_THANK_YOU_PAGE } from '../types/featureFlag.types';
import { ORDER, COMMON } from '../constants';
import { navigate } from 'gatsby';
import { navigation } from '../middlewares/navigation-constants';
import { useSelector } from 'react-redux';
import { KeyValue } from '../types/common.types';
import { OrderSummaryState } from '../reducers/orderSummary';
import { isValidUrl } from '../helpers/common';

export const PaymentRedirection = (): ReactElement => {
  const { orderSummary, productOrderType } = useSelector((state: KeyValue) => ({
    orderSummary: state?.orderSummary as OrderSummaryState,
    productOrderType: state?.cart?.order?.productOrder?.type
  }));

  const handleRedirection = () => {
    const pathName = window.location.search;
    const configuredRedirectUrl = process.env.GATSBY_PAYMENT_REDIRECTION_URL;
    // This logic is only for env redirection like UAT1, UAT2, UAT3
    if (
      isValidUrl(configuredRedirectUrl) &&
      configuredRedirectUrl !== window.location.origin
    ) {
      return window.open(
        `${configuredRedirectUrl}/${navigation.PAYMENT_REDIRECT_URL}${pathName}`,
        '_self'
      );
    }

    let merchantId = [];
    if (pathName.includes(ORDER.URL_PARAMS.MERCHANT_REF_ID)) {
      merchantId = pathName.split(ORDER.URL_PARAMS.MERCHANT_REF_ID + `=`);
    }
    const orderRefID =
      orderSummary?.refId || window.sessionStorage.getItem('det-onepay-ref-id');
    const flowType = window.sessionStorage.getItem('flow');

    if (
      isFeatureFlagEnabled(SHOP_THANK_YOU_PAGE) &&
      orderRefID === merchantId[1]
    ) {
      navigate(
        `/${
          productOrderType === ORDER.TYPE.RRPIPP
            ? navigation.RRP_IPP_ORDER_CONFIRMATION
            : navigation.THANK_YOU
        }${pathName}`
      );
    } else if (flowType === COMMON.PAYMENT_REDIRECT_FLOW.RRP) {
      navigate(`/${navigation.RRP_ORDER_CONFIRMATION}${pathName}`);
    } else {
      window.open(
        `${process.env.GATSBY_AEM_URL}${navigation.SPRINGD_THANKYOU_PAGE_PATH}${pathName}`,
        '_self'
      );
    }
  };

  useEffect(() => {
    handleRedirection();
  }, []);

  return <></>;
};
export default PaymentRedirection;
