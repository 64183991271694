import lang from './en_us.json';
import { replaceString } from '../../helpers/stringHelper';

export default (customLang?) => {
  const translate = (key?: keyof typeof customLang, replaceMap?: string[]) => {
    const str = customLang?.[key] || lang?.[key] || key;
    if (replaceMap) {
      return replaceString(str, replaceMap);
    } else {
      return str;
    }
  };
  return { t: translate, translate };
};
