import React, { ReactNode } from 'react';
import { navigate } from 'gatsby';
import { Text, Grid, Row, Column, Button } from '@dls/web';
import { useSelector } from 'react-redux';

import { trans as t } from '../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';

const CreditLimitReviewError = (props: KeyValue): ReactNode => {
  const { flowType } = useSelector((state: APP_TYPE_ANY) => ({
    flowType: state.creditLimitReview.flow
  }));
  const { creditLimitUrls } = props.data.creditLimitUrls;

  const handleAssistanceRedirect = () => {
    const flowTypeUrl = creditLimitUrls.find(item => item.flow === flowType);

    if (flowTypeUrl?.chatOnlineAgentLink) {
      navigate(flowTypeUrl.chatOnlineAgentLink);
    }
  };

  return (
    <Grid>
      <Row center="xs">
        <Column xs={12} sm={12} md={6}>
          <Text type="pageTitle">{t('CR_NOT_ELIGIBLE_FOR_CREDIT_REVIEW')}</Text>
        </Column>
      </Row>
      <Row center="xs">
        <Column xs={12} sm={12} md={6}>
          <Text type="smallBody" fontSize={'20px'}>
            {t('CR_MORE_ASSISTANCE_CHAT_WITH_ONLINE_AGENTS')}
          </Text>
        </Column>
      </Row>
      <Row center="xs">
        <Column xs={12} sm={12} md={6}>
          <Button secondary onClick={handleAssistanceRedirect}>
            {t('CHAT_WITH_US_VIA_WHATSAPP')}
          </Button>
        </Column>
      </Row>
    </Grid>
  );
};

export default CreditLimitReviewError;
