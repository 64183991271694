import isSimOnlyPlan from './is-sim-only-plan';
import CONSTANTS from '../constants/common';
import { composeRequestParams } from './common';
import { INFO_TYPES } from '../components/Info';
import { navigation } from '../middlewares/navigation-constants';

export const getMobileShareLinesOfMainLine = (user, service?): string => {
  if (!service) {
    return '';
  }
  const mainlines = user?.mainLines;
  const selectedMainline = mainlines[service?.serviceId];
  if (selectedMainline) {
    const msLines = selectedMainline.implGroupMember;
    if (msLines?.length) {
      return msLines
        .map(msLine => {
          return msLine.msisdn;
        })
        .join(', ');
    }
  }
  return '';
};

export const isMainLineHasMobileShare = (user, service): boolean => {
  const mainlines = user?.mainLines || {};
  const selectedMainline = mainlines[service?.serviceId];
  if (selectedMainline) {
    return selectedMainline.implGroupMember?.length;
  }
  return false;
};

export const isMSMainLine = (service): boolean => {
  if (!service) return false;
  return CONSTANTS.MS_MAINLINE_PLANS.some(plan => {
    return service.offeringName
      ?.toUpperCase()
      ?.replace(/ /g, '')
      .includes(plan);
  });
};

export const shouldShowMSConversionPopup = (
  selectedPlan,
  user,
  service
): boolean => {
  return (
    isSimOnlyPlan(selectedPlan) &&
    isMSMainLine(service) &&
    isMainLineHasMobileShare(user, service)
  );
};

export const getPendingOrderParams = (isOnePass: boolean) => {
  return composeRequestParams({
    [`${CONSTANTS.URL_PARAMS.INFO_TITLE}`]: 'PENDING_ORDER_TITLE',
    [`${CONSTANTS.URL_PARAMS.INFO_MESSAGE}`]: isOnePass
      ? 'PENDING_ORDER_MESSAGE'
      : 'PENDING_ORDER_MESSAGE_WORRY_FREE',
    [`${CONSTANTS.URL_PARAMS.INFO_CTA_TEXT}`]: isOnePass
      ? 'PENDING_ORDER_CTA_TEXT'
      : null,
    [`${CONSTANTS.URL_PARAMS.INFO_TYPE}`]: INFO_TYPES.ERROR,
    [`${CONSTANTS.URL_PARAMS.INFO_CALLBACK_URL}`]: isOnePass
      ? navigation.CHOOSE_NUMBER_PAGE
      : null,
    [`${CONSTANTS.URL_PARAMS.CHAT_WITH_US}`]: isOnePass
      ? 'CHAT_WITH_US_ONE_PASS'
      : 'CHAT_WITH_US_ONE_WORRY_FREE'
  });
};
