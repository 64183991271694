import { KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '@detox/actions';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';

import ORDER_CONSTANTS from '../constants/order';
import { Dispatch } from 'redux';
export interface PaymentState {
  isLoading?: boolean;
  isError?: boolean;
  status?: string;
  distributeLoading?: boolean;
  distributeSuccess?: boolean;
  distributeError?: boolean;
  isDoneVerifyPayment?: boolean;
  productOrderId?: string;
  productOrderReferenceNumber?: string;
  selectedDeliveryMode?: string;
  distributePromoCode?: string;
  orderAmount?: string;
  customerId?: string;
  serviceId?: string;
  paymentMethod?: string;
}
const initialState: PaymentState = {
  isError: false,
  isLoading: false
};

type PaymentActions = {
  value?: KeyValue;
  type: string;
};

export const paymentReducer = (
  state: PaymentState = initialState,
  action: PaymentActions
) => {
  switch (action.type) {
    case ACTION_TYPES.ORDER_SUMMARY.VERIFY_PAYMENT_LOADING: {
      return {
        ...state,
        isDoneVerifyPayment: false,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.VERIFY_PAYMENT_SUCCESS: {
      const res = action.value;
      const resStatus = res?.implValidateOnePayApiResponse?.status;
      return {
        ...state,
        isLoading: false,
        isError: false,
        isDoneVerifyPayment: true,
        paymentMethod: res?.implValidateOnePayApiResponse?.paymentMethod,
        status:
          ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS[resStatus] ||
          ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING
      };
    }
    case 'SUBMIT_ORDER_ERROR':
    case ACTION_TYPES.ORDER_SUMMARY.VERIFY_PAYMENT_FAILED: {
      return {
        ...state,
        isDoneVerifyPayment: false,
        isLoading: false,
        isError: true,
        status: ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING
      };
    }
    case ACTION_TYPES.PAYMENT.CASH_ON_DELIVERY_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.PAYMENT.CASH_ON_DELIVERY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        isDoneVerifyPayment: true,
        status: action.value
          ? ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED
          : ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED
      };
    }
    case ACTION_TYPES.PAYMENT.CASH_ON_DELIVERY_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.SET_PAYMENT_STATUS: {
      const productOrderReferenceNumber =
        action.value?.productOrderReferenceNumber;
      const { productOrderId, orderAmount, selectedDeliveryMode, customerId } =
        action.value || {};
      return {
        ...state,
        status: action.value?.orderStatus,
        productOrderReferenceNumber:
          productOrderReferenceNumber || state.productOrderReferenceNumber,
        orderAmount: orderAmount || state.orderAmount,
        productOrderId: productOrderId || state.productOrderId,
        selectedDeliveryMode:
          selectedDeliveryMode || state.selectedDeliveryMode,
        customerId: customerId || state.customerId,
        serviceId: action.value?.serviceId
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.DISTRIBUTE_PROMO_CODE_LOADING: {
      return {
        ...state,
        distributeLoading: true,
        distributeError: false,
        distributeSuccess: false,
        distributePromoCode: null
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.DISTRIBUTE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        distributeLoading: false,
        distributeError: false,
        distributeSuccess: true,
        distributePromoCode: action.value
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.DISTRIBUTE_PROMO_CODE_FAILURE: {
      return {
        ...state,
        distributeLoading: false,
        distributeError: true,
        distributeSuccess: false,
        distributePromoCode: null
      };
    }
    case SHOP_ACTION_TYPES.PAYMENT.RESET_PAYMENT: {
      return {
        initialState,
        productOrderReferenceNumber: state.productOrderReferenceNumber,
        productOrderId: state.productOrderId,
        orderAmount: state.orderAmount,
        selectedDeliveryMode: state.selectedDeliveryMode,
        customerId: state.customerId
      };
    }
  }
  return state;
};

export const clearBuyFlowData = () => (dispatch: Dispatch) => {
  dispatch({ type: SHOP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA });
};

export const resetPayment = () => (dispatch: Dispatch) => {
  dispatch({ type: SHOP_ACTION_TYPES.PAYMENT.RESET_PAYMENT });
};
