/* istanbul ignore file */
import { REQUEST_STATUS } from '../constants';
import { voucherGroupBy } from '../helpers/voucher';

export const initialState = {
  modified: false,
  vouchers: [],
  status: REQUEST_STATUS.IDLE
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VOUCHERS_LOADING':
    case 'GET_ALL_VOUCHERS_LOADING':
    case 'ADD_VOUCHERS_LOADING':
    case 'REMOVE_VOUCHERS_LOADING':
    case 'RESET_RESERVED_VOUCHERS_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        modified: false
      };
    }
    case 'GET_VOUCHERS_ERROR':
    case 'GET_ALL_VOUCHERS_ERROR':
    case 'ADD_VOUCHERS_ERROR':
    case 'REMOVE_VOUCHERS_ERROR':
    case 'RESET_RESERVED_VOUCHERS_ERROR': {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        modified: false
      };
    }
    case 'GET_VOUCHERS_SUCCESS':
    case 'GET_ALL_VOUCHERS_SUCCESS': {
      const voucherByServiceId = voucherGroupBy(action.payload, 'serviceNoX8');

      return {
        ...state,
        vouchers: action.payload,
        voucherByServiceId: voucherByServiceId,
        status: REQUEST_STATUS.SUCCEEDED,
        modified: false
      };
    }
    case 'ADD_VOUCHERS_SUCCESS':
    case 'REMOVE_VOUCHERS_SUCCESS': {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        modified: true
      };
    }
    case 'RESET_VOUCHERS':
    case 'RESET_RESERVED_VOUCHERS_SUCCESS': {
      return {
        ...state,
        vouchers: [],
        status: REQUEST_STATUS.SUCCEEDED,
        modified: false
      };
    }

    default: {
      return state;
    }
  }
};
