import { FORM_FIELD_TYPES, FORM_VALIDATION_TYPES } from '@wec-core/form-engine';
import * as Yup from 'yup';
import {
  IFormFieldConfig,
  TranslateFn,
  TranslateHelperFn
} from '../../types/registrationCheckout';

export const PAGE = {
  CUSTOMER_REGISTRATION: 'CUSTOMER_REGISTRATION',
  OTP_SUCCESS: 'OTP_SUCCESS',
  OTP: 'OTP',
  OTP_LIMIT_EXCEEDED: 'OTP_LIMIT_EXCEEDED',
  PAGE_ERROR: 'PAGE_ERROR'
};

export const RRPRegistrationFormConfig = (
  t: TranslateFn | TranslateHelperFn
): IFormFieldConfig | IFormFieldConfig[] => {
  return [
    {
      label: t('CO_NN_FULLNAME'),
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      name: 'fullName',
      bgColor: 'haze',
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_FULLNAME') }
        },
        {
          type: FORM_VALIDATION_TYPES.PATTERN_VALIDATION,
          params: {
            invalidMessage: t('CC_E_IV_FULLNAME'),
            regPattern: /^[aA-zZ\s]+$/
          }
        }
      ],
      placeholder: t('CO_NN_FULLNAME_PLACEHOLDER')
    },
    {
      label: t('EMAIL'),
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      name: 'email',
      bgColor: 'haze',
      placeholder: t('EMAIL_PLACEHOLDER'),
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_EMAIL') }
        },
        {
          type: 'email',
          params: { message: t('CC_E_IV_EMAIL') }
        }
      ]
    },
    {
      label: t('MOBILE_NUMBER'),
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      name: 'mobNumber',
      bgColor: 'haze',
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('WORRY_FREE_NUMBER_TITLE') }
        },
        {
          type: FORM_VALIDATION_TYPES.MOBILE_NUM_VALIDATION,
          params: {
            message: t('ENTER_VALID_NUMBE_STARTING_WITH_8_OR_9'),
            inValidLengthMsg: t('ENTER_VALID_NUMBE_STARTING_WITH_8_OR_9')
          }
        }
      ],
      placeholder: t('MOBILE_NUMBER_PLACEHOLDER'),
      displayFormat: 'mobile-sg'
    },
    {
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      name: 'ReadOnlyText',
      label: t('OTP_ON_GIVEN_NUMBER')
    },
    {
      component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
      name: 'termsAccepted',
      label: t('SINGTEL_PURCHASE_TERMS_AND_CONDITIONS'),
      showError: true,
      validations: ['boolean', 'required']
    },
    {
      label: t('PRE_F_CIS_SUBMIT'),
      component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
      name: 'submitButton',
      btnProps: {
        fullWidth: true
      },
      columnProps: {
        xs: 12,
        md: 4
      },
      rowProps: {
        center: 'xs'
      }
    }
  ];
};

export const rrpRegistrationValidationSchema = (
  t: TranslateFn | TranslateHelperFn
): IFormFieldConfig | IFormFieldConfig[] => {
  return Yup.object().shape({
    termsAccepted: Yup.boolean().oneOf([true], t('CONSENT_REQUIRED') as string)
  });
};
