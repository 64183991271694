import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import { getUrlParams } from '@lux/helpers';
import { Button, Spacing } from '@dls/web';
import { setUrlParams } from '@lux/helpers';

import SEO from '../components/SEO';
import CreditLimitReview from '../components/CreditLimitReview';
import CreditLimitReviewError from '../components/CreditLimitReviewError';
import CreditLimitRetrieved from '../components/CreditLimitRetrieved';
import { trans as t } from '../helpers/localisation';
import { ACTION_TYPES } from '../constants/actions';
import { Result } from '../components/Result/index';
import { ESHOP_FLOW_TYPES } from '../constants/credit';
import { isValidUrl } from '../helpers/common';
import { CREDIT_LIMIT_INDICATOR } from '../components/CreditLimitRetrieved/constants';

const CreditLimitReviewPage = props => {
  const { data } = props;
  const dispatch = useDispatch();
  const { flow, code, notEligibleForCR, creditLimitIndicator } = getUrlParams();
  const { flowType } = useSelector(state => ({
    flowType: state.creditLimitReview.flow
  }));
  const { creditLimitUrls } = data.creditLimitUrls;
  const flowUrls = creditLimitUrls?.find(item => item.flow === flowType);

  useEffect(() => {
    if (flow) {
      dispatch({
        type: ACTION_TYPES.CREDIT_LIMIT_REVIEW.UPDATE_FLOW_TYPE,
        payload: flow
      });
    }
  }, [flow]);

  const handleNavigation = (indicator, url) => {
    let redirUrl = url;
    if (!isValidUrl(redirUrl)) {
      return;
    }
    if (
      indicator !== CREDIT_LIMIT_INDICATOR.INCREASED &&
      ESHOP_FLOW_TYPES.includes(flowType)
    ) {
      redirUrl = setUrlParams(redirUrl, { clearAllTopics: true });
    }
    window.location.replace(redirUrl);
  };

  const getResultDetails = indicator => {
    switch (indicator) {
      case CREDIT_LIMIT_INDICATOR.INCREASED:
        return {
          status: 'success',
          statusText: t('CREDIT_LIMIT_REVIEW_COMPLETE_TITLE'),
          liner: t('WHATS_NEXT'),
          subTitle: t('CREDIT_LIMIT_REVIEW_COMPLETE_MESSAGE'),
          action: (
            <Button
              secondary
              text={t('BROWSE_FOR_MORE')}
              data-testid="increase-browse"
              onClick={() => handleNavigation(indicator, flowUrls?.successUrl)}
            />
          )
        };
      case CREDIT_LIMIT_INDICATOR.DECREASED:
      case CREDIT_LIMIT_INDICATOR.NOCHANGE:
        return {
          status: 'error',
          statusText: t('CREDIT_LIMIT_UNSUCCESSFULL'),
          subTitle: t('CREDIT_LIMIT_UNSUCCESSFULL_MESSAGE'),
          action: (
            <Button
              secondary
              text={t('START_BROWSING')}
              data-testid="decrease-nochange-browse"
              onClick={() =>
                handleNavigation(indicator, flowUrls?.failureBrowseUrl)
              }
            />
          )
        };
      default:
        return {};
    }
  };

  const renderCreditReviewScreen = () => {
    if (flow) {
      return <CreditLimitReview flowType={flow} />;
    } else if (code) {
      return (
        <CreditLimitRetrieved
          token={code}
          flowUrls={flowUrls}
          flowType={flowType}
        />
      );
    } else if (creditLimitIndicator) {
      const errorDataModel = getResultDetails(creditLimitIndicator);
      return <Result {...errorDataModel} />;
    } else if (notEligibleForCR) {
      return <CreditLimitReviewError data={data} />;
    }
  };
  return (
    <>
      <SEO title="credit-limit-review" />
      <Spacing top={4}>{renderCreditReviewScreen()}</Spacing>
    </>
  );
};

export default CreditLimitReviewPage;

export const query = graphql`
  query CreditLimitReviewQuery {
    creditLimitUrls {
      creditLimitUrls {
        flow
        successUrl
        failureUrl
        successBrowseUrl
        failureBrowseUrl
        chatOnlineAgentLink
      }
    }
  }
`;
