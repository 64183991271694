/**
 * Render the plan description
 *
 * @param item
 * @return {string}
 */
import unlimitedTalktimeSms from './unlimited-talktime-sms';

const createDescription = ({ data, talktime, sms, description = null }) => {
  const hasPlanDetails = data && talktime && sms;

  let renderDescription = description;

  if (hasPlanDetails) {
    const renderData = `${data.quantity}${data.unit} Data`.replace(/\s/, '');

    renderDescription = `${renderData}, ${unlimitedTalktimeSms({
      talktime,
      sms
    })}`;
  }

  return renderDescription;
};

export default createDescription;
