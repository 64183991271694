import { KeyValue } from './common.types';

export interface SMSAndTalkTime {
  sms: {
    unit?: string;
    quantity: string;
  };
  talktime: {
    unit?: string;
    quantity: string;
  };
}

export interface PlanState extends SMSAndTalkTime {
  readonly basePriceSchemaId: string;
  data: {
    unit?: string;
    quantity: string;
  };
  enabledOnPlanCatalog: boolean;
  enabledOnPhoneCatalog: boolean;
  enabledOnPhoneDetail: boolean;
  groupName: string;
  mecId: string;
  monthlyCharges: string;
  discountedPrice: string;
  discountedLiner: string;
  phoneCost: string;
  planDescSubLiner?: string;
  planId: string;
  planName: string;
  planRelId: string;
  ribbon?: string;
  selected: boolean;
  tags?: string[];
}

export interface PlanGroup {
  defaultPlan?: string;
  groupName: string;
  groupDisplayName: string;
  shortDescription: string;
  segment: string[];
  enabledOnPhoneCatalog?: boolean;
}
export interface Plan extends KeyValue {
  groupName: string;
  enabledOnPlanCatalog: boolean;
  enabledOnPhoneCatalog: boolean;
  enabledOnPhoneDetail: boolean;
  selected: boolean;
  mecId: string;
  planId: string;
  basePriceSchemaId: string;
  planRelId: string;
  planName: string;
  monthlyCharges: string | number;
  discountedPrice?: string;
  discountedLiner?: string;
  phoneCost: string;
  ribbon: null;
  planDescSubLiner: string;
  mainlineGroup: string;
  tags: string[];
  data?: {
    unit: string;
    quantity: number;
  };
  selectedMonthlyTerm?: string;
}

export enum FLOW {
  RES = 'res',
  CIS = 'cis'
}
