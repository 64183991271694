/* istanbul ignore file */
const CONSTANTS = {
  REQUEST_STATUS: {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    SUCCEEDED: 'SUCCEEDED',
    FAILED: 'FAILED'
  },
  FULFILMENT_TYPES: {
    POP_STATION: 'popstation',
    DOOR_STEP_DELIVERY: 'doorstep-delivery',
    COLLECT_AT_STORE: 'collect-at-store',
    PARTNER_DELIVERY: 'partner-delivery',
    SNAIL_MAIL: 'mailbox-delivery',
    NO_FULFILMENT: 'no-fulfilment'
  },
  FULFILMENT_TYPES_AEM: {
    POP_STATION: 'pop-station',
    DOOR_STEP_DELIVERY: 'courier',
    COLLECT_AT_STORE: 'singtel-store',
    PARTNER_DELIVERY: 'partner-delivery',
    SNAIL_MAIL: 'mailbox-delivery',
    DROP_SHIPPING: 'drop-shipping'
  },
  MAPPED_FULFILMENT_TYPES: {
    popstation: 'pop-station',
    'doorstep-delivery': 'courier',
    'collect-at-store': 'singtel-store',
    'partner-delivery': 'partner-delivery',
    'mailbox-delivery': 'sms-mail'
  },
  FULFILMENT_ACTIONS: {
    SUBMIT_FULFILLMENT_ERROR: 'SUBMIT_FULFILLMENT_ERROR',
    SUBMIT_FULFILLMENT_LOADING: 'SUBMIT_FULFILLMENT_LOADING',
    SUBMIT_FULFILLMENT_SUCCESS: 'SUBMIT_FULFILLMENT_SUCCESS',
    RESET_FULFILMENT: 'RESET_FULFILMENT',
    RETRIEVE_ADDRESS_ID_LOADING: 'RETRIEVE_ADDRESS_ID_LOADING',
    RETRIEVE_ADDRESS_ID_SUCCESS: 'RETRIEVE_ADDRESS_ID_SUCCESS',
    SET_DELIVERY_MODES: 'SET_DELIVERY_MODES',
    INIT_DATA: 'FULFILMENT_INIT_DATA',
    SET_DELIVERY_EXISTING_ADDRESS: 'FULFILMENT_SET_DELIVERY_EXISTING_ADDRESS',
    SET_POPSTATION_TIMESLOTS: 'FULFILMENT_SET_POPSTATION_TIMESLOTS',
    SET_POPSTATIONS: 'FULFILMENT_SET_POPSTATIONS',
    SET_CONFIRM_ADDRESS: 'FULFILMENT_SET_CONFIRM_ADDRESS',
    SET_VALID_FLOORS_ADDRESS: 'FULFILMENT_SET_VALID_FLOORS_ADDRESS',
    SET_ADDRESS: 'FULFILMENT_SET_ADDRESS',
    SET_DELIVERY_TIMESLOTS: 'SET_DELIVERY_TIMESLOTS',
    ADD_PROMO_CODE_ERROR: 'ADD_PROMO_CODE_ERROR',
    ADD_PROMO_CODE_SUCCESS: 'ADD_PROMO_CODE_SUCCESS',
    ADD_PROMO_CODE_LOADING: 'ADD_PROMO_CODE_LOADING',
    SET_COLLECT_AT_STORE_TIME_SLOTS:
      'FULFILMENT_SET_COLLECT_AT_STORE_TIME_SLOTS',
    SET_DELIVERY_MODE: 'FULFILMENT_SET_DELIVERY_MODE',
    RESET_DELIVERY_MODE: 'FULFILMENT_RESET_DELIVERY_MODE',
    SET_PRESELECT_DELIVERY_DATA: 'SET_PRESELECT_DELIVERY_DATA',
    CLEAR_PRESELECT_DELIVERY_DATA: 'CLEAR_PRESELECT_DELIVERY_DATA',
    SET_DELIVERY_OPTIONS: 'SET_DELIVERY_OPTIONS',
    RESET_PROMO_DATA: 'RESET_PROMO_DATA',
    CONFIRM_NEW_ADDRESS: 'CONFIRM_NEW_ADDRESS',
    HAS_CONFIRMED_FULFILMENT: 'HAS_CONFIRMED_FULFILMENT'
  },
  BILLING_METHODS: {
    PAPER: 'METH_PAPER',
    NONE_PAPER: 'METH_NON_PAPER'
  },
  BILLING_TYPE: {
    EBILL: 'E_BILL',
    PAPER_BILL: 'PAPER_BILL'
  },
  BILLING_FLOW_TYPE: {
    EBILL: 'ebill',
    PAPER_BILL: 'paperbill'
  }
};

export const PICKUP_TYPE_TO_KEY = {
  DOORSTEP_DELVIERY: 'doorstep-delivery',
  PARTNER_DELIVERY: 'partner-delivery',
  POPSTATION: 'popstation',
  COLLECT_AT_STORE: 'collect-at-store'
};

export const PICKUP_TYPE_API_TO_UI = {
  'drop-shipping': PICKUP_TYPE_TO_KEY.PARTNER_DELIVERY,
  'pop-station': PICKUP_TYPE_TO_KEY.POPSTATION,
  courier: PICKUP_TYPE_TO_KEY.DOORSTEP_DELVIERY
};

export const MCSS_DELIVERY_METHOD_TO_AEM = {
  'Courier To Home (PoPStation)': CONSTANTS.FULFILMENT_TYPES_AEM.POP_STATION,
  'Courier to Home': CONSTANTS.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY,
  'Drop Shipping': CONSTANTS.FULFILMENT_TYPES_AEM.DROP_SHIPPING
};

export const AEM_DELIVERY_METHOD_TO_TEXT = {
  courier: 'Doorstep delivery',
  'pop-station': 'POPStation',
  'drop-shipping': 'Delivery via partners',
  'singtel-store': 'Collect at store',
  'sms-mail': 'Mailbox delivery'
};

export default {
  ...CONSTANTS,
  PICKUP_TYPE_API_TO_UI,
  PICKUP_TYPE_TO_KEY,
  MCSS_DELIVERY_METHOD_TO_AEM,
  AEM_DELIVERY_METHOD_TO_TEXT
};
