import { useState, useEffect, useRef } from 'react';
import { APP_TYPE_ANY } from '../../types/common.types';

export const useToggleReadMore = (): {
  toggleReadMore: boolean;
  refItem: APP_TYPE_ANY;
} => {
  const [toggleReadMore, setToggleReadMore] = useState(true);
  const refItem = useRef<HTMLElement>();

  const setAddonLearnMore = () => {
    setToggleReadMore(
      refItem.current?.scrollHeight > refItem.current?.clientHeight
    );
  };

  useEffect(() => {
    setAddonLearnMore();
    window.addEventListener('resize', setAddonLearnMore);

    return () => {
      window.removeEventListener('resize', setAddonLearnMore);
    };
  }, []);

  return { toggleReadMore, refItem };
};
