import { FORM_MAPPING_ACTIONS } from './helper';
import {
  FormDispatch,
  FormReducerState,
  FieldObject,
  FieldValueConfig
} from '../../types/registrationCheckout';

export const checkoutInitialState = {
  formInputsMapping: {},
  formValues: {},
  enableReinitialize: true,
  addNewAddressModalOpen: false,
  valuesChangeOnDemand: []
};

export const checkoutFlowReducer = (
  state = checkoutInitialState,
  action: FormDispatch
): FormReducerState => {
  switch (action.type) {
    case FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE:
      return {
        ...state,
        ...(action.payload as FieldObject),
        enableReinitialize: true
      };

    case FORM_MAPPING_ACTIONS.SET_POSTAL_LOADING: {
      const postalCodeConfig = state.formInputsMapping['postal'];

      const updatedPostalCodeConfig = {
        ...postalCodeConfig,
        loading: action.payload,
        readOnly: action.payload
      };

      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          postal: updatedPostalCodeConfig
        }
      };
    }

    case FORM_MAPPING_ACTIONS.SET_POSTAL_CODE_ADDRESS: {
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          postalAddress: action.payload
        }
      };
    }

    case FORM_MAPPING_ACTIONS.SET_POSTAL_AND_REMAINING: {
      const {
        formValues,
        formInputsMapping,
        enableReinitialize
      } = action.payload as FieldObject;
      const updatedFormMapping = formInputsMapping as FieldObject;
      const updatedFormValues = formValues as FieldObject;

      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          ...updatedFormMapping
        },
        formValues: updatedFormValues,
        enableReinitialize: enableReinitialize
      };
    }

    case FORM_MAPPING_ACTIONS.SET_DOCUMENTS_GROUP: {
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          documents: action.payload
        }
      };
    }
    case FORM_MAPPING_ACTIONS.SET_STAFF_DOC_GROUP: {
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          staffPass: action.payload
        }
      };
    }
    case FORM_MAPPING_ACTIONS.SET_KYC_DOC_GROUP: {
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          kyc: action.payload
        }
      };
    }
    case FORM_MAPPING_ACTIONS.SET_PROOF_GROUP: {
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          proof: action.payload
        }
      };
    }

    case FORM_MAPPING_ACTIONS.SET_FORM_VALUES:
      return {
        ...state,
        formValues: { ...state.formValues, ...(action.payload as FieldObject) }
      };

    case FORM_MAPPING_ACTIONS.SET_FORM_INPUT_MAPPING:
      return {
        ...state,
        formInputsMapping: {
          ...state.formInputsMapping,
          ...(action.payload as FieldObject)
        }
      };

    case FORM_MAPPING_ACTIONS.TOGGLE_ADD_NEW_ADDRESS_MODAL:
      return {
        ...state,
        addNewAddressModalOpen: action.payload as boolean
      };

    case FORM_MAPPING_ACTIONS.SET_VALUES_CHANGE_ON_DEMAND:
      return {
        ...state,
        valuesChangeOnDemand: action.payload as FieldValueConfig[]
      };

    default:
      return state;
  }
};
