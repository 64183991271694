import React, { ReactNode, useEffect, useState } from 'react';
import { setUrlParams } from '@lux/helpers';
import { navigate } from 'gatsby';
import {
  Accordion,
  Button,
  Column,
  Divider,
  Notification,
  Row,
  Spacing,
  Table,
  Text
} from '@dls/web';
import {
  NOTICE_OF_ASSESSMENT_TABLE_COLUMNS,
  CPF_CONTRIBUTION_HISTORY_TABLE_COLUMNS,
  CREDIT_LIMIT_INDICATOR
} from './constants';
import { trans as t } from '../../helpers/localisation';
import {
  CREDIT_LIMIT_FLOWTYPE,
  CREDIT_LIMIT_QUERY_PARAMS
} from '../../constants/credit';
import { mapNoaData, mapCpfData } from '../../helpers/credit-limit';
import { api } from '@detox/actions';
import { FlowType } from './types';
import { navigation } from '../../middlewares/navigation-constants';
import { CP_FLOW_TYPE } from '../../constants/index';
import { isValidUrl } from '../../helpers/common';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';

interface ApiCallParams {
  redirectUrl: string;
  data: string;
  flowType?: FlowType;
}

type NotificationContentType = {
  type: string;
  content: APP_TYPE_ANY;
  title?: APP_TYPE_ANY;
  action?: APP_TYPE_ANY;
};

const CreditLimitRetrieved = ({
  token,
  flowUrls,
  flowType
}: KeyValue): ReactNode => {
  const [creditData, setCreditData] = useState<APP_TYPE_ANY>({});
  const { apiMyinfoCustomerInfo, apiCreditLimitReview } = api.mcss;
  const [accordionIndex, setAccordionIndex] = useState<number | string>(-1);
  const [notificationContent, setNotificationContent] = useState<
    NotificationContentType
  >();
  const [submitRetryAttempts, setSubmitRetryAttempts] = useState<number>(3);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        const params: ApiCallParams = {
          redirectUrl: `${window.location.origin}${window.location.pathname}`,
          data: token,
          flowType: CP_FLOW_TYPE
        };
        const customerInfoRes = await apiMyinfoCustomerInfo(
          params as APP_TYPE_ANY
        );

        if (customerInfoRes?.ImplCustomerInfoResponse) {
          setNotificationContent({
            type: 'info',
            content: t('CR_SUCCESSFULLY_RETRIEVED')
          });
          setCreditData(customerInfoRes.ImplCustomerInfoResponse);
        } else {
          setNotificationContent({
            type: 'alert',
            content: t('CR_RETRIEVE_ERROR')
          });
        }
      } else {
        console.log('Token not found in the URL.');
      }
    };
    fetchData();
  }, []);

  const accordionItems = [
    {
      key: 'noa',
      title: t('CR_NOTICE_OF_ASSESSMENT'),
      columns: NOTICE_OF_ASSESSMENT_TABLE_COLUMNS,
      data: [mapNoaData(creditData?.noaBasic)]
    },
    {
      key: 'cpfcontributions',
      title: t('CR_CPF_CONTRIBUTION_HISTORY'),
      columns: CPF_CONTRIBUTION_HISTORY_TABLE_COLUMNS,
      data: mapCpfData(creditData?.cpfcontributions)
    }
  ];

  const handleAccordionClick = (e: Event, value: string) => {
    setAccordionIndex(accordionIndex === value ? -1 : value);
  };

  const handleCreditLimitError = () => {
    if (submitRetryAttempts - 1) {
      setNotificationContent({
        type: 'alert',
        content: t('CR_CUSTOMER_INFO_ERROR')
      });
    } else {
      setNotificationContent({
        type: 'alert',
        content: t('CR_CUSTOMER_INFO_ERROR_REDIRECT'),
        title: t('GENERIC_ERROR_TITLE'),
        action: {
          type: 'button',
          text: t('CR_RETRIEVE_MYINFO'),
          onClick: () => {
            navigate(`?flow=${flowType}`);
          }
        }
      });
    }
    setSubmitRetryAttempts(submitRetryAttempts - 1);
  };

  const handleSubmitCreditLimit = async () => {
    const { transactionId, ...myInfoUserData } = creditData;
    let result;
    try {
      result = await apiCreditLimitReview({
        myInfoUserData,
        flowType: CP_FLOW_TYPE
      });
    } catch (e) {
      // no handling
    }

    const creditLimitIndicator = result?.creditLimitIndicator;
    if (!creditLimitIndicator) {
      handleCreditLimitError();
      return;
    }

    if (
      flowType === CREDIT_LIMIT_FLOWTYPE.DEFAULT ||
      creditLimitIndicator !== CREDIT_LIMIT_INDICATOR.INCREASED
    ) {
      return navigate(
        `/${navigation.CREDIT_LIMIT_REVIEW}?${CREDIT_LIMIT_QUERY_PARAMS.creditLimitIndicator}=${result.creditLimitIndicator}`
      );
    } else {
      switch (creditLimitIndicator) {
        case CREDIT_LIMIT_INDICATOR.INCREASED: {
          // without braces this would be not lexical scope declaration
          const url = setUrlParams(flowUrls.successUrl, {
            creditLimitIndicator: result.creditLimitIndicator
          });
          if (isValidUrl(url)) {
            window.location.replace(url);
          }
          break;
        }
        default:
          return navigate(
            `/${navigation.CREDIT_LIMIT_REVIEW}?${CREDIT_LIMIT_QUERY_PARAMS.notEligibleForCR}=true`
          );
      }
    }
  };

  const renderNotification = () => {
    return (
      <Row start="xs">
        <Column noGutter xs={6}>
          <Spacing top={1} bottom={5}>
            <Notification
              type={notificationContent.type}
              title={notificationContent.title}
              content={notificationContent.content}
              action={notificationContent.action}
            />
          </Spacing>
        </Column>
      </Row>
    );
  };

  return (
    <Row center="xs">
      <Column xs={10} md={10} noGutter>
        <Row start="xs">
          <Column noGutter xs={12}>
            <Spacing bottom={3}>
              <Text type="sectionTitle">
                {t('SINGTEL_CREDIT_LIMIT_REVIEW')}
              </Text>
            </Spacing>
          </Column>
        </Row>

        {notificationContent && renderNotification()}

        <Row start="xs">
          <Column noGutter xs={12}>
            <Spacing bottom={1}>
              <Text type="boldBody">
                {t('CR_NRIC_FIN')} {creditData?.uinfin?.value}
              </Text>
            </Spacing>
            <Divider />
          </Column>
        </Row>

        {accordionItems.map((item, idx) => (
          <Row start="xs" key={item.key}>
            <Column noGutter xs={12}>
              <Accordion
                title={item.title}
                desc={<Table columns={item.columns} data={item.data} />}
                selected={accordionIndex === idx}
                value={idx}
                onClick={handleAccordionClick}
              />
            </Column>
          </Row>
        ))}

        {submitRetryAttempts > 0 && (
          <Row start="xs">
            <Spacing top={7} bottom={10}>
              <Button
                onClick={handleSubmitCreditLimit}
                data-testid="cr-submit-cta"
              >
                {t('CR_SUBMIT_CTA')}
              </Button>
            </Spacing>
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default CreditLimitRetrieved;
