import { fulfillmentDocumentUploadActions } from '@detox/actions';
import { UploadDocumentNeededPayload } from '../../types/registrationCheckout';

export interface UploadDocumentNeededState {
  isLoading: boolean;
  isError: boolean;
  uploadDocumentNeeded?: UploadDocumentNeededPayload;
}

const {
  DOCUMENT_UPLOAD_NEEDED_LOADING,
  DOCUMENT_UPLOAD_NEEDED_SUCCESS,
  DOCUMENT_UPLOAD_NEEDED_FAILURE
} = fulfillmentDocumentUploadActions.UPLOAD_DOCUMENTS;

const initialValues = {
  isLoading: false,
  isError: false
};

export const uploadDocumentNeeded = (
  state: UploadDocumentNeededState = initialValues,
  action
): UploadDocumentNeededState => {
  switch (action.type) {
    case DOCUMENT_UPLOAD_NEEDED_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case DOCUMENT_UPLOAD_NEEDED_SUCCESS: {
      return {
        ...state,
        uploadDocumentNeeded: action.value,
        isLoading: false,
        isError: false
      };
    }
    case DOCUMENT_UPLOAD_NEEDED_FAILURE: {
      return {
        isError: true,
        isLoading: false
      };
    }
    default:
      return state;
  }
};

export default uploadDocumentNeeded;
