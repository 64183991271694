/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { AppState, ErrorMessage, KeyValue } from '../../types/common.types';
import { navigate } from 'gatsby';
import { navigation } from '../../middlewares/navigation-constants';
import { Modal, Text, Button, Spacing } from '@dls/web';
import { useDispatch, useSelector } from 'react-redux';
import slugify from '../../helpers/slugify';
import { cartActions, authActions } from '@detox/actions';
import { isTabAmendedFlow } from '../../helpers/common';
import { OrderSummaryState } from '../../reducers/orderSummary';
import { clearBuyFlowData } from '../../reducers/payment';
import { trans as t } from '../../helpers/localisation';
import { ButtonWrapper } from '../../components/Common';

type ErrorState = {
  hasError: boolean;
  errorMessage: string;
  errorTitle: string;
  redirectNeeded?: boolean;
  redirectUrl?: string;
  btnText?: string;
  errorStep?: string;
  errorInfo?: KeyValue;
  extraContactLink?: boolean;
  allowReload?: boolean;
};

export const getErrorInfo = (
  stateErrors: KeyValue,
  configuredErrors?: ErrorMessage[]
): KeyValue => {
  const commonProps = {
    redirectNeeded: stateErrors.redirectNeeded ?? true,
    redirectUrl: stateErrors.redirectUrl ?? '',
    btnText: stateErrors.btnText || t('OKAY_GOT_IT'),
    errorStep: stateErrors.errorStep || '',
    extraContactLink: stateErrors.extraContactLink ?? false
  };

  if (!stateErrors.errorInfo || !configuredErrors) {
    return {
      errorMessage: stateErrors.errorMessage || t('GENERIC_ERROR_MESSAGE'),
      errorTitle: stateErrors.errorTitle || t('GENERIC_ERROR_TITLE'),
      ...commonProps
    };
  }

  const apiErrorCodes = Array.isArray(stateErrors.errorInfo)
    ? stateErrors.errorInfo.map(eInfo => eInfo?.errorCode || '')
    : [stateErrors.errorInfo?.errorCode];
  const filteredApiErrorCodes = apiErrorCodes.filter(Boolean) || [];

  const defaultError = configuredErrors?.find(
    ({ errorCode }) => errorCode === 'GENERIC_ERROR'
  );

  const configuredAPIError =
    configuredErrors?.find(configuredError => {
      return filteredApiErrorCodes?.find(filteredApiErrorCode => {
        return configuredError?.errorCode === filteredApiErrorCode;
      });
    }) || defaultError;

  return {
    errorMessage: configuredAPIError?.description || t('GENERIC_ERROR_MESSAGE'),
    errorTitle: configuredAPIError?.title || t('GENERIC_ERROR_TITLE'),
    ...commonProps
  };
};

export const useErrorHandler = ({
  states = [],
  configs = { isPopup: true },
  callback,
  onCloseErrorPopUp,
  configuredErrors,
  shouldRedirect = true
}: {
  states: AppState[];
  callback?: () => void;
  configs?: { isPopup: boolean };
  onCloseErrorPopUp?: (errorStep: string) => void;
  configuredErrors?: ErrorMessage[];
  shouldRedirect?: boolean;
}): { renderError: () => ReactElement } => {
  const { plan, cart, productOrder, orderSummary } = useSelector(
    (state: KeyValue) => {
      return {
        plan: state.plan,
        cart: state.cart,
        productOrder: state.order?.productOrder || {},
        orderSummary: state.orderSummary as OrderSummaryState
      };
    }
  );
  const { isPopup } = configs;
  const dispatch = useDispatch();
  const [errorState, setErrorState] = useState<ErrorState>({
    hasError: false,
    errorMessage: '',
    errorTitle: '',
    redirectNeeded: true,
    redirectUrl: '',
    btnText: '',
    errorStep: '',
    extraContactLink: false,
    errorInfo: null,
    allowReload: false
  });
  useEffect(() => {
    const stateErrors = states.find(
      state => state?.isError || state?.error || state?.hasError
    );

    const concurrentTabErrorRes = states.find(
      state => state?.paymentErrorResponse
    );

    if (stateErrors) {
      const errorInfo = concurrentTabErrorRes?.paymentErrorResponse?.ErrorInfo;
      if (errorInfo && isTabAmendedFlow(errorInfo)) {
        setErrorState({
          hasError: true,
          errorMessage: t('ORDER_AMENDED_IN_OTHER_FLOW') as string,
          errorTitle: t('PRODUCT_CONFIGURATION_ERROR_TITLE') as string,
          redirectNeeded: true,
          btnText: stateErrors.btnText || t('OKAY_GOT_IT'),
          allowReload: stateErrors.allowReload
        });
      } else {
        const errorInfo = getErrorInfo(stateErrors, configuredErrors);

        setErrorState({
          hasError: true,
          errorMessage: errorInfo.errorMessage,
          errorTitle: errorInfo.errorTitle,
          redirectNeeded: errorInfo.redirectNeeded,
          redirectUrl: errorInfo.redirectUrl,
          btnText: errorInfo.btnText,
          extraContactLink: errorInfo.extraContactLink,
          errorStep: errorInfo.errorStep,
          allowReload: stateErrors.allowReload
        });
      }
      if (callback) {
        resetErrorState();
        callback();
      } else {
        handleError();
      }
    }
  }, states);

  useEffect(() => {
    const errorInfo = orderSummary?.paymentErrorResponse?.ErrorInfo;

    if (errorInfo && isTabAmendedFlow(errorInfo)) {
      window.localStorage.setItem('OrderInMultiTab', JSON.stringify(errorInfo));
    }
  }, [orderSummary?.paymentErrorResponse]);

  const handleError = () => {
    //todo: might need to add logic to go to error page in case of specific error code.
    if (!isPopup) {
      navigate(`/${navigation.ERROR}`);
      resetErrorState();
    }
  };

  const resetErrorState = () => {
    setErrorState({ hasError: false, errorMessage: '', errorTitle: '' });
    states.forEach(state => {
      state.isError = false;
      state.error = false;
    });
  };

  const handleEmptyCart = async productOrder => {
    if (productOrder) {
      await dispatch(
        cartActions.emptyCart({
          customerId: productOrder?.service?.customerId,
          productOrderId: productOrder?.productOrderId,
          productOrderItemId: productOrder?.productOrderItemId
        })
      );
      dispatch({ type: 'CLEAR_DELIVERY' });
    }
  };

  const onCloseErrorPopup = async (isBtnClick = false) => {
    // other logic for error handling
    resetErrorState();
    if (onCloseErrorPopUp) {
      onCloseErrorPopUp(errorState.errorStep);
    }
    if (!errorState.redirectNeeded || !shouldRedirect) {
      return;
    }

    let path = '';
    if (errorState?.redirectUrl && isBtnClick) {
      path = errorState.redirectUrl;
    } else if (errorState?.errorMessage === t('ORDER_AMENDED_IN_OTHER_FLOW')) {
      await handleEmptyCart(productOrder);
      const errorInfo = orderSummary?.paymentErrorResponse?.ErrorInfo;
      window.localStorage.setItem('OrderInMultiTab', JSON.stringify(errorInfo));
      path = `/${navigation.EMPTY_SHOPPING_CART}`;
    } else {
      const hasHandset = cart?.cartOrder?.device;
      path = hasHandset
        ? '/'
        : `/plans/?group=${slugify(plan?.selectedPlan?.groupName)}`;
      dispatch(authActions.deleteWorryFree());
      dispatch(clearBuyFlowData());
    }

    return navigate(path, { replace: true });
  };

  const renderReloadButton = () => {
    if (!errorState.allowReload) return null;
    return (
      <>
        <Button
          data-testid="reload-button"
          primary
          onClick={() => {
            window.location.reload();
          }}
        >
          {t('RELOAD_TEXT')}
        </Button>
      </>
    );
  };

  const renderError = () => {
    if (isPopup && errorState.hasError) {
      return (
        <Modal
          visible={true}
          title={errorState.errorTitle}
          onClose={() => onCloseErrorPopup(false)}
          backdropClosable={false}
        >
          <Modal.Content>
            <Text type="body">{errorState.errorMessage}</Text>

            {/* TODO: To be enhanced to handle multiple links and actions
                hard-coded link is intentional as this is the only scenario as of now
              */}
            {errorState.extraContactLink && (
              <Spacing top={2}>
                <Button
                  data-testid="open-contact-popup"
                  fullWidth
                  onClick={() => {
                    window.open(
                      'https://api.whatsapp.com/send?phone=6590181688&text=WAMOBSOPIPPNEWONLINE',
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  {errorState.btnText}
                </Button>
              </Spacing>
            )}

            {!errorState.extraContactLink && (
              <Spacing responsive={false} top={3}>
                <ButtonWrapper>
                  <Button
                    data-testid="close-error-popup"
                    secondary
                    onClick={() => onCloseErrorPopup(true)}
                  >
                    {errorState.btnText}
                  </Button>
                  {renderReloadButton()}
                </ButtonWrapper>
              </Spacing>
            )}
          </Modal.Content>
        </Modal>
      );
    }
    return null;
  };

  return { renderError };
};
