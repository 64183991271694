import { keyframes } from 'styled-components';

/**
 * Animates slight shrinkage and expansion of a component
 */
const bounce = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
`;

const animations = {
  bounce
};

export default animations;
