/**
 * Find the component in the AEM Page from the name provided
 *
 * @param {Object} allAemPages
 * @param {String} name
 * @returns {Object}
 */
const getAemComponentByName = (allAemPages, name) =>
  allAemPages?.edges[0] &&
  allAemPages?.edges[0].node.components.find(
    component => component.__typename === name
  );

export const getAemComponentByKey = (allAemPages, key) => {
  const comp =
    allAemPages?.edges[0] &&
    allAemPages?.edges[0].node.components.find(component => key in component);

  return comp ? comp[key] : null;
};

export default getAemComponentByName;
