import React from 'react';
import { Grid, Row, Column, Text, Button, Spacing } from '@dls/web';
import { navigate } from 'gatsby';

const EmptyCart = props => {
  return (
    <Grid>
      <Row data-testid="empty-cart">
        <Column xs={12} md={7}>
          <Text type="pageTitle">Your cart</Text>
          <Spacing top={3} bottom={2}>
            <Text type="boldBody">Nothing in your cart yet?</Text>
            <Text type="body">
              Start browsing for mobile devices, accessories and more.
            </Text>
          </Spacing>
        </Column>
      </Row>
      <Row between="md" data-testid="empty-cart">
        <Column xs={12} md={3}>
          <Button
            data-testid="btn-nav"
            tabIndex={0}
            fullWidth
            onClick={() => navigate(props.shopUrl)}
          >
            Shop now
          </Button>
        </Column>
      </Row>
    </Grid>
  );
};

export default EmptyCart;
