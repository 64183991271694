import { KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '../constants/actions';

interface UserPromotionsState {
  isLoading?: boolean;
  isError?: boolean;
  products?: KeyValue[];
  isCatalogLoading: boolean;
  isCatalogError: boolean;
  catalogSubscriberIdentifier: string | null;
  catalogData: KeyValue | null;
}

const initialCatalogState: Pick<
  UserPromotionsState,
  | 'isCatalogLoading'
  | 'isCatalogError'
  | 'catalogSubscriberIdentifier'
  | 'catalogData'
> = {
  isCatalogLoading: false,
  isCatalogError: false,
  catalogSubscriberIdentifier: null,
  catalogData: null
};

const initialState: UserPromotionsState = {
  isError: false,
  isLoading: false,
  products: [],
  ...initialCatalogState
};

type UserPromotionsActions = {
  type: string;
  value?: KeyValue;
};

type CatalogBannerActions = {
  type: string;
  payload: {
    subscriberIdentifier: string;
    error?: Error;
    data?: KeyValue;
  };
};

export const UserPromotionsReducer = (
  state: UserPromotionsState = initialState,
  action: UserPromotionsActions | CatalogBannerActions
) => {
  switch (action.type) {
    case ACTION_TYPES.USER_PROMOTIONS.FETCH_USER_MAIN_PRODUCTS_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.USER_PROMOTIONS.FETCH_USER_MAIN_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        products: (action as UserPromotionsActions).value
      };
    }
    case ACTION_TYPES.USER_PROMOTIONS.FETCH_USER_MAIN_PRODUCTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }

    case ACTION_TYPES.CATALOG_BANNER.LOAD_CATALOG_BANNER_REQUEST: {
      const { subscriberIdentifier } = (action as CatalogBannerActions).payload;
      return {
        ...state,
        isCatalogLoading: true,
        isCatalogError: initialCatalogState.isCatalogError,
        catalogSubscriberIdentifier: subscriberIdentifier
      };
    }
    case ACTION_TYPES.CATALOG_BANNER.LOAD_CATALOG_BANNER_SUCCESS: {
      const {
        subscriberIdentifier,
        data
      } = (action as CatalogBannerActions).payload;
      return state.catalogSubscriberIdentifier === subscriberIdentifier
        ? {
            ...state,
            isCatalogLoading: false,
            isCatalogError: initialCatalogState.isCatalogError,
            catalogData: data
          }
        : state;
    }
    case ACTION_TYPES.CATALOG_BANNER.LOAD_CATALOG_BANNER_ERROR: {
      const {
        subscriberIdentifier,
        error
      } = (action as CatalogBannerActions).payload;
      return state.catalogSubscriberIdentifier === subscriberIdentifier
        ? {
            ...state,
            isCatalogLoading: false,
            isCatalogError: true,
            catalogData: initialCatalogState.catalogData
          }
        : state;
    }
  }
  return state;
};
