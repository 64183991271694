import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Modal, Text, Spacing, Button, withTheme, useDevice } from '@dls/web';

const ButtonGroup = withTheme(styled.div`
  ${({ coreTheme }) => css`
    @media (min-width: ${coreTheme.brk_md}) {
      display: flex;
      justify-content: flex-start;
    }
  `}
`);

const ErrorModal = props => {
  const {
    open,
    errorMessage,
    onClose,
    onCtaClick,
    onSecondaryCtaClick
  } = props;
  const { isMobile } = useDevice();
  return (
    <Modal
      visible={open}
      title={
        errorMessage.serviceNumber
          ? `${errorMessage.title} ${errorMessage.serviceNumber}`
          : errorMessage.title
      }
      onClose={onClose}
      wide
    >
      <Modal.Content>
        <Spacing bottom={3}>
          <Text
            type="body"
            dangerouslySetInnerHTML={{ __html: errorMessage.text }}
          ></Text>
        </Spacing>
        <ButtonGroup>
          {errorMessage.ctaText && (
            <Button fullWidth={isMobile} primary onClick={onCtaClick}>
              {errorMessage.ctaText}
            </Button>
          )}
          <Spacing left={isMobile ? 0 : 2} top={isMobile ? 3 : 0}>
            {errorMessage.secondaryCtaText && (
              <Button
                secondary
                fullWidth={isMobile}
                onClick={onSecondaryCtaClick}
              >
                {errorMessage.secondaryCtaText}
              </Button>
            )}
          </Spacing>
        </ButtonGroup>
      </Modal.Content>
    </Modal>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool,
  errorMessage: PropTypes.shape({
    title: PropTypes.string,
    serviceNumber: PropTypes.string,
    text: PropTypes.string,
    ctaText: PropTypes.string,
    secondaryCtaText: PropTypes.string
  }),
  onClose: PropTypes.func,
  onCtaClick: PropTypes.func,
  onSecondaryCtaClick: PropTypes.func
};

export default ErrorModal;
