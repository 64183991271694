import React from 'react';
import { Button, Modal, Spacing, Text, TextLink, BulletList } from '@dls/web';
import useTranslate from '../../hooks/useTranslation';
import { PRODUCT } from '../../constants';
import {
  tncModalStandardAgreement,
  tncModalGeneralTermsLink,
  tncModalPromotionalTermsLink
} from '../../config/links';
import { APP_TYPE_ANY } from '../../types/common.types';

interface TncProps {
  isModalOpen: boolean;
  setIsModalOpen: APP_TYPE_ANY;
  tncType?: string;
  setTncType?: APP_TYPE_ANY;
}

export const TermsAndConditions = (props: TncProps): JSX.Element => {
  const { t } = useTranslate();

  const { isModalOpen, setIsModalOpen, tncType, setTncType } = props;

  const onCloseClick = () => {
    setIsModalOpen(false);
    setTncType && setTncType('');
  };

  const openTncLink = () => {
    const url = tncModalStandardAgreement;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openGeneralTnC = () => {
    const url = tncModalGeneralTermsLink;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openPromotionalTnC = () => {
    const url = tncModalPromotionalTermsLink;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderModalContent = () => {
    switch (true) {
      case tncType !== PRODUCT.FOOTER_LINER.TNC_TYPE_GROUP_PLANS_XO:
        return (
          <Modal.Content>
            <Spacing bottom={2}>
              <Text>{t('TNC_MODAL_FIRST_PARA')}</Text>
            </Spacing>
            <Spacing bottom={2}>
              <Text>{t('TNC_MODAL_SECOND_PARA')}</Text>
            </Spacing>
            <TextLink onClick={() => openTncLink()} data-testid="tnc-link">
              {t('TNC_MODAL_VIEW_FULL')}
            </TextLink>
          </Modal.Content>
        );
      case tncType === PRODUCT.FOOTER_LINER.TNC_TYPE_GROUP_PLANS_XO:
        return (
          <Modal.Content>
            <Spacing bottom={2}>
              <BulletList>
                {t('TNC_MODAL_FIRST_BULLET')}
                <TextLink
                  onClick={() => openGeneralTnC()}
                  data-testid="general-tnc-link"
                >
                  {t('TNC_MODAL_FIRST_BULLET_HERE')}
                </TextLink>
              </BulletList>
              <BulletList>
                {t('TNC_MODAL_SECOND_BULLET')}
                <TextLink
                  onClick={() => openPromotionalTnC()}
                  data-testid="promotional-tnc-link"
                >
                  {t('TNC_MODAL_SECOND_BULLET_HERE')}
                </TextLink>
              </BulletList>
            </Spacing>
            <Button
              secondary
              onClick={() => onCloseClick()}
              data-testid="close-tnc-modal-btn"
            >
              {t('TNC_MODAL_OKAY_GOT_IT')}
            </Button>
          </Modal.Content>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      data-testid="tnc-modal"
      visible={isModalOpen}
      title={t('TNC_MODAL_TITLE')}
      onClose={() => onCloseClick()}
      scrollable
    >
      {renderModalContent()}
    </Modal>
  );
};

export default TermsAndConditions;
