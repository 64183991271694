import React, { ReactElement } from 'react';
import { Spacing, Text, Button, Layout, Card, Divider } from '@dls/web';
import { trans as t } from '../../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import formatPrice from '../../../helpers/formatPrice';
import {
  StyledCardContent,
  StyledDiv,
  StyledSpacing,
  StyledStackSpacing
} from '../addonStyles';

interface TProps extends KeyValue {
  group: KeyValue;
  groupName: string;
  onSimOnlyAdd: (addon: APP_TYPE_ANY) => void;
  onSimOnlyRemove: (addon: APP_TYPE_ANY) => void | Promise<void>;
}

const AddonsSimOnlyItems = (props: TProps): ReactElement => {
  const { group, groupName, onSimOnlyAdd, onSimOnlyRemove } = props;

  const { isSelected, smallestValue, selectedAddonData } = group;

  const btnProps = isSelected
    ? {
        secondary: false,
        loading: 'success',
        text: 'Success',
        onClickEvent: () => {
          onSimOnlyRemove(selectedAddonData);
        },
        'data-testid': 'remove-' + groupName
      }
    : {
        text: t('ADD'),
        onClickEvent: () => {
          onSimOnlyAdd(group);
        },
        'data-testid': 'add-' + groupName
      };

  return (
    <Card shadow="sm" radius="sm" padding="sm">
      <StyledCardContent>
        <StyledSpacing responsive={false}>
          <StyledDiv>
            <Card.Title>
              <Text type="header">{groupName}</Text>
              {!isSelected && (
                <Text type="smallBody">
                  {t('ADDON_MULTIPLE_CONTRACT') as string}
                </Text>
              )}
            </Card.Title>
          </StyledDiv>

          <Spacing top={2}>
            <Divider />
            <Card.Footer
              rightContent={
                <div onClick={btnProps.onClickEvent}>
                  <Button secondary {...btnProps} />
                </div>
              }
            >
              <StyledStackSpacing>
                <Layout.Stack align="left" alignY="middle" space={0}>
                  {isSelected ? (
                    <>
                      <Text type="body">{`${formatPrice(
                        selectedAddonData.price
                      )}/mth`}</Text>
                      <Text type="body">{selectedAddonData.quantity}</Text>
                    </>
                  ) : (
                    <Text type="body">{smallestValue}</Text>
                  )}
                </Layout.Stack>
              </StyledStackSpacing>
            </Card.Footer>
          </Spacing>
        </StyledSpacing>
      </StyledCardContent>
    </Card>
  );
};

export default AddonsSimOnlyItems;
