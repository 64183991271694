import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUrlParams } from '@lux/helpers';
import { OrderConfirmation } from '@common-modules/shopping-cart';
import { Spacing, Text, Row, Column, useDevice } from '@dls/web';
import { api, rrpCartActions } from '@detox/actions';

import SEO from '../../../components/SEO';
import { trans as t } from '../../../helpers/localisation';
import { useErrorHandler } from '../../../hooks/useErrorHandler';

import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';

import CONSTANTS from '../../../constants/common';
import ORDER_CONSTANTS from '../../../constants/order';
import { ACTION_TYPES } from '../../../constants/actions';

import {
  isOrderFailed,
  isOrderSuccess
} from '../../../components/ThankYou/helper';
import { navigation } from '../../../middlewares/navigation-constants';
import { dataLayerPush } from '../../../helpers/common';
import { rrpOrderConfirmationDataLayer } from '../../../helpers/dataLayerHelpers';
import { getCatalogPath } from '../../../helpers/rrpCart';

export const OrderConfirmationPage = () => {
  const { rrpCancelMasterOrderItems } = rrpCartActions;
  const { apiVerifyPaymentApi, apiRrpSubmitOrder } = api.mcss;
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const [payment, setPayment] = useState<
    Partial<{
      status: string;
      orderStatus: APP_TYPE_ANY;
      masterOrderId: string;
      customerEmail: string;
      isError: boolean;
    }>
  >();

  const { renderError } = useErrorHandler({
    states: [{ ...payment, redirectNeeded: false }],
    onCloseErrorPopUp: () => {
      setPayment(prevState => ({
        ...prevState,
        isError: false
      }));
    }
  });
  const { pgStatus, merchantRefNum, zeroUpfront } = getUrlParams();

  const { rrpOrderSummary, rrpCart, rrpCheckout } = useSelector(
    (state: KeyValue) => ({
      rrpOrderSummary: state?.rrpOrderSummary?.orderSummary,
      rrpCart: state.rrpCart,
      rrpCheckout: state.rrpCheckout
    })
  );

  const resetRrpData = () => {
    dispatch({ type: ACTION_TYPES.RRP.RRP_CLEAR_ORDER_DATA });
    dispatch({ type: ACTION_TYPES.RRP.RESET_RRP_FLOW });
    window.sessionStorage.setItem('flow', '');
  };

  const getPaymentStatus = async () => {
    try {
      const paymentStatus = pgStatus.toLowerCase();
      let status = '';
      let orderStatus = {};
      if (paymentStatus === ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED) {
        status = ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED;
        orderStatus = getOrderStatusText(status);
        dispatch(
          rrpCancelMasterOrderItems({
            rrpMasterOrderId: rrpOrderSummary?.masterOrderId
          })
        );
      } else {
        if (zeroUpfront) {
          status = ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED;
        } else {
          const response = await apiVerifyPaymentApi({
            amount: rrpOrderSummary?.finalAmount?.afterTax,
            orderId: rrpOrderSummary?.masterOrderId,
            referenceId: merchantRefNum
          });
          const statusVal = response?.implValidateOnePayApiResponse?.status;
          status =
            ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS[statusVal] ||
            ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING;
        }
        orderStatus = getOrderStatusText(status);
        apiRrpSubmitOrder({ rrpMasterOrderId: rrpOrderSummary?.masterOrderId });
      }

      dataLayerPush(
        rrpOrderConfirmationDataLayer({
          baseParams: {
            cartItems: rrpCart?.addedItems,
            location: window.location,
            orderModel: rrpCheckout?.orderModel,
            order: { productOrderId: rrpOrderSummary?.masterOrderId }
          }
        })
      );

      setPayment({
        status,
        orderStatus,
        masterOrderId: rrpOrderSummary?.masterOrderId,
        customerEmail: rrpOrderSummary?.personalDetails?.email
      });
    } catch (e) {
      setPayment({
        isError: true
      });
    }
    resetRrpData();
  };

  useEffect(() => {
    if (rrpOrderSummary && pgStatus) {
      getPaymentStatus();
    }
  }, [pgStatus]);

  const getOrderStatusText = (status: string) => {
    switch (status) {
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_SUCCESS'),
          message: ' '
        };
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_FAILED'),
          message: t('THANK_YOU_PAGE_STATUS_FAILED_MESSAGE')
        };
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING: {
        return {
          title: t('THANK_YOU_PAGE_STATUS_PENDING'),
          message: t('THANK_YOU_PAGE_STATUS_PENDING_MESSAGE')
        };
      }
      default: {
        return {};
      }
    }
  };

  const renderCustomWhatNext = () => {
    const email = payment?.customerEmail;
    switch (payment?.status) {
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.COMPLETED: {
        return (
          <Spacing top={1}>
            <Text type="body">{t('RRP.CONFIRMATION_WhAT_NEXT', [email])}</Text>
          </Spacing>
        );
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.FAILED: {
        return (
          <Spacing top={2}>
            <Text type="body">
              {t('THANK_YOU_PAGE_FAILURE_LINER', [email])}
            </Text>
          </Spacing>
        );
      }
      case ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.PENDING: {
        return (
          <Spacing top={2}>
            <Text type="body">
              {t('THANK_YOU_PAGE_WHAT_NEXT_DESCRIPTION_1_PENDING', [email])}
            </Text>
          </Spacing>
        );
      }
      default: {
        return {};
      }
    }
  };

  const renderHelpBox = () => {
    return (
      <Row start="xs">
        <Column xs={12}>
          <Spacing top={isMobile ? 0 : 0.2} bottom={isMobile ? 0 : 2}>
            <Spacing topBottom={1}>
              <Text type="header">{t('THANK_YOU_PAGE_NEED_HELP_TITLE')}</Text>
            </Spacing>

            <Text type="body">
              {t('RRP.CONFIRMATION_NEED_HELP_TEXT')}
              <Text type="link" href={ORDER_CONSTANTS.URL.FAQ} target="_blank">
                {t('THANK_YOU_PAGE_NEED_HELP_TEXT_SUFFIX')}
              </Text>
            </Text>
          </Spacing>
        </Column>
      </Row>
    );
  };

  const renderImportantNoteContent = () => {
    return (
      <div>
        {t('RRP.CONFIRMATION_IMPT_NOTE', {
          duration: (
            <Text tag="span" type="smallBody" fontWeight="bold">
              30 minutes
            </Text>
          )
        })}
      </div>
    );
  };

  const composeThankYouPageData = () => {
    return {
      customContent: {
        WhatNext: renderCustomWhatNext(),
        HelpBox: renderHelpBox()
      },
      orderNo: payment?.masterOrderId,
      customerEmail: payment?.customerEmail,
      customerSupport: CONSTANTS.THANK_YOU_PAGE_CUSTOMER_SUPPORT,
      importantNote: {
        title: t('RRP.CONFIRMATION_IMPT_NOTE_TITLE'),
        content: renderImportantNoteContent()
      }
    };
  };

  const getActionButtonText = () => {
    let buttonText = '';
    if (isOrderFailed(payment)) {
      buttonText = t('THANK_YOU_PAGE_FAILURE_CTA_TEXT') as string;
    } else {
      buttonText = t('THANK_YOU_PAGE_CTA_TEXT') as string;
    }
    return buttonText;
  };

  return (
    <>
      {payment?.status && (
        <>
          <SEO description="" title="RRP Order Confirmation" />
          <OrderConfirmation
            data={composeThankYouPageData()}
            configs={{
              orderStatus: payment?.status,
              displayMapping: [
                {
                  itemType: CONSTANTS.ORDER_CONFIRMATION_SECTIONS.ORDER_STATUS
                },
                { itemType: CONSTANTS.ORDER_CONFIRMATION_SECTIONS.ORDER_INFO },
                {
                  itemType:
                    isOrderSuccess(payment) &&
                    CONSTANTS.ORDER_CONFIRMATION_SECTIONS.IMPORTANT_NOTE
                },
                {
                  itemType: CONSTANTS.ORDER_CONFIRMATION_SECTIONS.WHAT_NEXT
                },
                {
                  itemType:
                    isOrderFailed(payment) &&
                    CONSTANTS.ORDER_CONFIRMATION_SECTIONS.NEXT_STEP
                },
                { itemType: CONSTANTS.ORDER_CONFIRMATION_SECTIONS.BACK_HOME },
                { itemType: CONSTANTS.ORDER_CONFIRMATION_SECTIONS.HELP_BOX }
              ]
            }}
            localisation={{
              ORDER_STATUS_TITLE: payment?.orderStatus.title,
              ORDER_STATUS_BODY_TEXT: payment?.orderStatus.message,
              ORDER_INFO_SUBTITLE: t('THANK_YOU_PAGE_STATUS_SUBTITLE'),
              ACTION_BUTTON_BACK_TO_HOME: getActionButtonText(),
              ORDER_NEXT_STEP_TITLE: t('WHATS_NEXT')
            }}
            callbacks={{
              onClickBackBtn: () => {
                window.open(getCatalogPath(), '_self');
              }
            }}
          />
        </>
      )}
      {renderError()}
    </>
  );
};

export default OrderConfirmationPage;
