import { KeyValue } from '../types/common.types';
import { ACTION_TYPES } from '@detox/actions';

import ORDER_CONSTANTS from '../constants/order';

interface CreateCustomerProblemState {
  isLoading?: boolean;
  isError?: boolean;
  data?: KeyValue;
}
export const initialState: CreateCustomerProblemState = {
  isError: false,
  isLoading: false
};

type CreateCustomerProblemActions = {
  value?: KeyValue;
  type: string;
};

export const createCustomerProblemReducer = (
  state: CreateCustomerProblemState = initialState,
  action: CreateCustomerProblemActions
) => {
  switch (action.type) {
    case ACTION_TYPES.ORDER_SUMMARY.CREATE_PROBLEM_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.CREATE_PROBLEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.value
      };
    }
    case ACTION_TYPES.ORDER_SUMMARY.VERIFY_PAYMENT_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        status: ORDER_CONSTANTS.PAYMENT_VERIFY_STATUS.CREATE_PROBLEM_FAILED
      };
    }
  }
  return state;
};
