import React from 'react';
import { Grid, Row, Column, Modal, Text, Button } from '@dls/web';
import { Completed } from '@dls/assets/dist/icons/web';
import useTranslate from '../../hooks/useTranslation';
import { mask } from '../../helpers/pii-masker';

const OtpSuccessModal = props => {
  const { t } = useTranslate();
  const {
    otpSuccess,
    onClose,
    mobileNo,
    onSuccessClicked = () => {},
    otpSuccessDescription = t('VERIFICATION_OTP_SUCCESS_DESCRIPTION'),
    otpBtnText = t('VERIFICATION_OTP_SUCCESS_CLOSE')
  } = props;

  const maskNumber = mobileNo => {
    if (mobileNo) {
      const maskNumber = mask(mobileNo)
        .match(/.{1,4}/g)
        .join(' ');
      return `+65 ${maskNumber}`;
    }
  };

  return (
    <Modal visible={otpSuccess} onClose={onClose} data-testid="otpSuccessModal">
      <Grid>
        <Modal.Content>
          <Row center="xs">
            <Column>
              <Completed size="44" />
            </Column>
          </Row>
          <Row center="xs">
            <Column>
              <Text type="smallBody">
                {t('VERIFICATION_OTP_SUCCESS_MOBILE_NUMBER')}
                <Text type="boldBody"> {maskNumber(mobileNo)} </Text>
                {t('VERIFICATION_OTP_SUCCESS_SAVE')}
              </Text>
            </Column>
          </Row>
          <Row center="xs">
            <Column>
              <Text type="smallBody">{otpSuccessDescription}</Text>
            </Column>
          </Row>
          <Row center="xs">
            <Column xs={8}>
              <Button onClick={onSuccessClicked} fullWidth>
                {otpBtnText}
              </Button>
            </Column>
          </Row>
        </Modal.Content>
      </Grid>
    </Modal>
  );
};

export default OtpSuccessModal;
