import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { authActions, orderActions } from '@detox/actions';
import LoadingOverlayComponent from '../components/LoadingOverlay';
import NumberVerification from '../components/NumberVerification';
import WorryFreeOtp from '../components/WorryFreeOtp/WorryFreeOtp';
import NumberVerificationPassportHolder from '../components/NumberVerificationPassportHolder';
import { simCardType } from '../config/simcard-types';
import isFeatureFlagEnabled from '../helpers/feature-flags';

const is5GSAEnabled = isFeatureFlagEnabled('FEATURE_FLAG_ENABLE_5GSA');

export const PortInHandleOrder = ({
  contactId,
  customerId,
  handlePortInOrder,
  portInNumber,
  selectedPlan,
  selectedProduct,
  productOrder
}) => {
  const selectedAccessories = useSelector(
    state => state.accessory?.selectedAccessories
  );
  useEffect(() => {
    async function handleGomoNumberPortIn() {
      const { planId, planRelId, basePriceSchemaId } = selectedPlan;
      const {
        productOrderId,
        productOrderItemId,
        productOrderReferenceNumber,
        changeOwnershipRequired,
        telcoType
      } = productOrder;

      if (changeOwnershipRequired) {
        await handlePortInOrder({
          contactId,
          customerId,
          serviceNumber: portInNumber,
          planId,
          planRelId,
          basePriceSchemaId,
          simpleProductSpecId: selectedProduct && selectedProduct.productId,
          telcoType,
          currProductOrderId: productOrderId,
          currProductOrderItemId: productOrderItemId,
          currProductOrderReferenceNumber: productOrderReferenceNumber,
          accessories: selectedAccessories,
          simCardType: is5GSAEnabled
            ? simCardType.simType5G
            : simCardType.simType4G
        });
      }
    }

    if (productOrder) {
      handleGomoNumberPortIn();
    }
  }, [
    contactId,
    customerId,
    handlePortInOrder,
    portInNumber,
    selectedPlan,
    selectedProduct,
    productOrder
  ]);

  return <></>;
};

const NumberVerificationPage = props => {
  const {
    authStatus,
    userInformation,
    worryFreeDetails,
    setAuthStatus,
    handlePortInOrder,
    portInNumber,
    selectedPlan,
    selectedProduct,
    order
  } = props;
  const { contactId, customerIds, indentType } = worryFreeDetails;

  const isAuthenticated = !userInformation || !userInformation.anonymousUser;

  useEffect(() => {
    if (!isAuthenticated) {
      // Navigate to Worry Free if we are not logged in
      setAuthStatus('WORRY_FREE_LOGIN');
    }
  }, [isAuthenticated, setAuthStatus]);

  switch (authStatus) {
    case 'WORRY_FREE_OTP':
      return <WorryFreeOtp showOnePass={false} />;
    case 'LOGGED_IN':
      if (indentType === 'Passport Number') {
        return <NumberVerificationPassportHolder />;
      }

      return (
        <div className="fs-unmask">
          {!order.error && <LoadingOverlayComponent />}
          <WorryFreeOtp
            showOnePass={false}
            worryFreeStatus={order.error && order.error.errorMsg}
          />
          {!order.error && (
            <PortInHandleOrder
              handlePortInOrder={handlePortInOrder}
              contactId={contactId}
              customerId={customerIds[0]}
              portInNumber={portInNumber}
              selectedPlan={selectedPlan}
              selectedProduct={selectedProduct}
              productOrder={order.productOrder}
            />
          )}
        </div>
      );
    case 'WORRY_FREE_LOGIN':
    default:
      return <NumberVerification />;
  }
};

const mapStateToProps = state => {
  return {
    authStatus: state.auth.status,
    userInformation: state.user.information,
    worryFreeDetails: state.auth.worryFree,
    portInNumber: state.order.portInNumber,
    selectedProduct: state.product.selectedProduct,
    selectedPlan: state.plan.selectedPlan,
    order: state.order
  };
};

const mapDispatchToProps = dispatch => {
  const { sendOtp, setAuthStatus } = authActions;
  const { handlePortInOrder } = orderActions;

  return {
    sendOtp: payload => dispatch(sendOtp(payload)),
    setAuthStatus: status => dispatch(setAuthStatus(status)),
    handlePortInOrder: options => dispatch(handlePortInOrder(options))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumberVerificationPage);
