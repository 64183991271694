import { FORM_FIELD_TYPES, FORM_VALIDATION_TYPES } from '@wec-core/form-engine';
import { isValidNric } from '@lux/helpers';
import { trans as t } from '../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';

export interface FormReducerState {
  formInputsMapping: unknown;
  formValues: KeyValue;
  formConfigs?: KeyValue;
  valuesChangeOnDemand?: KeyValue[];
  enableReinitialize?: boolean;
  otpVisible?: boolean;
}

export interface CallbackValidation {
  valid: boolean;
  message?: string;
}

export const FORM_MAPPING_ACTIONS = {
  SET_OVERALL_FORM_STATE: 'SET_OVERALL_FORM_STATE',
  SET_FORM_VALUES: 'SET_FORM_VALUES',
  SET_FORM_INPUT_MAPPING: 'SET_FORM_INPUT_MAPPING',
  SET_NRIC_PASSTYPE: 'SET_NRIC_PASSTYPE',
  SET_VALUES_CHANGE_ON_DEMAND: 'SET_VALUES_CHANGE_ON_DEMAND',
  SET_CIS_EMAIL: 'SET_CIS_EMAIL'
};

export const nricValidation = (value: string): CallbackValidation => {
  if (isValidNric(value)) {
    return { valid: true };
  } else {
    return { valid: false, message: t('PRE_F_NRIC_TYPE_VALID') as string };
  }
};

export const cceNRICTitle = {
  component: FORM_FIELD_TYPES.FORM_TITLE,
  name: 'formTitle',
  title: t('PRE_ORDER_NRIC_INFO')
};

export const cisUserCheck = {
  component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
  name: 'isCisUser',
  label: t('IPHONE_CORPORATE_USER'),
  showError: true,
  events: {
    onSelectionChange: 'onCISCheckChange'
  }
};

export const cceNRIC = {
  component: FORM_FIELD_TYPES.TEXT_INPUT,
  name: 'nric',
  label: t('PRE_F_NRIC_TYPE'),
  bgColor: 'haze',
  placeholder: t('PRE_F_NRIC_TYPE'),
  events: {
    onValueChange: 'onNRICChange'
  },
  validations: [
    'string',
    {
      type: 'required',
      params: { message: t('PRE_F_NRIC_TYPE_REQUIRED') }
    },
    {
      type: FORM_VALIDATION_TYPES.CALLBACK_VALIDATION,
      params: {
        name: 'nricValidation'
      }
    }
  ]
};

export const ccePassType = {
  component: FORM_FIELD_TYPES.DROPDOWN_INPUT,
  name: 'passType',
  label: t('PRE_F_PASS_TYPE'),
  bgColor: 'haze',
  dataSource: 'passType',
  validations: [
    'string',
    {
      type: 'required',
      params: { message: t('PRE_F_PASS_TYPE_REQUIRED') }
    }
  ]
};

export const CISEMailGroup = [
  {
    component: FORM_FIELD_TYPES.TEXT_INPUT,
    name: 'cisEmail',
    label: t('PRE_F_CIS_EMAIL'),
    bgColor: 'haze',
    placeholder: t('PRE_F_CIS_EMAIL'),
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      },
      {
        type: 'email',
        params: { message: t('PRE_F_CIS_EMAIL_REQUIRED') }
      }
    ],
    hintMessage: t('CORPORATE_EMAIL_OTP_HINT')
  }
];

export const ccePromptSubmit = {
  component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
  name: 'submitButton',
  label: t('PRE_F_CIS_SUBMIT'),
  columnProps: {
    xs: 12,
    sm: 3,
    md: 3
  },
  sizing: 3,
  btnProps: {
    fullWidth: true
  }
};

export const getPassTypeList = [
  { text: t('PRE_F_PASS_TYPE_WP'), value: t('PRE_F_PASS_TYPE_WP') },
  { text: t('PRE_F_PASS_TYPE_EP'), value: t('PRE_F_PASS_TYPE_EP') },
  { text: t('PRE_F_PASS_TYPE_S_PASS'), value: t('PRE_F_PASS_TYPE_S_PASS') },
  { text: t('PRE_F_PASS_TYPE_DP'), value: t('PRE_F_PASS_TYPE_DP') }
];

export const ccetInitialState = {
  formInputsMapping: {},
  formValues: {},
  enableReinitialize: true,
  valuesChangeOnDemand: [],
  otpVisible: false
};

export const getFormDataMapping = <Tdata>(formValues: Tdata): Tdata[] => {
  return Object.values(formValues)
    .filter(Boolean)
    .reduce((acc: [], val: never) => acc.concat(val), []);
};

export const ccePromptReducer = (
  state = ccetInitialState,
  action: { type: string; payload: APP_TYPE_ANY }
): FormReducerState => {
  switch (action.type) {
    case FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE:
      return {
        ...state,
        ...action.payload,
        enableReinitialize: true
      };

    case FORM_MAPPING_ACTIONS.SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.payload
      };

    case FORM_MAPPING_ACTIONS.SET_CIS_EMAIL:
    case FORM_MAPPING_ACTIONS.SET_NRIC_PASSTYPE:
      return {
        ...state,
        formInputsMapping: { ...state.formInputsMapping, ...action.payload }
      };
    default:
      return ccetInitialState;
  }
};
