import { ctSendEvent } from '../helpers/clicktale';
import { dtIdentifyUser } from '../helpers/dynatrace';
import { dataLayerPush, gaSendEvent } from '../helpers/common';
import slugify from '../helpers/slugify';
import { getDetoxStore } from './navigation';
import { mask } from '../helpers/pii-masker';

const gaTrackEvent = action => {
  const { type, payload } = action;
  if (eventDefMap[type]) {
    gaSendEvent(eventDefMap[type](payload));
  }
};

const ctTrackEvent = action => {
  const { type, payload } = action;
  if (eventDefMap[type]) {
    const eventDef = eventDefMap[type](payload);
    ctSendEvent(eventDef.eventName, eventDef.payload);
  }
};

const dlTrackEvent = action => {
  const { type, payload } = action;
  if (eventDefMap[type]) {
    dataLayerPush(eventDefMap[type](payload));
  }
};

const dtIdentifyUserEvent = action => {
  const { payload } = action;
  dtIdentifyUser(payload.customerId);
};

export const eventDefMap = {
  GA_TRACKING_LIVEPERSON_TRIGGERED: () => ({
    eventCategory: 'Livechat',
    eventAction: 'click',
    eventLabel: 'Init chat'
  }),
  GA_TRACKING_NEW_NUMBER_SUCCESS: () => ({
    eventCategory: 'Mobile/number-selection/New',
    eventAction: 'Button',
    eventLabel: 'New - Clicked on a number'
  }),
  GA_TRACKING_NEW_NUMBER_ERROR: () => ({
    eventCategory: 'Detox',
    eventAction: 'New Number Errors',
    eventLabel: 'NGNIS_RESERVE_FAILED'
  }),
  GA_TRACKING_PORT_IN_SUCCESS: () => ({
    eventCategory: 'Mobile/number-selection/Port-in',
    eventAction: 'Button',
    eventLabel: 'Port-in - Entered port-in number and clicked on "Next"'
  }),
  GA_TRACKING_RECONTRACT_ERRORS: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Recontract Errors',
    eventLabel: payload.label
  }),
  GA_TRACKING_RECONTRACT_CLICK: () => ({
    eventCategory: 'Mobile/choose-your-number/Recontract',
    eventAction: 'Button',
    eventLabel: 'Recontract - Clicked on "Recontract"'
  }),
  GA_TRACKING_RECONTRACT_BLOCKING_MESSAGES: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Blocking Messages',
    eventLabel: mask(payload.messageText)
  }),
  GA_TRACKING_RECONTRACT_ORDER_IDS: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Order Ids',
    eventLabel: payload.productOrderReferenceNumber
  }),
  GA_TRACKING_RECONTRACT_ERROR_RETRY: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Recontract Errors',
    eventLabel: 'RECONTRACT_ERROR_RETRY',
    eventValue: payload.attempts
  }),
  GA_TRACKING_USER_PRODUCTS_ERRORS: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Get User Products Errors',
    eventLabel: payload.label
  }),
  GA_TRACKING_CIS_ERRORS: payload => ({
    eventCategory: 'Detox',
    eventAction: 'CIS Errors',
    eventLabel: payload.errorCode,
    eventValue: payload.token
  }),
  GA_TRACKING_CIS_GENERIC_ERRORS: payload => ({
    eventCategory: 'Detox',
    eventAction: 'CIS Errors',
    eventLabel: 'Error from validateCISToken',
    eventValue: payload.token
  }),
  GA_TRACKING_ADDONS_BLOCKING_MESSAGES: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Blocking Messages',
    eventLabel: mask(payload.messageText)
  }),
  GA_TRACKING_CHECKOUT_BLOCKING_MESSAGES: payload => ({
    eventCategory: 'Detox',
    eventAction: 'Shopping Cart Errors',
    eventLabel: mask(payload.messageText)
  }),
  GA_TRACKING_CHANGE_SIM: () => ({
    eventCategory: 'Mobile/add-on/change-sim',
    eventAction: 'Button',
    eventLabel: 'Recontract - Clicked on change sim'
  }),
  CT_TRACKING_SEND_CUSTOMER_ID: payload => ({
    eventName: 'CustomerId',
    payload: payload.customerId
  }),
  CT_TRACKING_SEND_PRODUCT_ORDER_ID: payload => ({
    eventName: 'OrderId',
    payload: payload.productOrderId
  }),
  SET_SELECTED_PRODUCT: payload => ({
    event: 'set-selected-product',
    ...(payload && payload.title && { product: slugify(payload.title) })
  }),
  SET_SELECTED_PLAN: payload => ({
    event: 'set-selected-plan',
    ...(payload && payload.planName && { plan: slugify(payload.planName) })
  }),
  PRODUCT_ADD_TO_CART: payload => ({
    event: 'product-add-to-cart',
    ...payload
  }),
  ABANDON_CART: payload => ({
    event: 'abandon-cart',
    ...payload
  }),
  ORDER_COMPLETE: payload => ({
    event: 'order-complete',
    ...payload
  }),
  ORDER_ECOMMERCE_DATA: payload => ({
    event: 'order-ecommerce-data',
    ...payload
  }),
  ORDER_ENHANCED_CONVERSION: payload => ({
    event: 'enhanced_conversion',
    ...payload
  }),
  DL_TRACKING_CHECKOUT_BLOCKING_MESSAGES: payload => ({
    event: 'shopping-cart-error',
    shoppingCartError: mask(payload.messageText)
  })
};

// Match the event definition to a Redux action:
export const eventsMap = {
  SET_SELECTED_PRODUCT: dlTrackEvent,
  SET_SELECTED_PLAN: dlTrackEvent,
  PRODUCT_ADD_TO_CART: dlTrackEvent,
  ABANDON_CART: dlTrackEvent,
  ORDER_COMPLETE: dlTrackEvent,
  ORDER_ECOMMERCE_DATA: dlTrackEvent,
  ORDER_ENHANCED_CONVERSION: dlTrackEvent,
  DL_TRACKING_CHECKOUT_BLOCKING_MESSAGES: dlTrackEvent,
  GA_TRACKING_LIVEPERSON_TRIGGERED: gaTrackEvent,
  GA_TRACKING_NEW_NUMBER_SUCCESS: gaTrackEvent,
  GA_TRACKING_NEW_NUMBER_ERROR: gaTrackEvent,
  GA_TRACKING_PORT_IN_SUCCESS: gaTrackEvent,
  GA_TRACKING_RECONTRACT_ERRORS: gaTrackEvent,
  GA_TRACKING_RECONTRACT_CLICK: gaTrackEvent,
  GA_TRACKING_RECONTRACT_BLOCKING_MESSAGES: gaTrackEvent,
  GA_TRACKING_RECONTRACT_ERROR_RETRY: gaTrackEvent,
  GA_TRACKING_USER_PRODUCTS_ERRORS: gaTrackEvent,
  GA_TRACKING_CIS_ERRORS: gaTrackEvent,
  GA_TRACKING_CIS_GENERIC_ERRORS: gaTrackEvent,
  GA_TRACKING_ADDONS_BLOCKING_MESSAGES: gaTrackEvent,
  GA_TRACKING_CHECKOUT_BLOCKING_MESSAGES: gaTrackEvent,
  GA_TRACKING_CHANGE_SIM: gaTrackEvent,

  CT_TRACKING_SEND_CUSTOMER_ID: ctTrackEvent,
  CT_TRACKING_SEND_PRODUCT_ORDER_ID: ctTrackEvent,

  DT_TRACKING_IDENTIFY_USER: dtIdentifyUserEvent
};

const dlSyncToStore = store => {
  const { selectedPlan, selectedProduct } = getDetoxStore(store);

  // Construct a model of information we want to send to GTM on every event
  const data = {
    plan: selectedPlan && selectedPlan.planName,
    product: selectedProduct && selectedProduct.title
  };

  const sanitisedData = Object.keys(data).reduce((acc, key) => {
    if (data[key]) {
      acc[key] = slugify(data[key]);
    }
    return acc;
  }, {});

  dataLayerPush(sanitisedData);
};

const eventTrackingMiddleware = store => next => action => {
  eventsMap[action.type] && eventsMap[action.type](action);

  const result = next(action);

  // sync the store to GTM datalayer
  // we use this condition to filter the events so we
  // do not spam GTM window.dataLayer
  if (eventsMap[action.type]) {
    dlSyncToStore(store);
  }

  return result;
};

export default eventTrackingMiddleware;
