import { useEffect } from 'react';

export let isBackPressed = false;
export const setBackPress = (backPress: boolean) => {
  isBackPressed = backPress;
};
export const useBrowserBack = (callback?: () => void) => {
  useEffect(() => {
    if (isBackPressed) {
      if (typeof callback === 'function') {
        callback();
      }
      isBackPressed = false;
    }
    window.addEventListener('popstate', setBackPress);
    return () => {
      window.removeEventListener('popstate', setBackPress);
    };
  }, []);

  const setBackPress = () => {
    isBackPressed = true;
  };
  return { isBackPressed, setBackPress };
};
