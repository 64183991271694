import { createSelector } from 'reselect';
import { selectedProduct } from './product';
import isSimOnlyPlan from '../helpers/is-sim-only-plan';

export const selectedPlan = state => state.plan?.selectedPlan;

export const isSimOnlyIPP = createSelector(
  [selectedPlan, selectedProduct],
  (plan, product) => isSimOnlyPlan(plan) && Boolean(product)
);

const initialState = {};

const planReducers = (state = initialState, action) => {
  if (action.type === `SET_SELECTED_PLAN`) {
    return {
      ...state,
      selectedPlan: action.payload
    };
  }

  return state;
};

export default planReducers;
