/* istanbul ignore file */
export const REQUEST_STATUS = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED'
};

export const VOUCHER_ACTION = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};

export const RIBBON_TEXT = {
  PRE_ORDER: 'PRE-ORDER'
};

export const CP_FLOW_TYPE = 'CP';

export { default as ADDON } from './addon';
export { default as AUTH } from './auth';
export { default as CART } from './cart';
export { default as ORDER } from './order';
export { default as FULFILLMENT } from './fulfillment';
export { default as CATALOGUE } from './catalogue';
export { default as RRPCART } from './rrpcart';
export { default as COMMON } from './common';
export { default as ACTIONS } from './actions';
export { PRODUCT } from './product';
export { USER } from './user';

export const SD_NAVIGATE_MAPPING = {
  rrpProductCatalogue: 'rrp-product-catalogue'
};
