import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PersistGate } from 'redux-persist/integration/react';
import { PersistStatusContext } from '../PersistStatusProvider';

const Opaque = styled.div`
  opacity: 0;
`;

/**
 * This component retrieves the persisted status from PersistStatusContext
 * and hides the UI when persisting is still in progress.
 *
 * There is a usePersistGate prop to use the PersistGate from redux-persist instead
 */

const PersistStatusConsumer = props => {
  const { persisted, persistor } = useContext(PersistStatusContext);

  if (props.usePersistGate) {
    return (
      <PersistGate loading={null} persistor={persistor}>
        {props.children}
      </PersistGate>
    );
  }

  if (persisted) {
    return props.children;
  }

  return <Opaque data-testid="opaque">{props.children}</Opaque>;
};

PersistStatusConsumer.propTypes = {
  usePersistGate: PropTypes.bool,
  children: PropTypes.element
};

export default PersistStatusConsumer;
