const colours = {
  primary: '#ED1A3D',
  red_100: '#FDE8EB',
  primaryVariant: '#D01131',
  nearBlack: '#222222',
  haze: '#F7F7F7',
  turqoise: '#1AEDCA',
  link: '#1A3DED',
  linkVariant: '#0025DF',
  zircon: '#E1E1E1',
  white: '#FFFFFF',
  powderBlue: '#E8F5F9',
  yellow: '#FFB824',
  cerulean: '#009FD4',
  cherise: '#E32490',
  lightSlate: '#8589A1',
  cisPrimary: '#006E91',
  cisSecondary: '#0293C1',
  bright_blue: '#3E88E1',
  vivid_cyan: '#05BAF2',
  blue_50: '#F0FBFE',
  blue_100: '#E5F5FA',
  blue_1000: '#0084B0',
  grey_50: '#F7F7F7',
  grey_200: '#EDEDED',
  grey_400: '#D6D6D6',
  grey_500: '#999999',
  grey_600: '#707070',
  grey_900: '#222222',
  grey_cc: '#CCCCCC',
  grey_e1: '#E1E1E1',
  green_100: '#E6F8E9',
  green_900: '#00A11D',
  yellow_300: '#FFE9BD',
  yellow_400: '#FFE3A7',
  yellow_900: '#FFDB6C',
  background_primary: 'transparent',
  cyan: '#19C5FF',
  secondary_d1: '#5C50BB'
};

const card = {
  card_color_primary: colours.nearBlack
};

const tab = {
  tab_text_pri: colours.nearBlack,
  tab_text_pri_active: colours.link,
  tab_bottom_separator: colours.grey_400
};

export default {
  ...colours,
  page_background_pri: colours.white,
  page_text_colour_pri: colours.nearBlack, // primary color for body text
  page_text_colour_alt: colours.grey_600, // alternate (lighter) color for body text
  page_link_colour: colours.link, // anchor text color
  page_link_colour_active: colours.linkVariant, // anchor color on hover.
  ...card,
  ...tab
};
