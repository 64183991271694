declare global {
  interface Window {
    dtrum: {
      identifyUser: (id: string) => void;
    };
  }
}

export const dtIdentifyUser = (id: string) => {
  if (window.dtrum) {
    window.dtrum.identifyUser(id);
  }
};
