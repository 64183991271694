import React, { ReactElement } from 'react';
import {
  Row,
  Column,
  Card,
  Ribbon,
  Carousel,
  Text,
  TextLink,
  Button,
  Spacing,
  useTheme,
  useDevice
} from '@dls/web';
import { BackArrow as BackArrowIcon } from '@dls/assets/dist/icons/web';
import {
  StyledImg,
  StyledPlusIcon,
  StyledImageCaptionContainer,
  StyledImageListContainer,
  StyledBlackArrow,
  StyledHeroImage
} from './styles';
import ImageSimOnly from '../../assets/images/exclusive-validation/simonly.jpg';
import ImageDevice from '../../assets/images/exclusive-validation/device.jpg';
import { trans as t } from '../../helpers/localisation';
import PlanCatalogCard from '../PlanCatalogCard/PlanCatalogCard';
import { getRibbon, setPriceText } from '../../helpers/catalogue-helpers';
import htmlStringToReactComponent from '../../helpers/htmlStringToReactComponent';
import { simOnlyTncUrl } from '../../config/links';
import { APP_TYPE_ANY } from '../../types/common.types';

interface SimOnlyPlusUpSellProps {
  phones: APP_TYPE_ANY;
  hideSimOnlyPlusUpSellScreen: () => void;
  showEligibleDevicesForSimOnlyPlus: () => void;
  proceedSimOnlyPlusWithoutDeviceFlow: () => void;
  navigateProductDetailsPage?: (phone: APP_TYPE_ANY) => APP_TYPE_ANY;
  selectedPlanGroup?: APP_TYPE_ANY;
  selectedPlan: APP_TYPE_ANY;
  topChoicesLimit?: number;
}

const SimOnlyPlusUpSell = ({
  phones,
  hideSimOnlyPlusUpSellScreen,
  showEligibleDevicesForSimOnlyPlus,
  proceedSimOnlyPlusWithoutDeviceFlow,
  navigateProductDetailsPage,
  selectedPlanGroup,
  selectedPlan,
  topChoicesLimit
}: SimOnlyPlusUpSellProps): ReactElement => {
  const { theme } = useTheme();
  const { isMobile } = useDevice();
  const {
    planName,
    monthlyCharges,
    isCisPlanCatalogue,
    $discountedPrice,
    discountedPrice,
    discountedLiner,
    data,
    talktime,
    sms,
    tags,
    planDescSubLiner,
    handlePlanClick,
    plan,
    upsellRibbon
  } = selectedPlan || {};
  const topChoicesPhones =
    phones.length > topChoicesLimit ? phones.slice(0, topChoicesLimit) : phones;

  const handleTermsAndConditionsClick = () => {
    window.open(simOnlyTncUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Spacing top={isMobile ? 0 : 1}>
      <Row>
        <Column xs={12} sm={4} md={4} lg={4} noGutter>
          <Spacing bottom={3}>
            <Row>
              <StyledBlackArrow>
                <BackArrowIcon color={theme.cl_sec_d1} size={24} />
              </StyledBlackArrow>
              <Spacing top={isMobile ? 0.5 : 0}>
                <Text type="body">
                  <TextLink secondary onClick={hideSimOnlyPlusUpSellScreen}>
                    {t('PLAN_SELECTION')}
                  </TextLink>
                </Text>
              </Spacing>
            </Row>
          </Spacing>
          {selectedPlan && (
            <Spacing bottom={5}>
              <PlanCatalogCard
                name={planName}
                upsellRibbon={upsellRibbon}
                price={monthlyCharges}
                discountPrice={
                  isCisPlanCatalogue ? $discountedPrice : discountedPrice
                }
                discountedLiner={discountedLiner}
                data={data}
                talktime={talktime}
                sms={sms}
                tags={tags}
                planDescSubLiner={planDescSubLiner}
                onPlanClick={() => handlePlanClick(plan)}
                isHideButton={true}
              />
            </Spacing>
          )}
        </Column>
        <Column xs={12} sm={8} md={8} lg={8} noGutter>
          <Spacing left={isMobile ? 0 : 10}>
            <Spacing bottom={3}>
              <Text type="header">{t('WANT_DEVICE_WITH_PLAN')}</Text>
            </Spacing>

            <StyledImageListContainer>
              <StyledImageCaptionContainer>
                <StyledImg src={ImageSimOnly} alt="Image of Sim Only" />
                <Text>{t('SIM_ONLY_PLUS_PLAN')}</Text>
              </StyledImageCaptionContainer>
              <StyledPlusIcon />
              <StyledImageCaptionContainer>
                <StyledImg src={ImageDevice} alt="Image of Device" />
                <div>
                  {upsellRibbon && (
                    <Ribbon.Variant1>{upsellRibbon}</Ribbon.Variant1>
                  )}
                  <Text>{t('DEVICE_INSTALLMENT_PLAN')}</Text>
                </div>
              </StyledImageCaptionContainer>
            </StyledImageListContainer>

            <Spacing top={3} bottom={5}>
              <Text>
                {t('SIM_ONLY_PLUS_UPSELL_DESCRIPTION', {
                  tnc: (
                    <TextLink
                      type="smallBody"
                      onClick={handleTermsAndConditionsClick}
                    >
                      Terms and Conditions
                    </TextLink>
                  ) as APP_TYPE_ANY
                })}
              </Text>
            </Spacing>

            <Spacing bottom={3}>
              <Text type="header">{t('TOP_CHOICES_FOR_YOU')}</Text>
            </Spacing>

            <Spacing bottom={3}>
              <Carousel
                type="card"
                infiniteLoop={true}
                outsideButtons={true}
                pagination={2}
              >
                {topChoicesPhones.map(phone => {
                  const ribbonForPlan = getRibbon(phone.ribbon, selectedPlan);
                  return (
                    <Card
                      onClick={() => navigateProductDetailsPage(phone)}
                      key={phone.title}
                    >
                      <Card.Content>
                        {phone.heroImage && (
                          <StyledHeroImage
                            image={
                              phone.heroImage?.childImageSharp?.gatsbyImageData
                            }
                            alt={phone.title}
                          />
                        )}
                        <Column sm={12}>
                          {ribbonForPlan && (
                            <Ribbon.Variant1>
                              {ribbonForPlan?.title || ''}
                            </Ribbon.Variant1>
                          )}
                          <Row>
                            <Text type="header">{phone.title}</Text>
                          </Row>
                          <Row>
                            <Text type="body">{setPriceText(phone)}</Text>
                          </Row>
                        </Column>
                      </Card.Content>
                    </Card>
                  );
                })}
              </Carousel>
            </Spacing>

            <Row>
              <Column>
                <Button
                  fullWidth={true}
                  onClick={showEligibleDevicesForSimOnlyPlus}
                >
                  {t('OK_SHOW_ELIGIBLE_DEVICES')}
                </Button>
              </Column>
              <Column>
                <Button
                  fullWidth={true}
                  secondary
                  onClick={proceedSimOnlyPlusWithoutDeviceFlow}
                >
                  {t('NO_THANKS')}
                </Button>
              </Column>
            </Row>

            {selectedPlanGroup?.footerLiner && (
              <Spacing top={4} bottom={4}>
                {htmlStringToReactComponent(selectedPlanGroup.footerLiner)}
              </Spacing>
            )}
          </Spacing>
        </Column>
      </Row>
    </Spacing>
  );
};

export default SimOnlyPlusUpSell;
