import { format } from 'date-fns';
import {
  formatContactNumber,
  formatNumber,
  getDisplayUnitNumber,
  maskString,
  splitNumber
} from '../../helpers/common';
import { getAddressLines } from '../../helpers/address';
import formatPrice from '../../helpers/formatPrice';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { Contact } from '../../types/user.types';
import {
  SectionContent,
  DeliveryAddress
} from '../../types/orderSummary.types';
import {
  getOrderSummaryDataModelOptionRrpIppBill,
  getOrderSummaryDataModelOptionRrpIppOrderTotal,
  AccessoryFromAction
} from '../../types/shoppingCart.types';
import { BaseAddress } from '../../types/registrationCheckout';
import CONSTANTS from '../../constants/common';
import { trans as t } from '../../helpers/localisation';

export const composeContent = (data: KeyValue): APP_TYPE_ANY => {
  return Object.entries(data).reduce((result, [key, value]) => {
    if (value) {
      result.push(value);
    }
    return result;
  }, []);
};

export const getPersonalInfo = ({
  userContact,
  needMask
}: {
  userContact: Contact;
  needMask?: boolean;
}): SectionContent | KeyValue => {
  let info = {};

  if (userContact) {
    info = {
      name: userContact.registeredName
    };
    if (needMask) {
      info = {
        ...info,
        nricFin: maskString(userContact.indentValue),
        phone: maskString(userContact.phone),
        email: maskString(userContact.email)
      };
    } else {
      info = {
        ...info,
        nricFin: userContact.indentValue,
        phone: formatContactNumber(userContact.phone),
        email: userContact.email
      };
    }
  }

  return {
    title: t('PERSONAL_DETAILS'),
    content: composeContent(info)
  };
};

export const getBillingAddressInfo = (
  contactAddress: BaseAddress
): SectionContent => {
  let content: string[] = [];
  if (contactAddress) {
    content = [
      [
        [
          contactAddress.houseNumber,
          contactAddress.streetName,
          contactAddress.buildingName
        ]
          .filter(Boolean)
          .join(' '),
        getDisplayUnitNumber({
          floor: contactAddress.floor,
          unitNumber: contactAddress.unitNumber || contactAddress.apartment
        })
      ].join(', '),
      `${CONSTANTS.SINGAPORE} ${contactAddress.postcode}`
    ];
  }
  return {
    title: t('CO_EN_ADD_ADDRESS_TITLE') as string,
    content
  };
};

interface getOrderSummaryDataModelAddressInfoOption {
  deliveryAddress: DeliveryAddress;
  deliveryStartDateAndTime: number;
  deliveryTimeSlot: string;
}

export const getOrderAddressInfo = ({
  deliveryAddress,
  deliveryStartDateAndTime,
  deliveryTimeSlot
}: getOrderSummaryDataModelAddressInfoOption): SectionContent => {
  const content: string[] = [];
  if (deliveryStartDateAndTime) {
    const date = new Date(deliveryStartDateAndTime); // date here is based on SGT 00:00hrs (GMT+8)
    const timeZoneOffset = date.getTimezoneOffset();
    const TIMEZONE_OFFSET_SGT = -480; // value defined here is equivalent to return value of `Date.prototype.getTimezoneOffset()` executed in SG time locale
    const timeZoneAdjustedLocalDate = new Date(
      date.valueOf() + (timeZoneOffset - TIMEZONE_OFFSET_SGT) * 60 * 1000 // adjust local datetime to display as if locale is in SGT
    );
    const displayDate = format(timeZoneAdjustedLocalDate, 'dd MMM yyyy');
    content.push(`${displayDate}, ${deliveryTimeSlot}`);
  }
  if (deliveryAddress) {
    content.push(...getAddressLines(deliveryAddress));
  }
  return {
    content
  };
};

interface getOrderSummaryDataModelOptionRrpIppProduct {
  accessories: AccessoryFromAction[];
  skuData: KeyValue;
}

export const getOrderProductsListInfo = ({
  accessories,
  skuData
}: getOrderSummaryDataModelOptionRrpIppProduct): APP_TYPE_ANY => {
  const items = [];
  if (accessories) {
    accessories.forEach(accessory => {
      const itemSkuData = skuData[accessory.skuId];
      const imageSrc =
        process.env.GATSBY_AEM_URL + itemSkuData?.smallImage?.src;
      items.push({
        id: accessory.accessoryId,
        name: accessory.name,
        quantity: formatNumber(1),
        imageSrc,
        price: accessory.oneTimePrice.finalAmountIncludingTax
      });
    });
  }
  return {
    items
  };
};

export const getOrderSummaryDataModelRrpIppOrderTotal = ({
  mobile
}: getOrderSummaryDataModelOptionRrpIppOrderTotal): APP_TYPE_ANY => {
  const list = [];

  const deliveryFee = mobile?.deliveryCharges?.price || 0;

  list.push({
    name: t('DROP_SHIPPING.DELIVERY_FEE.TEXT'),
    amount: deliveryFee === 0 ? t('CART.TEXT.FREE') : formatPrice(deliveryFee)
  });

  return {
    list,
    finalAmount: deliveryFee
  };
};

export const getOrderSummaryDataModelRrpIppBill = ({
  mobile,
  accessory
}: getOrderSummaryDataModelOptionRrpIppBill): APP_TYPE_ANY => {
  return [
    {
      title: t('DROP_SHIPPING.IPP.MONTHLY_BILL.TITLE'),
      dataSource: [
        {
          type: 'mobile-number',
          name: t('CART.CHARGE_TO_MOBILE', {
            serviceId: splitNumber(mobile.serviceId)
          })
        },
        {
          name: t('CART.PRODUCT_INSTALMENT_PLAN.TEXT'),
          description: t('CART.INSTALMENT_TERM_DESCRIPTION', {
            monthlyTerm: accessory?.monthlyTerm
          }),
          price: t('PRICE_PER_MONTH', {
            price: formatPrice(
              accessory?.recurringPrice?.finalAmountIncludingTax
            )
          })
        }
      ],
      note: t('DROP_SHIPPING.IPP.MONTHLY_BILL.NOTE')
    }
  ];
};
