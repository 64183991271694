import CONSTANTS from '../constants/checkout';
import { ACTION_TYPES } from '@detox/actions';
import { ACTION_TYPES as APP_ACTION_TYPES } from '../constants/actions';
import { trans as t } from '../helpers/localisation';

const { CHECK_IDENTITY_SUCCESS } = ACTION_TYPES.CHECKOUT_ACTIONS;
export const initialState = {
  verificationData: {
    id: null,
    passType: null,
    passTypeText: null,
    mobileNumber: null,
    mobileNumberWithoutFormat: null
  },
  checkoutFormData: null,
  checkoutFormDocumentData: {},
  checkoutFlow: null,
  addNewAddressData: {},
  documentData: {
    implFileDetails: []
  },
  documentUpload: [],
  stepperTitles: [],
  checkoutUserData: null,
  resettingForm: false,
  errorTitle: '',
  errorMessage: '',
  btnText: '',
  extraContactLink: false
};

const clearedErrorText = {
  errorTitle: '',
  errorMessage: '',
  btnText: '',
  extraContactLink: false
};

const blockedUserError = {
  errorTitle: t('BLOCK_USER_ERROR_TITLE'),
  errorMessage: t('BLOCK_USER_ERROR_MESSAGE')
};

const checkoutFailError = {
  btnText: t('CO_CONTACT_US'),
  errorTitle: t('CO_CONTACT_ERROR'),
  errorMessage: t('CO_CONTACT_ERROR_MSG'),
  extraContactLink: true
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_VERIFICATION_ALL_DETAILS:
      return {
        ...state,
        verificationData: {
          ...state.verificationData,
          ...action.payload,
          mobileNumber: action.payload.mobileNumber,
          mobileNumberWithoutFormat: action.payload.mobileNumberWithoutFormat
        },
        checkoutFormData: {
          ...state.checkoutFormData,
          contactNumber: action.payload.mobileNumber
        },
        resettingForm: false
      };
    case CONSTANTS.CHECKOUT_RESET_VERIFICATION_DATA:
      return {
        ...state,
        verificationData: {}
      };

    case CONSTANTS.SET_CHECKOUT_FORM_DATA:
      return {
        ...state,
        checkoutFormData: action.payload
      };
    case CONSTANTS.SET_CHECKOUT_FORM_DOCUMENT_DATA:
      return {
        ...state,
        checkoutFormDocumentData: {
          ...state.checkoutFormDocumentData,
          ...action.payload
        }
      };
    case CONSTANTS.SET_CHECKOUT_FLOW:
      return {
        ...state,
        checkoutFlow: action.payload,
        isLoading: false,
        resettingForm: false,
        ...clearedErrorText
      };
    case CONSTANTS.UPLOAD_DOCUMENT_SUCCESS: {
      const { fileName, fieldName, documentIds } = action.value;
      const newUpload = { fileName, fieldName, documentId: documentIds[0] };
      const updatedUpload = [...state.documentUpload, newUpload];

      return {
        ...state,
        documentUpload: updatedUpload
      };
    }
    case CONSTANTS.DELETE_DOCUMENT: {
      const { fieldName } = action.payload;
      const filteredUpload = state.documentUpload.filter(
        docUploaded => docUploaded.fieldName !== fieldName
      );

      const { [fieldName]: _, ...otherData } = state.checkoutFormDocumentData;

      return {
        ...state,
        documentUpload: filteredUpload,
        checkoutFormDocumentData: otherData
      };
    }
    case CONSTANTS.RETRIEVE_DOCUMENT_DETAILS_SUCCESS_DATA:
      return {
        ...state,
        documentData: {
          ...state.documentData,
          ...action.value
        }
      };

    case CONSTANTS.SET_ADDRESS_FORM_DATA:
      return {
        ...state,
        addNewAddressData: action.payload
      };
    case CONSTANTS.RESET_CHECKOUT_FORM_DATA:
      return {
        ...state,
        checkoutFormData: null,
        addNewAddressData: {},
        checkoutFormDocumentData: {},
        documentUpload: [],
        resettingForm: true
      };
    case CONSTANTS.CHECKOUT_LOADING: {
      return {
        ...state,
        isLoading: action.value,
        ...clearedErrorText
      };
    }
    case CONSTANTS.UPDATE_CUSTOMER_SUCCESS:
    case CONSTANTS.CREATE_CUSTOMER_SUCCESS: {
      const {
        contactId,
        customerId,
        billingAccountRes,
        addressId
      } = action.value;

      return {
        ...state,
        customerCreated: true,
        newAddedAddressId: addressId,
        createdAccountId: { contactId, customerId },
        billingAccountId: billingAccountRes?.billingArangmentId,
        isLoading: false,
        isError: false,
        ...clearedErrorText
      };
    }
    case CONSTANTS.RESET_CREATE_CUSTOMER_STATUS: {
      return {
        ...state,
        customerCreated: false
      };
    }
    case CONSTANTS.SET_STEPPER_TITLES: {
      return {
        ...state,
        stepperTitles: action.payload
      };
    }
    case CHECK_IDENTITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        identityCheck: action.data,
        ...clearedErrorText
      };
    }
    case ACTION_TYPES.CHECKOUT_ACTIONS.UPDATE_BILLING_ADDRESS_LOADING:
    case ACTION_TYPES.CHECKOUT_ACTIONS.CREATE_BILLING_ADDRESS_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        ...clearedErrorText
      };
    }
    case ACTION_TYPES.CHECKOUT_ACTIONS.UPDATE_BILLING_ADDRESS_SUCCESS:
    case ACTION_TYPES.CHECKOUT_ACTIONS.CREATE_BILLING_ADDRESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...clearedErrorText,
        billingAccountId: action?.value?.billingAccountRes?.billingArangmentId,
        billingAddress:
          action?.value?.contactDataRes?.contactBillingArrangementDetails
      };
    }
    case CONSTANTS.CHECKOUT_ERROR: {
      let customError = {};
      const customerError = action?.value?.ErrorInfo?.errorCode || '';

      if (CONSTANTS.BLOCK_ERROR_CODES.includes(customerError)) {
        customError = blockedUserError;
      } else {
        customError = checkoutFailError;
      }

      return {
        ...state,
        isLoading: false,
        isError: true,
        ...customError
      };
    }
    case ACTION_TYPES.CHECKOUT_ACTIONS.UPDATE_BILLING_ADDRESS_FAILED:
    case ACTION_TYPES.CHECKOUT_ACTIONS.CREATE_BILLING_ADDRESS_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        ...clearedErrorText
      };
    }
    case APP_ACTION_TYPES.ORDER.RESET_ORDER:
    case APP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default checkoutReducer;
