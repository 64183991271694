import React from 'react';
import { RRPCART } from '../constants';
import {
  cisPointOfEntryUrl,
  feedbackFormUrl,
  pp2FaqUrl,
  storeLocator,
  plansUrl
} from './links.js';
import { CI_ELIGIBLE_MONTHS } from './index';
import { trans as t } from '../helpers/localisation';

export const PROMO_ERROR_CODE = {
  REMOVE_APPLIED_PROMO_CODE: 'REMOVE_APPLIED_PROMO_CODE',
  ONLY_ONE_PROMO_CODE_IS_ALLOWED: 'ONLY_ONE_PROMO_CODE_IS_ALLOWED',
  COUPON_INVALID: 'COUPON_INVALID',
  EMPTY: 'EMPTY'
};

export const promoCodeError = {
  [PROMO_ERROR_CODE.REMOVE_APPLIED_PROMO_CODE]: {
    text: t('DROP_SHIPPING.PROMO_CODE_REMOVE_TO_ADD.TEXT')
  },
  [PROMO_ERROR_CODE.ONLY_ONE_PROMO_CODE_IS_ALLOWED]: {
    text: t('DROP_SHIPPING.ONLY_ONE_PROMO_CODE_IS_ALLOWED.TEXT')
  },
  [PROMO_ERROR_CODE.COUPON_INVALID]: {
    text: t('DROP_SHIPPING.COUPON_INVALID.TEXT')
  },
  [PROMO_ERROR_CODE.EMPTY]: {
    text: t('DROP_SHIPPING.COUPON_EMPTY.TEXT')
  },
  COUPON_ALREADY_APPLIED: {
    text: t('DROP_SHIPPING.ONLY_ONE_PROMO_CODE_IS_ALLOWED.TEXT')
  },
  DEFAULT: {
    text: t('DROP_SHIPPING.PROMO_CODE_GENERIC_ERROR.TEXT')
  }
};

export const rrpModal = {
  [RRPCART.MODAL_TYPE.EMPTY_CART]: {
    title: t('PROCEED_TO_EMPTY_CART'),
    body: t('PROCEED_TO_EMPTY_CART_DESCRIPTION'),
    ctaText: t('PROCEED_TO_EMPTY_CART_CTA')
  },
  [RRPCART.MODAL_TYPE.SESSION_TIMEOUT]: {
    title: t('IDLE_TITLE'),
    body: t('IDLE_CONTENT'),
    ctaText: t('IDLE_BUTTON')
  },
  [RRPCART.MODAL_TYPE.SESSION_EXPIRED]: {
    title: t('SESSION_TIMEOUT_TITLE'),
    body: t('SESSION_TIMEOUT_CONTENT'),
    ctaText: t('SESSION_TIMEOUT_BUTTON')
  }
};

export default {
  OTP_MISMATCH_ERROR: {
    state: 'danger',
    text:
      "We're unable to find a record that matches the mobile and NRIC/FIN numbers entered. Please try again"
  },
  OTP_EXCEED_LIMIT_ERROR: {
    state: 'danger',
    text:
      "You've reached the maximum number of attempts allowed. Please try again in 30 minutes"
  },
  OTP_VERIFY_ERROR: {
    state: 'danger',
    text: 'Ensure this is a valid 6-digit OTP'
  },
  OTP_GENERIC_ERROR: {
    state: 'danger',
    text:
      "We're sorry this isn't working at the moment. Please try again shortly"
  },
  OTP_TIMEOUT_ERROR: {
    state: 'danger',
    text: 'That OTP has expired. Click "Resend OTP" to receive a new one'
  },
  OTP_RESEND_SUCCESS: {
    state: 'success',
    text: 'OTP sent!'
  },
  NEW_NEW_CUSTOMER: {
    state: 'danger',
    text: t('WORRY_FREE_MISMATCH')
  },
  NEW_CUSTOMER_TO_MOBILE: {
    state: 'danger',
    text: t('WORRY_FREE_MISMATCH')
  },
  ELIGIBILITY_NOT_CI_ELIGIBLE: {
    state: 'danger',
    text: `Your recontract eligibility starts from the ${CI_ELIGIBLE_MONTHS}th month of your 24-month contract.`
  },
  ELIGIBILITY_MOBILE_SHARE_MAX_3: {
    state: 'danger',
    text: 'Main mobile line is already linked to maximum of 3 MobileShare lines'
  },
  ELIGIBILITY_SUSPENDED: {
    state: 'danger',
    text: 'This mobile line was temporarily suspended'
  },
  ELIGIBILITY_HAS_PENDING_ORDER: {
    state: 'danger',
    text: 'Pending request to manage mobile service'
  },
  ELIGIBILITY_HAS_PENDING_PAYMENT_ORDER: {
    text:
      'Dear customer, we have detected there is a pending order, please contact 1688 for further assistance'
  },
  FOREIGNER_NOT_ELIGIBLE: {
    state: 'danger',
    text: `Sorry, you have not met the <a target="_blank" rel="noopener" href="${pp2FaqUrl}">eligibility criteria</a> for Phone Instalment Plans.`
  },
  THREE_MONTHS_NOT_ELIGIBLE: {
    state: 'danger',
    text:
      'To get another device, your current device instalment must be minimum 3 months and above'
  },
  IPP_30_DAY_BLOCK: {
    state: 'danger',
    title: "We're sorry this isn't working at the moment",
    text: 'Eligible for recontract 30 days after current contract start date'
  },
  RECONTRACT_NO_SERVICES_FOUND: {
    state: 'danger',
    text: "You don't have a service that's eligible for recontract"
  },
  MS_RECONTRACT_NO_SERVICES_FOUND: {
    state: 'danger',
    text: "You don't have a service that's eligible for recontract"
  },
  MS_NEW_NO_SERVICES_FOUND: {
    state: 'danger',
    text: "You don't have an eligible main line."
  },
  RECONTRACT_ORDER_ALREADY_FOUND: {
    state: 'danger',
    text:
      'We are still processing your order. Please refer to your confirmation email for more details.'
  },
  RECONTRACT_GENERIC_ERROR: {
    state: 'danger',
    title: "We're sorry this isn't working at the moment",
    text: `<a target='_blank' href=${feedbackFormUrl}>Tell us more</a> about the issue you've encountered to help us make things right.`
  },
  CIS_SESSION_TOKEN_EXPIRED: {
    state: 'danger',
    title: t('CIS_ERROR_INVALID_TITLE'),
    subTitle: t('CIS_ERROR_INVALID_TOKEN_SUBTITLE'),
    ctaText: t('CIS_ERROR_INVALID_TOKEN_CTA_TEXT'),
    ctaUrl: cisPointOfEntryUrl
  },
  CIS_SESSION_TOKEN_INVALIDATED: {
    state: 'danger',
    title: t('CIS_ERROR_INVALID_TITLE'),
    subTitle: t('CIS_ERROR_INVALID_TOKEN_SUBTITLE'),
    ctaText: t('CIS_ERROR_INVALID_TOKEN_CTA_TEXT'),
    ctaUrl: cisPointOfEntryUrl
  },
  CIS_SESSION_TOKEN_DOES_NOT_EXIST: {
    state: 'danger',
    title: t('CIS_ERROR_INVALID_TITLE'),
    subTitle: t('CIS_ERROR_INVALID_TOKEN_SUBTITLE'),
    ctaText: t('CIS_ERROR_INVALID_TOKEN_CTA_TEXT'),
    ctaUrl: cisPointOfEntryUrl
  },
  CIS_GENERIC_ERROR: {
    state: 'danger',
    title: t('CIS_ERROR_INVALID_TITLE'),
    subTitle: t('CIS_ERROR_GENERIC_SUBTITLE'),
    text: 'Call 1609 or try again later'
  },
  CIS_MYINFO_MISMATCHED_ERROR: {
    state: 'danger',
    text:
      'Please ensure that the NRIC/FIN linked to your OnePass account matches the one retrieved from MyInfo.'
  },
  CIS_MYINFO_MISMATCHED_NOTIFICATION: {
    title: 'The NRIC/FIN linked to your OnePass account does not match Myinfo',
    text:
      'Please use the Singpass account that matches your Singtel account when retrieving your information.'
  },
  CIS_MYINFO_FAILED_ERROR: {
    state: 'danger',
    text:
      "Please ensure that the NRIC/FIN linked to your mobile number you're recontracting for matches the one retrieved from MyInfo."
  },
  CIS_MYINFO_NRIC_MISMATCH: {
    state: 'danger',
    Content: ({ cisHomeHref }) => (
      <span>
        The mobile entered and NRIC/FIN from Myinfo does not match our records.{' '}
        <a href={cisHomeHref}>Back to CIS Home</a>
      </span>
    )
  },
  NGNIS_SEARCH_NUMBERS_ERROR: {
    state: 'danger',
    text: "Sorry. We're unable to display new numbers now"
  },
  NGNIS_NUMBER_RESERVED_ERROR: {
    state: 'danger',
    title: 'That number is no longer available',
    text: 'Please select another number',
    ctaText: 'Okay'
  },
  NGNIS_NUMBER_SELECTED_EXCEED_LIMIT_ERROR: {
    state: 'danger',
    title: "Sorry this isn't working at the moment",
    text: 'Please refresh the page or call 1609 for assistance',
    ctaText: 'Refresh page'
  },
  PORT_IN_SUBMITTED_ORDER_FOUND: {
    state: 'danger',
    title: '',
    text:
      'You have previously submitted a port-in order for this service number. The order is currently being processed. Please refer to your confirmation email to review your order details.',
    ctaText: 'Okay'
  },
  PORT_IN_NUMBER_NOT_ELIGIBLE: {
    state: 'danger',
    title: 'This number is not eligible for port-in',
    text: 'Please call 1609 for assistance',
    ctaText: 'Okay'
  },
  PORT_IN_NUMBER_GENERIC_ERROR: {
    state: 'danger',
    title: "Sorry this isn't working at the moment",
    text: 'Please call 1609 for assistance',
    ctaText: 'Okay'
  },
  ORDER_WIPE_PROMPT: {
    state: 'danger',
    title: 'Start a new order with mobile number ',
    text: 'This will clear all your previous selections.',
    ctaText: 'OK, proceed'
  },
  ADDON_GENERIC_ERROR: {
    state: 'danger',
    title: '',
    text: `We're sorry this isn't working at the moment. <a target="_blank" href=${feedbackFormUrl}>Tell us more</a> about the issue you've encountered to help us make things right`,
    ctaText: ''
  },
  ADDON_SHOW_5G_NOTE: {
    state: 'info',
    text: `All new SIM Cards are 5G compatible. If you are on a 5G plan or 5G add-on, switch to a 5G compatible SIM Card to experience optimal 5G speeds.`
  },
  API_FAILURE_GENERIC_ERROR: {
    state: 'danger',
    text:
      'For more assistance, please chat with us. Our online agents are here to help from 10am to 6pm daily.'
  }
};
