import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Row, Column, Text } from '@dls/web';
import colours from '../../theme/colours';

import getAemComponentByName from '../../helpers/getAemComponentByName';

import IconClose from '../../assets/svgs/close.svg';

import '../../fragments/aem-components';
import theme from '../../theme';

const DETOX_MAINTENANCE = 'DETOX_MAINTENANCE';

const StyledContainer = styled.div`
  background-color: ${theme.colours.nearBlack};
`;

const StyledRightAlignColumn = styled(props => <Column {...props} />)`
  text-align: right;
`;

const StyledMobileIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${theme.spacing(2)};
`;

const StyledIconClose = styled(IconClose)`
  stroke: ${theme.colours.white};
  vertical-align: middle;
  cursor: pointer;
`;

export const MaintenanceMessage = props => {
  const { data, location } = props;

  const { allAemPages } = data;

  const maintenanceMessage = getAemComponentByName(
    allAemPages,
    'MaintenanceMessage'
  );
  const { maintenanceGroupingModels = [] } = { ...maintenanceMessage };

  /**
   * Filter the array of maintenance messages based on validity
   */
  const validMaintenanceMessages = maintenanceGroupingModels.filter(group => {
    let { startDate, endDate } = group;
    // Typecasting the values into Numbers for easier computation
    startDate = Number(startDate);
    endDate = Number(endDate);

    const now = Date.now();
    return now >= startDate && now <= endDate;
  });

  const [maintenanceItem, setMaintenanceItem] = useState(null);

  useEffect(() => {
    const globalMessage = validMaintenanceMessages.find(
      ({ pageURL }) => pageURL === '*'
    );

    if (globalMessage) {
      setMaintenanceItem(globalMessage);
    } else {
      if (location.pathname === '/') {
        setMaintenanceItem(
          validMaintenanceMessages.find(({ pageURL }) => pageURL === '/')
        );
      } else {
        setMaintenanceItem(
          validMaintenanceMessages.find(
            ({ pageURL }) =>
              pageURL !== '/' && location.pathname.startsWith(pageURL)
          )
        );
      }
    }
  }, [location.pathname, validMaintenanceMessages]);

  // Set the maintenance onload to not show. This will prevent a flicker if users have chose to hide the message and on each page load
  const [showMaintenance, setShowMaintenance] = useState(false);

  // Retrieve the maintenance message status from the sessionStorage
  useEffect(() => {
    const isMaintenanceHidden = window.sessionStorage.getItem(
      DETOX_MAINTENANCE
    );

    if (isMaintenanceHidden) {
      // We always set the maintenance message to be dismissed should the key name exists
      setShowMaintenance(false);
    } else {
      setShowMaintenance(true);
    }
  }, [maintenanceItem]);

  const onClose = () => {
    window.sessionStorage.setItem(DETOX_MAINTENANCE, JSON.stringify(false));
    setShowMaintenance(false);
  };

  return showMaintenance && maintenanceItem ? (
    <StyledContainer data-testid="maintenance-message">
      <Grid>
        <Row center="sm" middle="md">
          <Column />
          <Column sm={12} md={8}>
            <Text color={colours.white} type="smallBody">
              {maintenanceItem.maintenanceMessage}
            </Text>
          </Column>
          <StyledRightAlignColumn>
            <StyledIconClose
              onClick={onClose}
              data-testid="maintenance-close"
            />
          </StyledRightAlignColumn>
        </Row>
      </Grid>
    </StyledContainer>
  ) : null;
};

MaintenanceMessage.propTypes = {
  /** The content of the maintenance message */
  data: PropTypes.object
};

/* istanbul ignore next */
const MaintenanceComponent = props => (
  <StaticQuery
    query={graphql`
      {
        allAemPages(
          filter: { pageUrl: { eq: "/eshop/admin/maintenance-message.xjson" } }
        ) {
          edges {
            node {
              title
              components {
                ...AemComponentsFragment
              }
            }
          }
        }
      }
    `}
    render={data => <MaintenanceMessage data={data} {...props} />}
  />
);

export default MaintenanceComponent;
