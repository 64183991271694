export const NOTICE_OF_ASSESSMENT_TABLE_COLUMNS = [
  {
    title: 'Year of Assessment',
    key: 'yearofassessment'
  },
  {
    title: 'Assessable Income',
    key: 'assessableincome'
  }
];

export const CPF_CONTRIBUTION_HISTORY_TABLE_COLUMNS = [
  {
    title: 'For Month',
    key: 'month'
  },
  {
    title: 'Paid On',
    key: 'date'
  },
  {
    title: 'Amount ($)',
    key: 'amount'
  },
  {
    title: 'Employer Contribution',
    key: 'employer'
  }
];

export const CREDIT_LIMIT_INDICATOR = {
  NOCHANGE: 'NOCHANGE',
  DECREASED: 'DECREASE',
  INCREASED: 'INCREASE'
};
