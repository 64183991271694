import { FULFILLMENT } from '../../constants';
import CONSTANTS from '../../constants/checkout';
import { ACTION_TYPES as APP_ACTION_TYPES } from '../../constants/actions';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

const initialState = {
  status: REQUEST_STATUS.IDLE,
  address: {},
  savedAddress: null,
  savedDeliveryAddress: null,
  previousSearch: '',
  error: null,
  hasInvalid: false,
  isLoading: false,
  validFloors: null,
  savedValidFloors: null
};

const addressInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ADDRESS_INFO_LOADING':
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        isLoading: true
      };

    case 'GET_ADDRESS_INFO_SUCCESS':
      const { payload: data, meta } = action;
      const { postcode, saveNewAddressInfo = true } = meta;

      let address = {};
      let validFloors = [];
      if (data && data.length) {
        address = data.find(d => d.unitNumber || d.floor) || data[0];
        validFloors = data
          .filter(
            floorData => !!floorData.floor && floorData.floor.includes('#')
          )
          .map(({ floor = '' }) => {
            const floorUnit = floor.split('#')[1];
            const [floorNumber, ...rest] = floorUnit?.split('-');
            const unitNumber = rest.join('-');

            return {
              floorNo: floorNumber.padStart(2, '0'),
              unitNo: unitNumber.padStart(2, '0')
            };
          });
      }
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        validFloors,
        address,
        savedAddress: saveNewAddressInfo ? address : state.savedAddress,
        previousSearch: postcode,
        hasInvalid: false,
        isLoading: false
      };
    case CONSTANTS.UPDATE_ADDRESS_INFO: {
      const physicalAddress = action.payload?.physicalAddress;
      const getAddressDetail = addressDetail => {
        return addressDetail && !addressDetail.includes('#')
          ? addressDetail
          : null;
      };
      const addressDetails = {
        ...physicalAddress,
        unitNumber: getAddressDetail(physicalAddress.originalUnitNumber),
        floor: getAddressDetail(physicalAddress.floorNumber)
      };
      const newSavedAddress = { ...state.savedAddress, ...addressDetails };
      return {
        ...state,
        address: {
          ...state.address,
          ...addressDetails
        },
        savedAddress: newSavedAddress,
        savedDeliveryAddress: state.savedDeliveryAddress || newSavedAddress
      };
    }

    case 'GET_ADDRESS_INFO_FAILED':
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error,
        address: {},
        validFloors: null,
        isLoading: false,
        previousSearch: action.meta?.postcode,
        hasInvalid: true
      };
    case FULFILLMENT.FULFILMENT_ACTIONS.RETRIEVE_ADDRESS_ID_LOADING: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FULFILLMENT.FULFILMENT_ACTIONS.RETRIEVE_ADDRESS_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case CONSTANTS.RESET_CHECKOUT_FORM_DATA:
    case CONSTANTS.RESET_ADDRESS_DATA:
    case APP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA:
      return initialState;

    case CONSTANTS.CONFIRM_NEW_ADDRESS:
      return {
        ...state,
        address: action.payload,
        savedDeliveryAddress: action.payload,
        savedValidFloors: state.validFloors
      };
    case CONSTANTS.REVERT_NEW_ADDRESS:
      return {
        ...state,
        address: state.savedDeliveryAddress,
        validFloors: state.savedValidFloors
      };

    case CONSTANTS.CONFIRM_SAVED_NEW_ADDRESS:
      return {
        ...state,
        savedAddress: state.address
      };
    default:
      return state;
  }
};

export const confirmNewAddress = address => dispatch => {
  dispatch({
    type: CONSTANTS.CONFIRM_NEW_ADDRESS,
    payload: address
  });
};

export const revertNewAddress = () => dispatch => {
  dispatch({
    type: CONSTANTS.REVERT_NEW_ADDRESS
  });
};

export const confirmSavedNewAddress = () => dispatch => {
  dispatch({
    type: CONSTANTS.CONFIRM_SAVED_NEW_ADDRESS
  });
};

export default addressInfo;
