import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Column } from '@lux/components';
import { FootnotePrimary } from '../Base';
import { noop, remCalc } from '@lux/helpers';

import IconArrowUp from '@lux/components/src/assets/svgs/arrow-up.svg';
import IconArrowDown from '@lux/components/src/assets/svgs/arrow-down.svg';
import theme from '../../theme';

const FormGroup = styled.div`
  position: relative;
  cursor: pointer;
`;

const StyledColumn = styled(props => <Column {...props} />)`
  padding: 0;
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${theme.spacing(2)} ${theme.spacing(1)};
  pointer-events: none;
`;

const MobileSelect = styled.select`
  width: 100%;
  background-color: ${theme.colours.grey_50};
  padding: ${theme.spacing(2)};
  border: none;
  border-bottom: 1px solid ${theme.colours.grey_400};
  appearance: none;
  border-radius: 0;
  outline: none;
`;

const DesktopSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${theme.colours.grey_50};
  padding: ${theme.spacing(2)};
  border: none;
  border-bottom: 1px solid ${theme.colours.grey_400};
  appearance: none;
  border-radius: 0;
  outline: none;
`;

const SelectedOption = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const StyledList = styled.ul`
  list-style-type: none;
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: 0 2px 5px -1px ${theme.colours.grey_400};
  max-height: ${remCalc(380)};
  overflow-y: auto;
  z-index: 99;
`;

const ListItem = styled.li`
  background-color: ${theme.colours.grey_50};
  padding: ${theme.spacing(2)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colours.powderBlue};
  }

  ${p =>
    p.selected &&
    css`
      background-color: ${theme.colours.powderBlue};
    `};
`;

const HintMessage = styled(FootnotePrimary)`
  display: block;
  height: ${remCalc(16)};
  color: ${theme.states.danger.colour};
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(2)};
  cursor: text;
`;

const Select = props => {
  const { disabled, hintMessage, items, onChange, tabIndex, ...rest } = props;

  const selectedIndexFromProps = rest.selectedIndex;
  const [selectedIndex, setSelectedIndex] = useState(
    selectedIndexFromProps || 0
  );
  const [isListOpen, toggleListOpen] = useState(false);

  useEffect(() => {
    selectedIndexFromProps > 0 && setSelectedIndex(selectedIndexFromProps);
  }, [selectedIndexFromProps]);

  const selectItem = value => {
    setSelectedIndex(items.findIndex(item => item.value === value));
    toggleListOpen(false);
    onChange(items.find(item => item.value === value));
  };

  const handleKeyDown = e => {
    switch (e.key) {
      // Enter Key
      case 'Enter':
        if (isListOpen) {
          selectItem(items[selectedIndex].value);
        } else {
          toggleListOpen(true);
        }
        break;
      // Escape Key
      case 'Escape':
        toggleListOpen(false);
        break;
      // Space
      case 'Space':
        toggleListOpen(true);
        break;
      // Up Arrow
      case 'ArrowUp':
        /* istanbul ignore else */
        if (isListOpen) {
          selectedIndex > 0 && setSelectedIndex(selectedIndex - 1);
        } else {
          e.preventDefault();
        }
        break;
      // Down Arrow
      case 'ArrowDown':
        /* istanbul ignore else */
        if (isListOpen) {
          selectedIndex < items.length - 1 &&
            setSelectedIndex(selectedIndex + 1);
        } else {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const itemsList = useRef(null);

  useLayoutEffect(() => {
    const handleOutsideClick = e => {
      const isOutside =
        isListOpen && !Array.from(itemsList.current).includes(e.target);

      if (isListOpen && isOutside) {
        toggleListOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [isListOpen]);

  const toggleOpenList = e => {
    e.stopPropagation();
    toggleListOpen(!isListOpen);
  };

  return (
    <Fragment>
      <StyledColumn sm={false} md={12}>
        <FormGroup>
          <DesktopSelect
            onClick={toggleOpenList}
            disabled={disabled}
            onKeyDown={e => handleKeyDown(e)}
            tabIndex={tabIndex}
            {...rest}
          >
            <SelectedOption data-testid="selected-option">
              {items[selectedIndex].text}
            </SelectedOption>
          </DesktopSelect>
          <Icon
            onClick={() => !disabled && toggleListOpen(!isListOpen)}
            data-testid="select-icon"
          >
            {isListOpen ? (
              <IconArrowUp width={8} height={8} />
            ) : (
              <IconArrowDown width={8} height={8} />
            )}
          </Icon>
          {isListOpen && (
            <StyledList ref={itemsList} data-testid="items-list">
              {items.map(({ text, value }, key) => (
                <ListItem
                  key={key}
                  selected={selectedIndex === key}
                  onClick={() => selectItem(value)}
                  data-testid={`item-${value}`}
                >
                  {text}
                </ListItem>
              ))}
            </StyledList>
          )}
          {hintMessage && <HintMessage>{hintMessage}</HintMessage>}
        </FormGroup>
      </StyledColumn>
      <StyledColumn sm={12} md={false}>
        <FormGroup>
          <MobileSelect
            data-testid="mobile-select"
            disabled={disabled}
            onChange={e => selectItem(e.target.value)}
            value={items[selectedIndex].value}
            {...rest}
          >
            {items.map(({ text, value }, index) => (
              <option key={index} value={value}>
                {text}
              </option>
            ))}
          </MobileSelect>
          <Icon>
            {isListOpen ? (
              <IconArrowUp width={8} height={8} />
            ) : (
              <IconArrowDown width={8} height={8} />
            )}
          </Icon>
          {hintMessage && <HintMessage>{hintMessage}</HintMessage>}
        </FormGroup>
      </StyledColumn>
    </Fragment>
  );
};

Select.defaultProps = {
  items: [],
  selectedIndex: 0,
  onChange: noop
};

Select.propTypes = {
  /** The message to appear below the input for a success or error state */
  hintMessage: PropTypes.string,
  /** The items in the select */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  /** Set the selectedIndex */
  selectedIndex: PropTypes.number,
  /** Set the tab index value */
  tabIndex: PropTypes.number,
  /** The onChange event handler */
  onChange: PropTypes.func
};

export default Select;
