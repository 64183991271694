export const CHOOSE_SIM_TYPE = {
  SIM_TYPE_ESIM: 'eSIM',
  SIM_TYPE_PhysicalSIM: 'physicalSIM',
  SIM_TYPE_noSIM: 'noSIM'
};

export const selectedSimTypeMap = {
  '5G3IN1': CHOOSE_SIM_TYPE.SIM_TYPE_PhysicalSIM,
  eSIM5GPost: CHOOSE_SIM_TYPE.SIM_TYPE_ESIM
};

export type SimTypeId = 'noSIM' | 'physicalSIM' | 'eSIM';
export type SimTransactionType = 'newSignup' | 'recontract' | 'portIn';

export interface BuyFlowType<Ttype> {
  newSignup: Ttype;
  recontract: Ttype;
  portIn: Ttype;
}
export interface ChooseSimType {
  simTypeId: SimTypeId;
  simTitle: string;
  simPrice: BuyFlowType<number | string>;
  transactionType: BuyFlowType<boolean>;
  descWithDevice: string;
  descWithoutDevice: string;
  footerWithDevice: string;
  footerWithoutDevice: string;
  notAvailable: boolean;
  footerNotAvailable: string;
}

export const CHOOSE_NUMBER_TAB = {
  NEW: '#new-number',
  PORT_IN: '#port-in'
};

export const eSIMCompatibleTags = [
  'Dual SIM (nano-SIM and eSIM) Dual eSIM support',
  'Dual SIM (2Nano + eSIM)',
  'Dual SIM (Nano + eSIM)'
];
