import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import RRPShoppingCart from '../../../components/RRPShoppingCart';

import '../../../fragments/rrp-product';

export const RRPShoppingCartPage = props => {
  const { data } = props;

  return (
    <>
      <SEO title="RRP Shopping Cart" />
      <RRPShoppingCart data={data} />
    </>
  );
};

export const query = graphql`
  query RRPShoppingCartQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
    allAddonMobileLob {
      edges {
        node {
          addonId
        }
      }
    }
    allAspireBillingOffer {
      edges {
        node {
          boId
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
    allPlan {
      edges {
        node {
          ...PlanDetailFragment
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    allRrpCart {
      edges {
        node {
          type
          popupTitle
          popupDescription
          popupCTAURL
          popupCTALabel
          maxLimit
          description
        }
      }
    }
  }
`;

export default RRPShoppingCartPage;
