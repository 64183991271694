import React from 'react';
import { Modal, Spacing, Text, Button } from '@dls/web';

type ModalTemplateProps = {
  templateId: string;
  open: boolean;
  title: string;
  content: string;
  onClose: () => void;
  ctaText: string;
  onCtaClick: () => void;
};

export const ModalTemplate = ({
  templateId,
  open,
  title,
  content,
  onClose,
  ctaText,
  onCtaClick
}: ModalTemplateProps) => {
  return (
    <Modal
      data-testid={`modal-template-${templateId}`}
      visible={open}
      title={title}
      onClose={onClose}
    >
      <Modal.Content>
        <Spacing bottom={3}>
          <Text type="body">{content}</Text>
        </Spacing>
        {ctaText && <Button onClick={onCtaClick}>{ctaText}</Button>}
      </Modal.Content>
    </Modal>
  );
};

export default ModalTemplate;
