import * as React from 'react';
import styled from 'styled-components';
import { Completed, Alert, Default } from '@dls/assets/dist/icons/web';
import { Text, Row, Column, Spacing } from '@dls/web';

const StyledIcon = styled.div`
  > svg {
    width: 64px;
    height: 64px;
  }
`;

export const IconMap = {
  success: Completed,
  error: Alert,
  info: Default
};

export type ResultStatusType = keyof typeof IconMap;

export interface ResultProps {
  icon?: React.ReactNode;
  status?: ResultStatusType;
  statusText?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  liner?: React.ReactNode;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

interface IconProps {
  icon: React.ReactNode;
  status: ResultStatusType;
}

const Icon: React.FC<IconProps> = ({ icon, status }) => {
  const iconNode = React.createElement(IconMap[status], { size: 64 });

  if (icon === null || icon === false) {
    return null;
  }

  return (
    <StyledIcon data-testid="result-icon" style={{ marginBottom: 16 }}>
      {icon || iconNode}
    </StyledIcon>
  );
};

interface ActionProps {
  action: React.ReactNode;
}

const Action: React.FC<ActionProps> = ({ action }) => {
  if (!action) {
    return null;
  }
  return (
    <Spacing bottom={4} top={2}>
      {action}
    </Spacing>
  );
};

export const Result: React.FC<ResultProps> = ({
  title,
  liner,
  subTitle,
  icon,
  status = 'info',
  statusText,
  action,
  children
}) => {
  return (
    <Row center="xs">
      <Column noGutter xs={11} lg={5}>
        <Icon icon={icon} status={status} />
        <Spacing bottom={2}>
          <Text type="pageTitle" data-testid="result-status-text">
            {statusText}
          </Text>
        </Spacing>
        {title && (
          <Text type="boldBody" data-testid="result-title">
            {title}
          </Text>
        )}
        {liner && (
          <Text type="body" fontWeight="bold" data-testid="result-liner">
            {liner}
          </Text>
        )}
        {subTitle && (
          <Text type="body" data-testid="result-subtitle">
            {subTitle}
          </Text>
        )}
        <Action action={action} />
        {children && <div data-testid="result-content">{children}</div>}
      </Column>
    </Row>
  );
};

export default Result;
