import wrapWithProvider from './wrap-with-provider';
import wrapWithPersistGate from './wrap-with-persist-gate';
import 'unfetch/polyfill/index';
import smoothscroll from 'smoothscroll-polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import isFeatureFlagEnabled from './src/helpers/feature-flags';
import { FEATURE_FLAG_ENABLE_QUALTRICS } from './src/types/featureFlag.types';
import { isQualtricsPage } from './src/helpers/common';

smoothscroll.polyfill();

export const onRouteUpdate = ({ location }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'optimize.activate' });

  if (process.env.NODE_ENV === 'development') {
    console.log('Route change:', location.pathname);
    // Fix the page not found in develop env
    const GATSBY_NAVIGATE = window.___navigate || {};
    window.addEventListener('popstate', () =>
      GATSBY_NAVIGATE(window.location.pathname, { replace: true })
    );
  }
  window.detoxAbortController && window.detoxAbortController.abort();
  const isQualtricsEnabled = isFeatureFlagEnabled(
    FEATURE_FLAG_ENABLE_QUALTRICS
  );
  
  /**
   * Inject Qualtrics Script if feature is enabled and location path matches 
   **/
  if (isQualtricsEnabled && isQualtricsPage(location?.pathname)) {
    const script = document.createElement('script');
    script.src = 'qualtrics-survey.js';
    script.defer = true;
    document.body.appendChild(script);
  }
};

export const wrapPageElement = wrapWithPersistGate;
export const wrapRootElement = wrapWithProvider;
