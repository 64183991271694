import CONSTANTS from '../constants/checkout';

interface DownloadedDocument {
  fileName: string;
  documentID: string;
  fileContent: string;
}

interface DocumentsData {
  documentData: DownloadedDocument[];
}

type DocumentsActionsTypes = 'RETRIEVE_DOCUMENT_CONTENT_SUCCESS_DATA';

interface DocumentsActions {
  type: DocumentsActionsTypes;
  value: DownloadedDocument;
}

export const initialState = {
  documentData: []
};

const documentsReducer = (
  state = initialState,
  action: DocumentsActions
): DocumentsData => {
  switch (action.type) {
    case CONSTANTS.RETRIEVE_DOCUMENT_CONTENT_SUCCESS_DATA: {
      const { fileName = '', documentID, fileContent = '' } = action.value;
      const updatedFileContent = `data:image/${fileName
        .split('.')
        .pop()};base64,${fileContent}`;

      const newUpload = {
        fileName,
        documentID,
        fileContent: updatedFileContent
      };
      const updatedDocumentData = [...state.documentData, newUpload];

      return {
        documentData: updatedDocumentData
      };
    }
    default:
      return state;
  }
};

export default documentsReducer;
