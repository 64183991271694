import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUrlParams, getCookie } from '@lux/helpers';
import { bridgeActions } from '@detox/actions';

import CONSTANT from '../constants/common';

const {
  DETOX_LIGHTSABER_SESSION_TOKEN,
  Detox_MasterOrderId,
  Detox_ProductOrderId
} = CONSTANT;

export const BenjaminShearesPage = () => {
  const dispatch = useDispatch();
  const { retrieveBridgeData } = bridgeActions;

  const getCachedData = async () => {
    const cookieToken: string = getCookie(DETOX_LIGHTSABER_SESSION_TOKEN);
    const masterOrderId: string = getCookie(Detox_MasterOrderId); // RRP Fullprice
    const productOrderId: string = getCookie(Detox_ProductOrderId); // RRP Ipp
    const apigeeAuthToken: string = window.sessionStorage.getItem(
      'ApigeeAuthToken'
    );

    const payload = {
      uxfToken: cookieToken,
      apigeeAuthToken: apigeeAuthToken,
      key: productOrderId || masterOrderId
    };

    await dispatch(retrieveBridgeData(payload));
    const urlParams = getUrlParams();

    window.location.replace(`/${urlParams.page}`);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const apigeeAuthToken: string = window.sessionStorage.getItem(
        'ApigeeAuthToken'
      );

      if (apigeeAuthToken) {
        clearInterval(interval);
        getCachedData();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default BenjaminShearesPage;
