import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { graphql, navigate } from 'gatsby';
import { cartActions, checkoutActions, rrpCartActions } from '@detox/actions';
import { ShoppingCart as RRPShoppingCartModule } from '@common-modules/shopping-cart';
import RRPModal from '../../../components/RRPShoppingCart/RRPModal';
import GenericError from '../../../components/ShoppingCart/GenericError';
import { APP_TYPE_ANY, KeyValue } from '../../../types/common.types';
import { ORDER, RRPCART, FULFILLMENT } from '../../../constants';
import { rrpModal } from '../../../config/notifications';
import { navigation } from '../../../middlewares/navigation-constants';
import { trans as t } from '../../../helpers/localisation';
import { getShoppingCartDataModel } from '../../../helpers/cart';
import { getProductBySku } from '../../../helpers/rrp-product';
import {
  isValidUrl,
  flattenNodes,
  dataLayerPush
} from '../../../helpers/common';
import '../../../fragments/rrp-product';
import { getCartError, getCartErrorDataModel } from '../../../helpers/cart';
import { getAccessoryOnlineStock } from '../../../helpers/accessory';
import { useModal } from '../../../hooks/useModal';
import { RRPCartState } from '../../../reducers/rrpCart';
import { getCatalogPath } from '../../../helpers/rrpCart';
import { rrpCheckoutDataLayer } from '../../../helpers/dataLayerHelpers';
import { SkeletonType } from '../../../components/SkeletonLoader';
import { useLoader } from '../../../hooks/useLoader';

const { TYPE } = ORDER;

type RrpIppShoppingCartPageProps = {
  data: KeyValue;
  cart: KeyValue;
  isLoading: boolean;
  customerId: string;
  isSkeletonLoading: boolean;
  fulfillment: KeyValue;
  getShoppingCartData: (options: KeyValue) => void;
  emptyCart: (options: KeyValue) => Promise<void>;
  checkout: (options: KeyValue) => Promise<void>;
  rrpCart: RRPCartState;
  cancelMasterOrder: (params: {
    productOrderId: string;
    customerId: string;
    productOrderItemId: string;
  }) => void;
};

export const RrpIppShoppingCartPage = ({
  isLoading,
  isSkeletonLoading,
  data,
  cart,
  fulfillment,
  getShoppingCartData,
  emptyCart,
  checkout,
  rrpCart,
  cancelMasterOrder
}: RrpIppShoppingCartPageProps): React.ReactElement => {
  const errorScenariosFlattened = flattenNodes(data.allErrorMessage);
  const { cartOrder, order } = cart;
  const [checkoutClicked, setCheckoutClicked] = useState<boolean>(false);
  const productOrder = order?.productOrder;
  const isChangingProduct = useRef(false);
  const allRrpProduct = flattenNodes(data.allRrpProduct);
  const productVariantBySku = getProductBySku(allRrpProduct);

  const [modalType, setModalType] = useState(null);
  const [errorScenarioMessage, setErrorScenarioMessage] = useState<
    APP_TYPE_ANY
  >();

  const { renderedModal, setModalData } = useModal();

  const getIppOrderData = () => {
    return { ...rrpCart?.ippOrderData };
  };

  const fetchShoppingCartData = async () => {
    getShoppingCartData({
      ...getIppOrderData(),
      isRrpIppOrder: true
    });
  };

  const { Loader } = useLoader({
    loadings: [isLoading],
    skeletonLoadings: [isSkeletonLoading],
    options: {
      type: SkeletonType.sideBar
    }
  });

  useEffect(() => {
    if (rrpCart.finishCancelMasterOrder && !rrpCart.finishEmptyCart) {
      if (isChangingProduct.current) {
        navigate(`/${navigation.RRP_PRODUCT_CATALOG}`, { replace: true });
      } else {
        navigate(rrpCart.pagePathname, { replace: true });
      }
    }
  }, [rrpCart.finishCancelMasterOrder]);
  useEffect(() => {
    if (rrpCart?.ippOrderData) {
      fetchShoppingCartData();
    }
  }, []);

  const handleReservationFailed = () => {
    emptyCart({
      customerId: getIppOrderData()?.customerId,
      productOrderId: productOrder?.productOrderId,
      productOrderItemId: productOrder?.productOrderItemId
    });
    setErrorScenarioMessage({
      title: t('GENERIC_ERROR_TITLE'),
      errorText: t('DROP_SHIPPING.IPP.NO_LONGER_AVAILABLE'),
      secondaryButtonText: t('DROP_SHIPPING.IPP.START_SHOPPING')
    });
  };

  const checkStockAvailability = async () => {
    const sku = order.accessories[0]?.sku;
    const availabilityList = await getAccessoryOnlineStock([sku]);
    const stockAvailable = availabilityList?.filter(
      item => item?.itemCode === sku && item?.availableQty > 0
    );
    if (!stockAvailable?.length) {
      handleReservationFailed();
    }
    return stockAvailable?.length;
  };

  const dataModel = getShoppingCartDataModel({
    cartOrder,
    productVariantBySku
  });

  useEffect(() => {
    if (order?.accessories?.length && Object.keys(dataModel)?.length) {
      checkStockAvailability();
    }
  }, [order?.accessories]);

  const handleChangeItem = item => {
    isChangingProduct.current = !!item.equipmentId;
    const { customerId, productOrderId, productOrderItemId } =
      rrpCart.ippOrderData || {};
    return setModalData({
      title: t('RRP_REMOVE_CART_ITEM_TITLE') as string,
      buttonText: t('PROCEED') as string,
      secondaryButtonText: t('KEEP_CURRENT_CART_TEXT') as string,
      message: t('RRP_REMOVE_CART_ITEM_MESSAGE') as string,
      visible: true,
      callback: () => {
        cancelMasterOrder({ customerId, productOrderId, productOrderItemId });
      }
    });
  };

  const handleEmptyCart = async () => {
    await emptyCart({
      customerId: getIppOrderData().customerId,
      productOrderId: productOrder?.productOrderId,
      productOrderItemId: productOrder?.productOrderItemId
    }).then(() => {
      navigate(`/${navigation.EMPTY_SHOPPING_CART}`, {
        state: {
          shopUrl: getCatalogPath()
        }
      });
    });
  };

  const handleCheckout = async () => {
    const order = {
      type: TYPE.NEW,
      productOrderId: productOrder?.productOrderId,
      productOrderReferenceNumber: productOrder?.productOrderReferenceNumber,
      productId: productOrder?.productId,
      productOrderItemId: productOrder?.productOrderItemId,
      cart: { ...cart, ...productOrder },
      isRrpIppOrder: true
    };
    const isStockAvailable = await checkStockAvailability();
    if (isStockAvailable) {
      dataLayerPush(
        rrpCheckoutDataLayer({
          baseParams: {
            cartItems: rrpCart?.addedItems,
            location: window.location,
            ippDetails: rrpCart?.tempCartItem
          }
        })
      );
    }
    isStockAvailable &&
      (await checkout(order).then(() => {
        setCheckoutClicked(true);
      }));
  };

  const renderModal = () => {
    switch (modalType) {
      case RRPCART.MODAL_TYPE.EMPTY_CART:
        return (
          <RRPModal
            header={rrpModal?.[modalType]?.title}
            visible={Boolean(modalType)}
            message={rrpModal?.[modalType]?.body}
            onClose={() => setModalType(null)}
            ctaText={rrpModal?.[modalType]?.ctaText}
            onButtonClick={handleEmptyCart}
          />
        );
      default:
        return null;
    }
  };

  const renderCartErrors = () => {
    return (
      <GenericError
        title={errorScenarioMessage?.title}
        errorText={errorScenarioMessage?.errorText}
        secondaryButtonText={
          errorScenarioMessage?.secondaryButtonText ||
          t('DROP_SHIPPING.BACK_TO_PRODUCT_CATALOGUE.CTA')
        }
        onSecondaryClick={() => navigate(getCatalogPath())}
      />
    );
  };

  const showValidateError = () => {
    const errorScenarioData = getCartError(
      cart?.checkoutErrorData,
      errorScenariosFlattened
    );
    const cartErrorDataModel = getCartErrorDataModel(errorScenarioData);
    setErrorScenarioMessage(cartErrorDataModel);
  };

  useEffect(() => {
    if (
      checkoutClicked &&
      !cart?.checkoutLoading &&
      !fulfillment?.allocateOrReserve?.isLoading
    ) {
      if (
        fulfillment?.allocateOrReserve?.status ===
        FULFILLMENT.REQUEST_STATUS.FAILED
      ) {
        handleReservationFailed();
      } else if (cart?.checkoutErrorData && cart?.checkoutOrderError) {
        showValidateError();
      } else if (!cart?.checkoutOrderError) {
        navigate(`/${navigation.RRP_IPP_FULFILMENT}`);
      }
    } else if (cart.error) {
      showValidateError();
    }
  }, [
    cart.error,
    cart?.checkoutLoading,
    cart?.checkoutErrorData,
    cart?.checkoutOrderError,
    checkoutClicked
  ]);

  if (errorScenarioMessage) {
    return renderCartErrors();
  }
  return (
    <div data-testid="rrp-ipp-shopping-cart">
      <Loader>
        {renderModal()}
        <RRPShoppingCartModule
          data={dataModel}
          callbacks={{
            onRemoveItem: handleChangeItem,
            onEmptyCart: () => setModalType(RRPCART.MODAL_TYPE.EMPTY_CART),
            onCheckout: handleCheckout
          }}
          configs={{
            loading: isLoading
          }}
          localisation={{}}
        />
      </Loader>
      {renderedModal}
    </div>
  );
};

export const query = graphql`
  query RrpIppShoppingCartQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
    allErrorMessage {
      edges {
        node {
          ...AemComponentsFragment
        }
      }
    }
  }
`;

/* istanbul ignore next */
const mapStateToProps = state => {
  const { cart, user, bridge, fulfillment, rrpCart } = state;

  return {
    isSkeletonLoading: cart.skeletonLoading,
    isLoading: cart.loading || cart.checkoutLoading || rrpCart.isLoading,
    cart,
    customerId: user?.information?.clientContext?.customers?.[0]?.customerId,
    fulfillment,
    rrpCart
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  const { getShoppingCartData, emptyCart } = cartActions;
  const { checkout } = checkoutActions;

  return {
    cancelMasterOrder: params =>
      dispatch(rrpCartActions.deleteRRPIppOrder(params)),
    emptyCart: options => dispatch(emptyCart(options)),
    getShoppingCartData: options => dispatch(getShoppingCartData(options)),
    checkout: options => dispatch(checkout(options))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RrpIppShoppingCartPage);
