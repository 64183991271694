import React, { useEffect } from 'react';
import { getCookie } from '@lux/helpers';
import PropTypes from 'prop-types';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import { WorryFreeLogin } from '../WorryFreeLogin/WorryFreeLogin';
import { getUIAMLoginUrl } from '../../config';
import { navigation } from '../../middlewares/navigation-constants';
import CONSTANT from '../../constants/common';

const WORRY_FREE_LOGIN = 'WORRY_FREE_LOGIN';
const WORRY_FREE_OTP = 'WORRY_FREE_OTP';
const LOGGED_IN = 'LOGGED_IN';

const Auth = props => {
  const {
    isAuthenticated,
    setAuthStatus,
    authStatus,
    isWorryFreeEnabled,
    worryFreeInitialFormData,
    loading
  } = props;

  useEffect(() => {
    const isLoginRequired = !isWorryFreeEnabled && !isAuthenticated;
    const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
    if (isLoginRequired) {
      if (!uiamAccessToken) {
        window.open(getUIAMLoginUrl(navigation.CHOOSE_NUMBER_PAGE), '_self');
      }
    } else if (!isAuthenticated) {
      setAuthStatus(WORRY_FREE_LOGIN);
    } else {
      setAuthStatus(LOGGED_IN);
    }
  }, [isAuthenticated, isWorryFreeEnabled, setAuthStatus]);

  if (loading) {
    return (
      <div data-testid="auth-loading">
        <LoadingOverlay />
      </div>
    );
  }

  if (authStatus === LOGGED_IN || !authStatus) {
    return props.children;
  }
  return <WorryFreeLogin initialFormData={worryFreeInitialFormData} />;
};

Auth.defaultProps = {
  isWorryFreeEnabled: true
};

Auth.propTypes = {
  isAuthenticated: PropTypes.bool,
  setAuthStatus: PropTypes.func,
  authStatus: PropTypes.oneOf([WORRY_FREE_LOGIN, WORRY_FREE_OTP, LOGGED_IN]),
  /** Set the if Worry Free login is enabled */
  isWorryFreeEnabled: PropTypes.bool,
  worryFreeInitialFormData: PropTypes.shape({
    mobileNumber: PropTypes.string,
    idNumber: PropTypes.string
  }),
  loading: PropTypes.bool,
  children: PropTypes.any
};

export default Auth;
