import { ACTION_TYPES } from '@detox/actions';
import { ACTION_TYPES as APP_ACTION_TYPES } from '../constants/actions';
import { RetrieveMyInfoUrl } from '../types/registrationCheckout';
import CONSTANTS from '../constants/checkout';
import { CustomerInfoResponse } from '../types/checkout.types';
import { APP_TYPE_ANY } from '../types/common.types';
import { isInCompleteSingpassData } from '../components/Checkout/helper';

const {
  RETRIEVE_MY_INFO_URL_LOADING,
  RETRIEVE_MY_INFO_URL_SUCCESS,
  RETRIEVE_MY_INFO_URL_FAILURE,
  GET_CUSTOMER_MY_INFO_LOADING,
  GET_CUSTOMER_MY_INFO_SUCCESS,
  GET_CUSTOMER_MY_INFO_FAILURE
} = ACTION_TYPES.CUSTOMER_MY_INFO;

export interface CustomerMyInfo {
  isLoading: boolean;
  isError: boolean;
  retrieveMyInfoUrl: RetrieveMyInfoUrl;
  customerInfoResponse: CustomerInfoResponse;
  isCompleteMyInfoData: boolean;
  successCode: string;
}

interface CustomerMyInfoActions {
  type: string;
  value?: APP_TYPE_ANY;
}

export const initialState: CustomerMyInfo = {
  isLoading: false,
  isError: false,
  retrieveMyInfoUrl: null,
  customerInfoResponse: null,
  isCompleteMyInfoData: false,
  successCode: ''
};

export const customerMyInfoReducer = (
  state = initialState,
  action: CustomerMyInfoActions
): CustomerMyInfo => {
  switch (action.type) {
    case GET_CUSTOMER_MY_INFO_LOADING:
    case RETRIEVE_MY_INFO_URL_LOADING: {
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    }
    case APP_ACTION_TYPES.USER_IDENTITY_CHECK.CLEAR_MY_INFO_URL: {
      return {
        ...state,
        retrieveMyInfoUrl: null
      };
    }
    case RETRIEVE_MY_INFO_URL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        retrieveMyInfoUrl: action.value
      };
    }
    case GET_CUSTOMER_MY_INFO_FAILURE:
    case RETRIEVE_MY_INFO_URL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    }
    case CONSTANTS.SET_MYINFO_SUCCESS_CODE: {
      return {
        ...state,
        successCode: action.value
      };
    }
    case GET_CUSTOMER_MY_INFO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        customerInfoResponse: action.value,
        isCompleteMyInfoData: isInCompleteSingpassData(
          action.value?.ImplCustomerInfoResponse || {}
        )
      };
    }
    case APP_ACTION_TYPES.ORDER.RESET_ORDER:
    case APP_ACTION_TYPES.PAYMENT.CLEAR_BUY_FLOW_DATA:
    case CONSTANTS.CHECKOUT_CLEAR_CUSTOMER_MY_INFO: {
      return initialState;
    }
    default:
      return state;
  }
};

export default customerMyInfoReducer;
