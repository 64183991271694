import React from 'react';
import { Column, Row, Spacing, Text, Button } from '@dls/web';
import Notification from '../Notification';
import colours from '../../theme/colours';

const ErrorPanel = ({
  pageTitle = null,
  errorMessage = null,
  errorNote = null,
  actionButtonText = null,
  onActionClick = () => {
    // void
  },
  ...props
}) => {
  const hasError = errorMessage || errorNote;
  return hasError ? (
    <Row {...props}>
      <Column xs={12} sm={12} md={8} lg={8}>
        {pageTitle && (
          <Spacing bottom={2} bottomMd={3}>
            <Text type="bannerTitle">{pageTitle}</Text>
          </Spacing>
        )}
        <Spacing bottom={5} bottomMd={9}>
          {errorMessage && (
            <Spacing bottom={3} bottomMd={5}>
              <Notification state="danger">{errorMessage}</Notification>
            </Spacing>
          )}
          {errorNote && (
            <Text type="smallBody" color={colours.grey_600}>
              {errorNote}
            </Text>
          )}
        </Spacing>
        {actionButtonText && (
          <Button
            data-testid="error-panel-action-button"
            onClick={onActionClick}
          >
            {actionButtonText}
          </Button>
        )}
      </Column>
      <Column sm={false} xs={false} md={true} lg={true} />
    </Row>
  ) : null;
};

export default ErrorPanel;
