import React from 'react';
import { Modal, Spacing, Text, Button } from '@dls/web';
import { trans as t } from '../../../helpers/localisation';

interface OOSModalProps {
  oosModalToggle: boolean;
  setOOSModalToggle: () => void;
}

export const OOSModal = (props: OOSModalProps): JSX.Element => {
  const { oosModalToggle, setOOSModalToggle } = props;

  return (
    <Modal
      data-testid="tnc-modal"
      visible={oosModalToggle}
      title={t('ITEM_WENT_OOS_TITLE')}
      backdropClosable={false}
      onClose={setOOSModalToggle}
      scrollable
    >
      <Modal.Content>
        <Spacing bottom={2}>
          <Text>{t('ITEM_WENT_OOS_MESSAGE')}</Text>
        </Spacing>
        <Spacing responsive={false} top={3}>
          <Button secondary onClick={setOOSModalToggle}>
            {t('START_BROWSING')}
          </Button>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};

export default OOSModal;
