import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Modal, Spacing, Text } from '@dls/web';
import { useSelector } from 'react-redux';
import { KeyValue } from '../../types/common.types';
import useTranslate from '../useTranslation';
import { navigate } from 'gatsby';
import { OrderSummaryState } from '../../reducers/orderSummary';
import { isTabAmendedFlow } from '../../helpers/common';

export const useAmendedTab = (): {
  renderAmendedErrorPopup: () => ReactElement;
  tabIsAmended?: boolean;
} => {
  const { productOrder, orderSummary } = useSelector((state: KeyValue) => {
    return {
      productOrder: state?.order?.productOrder || {},
      orderSummary: state.orderSummary as OrderSummaryState
    };
  });
  const { t } = useTranslate();
  const [tabAmended, setTabAmended] = useState(false);
  const errorInfo = JSON.parse(window.localStorage.getItem('OrderInMultiTab'));
  const errorStateHavingAmenedTabInfo =
    orderSummary?.paymentErrorResponse?.ErrorInfo;

  useEffect(() => {
    const isConcurrentOrder =
      productOrder &&
      errorInfo?.backendErrorInfo?.includes(productOrder?.productOrderId);
    const isStateUpdateWithPaymentErr =
      errorStateHavingAmenedTabInfo &&
      isTabAmendedFlow(errorStateHavingAmenedTabInfo);
    if (!isStateUpdateWithPaymentErr) {
      setTabAmended(isConcurrentOrder);
    }
  }, [errorInfo, productOrder]);

  const onCloseErrorPopup = (isBtnClick = false) => {
    return navigate('/', { replace: true });
  };

  const renderAmendedErrorPopup = () => {
    if (tabAmended) {
      return (
        <Modal
          visible={true}
          title={t('PRODUCT_CONFIGURATION_ERROR_TITLE')}
          onClose={() => onCloseErrorPopup(false)}
          backdropClosable={false}
        >
          <Modal.Content>
            <Text type="body">{t('ORDER_AMENDED_IN_OTHER_FLOW')}</Text>
            <Spacing responsive={false} top={3}>
              <Button secondary onClick={() => onCloseErrorPopup(true)}>
                {t('OKAY_GOT_IT')}
              </Button>
            </Spacing>
          </Modal.Content>
        </Modal>
      );
    }
    return null;
  };

  return { renderAmendedErrorPopup, tabIsAmended: tabAmended };
};
