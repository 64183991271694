import React from 'react';
import styled, { css } from 'styled-components';
import { Text, TextLink, withTheme } from '@dls/web';
import { cisPointOfEntryUrl } from '../../config/links';
import useTranslate from '../../hooks/useTranslation';
import imageCisFrancis from '../../assets/images/cis-francis.png';
import theme from '../../theme';
const StyledStrip = withTheme(styled.div`
  ${({ coreTheme }) => css`
    display: flex;
    align-items: center;
    height: 82px;
    padding: ${theme.spacing(2)};
    @media (min-width: ${coreTheme.brk_md}) {
      justify-content: center;
      height: 64px;
      text-align: center;
    }
    border-bottom: 1px solid ${theme.colours.grey_400};
    background-color: ${theme.colours.white};
    color: ${theme.colours.nearBlack};
  `}
`);

const ImageCisFrancis = withTheme(styled.img`
${({ coreTheme }) => css`
  display: inline-block;
  width: 40px;
  height: 40px;
  @media (min-width: ${coreTheme.brk_md}) {
    width: 32px;
    height: 32px;
  }
`}
  margin-right: ${theme.spacing(1.5)};
  vertical-align: middle;
`);

const StyledContent = styled.div`
  font-size: 14px;
  margin: auto 0;
`;

const navigateToCisFlow = () => {
  window.open(cisPointOfEntryUrl, '_blank');
};

const CisPointOfEntry = () => {
  const t = useTranslate().t;
  return (
    <StyledStrip>
      <ImageCisFrancis src={imageCisFrancis} alt="" />
      <StyledContent>
        <Text type="smallBody">
          {t('CIS_TEXT')}
          <TextLink inline secondary onClick={navigateToCisFlow}>
            {t('CIS_LINK')}
          </TextLink>
        </Text>
      </StyledContent>
    </StyledStrip>
  );
};

export default CisPointOfEntry;
