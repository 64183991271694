import React from 'react';
import { Spacing, Divider } from '@dls/web';
import * as Yup from 'yup';
import {
  Form,
  FORM_FIELD_TYPES,
  FORM_VALIDATION_TYPES
} from '@wec-core/form-engine';
import useTranslate from '../../hooks/useTranslation';
import { formatContactNumber } from '../../helpers/common';

const CustomerPersonalDetails = ({ userInformation, formikRef }) => {
  const { t } = useTranslate();

  const formFieldsConfig = [
    {
      component: FORM_FIELD_TYPES.FORM_TITLE,
      title: t('EKYC_CONTACT_DETAILS_TITLE')
    },
    {
      component: FORM_FIELD_TYPES.READ_ONLY_LABEL,
      label: t('EKYC_CONTACT_DETAILS_DESC')
    },
    {
      label: t('CO_NN_CONTACT_NUMBER'),
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      displayFormat: 'mobile-sg',
      name: 'number',
      bgColor: 'haze',
      id: 'pd-phone',
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_CONTACT') }
        },
        {
          type: FORM_VALIDATION_TYPES.MOBILE_NUM_VALIDATION,
          params: {
            message: t('CC_E_IV_CONTACT'),
            inValidLengthMsg: t('CC_E_IVL_CONTACT')
          }
        }
      ]
    },
    {
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      label: t('CO_NN_EMAIL') as string,
      name: 'email',
      bgColor: 'haze'
    },
    {
      component: FORM_FIELD_TYPES.TEXT_INPUT,
      label: t('CO_NN_CONFIRM_EMAIL') as string,
      name: 'confirmEmail',
      bgColor: 'haze'
    }
  ];

  return (
    <>
      <Spacing topBottom={2}>
        <Divider />
      </Spacing>
      <Form
        innerRef={formikRef}
        initialValues={{
          number: formatContactNumber(userInformation.phone),
          email: userInformation.email,
          confirmEmail: ''
        }}
        data={[]}
        formFieldsConfig={formFieldsConfig}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(t('CC_E_EMAIL'))
            .email(t('CC_E_IV_EMAIL')),
          confirmEmail: Yup.string()
            .required(t('CC_E_EMAIL'))
            .email(t('CC_E_IV_EMAIL'))
            .oneOf([Yup.ref('email'), null], t('CC_E_CONFIRM_EMAIL_VALID'))
        })}
      />
    </>
  );
};

export default CustomerPersonalDetails;
