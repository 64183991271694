import {
  AemVariableConfig,
  APP_TYPE_ANY,
  ERROR_CODES,
  ErrorMessage,
  KeyValue
} from '../types/common.types';
import { Checkout } from '../types/checkout.types';
import { DeliveryState } from '../types/fulfilment.types';
import FULFILMENT_CONSTANTS from '../constants/fulfillment';
import CHECKOUT_CONSTANTS from '../constants/checkout';
import { UserInformation } from '../types/user.types';
import {
  BaseAddress,
  BillingAccountDetail
} from '../types/registrationCheckout';
import { getUrlParams } from '@lux/helpers';
import { ProductOrder } from '../types/orderSummary.types';
import { AmendedTabErrorData } from '../types/order';
import { COMMON } from '../constants';
import CONSTANTS from '../constants/common';
import { trans as t } from './localisation';
import { simCardType } from '../config/simcard-types';
import { config } from '@detox/actions';
import sleep from './sleep';
import rrpHeadersPages, { rrpIppPages } from '../constants/rrpHeadersPages';
import { AEM_CONFIG_KEYS } from '../config/common';
import { navigation } from '../middlewares/navigation-constants';

declare global {
  interface Window {
    dataLayer: APP_TYPE_ANY;
    ga: APP_TYPE_ANY;
  }
}

const getErrorMessage = (
  errorCode: ERROR_CODES,
  allErrors: ErrorMessage[] = []
): ErrorMessage => {
  return allErrors.find(error => errorCode === error.errorCode);
};

export const maskString = (
  str: string | number,
  length = 4,
  type: 'HEAD' | 'TAIL' = 'HEAD'
): string => {
  if (!str) return '';
  const maskedChars = Array(length)
    .fill('*')
    .join('');
  if (typeof str === 'number') {
    str = str.toString();
  }
  if (type === 'HEAD') {
    const unmaskedString = str.substr(length);
    return `${maskedChars}${unmaskedString}`;
  } else {
    const unmaskedString = str.substr(0, str.length - length);
    return `${unmaskedString}${maskedChars}`;
  }
};

const arrayUnique = (values: string[]): string[] => {
  return [...new Set(values)].filter(Boolean);
};

export { getErrorMessage, arrayUnique };

export const filterBillingAddress = (
  billingAccountDetails: BillingAccountDetail[] = []
): BillingAccountDetail[] => {
  return billingAccountDetails?.filter(
    item =>
      item?.baStatus === config?.billingAccountStatus?.OPEN ||
      item?.baStatus === config?.billingAccountStatus?.TENTATIVE
  );
};
export const getAccountBillingInfo = ({
  checkout,
  delivery,
  userInformation,
  productOrder,
  newBarId
}: {
  checkout: Checkout;
  delivery: DeliveryState;
  userInformation?: UserInformation;
  productOrder?: ProductOrder;
  newBarId?: string;
}): {
  barId: string;
  billingMethod: string;
  faId: string;
  customerId: string;
} => {
  const defaultCustomerId =
    productOrder?.service?.customerId ||
    userInformation?.clientContext?.customers?.[0]?.customerId;
  let barId,
    billingMethod,
    customerId = defaultCustomerId;
  const newBillingAccountId =
    newBarId ||
    (checkout.checkoutFormData?.billingAddress ===
      CHECKOUT_CONSTANTS.NEW_BILLING_ADDRESS_ID &&
      checkout.billingAccountId);
  const billingAccountDetails = filterBillingAddress(
    delivery?.deliveryDetails?.billingAccountDetails
  );
  if (newBillingAccountId) {
    // user added new billing address
    barId = newBillingAccountId;
    billingMethod =
      checkout?.checkoutFormData?.billingPreference === 0
        ? FULFILMENT_CONSTANTS?.BILLING_METHODS.NONE_PAPER
        : FULFILMENT_CONSTANTS.BILLING_METHODS.PAPER;
  } else {
    const selectedExistBillingAccountIndex =
      checkout.checkoutFormData?.billingAddress;
    if (selectedExistBillingAccountIndex !== undefined) {
      //  user select billing
      const selectedBillingAccountDetails =
        billingAccountDetails?.[selectedExistBillingAccountIndex];
      barId = selectedBillingAccountDetails?.billingAccountNumber;
      billingMethod = selectedBillingAccountDetails?.billDeliveryMethod;
      customerId =
        selectedBillingAccountDetails?.customerId || defaultCustomerId;
    } else if (
      billingAccountDetails &&
      billingAccountDetails.length > 0 &&
      productOrder?.service?.serviceId
    ) {
      const selectedBillingAccount = billingAccountDetails.filter(
        billingAccount => {
          const subscriberList = billingAccount?.subscribers;
          return (
            subscriberList?.length > 0 &&
            subscriberList.find(
              subscriberItem =>
                subscriberItem?.subscriberResourceVal ===
                productOrder?.service?.serviceId
            )
          );
        }
      );
      /** BarId always consider the selected billing account details **/
      barId = selectedBillingAccount?.[0]?.billingAccountNumber;
      billingMethod = selectedBillingAccount?.[0]?.billDeliveryMethod;
      customerId = selectedBillingAccount?.[0]?.customerId;
    } else {
      barId =
        billingAccountDetails?.[0]?.billingAccountNumber ||
        userInformation?.clientContext?.billingArrangements?.[0]?.barId;
    }
  }
  const faId =
    billingAccountDetails?.find(ba => ba.billingAccountNumber === barId)
      ?.faId || userInformation?.clientContext?.financialAccounts?.[0].faId;
  return {
    barId,
    billingMethod,
    faId,
    customerId
  };
};

export const getDisplayUnitNumber = ({
  unitNumber,
  floor
}: {
  unitNumber: number | string;
  floor: number | string;
}): string => {
  if (!unitNumber) return '';
  if (typeof unitNumber === 'number') {
    unitNumber = unitNumber.toString();
  }

  if (typeof floor === 'number') {
    floor = floor.toString();
  }
  if (unitNumber.includes('#')) {
    return unitNumber;
  }
  if (floor.length === 1) {
    floor = `0${floor}`;
  }
  if (unitNumber.length === 1) {
    unitNumber = `0${unitNumber}`;
  }
  return `#${floor}-${unitNumber}`;
};

export const getDisplayAddress = (
  address: BaseAddress,
  hasPostalCode = true
): APP_TYPE_ANY => {
  if (!address) return '';
  const unitNumber = address.unitNumber || address.apartment;
  return `${address?.blockOrHouseNum ? `${address?.blockOrHouseNum} ` : ''}${
    address?.streetName ? `${address.streetName} ` : ''
  }${
    address.buildingName ? `${address.buildingName} ` : ''
  }${getDisplayUnitNumber({
    floor: address.floor,
    unitNumber
  })}${hasPostalCode ? ` ${address?.postcode}` : ''}`;
};

export const formatContactNumber = (contactNo: APP_TYPE_ANY): APP_TYPE_ANY => {
  if (contactNo?.indexOf('+') >= 0) return contactNo;
  else {
    const splitNo = contactNo?.split('');
    if (splitNo?.length) {
      splitNo?.splice(4, 0, ' ');
      return `+65 ${splitNo.join('')}`;
    }
    return null;
  }
};

export const getQueryData = (
  location: Partial<Location>,
  paramKey: string
): APP_TYPE_ANY => {
  const queryParams = getUrlParams(location?.search);
  return queryParams[paramKey];
};

export const getFormattedDisplayAddress = (
  address: BaseAddress
): APP_TYPE_ANY => {
  if (!address) return '';
  const unitNumber = address.unitNumber || address.apartment;
  const blockOrHouseNumber = address?.blockOrHouseNum || address?.houseNumber;
  const addressData = [
    {
      text: `${blockOrHouseNumber ? `${blockOrHouseNumber} ` : ''}${
        address?.streetName ? `${address.streetName} ` : ''
      }${address.buildingName ? `${address.buildingName} ` : ''}`
    },
    {
      text: getDisplayUnitNumber({
        floor: address.floor,
        unitNumber
      }),
      nowrap: true
    }
  ];

  return addressData;
};

export const isValidUrl = (url?: string): boolean => {
  if (!url) {
    return false;
  }
  let givenURL;
  try {
    givenURL = new URL(url);
  } catch (error) {
    return false;
  }
  return givenURL.protocol === 'http:' || givenURL.protocol === 'https:';
};

export const composeRequestParams = (params: KeyValue = {}): APP_TYPE_ANY => {
  const paramArray = Object.keys(params).filter(key => Boolean(params[key]));
  return paramArray.reduce((result, key, index) => {
    result = `${result}${key}=${params[key]}${
      index < paramArray.length - 1 ? '&' : ''
    }`;
    return result;
  }, '?');
};

export const includes = (array: string[], text: string): boolean => {
  return !!array?.some(string => {
    return string.trim().toUpperCase() === text?.trim()?.toUpperCase();
  });
};

export const isTabAmendedFlow = (
  amendedTabErrorData: AmendedTabErrorData
): APP_TYPE_ANY => {
  return (
    amendedTabErrorData?.errorUUID &&
    amendedTabErrorData?.backendErrorInfo?.includes(
      COMMON.AMENDED_IN_OTHER_FLOW_ERROR
    )
  );
};

export const deepCopy = (data: KeyValue): APP_TYPE_ANY => {
  return JSON.parse(JSON.stringify(data));
};

export const getShortenUrl = (url = '', includeUrlParams = true): string => {
  const splitUrl = url.split('/');
  const shortenURl = splitUrl[splitUrl.length - 1];
  if (includeUrlParams) {
    return shortenURl;
  }
  return shortenURl.split('?')[0];
};

export function formatNumber(
  value: number,
  targetLength = 2,
  padString = '0'
): string {
  return String(value).padStart(targetLength, padString);
}

type GetFirstEntriesReturn =
  | { [key: string]: APP_TYPE_ANY }
  | APP_TYPE_ANY[]
  | undefined;

export const getFirstEntries = <T>(
  data: T,
  numberOfEntriesToGet: number
): GetFirstEntriesReturn => {
  if (typeof data !== 'object') {
    return undefined;
  }

  if (Array.isArray(data)) {
    return data.slice(0, numberOfEntriesToGet);
  }

  return Object.fromEntries(
    Object.entries(data).slice(0, numberOfEntriesToGet)
  );
};

export const isValidPhoneNumber = (number: string): boolean => {
  const exp = /^\+65 [89][0-9]{3} [0-9]{4}$/;
  return exp.test(number);
};

/**
 *
 * @param value string
 * @param invertCheck to check if values is not empty
 * @returns boolean true or false
 */
export const isNullOrUndefinedOrEmptyCheck = (
  value: string,
  invertCheck = false
): boolean => {
  if (!invertCheck) {
    return value === null || value === undefined || value.trim() === '';
  }

  return value !== null && value !== undefined && value.trim() !== '';
};

export const getAdditionalBillDescription = (description = ''): string => {
  if (
    description.includes(simCardType.simType5G) ||
    description.includes(simCardType.simType4G)
  ) {
    return t('PHYSICAL_SIM_CARD_FEE') as string;
  } else if (description.includes(simCardType.simTypeESim)) {
    return t('ESIM_FEE') as string;
  }

  return description;
};

export const getErrorResponseHeaders = async (
  response: Response,
  callback?: (error: Error) => void
): Promise<string | null> => {
  try {
    return response.headers.get('x-idpf-verify-error');
  } catch (error) {
    if (callback) {
      callback(error);
    }
    return null;
  }
};

export const isErrorMatchFound = (idpfVerifyError: string): boolean => {
  if (!idpfVerifyError) return false;
  const normalizedError = idpfVerifyError?.toUpperCase().replace(/ /g, '');
  return CONSTANTS.IDPF_VERIFY_ERRORS.some(item =>
    item.includes(normalizedError)
  );
};

export const isApigeeAuthFailure = (
  errorStatus: number,
  errorFault: string
): boolean => {
  return (
    errorStatus === CONSTANTS.AUTH_UNAUTHORIZED &&
    CONSTANTS.AUTH_FAULT_ERRORS.some(error =>
      error.includes(errorFault?.toUpperCase()?.replace(/ /g, ''))
    )
  );
};

export const flattenNodes = (arr: APP_TYPE_ANY): APP_TYPE_ANY => {
  return arr?.edges?.map(({ node }) => node);
};

export const dataLayerPush = (
  payload: APP_TYPE_ANY,
  resetEcommerceNeeded?: APP_TYPE_ANY
): void => {
  if (window.dataLayer) {
    if (resetEcommerceNeeded) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(payload);
  }
};

/**
 * Split the phone number into groups of 4 digits
 *
 * @param {string|number} number
 * @returns {string}
 */
export const splitNumber = (number: number | string): string =>
  String(number)
    .match(/.{4}/g)
    .join(' ');

/**
 * Check and return if an object is empty
 *
 * @param {Object} obj
 * @returns {Boolean}
 */
export const isEmpty = (obj: APP_TYPE_ANY): boolean => {
  return obj ? Object.keys(obj).length === 0 : true;
};

export const gaSendEvent = (options: APP_TYPE_ANY): void => {
  if (window.ga) {
    window.ga('send', 'event', options);
  }
};

export const fetchRetry = async (
  url: string,
  fetchOptions = {},
  retryDelay = 1000
): Promise<APP_TYPE_ANY> => {
  await sleep(retryDelay);
  return fetch(url, fetchOptions).then(res => res.json());
};

/**
 *
 * @param {*} arr Stack
 * @param {*} needle Find tag
 */
export const isTagged = (arr: APP_TYPE_ANY[], needle: string): boolean => {
  if (!arr) return false;

  return arr.some(item => item.toLowerCase().includes(needle.toLowerCase()));
};

export const isRRPPage = (location: Location, isIpp = false): boolean => {
  return (isIpp ? rrpIppPages : rrpHeadersPages).reduce(
    (result: boolean, pageName) => {
      if (!result && location.pathname.includes(pageName)) {
        result = true;
      }
      return result;
    },
    false
  );
};

export const getAemConfig = (
  configs: AemVariableConfig[] = [],
  key: AEM_CONFIG_KEYS
): string => {
  return configs.find(({ name }) => name === key)?.value;
};

export const getAemConfigs = (
  configs: AemVariableConfig[] = [],
  keys: AEM_CONFIG_KEYS[]
): string[] => {
  return keys.map(name => configs.find(config => config.name === name)?.value);
};

export function extractBasePath(url) {
  const protocolIndex = url.indexOf('://');
  if (protocolIndex !== -1) {
    const afterProtocol = url.substring(protocolIndex + 3);
    const domainEndIndex = afterProtocol.indexOf('/');
    if (domainEndIndex !== -1) {
      return url.substring(0, protocolIndex + 3 + domainEndIndex);
    } else {
      // URL has no path
      return url;
    }
  } else {
    // No protocol found, return null
    return null;
  }
}

export const removeUndefinedFields = (datalayer: KeyValue = {}): KeyValue => {
  return Object.entries(datalayer).reduce((result, [key, value]) => {
    if (value !== undefined && value !== null) {
      result[key] = value;
    }
    return result;
  }, {});
};

export const isQualtricsPage = (pagePath: string) => {
  return pagePath === `/${navigation.THANK_YOU}`;
};
