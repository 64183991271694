import { AemRrpProduct, AemRrpProductSmallImage } from '../types/product.types';

export interface GetProductBySkuReturnValue {
  [key: string]: {
    productId: string;
    ribbonText: string;
    smallImage: AemRrpProductSmallImage;
    maxAllowedQuantity: number;
    tags: string[];
    shippingLiner: string;
  };
}

export const getProductBySku = (
  products: AemRrpProduct[]
): GetProductBySkuReturnValue => {
  const variantBySku = products.reduce((acc, cur) => {
    cur.variants.forEach(variant => {
      const { sku, ...data } = variant;
      if (!(sku in acc)) {
        acc[sku] = {
          ...data,
          productId: cur.id,
          maxAllowedQuantity: cur.maxAllowedQuantity,
          tags: cur.tags,
          shippingLiner: cur.shippingLiner
        };
      }
    });
    return acc;
  }, {});

  return variantBySku;
};
