import { FULFILLMENT } from '../../constants';

const REQUEST_STATUS = FULFILLMENT.REQUEST_STATUS;

const initialState = {
  status: REQUEST_STATUS.IDLE,
  data: []
};

const lampStock = (state = initialState, actions) => {
  switch (actions.type) {
    case 'GET_COLLECT_NOW_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        errorCode: undefined
      };
    }
    case 'GET_COLLECT_NOW_SUCCESS': {
      return {
        ...state,
        data: actions.payload,
        status: REQUEST_STATUS.SUCCEEDED
      };
    }
    case 'GET_COLLECT_NOW_ERROR': {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        errorCode: actions.payload,
        data: []
      };
    }
    default: {
      return state;
    }
  }
};

export default lampStock;
