export const PRODUCT = {
  STOCK_STATUS: {
    AVAILABLE: 'AL',
    NOT_AVAILABLE: 'NA'
  },
  STORE_ID: {
    WARE_HOUSE: 'WareHouse'
  },
  TAGS: {
    POP_STATION: 'singtel:eshop-delivery/pop-station',
    STORE_ONLY: 'singtel:eshop-delivery/store-only-delivery'
  },
  FULFILMENT_TYPES: {
    DOOR_STEP_DELIVERY: 'courier',
    COLLECT_AT_STORE: 'singtel-store',
    POP_STATION: 'pop-station'
  },
  STOCK_AVAILABLE_STATUS: ['Available', 'Selling Fast'],
  FOOTER_LINER: {
    TNC_TYPE_GROUP_PLANS_XO: 'TNC_TYPE_GROUP_PLANS_XO'
  },
  PAYMENT_TERM_TYPE: {
    FULL_PRICE: 'fullPrice'
  }
};

export enum SORT_TYPE {
  AZ = 'AZ',
  ZA = 'ZA',
  PRICE_HIGH_TO_LOW = 'HL',
  PRICE_LOW_TO_HIGH = 'LH',
  LATEST = 'DATE',
  POPULARITY = 'POP'
}
