import is5GVasAddon from './is-5g-addon';

const has5GAddon = (
  availableAddons: Object,
  selectedAddons: Array<string>
): Boolean => {
  //Find 5g VAS
  const is5GVas = is5GVasAddon(availableAddons);

  //Find whether 5G VAS was added in customer order
  return (
    availableAddons &&
    Object.keys(availableAddons)
      .map(key => {
        return {
          ...availableAddons[key],
          addonId: key
        };
      })
      .filter(
        addon =>
          selectedAddons &&
          selectedAddons.includes(addon.addonId) &&
          is5GVas.includes(addon.addonId)
      ).length > 0
  );
};

export default has5GAddon;
