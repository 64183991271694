import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseState } from '../../../types/state.types';
import AUTH_CONSTANTS from '../../../constants/auth';
import { WorryFreeLogin } from '../../../components/WorryFreeLogin/WorryFreeLogin';
import { authActions, rootActions } from '@detox/actions';
import CONSTANT from '../../../constants/common';
import { getUIAMLoginUrl } from '../../../config';
import { navigation } from '../../../middlewares/navigation-constants';
import { getCookie } from '@lux/helpers';
import { Grid } from '@dls/web';
import { getUIAMEnabledWithToken } from '../../../selectors';
import { graphql, navigate } from 'gatsby';
import { useLoader } from '../../../hooks/useLoader';
import getAemComponentByName from '../../../helpers/getAemComponentByName';
import { KeyValue } from '../../../types/common.types';

type PropTypes = {
  data: KeyValue;
};

export const RRPLogin = (props: PropTypes): ReactElement => {
  const { allAemPages } = props.data;
  const siteConfigurations = getAemComponentByName(
    allAemPages,
    'SiteConfigurations'
  );
  const isWorryFreeEnabled =
    siteConfigurations && siteConfigurations.switches.DETOX_WORRY_FREE;
  const dispatch = useDispatch();
  const { user, auth, apigeeTokenEnabled } = useSelector<
    BaseState,
    Partial<BaseState>
  >(state => ({
    user: state.user,
    auth: state.auth,
    apigeeTokenEnabled: getUIAMEnabledWithToken(state)
  }));
  const { Loader } = useLoader({ states: [user] });
  const authStatus = auth.status;
  const isOnePassUser = user.information?.userDetails?.loginId;
  const isUserLoggedIn = !!user.information?.clientContext;
  useEffect(() => {
    if (apigeeTokenEnabled) {
      dispatch(
        authActions.getUserInfoWithCleanWorryFree({
          cleanWorryFree: !isUserLoggedIn
        })
      );
    }
  }, [apigeeTokenEnabled]);
  useEffect(() => {
    if (isUserLoggedIn && isOnePassUser) {
      gotoServiceList();
    }
  }, [isOnePassUser]);
  useEffect(() => {
    if (isUserLoggedIn && authStatus === AUTH_CONSTANTS.LOGGED_IN) {
      gotoServiceList();
    }
  }, [authStatus]);
  useEffect(() => {
    if (auth.sessionToken) {
      if (isWorryFreeEnabled) {
        dispatch(authActions.setAuthStatus(AUTH_CONSTANTS.WORRY_FREE_LOGIN));
      } else {
        const uiamAccessToken = getCookie(CONSTANT.UIAM_ACCESS_TOKEN);
        if (!uiamAccessToken) {
          window.open(getUIAMLoginUrl(navigation.RRP_SERVICE_LIST), '_self');
        }
      }
    }
  }, [auth.sessionToken]);

  const gotoServiceList = () => {
    dispatch(authActions.resetAuth());
    dispatch(rootActions.resetByKeys(['user']));
    navigate(`/${navigation.RRP_SERVICE_LIST}`, { replace: true });
  };

  return (
    <Grid>
      <Loader hasSkeletonLoader={false} />
      <WorryFreeLogin onePassTargetUrl={navigation.RRP_SERVICE_LIST} />
    </Grid>
  );
};

export const query = graphql`
  {
    allAemPages {
      edges {
        node {
          components {
            ...AemComponentsFragment
          }
          pageUrl
          title
        }
      }
    }
  }
`;

export default RRPLogin;
