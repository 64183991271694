import { ACTION_TYPES } from '@detox/actions';
import { APP_TYPE_ANY, KeyValue } from '../types/common.types';
import { ACTION_TYPES as SHOP_ACTION_TYPES } from '../constants/actions';

export interface rrpRegistrationForm {
  fullName: string;
  email: string;
  mobNumber: number;
}
export interface rrpCheckoutState extends KeyValue {
  isLoading: boolean;
  isError: boolean;
  rrpMasterOrderId: string;
  rrpFlow: boolean;
  formData: rrpRegistrationForm;
  isCustomerRegistered: boolean;
  isOtpSend: boolean;
  isOtpVerified: boolean;
  orderModel: [];
  errorInfo: KeyValue;
}

interface rrpCheckoutAction {
  type: string;
  data?: APP_TYPE_ANY;
}

const initialState: rrpCheckoutState = {
  isLoading: false,
  isError: false,
  otpError: false,
  rrpMasterOrderId: '',
  rrpFlow: false,
  formData: {
    fullName: '',
    email: '',
    mobNumber: null
  },
  isCustomerRegistered: false,
  isOtpSend: false,
  isOtpVerified: false,
  orderModel: [],
  errorInfo: null
};

export const rrpCheckoutReducer = (
  state: rrpCheckoutState = initialState,
  action: rrpCheckoutAction
): rrpCheckoutState => {
  switch (action.type) {
    case SHOP_ACTION_TYPES.RRP.SET_RRP_MASTER_ORDERID: {
      return {
        ...initialState,
        rrpFlow: state.rrpFlow || false,
        rrpMasterOrderId: action.data.rrpMasterOrderId
      };
    }
    case ACTION_TYPES.RRP.REGISTER_CUSTOMER_LOADING:
    case ACTION_TYPES.RRP.SEND_OTP_LOADING:
    case ACTION_TYPES.RRP.RESEND_OTP_LOADING: {
      return {
        ...state,
        otpError: false,
        isLoading: true
      };
    }
    case ACTION_TYPES.RRP.REGISTER_CUSTOMER_ERROR:
    case ACTION_TYPES.RRP.SEND_OTP_ERROR:
    case ACTION_TYPES.RRP.RESEND_OTP_ERROR: {
      return {
        ...state,
        isError: true,
        isLoading: false,
        otpError: true,
        otpSent: false,
        isCustomerRegistered: false,
        isOtpVerified: false,
        isOtpSend: false,
        errorInfo: action?.data
      };
    }

    case ACTION_TYPES.RRP.REGISTER_CUSTOMER_SUCCESS: {
      const { isCustomerRegistered, isOtpVerified } = action.data;
      return {
        ...state,
        isLoading: false,
        isError: false,
        isOtpSend: false,
        isCustomerRegistered,
        isOtpVerified
      };
    }

    case ACTION_TYPES.RRP.SEND_OTP_SUCCESS:
    case ACTION_TYPES.RRP.RESEND_OTP_SUCCESS: {
      const { isOtpSend } = action.data;
      return {
        ...state,
        ...action.data,
        isLoading: false,
        isError: false,
        isCustomerRegistered: false,
        isOtpVerified: false,
        isOtpSend
      };
    }

    case SHOP_ACTION_TYPES.RRP.SET_FULFILMENT_STATE: {
      const { orderModel } = action.data;
      return {
        ...state,
        orderModel
      };
    }

    case SHOP_ACTION_TYPES.RRP.RESET_FULFILMENT_STATE: {
      return {
        ...state,
        orderModel: null
      };
    }

    case SHOP_ACTION_TYPES.RRP.SET_RRP_FLOW: {
      return {
        ...state,
        rrpFlow: true
      };
    }

    case SHOP_ACTION_TYPES.RRP.RESET_RRP_FLOW: {
      return {
        ...initialState,
        rrpFlow: false
      };
    }

    default:
      return state;
  }
};
