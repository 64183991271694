import { remCalc } from '@lux/helpers';

import grid from './grid';
import colours from './colours';
import fonts from './fonts';
import states from './states';
import animations from './animations';

const SPACER = 8;
const spacing = size => remCalc(SPACER * size);

const theme = {
  colours,
  fonts,
  grid,
  spacing,
  states,
  animations
};

export default theme;
