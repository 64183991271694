export const initialState = {};

const promotionsReducer = (state = initialState, action) => {
  if (action.type === `SAVE_PROMOTION_ORDER_DETAILS`) {
    return {
      ...state,
      promoOrderDetails: action.value
    };
  }

  return state;
};

export default promotionsReducer;
