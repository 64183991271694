import { graphql } from 'gatsby';
import React, { ReactElement } from 'react';
import SEO from '../../../components/SEO';
import { TObject } from '../../../types/registrationCheckout';
import RrpIppFulfilment from '../../../components/RrpIppFulfilment';

interface TProps {
  data: TObject;
}

export const IppFulfilment: React.FC<TProps> = ({ data }): ReactElement => {
  return (
    <>
      <SEO title="IPP Fulfilment" description={null} />
      <RrpIppFulfilment data={data} />
    </>
  );
};

export const query = graphql`
  query RrpIppFulfilmentQuery {
    allRrpProduct {
      edges {
        node {
          ...RrpProductFragment
        }
      }
    }
    rrpFulfilment {
      id
      backOrderLinerForFulfilment
      backOrderLinerForProductDetails
      preOrderLinerForFulfilment
      preOrderLinerForProductDetails
      endEApptTimeInHours
      leadDays {
        sku
        firstDeliveryDate
        lastDeliveryDate
        deliveryPeriod
      }
      fulfilmentList {
        fulfilmentId
        tags
        fulfilment {
          title
          desc
          price
          ribbon
          liner
          icon
          deliveryLinerWithDevice
          deliveryLinerWithoutDevice
          offsetWithDocument
          offsetWithoutDocument
          maxAllowedItems
          enabledForBackOrder
          enabledForPickupType
          enableUploadDocument
          enabledForTradeIn
          enabledForAccessories
          enabledForFreeGifts
          enabledForHandset
          enabledForSIM
        }
      }
    }
  }
`;

export default IppFulfilment;
