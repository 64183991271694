/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import SEO from '../../../components/SEO';
import { trans as t } from '../../../helpers/localisation';
import {
  CartBoxReturnValue,
  IVariant,
  ProductDetails
} from '@common-modules/product-catalogue';
import { PreSelection } from '../../../types/product.types';
import {
  getDeviceVariants,
  getProductSpecsFromUrl,
  setUrlAfterChangeVariant
} from '../../../helpers/productDetails';
import { hideCampaigns } from '../../../components/LivePerson/LivePerson';
import { getAccessoryOnlineStock } from '../../../helpers/accessory';
import { getAdditionalMetaData } from '../../phone/helpers';
import { useBrowserBack } from '../../../hooks/useBrowserBack/useBrowserBack';
import { useAuthToken } from '../../../hooks/useAuthToken';
import { rrpCartActions, RRPOrderProduct } from '@detox/actions';
import { BaseState } from '../../../types/state.types';
import { useModal } from '../../../hooks/useModal';
import { navigation } from '../../../middlewares/navigation-constants';
import {
  getOrderItemDetails,
  isCartHavingIppItem
} from '../../../helpers/rrpCart';
import RRP_CART_CONSTANTS from '../../../constants/rrpcart';
import {
  clearOrderDetails,
  initRRP,
  resetCartState,
  setTempCartItem
} from '../../../reducers/rrpCart';
import { IppEligibilityLiner } from '../../../components/Liners/rrpIppEligibilityLiner';
import {
  getMaxItemLimit,
  navigateToSDRRP,
  validateCart
} from '../../../helpers/productCatalogHelper';
import useStockAvailability from '../../../hooks/useStockAvailability';
import { setRRPFlow } from '../../../reducers/productCatalogs';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { useLoader } from '../../../hooks/useLoader';
import { getUIAMEnabledWithToken } from '../../../selectors';
import { PRODUCT } from '../../../constants';
import COMMON from '../../../constants/common';
import { AppState } from '../../../types/common.types';
import { dataLayerPush, flattenNodes } from '../../../helpers/common';
import { useDevice } from '@dls/web';
import { resetUser } from '../../../reducers/user';
import { getRrpProductDetailsAddToCartDataLayer } from '../../../helpers/dataLayerHelpers';
import { EventLabel } from '../../../types/dataLayer.type';
import ShareModal from '../../../components/ShareModal/ShareModal';

export const PhoneDetails = ({ data, location, resetByKeys }) => {
  const {
    title,
    productId,
    colours,
    smallImage,
    imageGallery,
    videoUrl,
    ribbon,
    description,
    metaTitle,
    metaDescription,
    metaKeywords,
    maxAllowedQuantity,
    slug,
    tags,
    brand,
    freebies,
    groupId,
    sellingFastQuantity
  } = data.rrpProduct;
  const skuVariants = useRef(data.rrpProduct.skuVariants);
  const variableConfigs = flattenNodes(data.allVariableConfig) || [];
  const { isMobile } = useDevice();
  const additionalMetaData = getAdditionalMetaData({
    title,
    metaTitle,
    metaDescription,
    metaKeywords,
    isRRP: true
  });
  useEffect(() => {
    dispatch(clearOrderDetails());
    dispatch(initRRP(variableConfigs));
    dispatch(resetUser());
    return () => hideCampaigns();
  }, []);

  const [currentVariant, setCurrentVariant] = useState<IVariant>(null);
  const dispatch = useDispatch();
  useBrowserBack();

  const { setModalData, renderedModal } = useModal();
  const clearCartItemType = useRef(null);
  const { rrpCart, auth, apigeeTokenEnabled } = useSelector<
    BaseState,
    Partial<BaseState>
  >(state => ({
    rrpCart: state.rrpCart,
    auth: state.auth,
    apigeeTokenEnabled: getUIAMEnabledWithToken(state)
  }));
  useAuthToken(
    { auth, shouldCleanWorryFee: false, cleanWorryFreeOnePassHeader: false },
    [apigeeTokenEnabled]
  );
  const [formattedVariants, setFormattedVariants] = useState<
    { variants: IVariant[] } & AppState
  >({
    variants: []
  });
  const { renderError } = useErrorHandler({
    states: [rrpCart, auth, formattedVariants],
    shouldRedirect: false
  });
  const { Loader } = useLoader({ states: [rrpCart, auth] });
  const [freeGiftStocks] = useStockAvailability(freebies, auth.sessionToken);
  const setFormattedDeviceVariants = async () => {
    const variantData = await getDeviceVariants({
      skuVariants: skuVariants.current,
      colors: colours,
      imageGallery,
      seoImageTag: smallImage.alt || title,
      tags,
      brand,
      sellingFastThreshold: Number(sellingFastQuantity),
      productTitle: title,
      groupId
    });
    setFormattedVariants({
      variants: variantData.variants,
      isError: !variantData.status,
      allowReload: true
    });
  };

  const isLoading = formattedVariants?.variants?.length === 0;

  const preSelection = useRef<PreSelection>(
    getProductSpecsFromUrl(location.pathname, slug, true, colours)
  );
  const [showShareModal, setShareModal] = useState<boolean>(false);
  const shareIconsList = process.env.GATSBY_SHARE_ICONS;

  useEffect(() => {
    dispatch(setRRPFlow(true));
    dispatch(resetCartState());
  }, []);

  useEffect(() => {
    if (rrpCart.addItemSuccess && rrpCart.shouldGoToCart) {
      navigateToShoppingCart();
      dispatch(resetCartState());
    }
  }, [rrpCart.shouldGoToCart]);

  useEffect(() => {
    if (rrpCart.addItemSuccess) {
      setTimeout(() => {
        dispatch(resetCartState());
      }, 1000);
      dataLayerPush(
        getRrpProductDetailsAddToCartDataLayer({
          baseParams: { cartItems: rrpCart?.addedItems, location },
          eventLabel: EventLabel.ADD_TO_CART
        })
      );
    }
  }, [rrpCart.addItemSuccess]);

  useEffect(() => {
    if (apigeeTokenEnabled) {
      setFormattedDeviceVariants();
    }
  }, [apigeeTokenEnabled]);

  useEffect(() => {
    if (
      rrpCart.finishCancelMasterOrder &&
      rrpCart.tempCartItem &&
      rrpCart.addItemAfterCancel
    ) {
      if (
        rrpCart.tempCartItem?.type ===
        RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE
      ) {
        const { product, quantity } = rrpCart.tempCartItem;
        const orderItemDetails = getOrderItemDetails({ product, quantity });
        handleAddFullPriceItem(orderItemDetails, rrpCart.shouldGoToCart);
      } else {
        gotoServiceList();
      }
    }
  }, [rrpCart.finishCancelMasterOrder]);

  const onChangeVariant = (variant: IVariant) => {
    setUrlAfterChangeVariant(variant, slug, location.search, true);
    dispatch(resetCartState());
    setCurrentVariant(variant);
  };
  const productData = {
    freeGiftsByDevice: freebies,
    freeGiftStockAvail: freeGiftStocks,
    videoUrl,
    ribbon
  };

  const onNextClick = (data: CartBoxReturnValue) => {
    handleAddItem(data, true);
  };

  const onOOSLinkClick = () => {
    navigate(`/${navigation.RRP_PRODUCT_CATALOG}`, { replace: true });
  };

  const gotoServiceList = () => {
    dispatch(resetCartState({ clearIppServices: true }));
    navigate(`/${navigation.RRP_LOGIN}`);
  };

  function cancelMasterOrder(
    paymentTerm = clearCartItemType.current,
    shouldGoToCart = false
  ) {
    if (paymentTerm === RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE) {
      return dispatch(
        rrpCartActions.rrpCancelMasterOrderItems({
          rrpMasterOrderId: rrpCart.masterOrderId,
          addItemAfterCancel: true
        })
      );
    }
    const { customerId, productOrderId, productOrderItemId } =
      rrpCart.ippOrderData || {};
    dispatch(
      rrpCartActions.deleteRRPIppOrder({
        customerId,
        productOrderId,
        productOrderItemId,
        addItemAfterCancel: true,
        shouldGoToCart
      })
    );
  }

  const navigateToShoppingCart = () => {
    navigate(`/${navigation.RRP_SHOPPING_CART}`);
  };

  const showShoppingCartFull = () => {
    return setModalData({
      title: t('RRP_CART_FULL_TITLE') as string,
      message: t('RRP_CART_FULL_MESSAGE') as string,
      visible: true,
      buttonText: t('ADJUST_ITEM_TEXT') as string,
      callback: navigateToShoppingCart
    });
  };

  const showClearCartModal = (
    clearItemType: string,
    shouldGoToCart?: boolean
  ) => {
    clearCartItemType.current = clearItemType;
    setModalData({
      title: t('RRP_REMOVE_CART_ITEM_TITLE') as string,
      buttonText: t('PROCEED') as string,
      secondaryButtonText: t('KEEP_CURRENT_CART_TEXT') as string,
      message: t('RRP_REMOVE_CART_ITEM_MESSAGE') as string,
      visible: true,
      callback: () => {
        cancelMasterOrder(undefined, shouldGoToCart);
      }
    });
  };

  const showOutOfStockModal = () => {
    setModalData({
      title: t('RRP_OUT_OF_STOCK_TITLE') as string,
      message: t('RRP_OUT_OF_STOCK_MESSAGE') as string,
      visible: true,
      buttonText: t('SELECT_OTHER_ITEM_TEXT') as string,
      callback: () => {
        window.history.go(-1);
      }
    });
  };

  const handleAddFullPriceItem = (
    orderItemDetails: RRPOrderProduct[],
    shouldGoToCart?: boolean
  ) => {
    if (rrpCart?.masterOrderId) {
      dispatch(
        rrpCartActions.rrpAddMasterOrderItems({
          rrpMasterOrderId: rrpCart.masterOrderId,
          orderItemDetails,
          shouldGoToCart,
          shouldFetchOrderDetailsFromApi: false
        })
      );
    } else {
      dispatch(
        rrpCartActions.rrpCreateMasterOrder(
          orderItemDetails,
          shouldGoToCart,
          true
        )
      );
    }
  };

  const handleAddItem = (
    data: CartBoxReturnValue,
    shouldGoToCart?: boolean
  ) => {
    if (!currentVariant.stockCount) {
      return showOutOfStockModal();
    }
    const { selectedPaymentTerm, product, quantity } = data;
    const orderItemDetails = getOrderItemDetails({ product, quantity });
    if (
      quantity === 0 &&
      selectedPaymentTerm?.id === PRODUCT.PAYMENT_TERM_TYPE.FULL_PRICE
    ) {
      if (shouldGoToCart) {
        return navigateToShoppingCart();
      }
      return showShoppingCartFull();
    }
    dispatch(
      setTempCartItem({
        cartBoxData: {
          ...data,
          type:
            selectedPaymentTerm.id ===
            RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE
              ? RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE
              : RRP_CART_CONSTANTS.CART_ITEM_TYPES.IPP
        },
        pathName: window.location.pathname
      })
    );
    switch (selectedPaymentTerm.id) {
      case RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE: {
        const cartValidation = validateCart({
          currentVariant,
          itemsInCart: rrpCart.addedItems,
          numberOfItemToAdd: quantity,
          maxAllowedQuantityForCurrentProduct: maxAllowedQuantity
        });
        if (!cartValidation.isValid) {
          return setModalData({
            title: cartValidation.title,
            message: cartValidation.message,
            visible: true,
            buttonText: cartValidation.buttonText
          });
        }
        if (isCartHavingIppItem(rrpCart)) {
          return showClearCartModal(
            RRP_CART_CONSTANTS.CART_ITEM_TYPES.IPP,
            shouldGoToCart
          );
        }
        return handleAddFullPriceItem(orderItemDetails, shouldGoToCart);
      }
      default: {
        if (!rrpCart.masterOrderId) {
          if (rrpCart.ippOrderData) {
            return cancelMasterOrder(RRP_CART_CONSTANTS.CART_ITEM_TYPES.IPP);
          }
          return gotoServiceList();
        }
        showClearCartModal(RRP_CART_CONSTANTS.CART_ITEM_TYPES.FULL_PRICE);
      }
    }
  };

  const cartBoxPrimaryClick = (data: CartBoxReturnValue) => {
    handleAddItem(data, true);
  };

  const cartBoxSecondaryClick = (data: CartBoxReturnValue) => {
    handleAddItem(data);
  };
  const maxItemLimit = getMaxItemLimit({
    currentVariant,
    itemsInCart: rrpCart.addedItems,
    maxAllowedQuantityForCurrentProduct: maxAllowedQuantity
  });

  const toggleShareModal = () => {
    setShareModal(!showShareModal);
  };

  return (
    <div className="fs-unmask">
      <SEO
        title={t('RRP_ADDITIONAL_META_TITLE', { '0': title })}
        description={description}
        microdata={{}}
        additionalMeta={additionalMetaData}
        hasDefaultTitle={false}
      />
      <ProductDetails
        data={{
          productDetails: {
            ...productData,
            deviceId: productId,
            variants: formattedVariants.variants,
            preSelectionOptions: {
              storage: preSelection.current?.storage,
              color: preSelection.current?.color,
              paymentTerm: preSelection.current?.monthlyTerm
            }
          },
          productModel: {
            brandCode: '',
            brandDescription: 'selectedPhone.brand',
            modelCode: productId,
            modelDescription: title,
            productDetails: description
          }
        }}
        configs={{
          miniCartPosition: 'top',
          showSummaryBar: true,
          hasPaymentTerms: true,
          hasSummaryPrice: true,
          isLoading,
          moduleLoaders: {},
          dataMapping: {
            deviceId: 'modelCode',
            brandId: 'brandCode',
            deviceTitle: 'modelDescription'
          },
          showStorageDivider: true,
          baseUrl: location.origin,
          isQuantityRequired: false,
          titleSectionVariant: 'top',
          cartBox: {
            hasPriceSlash: rrpCart.hasPriceSlash,
            quantity: {
              minValue: maxItemLimit ? 1 : 0,
              maxValue: maxItemLimit
            },
            customIppLiner: <IppEligibilityLiner />,
            showCartBox: true,
            status: rrpCart.addItemSuccess
              ? 'success'
              : rrpCart.isLoading
              ? 'inProgress'
              : null
          },
          showShareBtn:
            shareIconsList && shareIconsList !== COMMON.SHARE_ICON.NONE
        }}
        localisation={{
          EQUIPMENT_MOBILE_DEVICE_TITLE: 'Phones',
          EQUIPMENT_DETAILS_STORAGE_TITLE: t('CHOOSE_YOUR_SIZE'),
          TODAY: t('TEXT_YOU_PAY_TODAY'),
          EQUIPMENT_DETAILS_BOX_TITLE: t('TEXT_PRODUCT_DETAILS'),
          EQUIPMENT_DETAILS_TECH_SPECS: t('TEXT_PRODUCT_DETAILS'),
          ACTION_BUTTON_NEXT: t('TEXT_BUY_NOW'),
          CART_BOX_IPP_DESCRIPTION: t('CART_BOX_IPP_DESCRIPTION', {
            paymentTerm: currentVariant?.paymentTerm
          })
        }}
        callbacks={{
          onChangeVariant,
          onNextClick,
          onOOSLinkClick,
          checkStock: getAccessoryOnlineStock,
          cartBoxPrimaryClick,
          cartBoxSecondaryClick,
          onShareClick: toggleShareModal
        }}
      />
      {renderedModal}
      {renderError()}
      {shareIconsList && shareIconsList !== COMMON.SHARE_ICON.NONE && (
        <ShareModal showModal={showShareModal} onClose={toggleShareModal} />
      )}
      <Loader />
    </div>
  );
};
