import CONSTANTS from '../constants/common';
import {
  Category,
  DATA_LAYER_FLOWS,
  DataLayer,
  DataLayerBaseParams,
  DataLayerFlowParams,
  Event,
  EventActions,
  EventCategory,
  EventLabel,
  EventName,
  FlowType,
  IppDetails
} from '../types/dataLayer.type';
import { SelectedPlan } from '../types/shoppingCart.types';
import { SkuVariant } from '../types/product.types';
import isSimOnlyPlan from './is-sim-only-plan';
import { LineOfBusiness } from '../types/user.types';
import { CONFIGS } from '../config/common';
import { SelectedAccessory } from '../types/accessory.types';
import { ORDER } from '../constants';
import { OrderItemDetail } from '@detox/actions';
import { isCartHavingIppItem } from './rrpCart';
import { removeUndefinedFields } from './common';

const getFlow = ({
  selectedVariant,
  selectedPlan
}: {
  selectedPlan: Partial<SelectedPlan>;
  selectedVariant: Partial<SkuVariant>;
}): DATA_LAYER_FLOWS => {
  switch (true) {
    case !selectedVariant:
      return DATA_LAYER_FLOWS.NA;
    case isSimOnlyPlan(selectedPlan):
      return DATA_LAYER_FLOWS.SOP_PLAN_DEVICE;
    case !!selectedPlan:
      return DATA_LAYER_FLOWS.PHONE_PLAN_WITH_DEVICE;
    default:
      return DATA_LAYER_FLOWS.ON_LY_DEVICE;
  }
};

const getCategoryNameFromTag = (category: string, tag?: string) => {
  if (!tag) return '';
  return tag.replace(category, '');
};

const getMainProductEcommerceItem = ({
  selectedVariant,
  selectedPlan
}: {
  selectedVariant: Partial<SkuVariant>;
  selectedPlan: SelectedPlan;
}) => {
  const categoryTag = selectedVariant?.tags?.find(tag =>
    tag.includes(CONFIGS.TAGS.MAIN_PRODUCT_CATEGORY)
  );
  return {
    item_id: selectedVariant?.productId,
    item_brand: selectedVariant?.brand,
    item_name: selectedVariant?.title,
    item_category:
      getCategoryNameFromTag(CONFIGS.TAGS.MAIN_PRODUCT_CATEGORY, categoryTag) ||
      Category.NA,
    item_category2: getFlow({ selectedPlan, selectedVariant }),
    item_variant: `${selectedVariant?.colour || 'NA'}-${selectedVariant?.size ||
      'NA'}`,
    item_colour: selectedVariant?.colour,
    item_size: selectedVariant?.size,
    price: selectedVariant?.price,
    quantity: 1
  };
};

const getAccessoriesEcommerceItems = ({
  accessories,
  selectedPlan,
  selectedVariant
}: {
  accessories: Partial<SelectedAccessory>[];
  selectedPlan: SelectedPlan;
  selectedVariant: Partial<SkuVariant>;
}) => {
  return (
    accessories?.map(accessory => {
      const accessoryCategoryTags = accessory.tags.filter(tag =>
        tag.includes(CONFIGS.TAGS.ACCESSORY_CATEGORY)
      );
      return {
        item_id: accessory.deviceCode,
        item_brand: accessory.brand,
        item_name: accessory.deviceTitle,
        item_category: Category.ACCESSORY,
        item_category2: getFlow({
          selectedPlan,
          selectedVariant
        }),
        item_category3:
          getCategoryNameFromTag(
            CONFIGS.TAGS.ACCESSORY_CATEGORY,
            accessoryCategoryTags[0]
          ) || Category.NA,
        item_category4:
          getCategoryNameFromTag(
            CONFIGS.TAGS.ACCESSORY_CATEGORY,
            accessoryCategoryTags[1]
          ) || Category.NA,
        item_variant: `${accessory.colorCode || 'NA'}-${accessory.memorySize ||
          'NA'}`,
        item_colour: accessory.colorCode || 'NA',
        item_size: accessory.memorySize || 'NA',
        price: accessory.price,
        quantity: 1
      };
    }) || []
  );
};

const getEcommerceData = ({
  selectedVariant,
  selectedPlan,
  accessories,
  order
}: Partial<DataLayerBaseParams>) => {
  const ecommerceItems = [
    getMainProductEcommerceItem({ selectedVariant, selectedPlan }),
    ...getAccessoriesEcommerceItems({
      selectedPlan,
      selectedVariant,
      accessories
    })
  ];
  const itemSummary = ecommerceItems.reduce(
    (acc, item) => {
      return {
        totalPrice: acc.totalPrice + item.price,
        count: ++acc.count
      };
    },
    { totalPrice: 0, count: 0 }
  );
  return {
    currency: CONSTANTS.DEFAULT_CURRENCY,
    value: itemSummary.totalPrice,
    units: itemSummary.count,
    bag: 1,
    item: ecommerceItems
  };
};

const getRrpEcommerceData = (params: DataLayerBaseParams) => {
  const { cartItems = [] } = params || {};
  const ecommerce = cartItems?.reduce(
    (result, item) => {
      result.totalValue =
        result.totalValue + Number(item.productDetails?.price);
      result.items.push({
        item_id: item.sku,
        item_name: item.productDetails?.title,
        item_brand: item.productDetails?.brand,
        item_colour: item.productDetails?.color,
        item_storage: item.productDetails?.size,
        price: item.productDetails?.price,
        quantity: 1
      });
      return result;
    },
    { totalValue: 0, items: [] }
  );
  return {
    currency: CONSTANTS.DEFAULT_CURRENCY,
    value: ecommerce.totalValue,
    units: cartItems.length,
    bag: 1,
    item: ecommerce.items
  };
};

const getFlowFromOrder = (
  flowType: string,
  isRRp = false,
  cartItems: OrderItemDetail[]
): FlowType => {
  if (isRRp) {
    const isIpp = isCartHavingIppItem({ addedItems: cartItems });
    return isIpp ? FlowType.RRP_IPP : FlowType.RRP;
  }
  switch (flowType) {
    case ORDER.TYPE.NEW:
      return FlowType.NEW;
    case ORDER.TYPE.RECON:
      return FlowType.RECON;
    case ORDER.TYPE.PORTIN:
      return FlowType.PORT_IN;
  }
};

const getFlowType = ({
  rrpFlow,
  cartItems,
  ippDetails
}: {
  rrpFlow: boolean;
  cartItems: OrderItemDetail[];
  ippDetails?: IppDetails;
}): FlowType => {
  if (!rrpFlow) return;
  const isIpp = isCartHavingIppItem({ addedItems: cartItems });
  return isIpp && ippDetails
    ? (ippDetails.selectedPaymentTerm?.description as FlowType)
    : FlowType.FULL_PRICE;
};

const getCartParams = (baseParams: DataLayerBaseParams) => {
  const { cartItems, orderModel, delivery } = baseParams;
  return cartItems?.map(cartItem => {
    const sku = cartItem.sku;
    const delivery_type =
      delivery?.type ||
      orderModel?.find(model =>
        model?.items?.list?.find(item => item.id === sku)
      )?.methods?.pickupOptions?.[0]?.type;
    return {
      product_name: cartItem.productDetails?.title,
      color_storage: `${cartItem.productDetails?.color}|${cartItem
        .productDetails?.size || ''}`,
      delivery_type
    };
  });
};

const getDataLayer = ({
  baseParams,
  flowParams
}: {
  baseParams: DataLayerBaseParams;
  flowParams: DataLayerFlowParams;
}): DataLayer => {
  try {
    const {
      location,
      flow = getFlowFromOrder(
        baseParams.order?.type,
        baseParams.rrpFlow,
        baseParams.cartItems
      ),
      order,
      rrpFlow,
      cartItems,
      ippDetails
    } = baseParams;
    const dataLayer: DataLayer = {
      event: Event.ADD_TO_CART,
      event_name: `${flowParams.event_category ||
        EventCategory.CONTENT_CLICK}_${flowParams.event_action ||
        EventActions.BUTTON_CLICK}`,
      event_action: EventActions.BUTTON_CLICK,
      event_label: '',
      event_category: EventCategory.CONTENT_CLICK,
      page_url_without_query_string_parameters: `${location.host}${location.pathname}`,
      lob: rrpFlow ? null : LineOfBusiness.MOBILE,
      network_line_type: flow,
      plan: getFlowType({ rrpFlow, cartItems, ippDetails }),
      sim_card_type: order?.sim?.selectedSimType || 'NA',
      order_id: order?.productOrderId,
      parameters: getCartParams(baseParams),
      ecommerce: rrpFlow
        ? getRrpEcommerceData(baseParams)
        : getEcommerceData(baseParams)
    };

    for (const key in flowParams) {
      if (dataLayer[key] !== undefined) {
        dataLayer[key] = flowParams[key];
      }
    }
    return removeUndefinedFields(dataLayer) as DataLayer;
  } catch (e) {
    // ignore and move on
  }
};

export const getChooseAddonsDataLayer = (
  baseParams: DataLayerBaseParams
): DataLayer => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'choose-add-ons'
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getProductDetailSubmitDataLayer = (
  baseParams: DataLayerBaseParams
) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'next',
    event_category: EventCategory.HEADER_CLICK,
    event_name: EventName.ORDER_INITIATE
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getChangeFlowDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'choose-add-ons',
    event_name: EventName.SELECT_NETWORK_LINE_TYPE
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getShoppingCartDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'add',
    event: Event.ADD_TO_CART
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getCheckoutDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'checkout',
    event: Event.CHECKOUT
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getFulfilmentDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'review-order',
    event: Event.ORDER_FULFILMENT
  };
  return getDataLayer({ baseParams, flowParams });
};
export const getOrderSummaryDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: 'proceed',
    event: Event.ORDER_SUMMARY
  };
  return getDataLayer({ baseParams, flowParams });
};
export const getThankYouDataLayer = (baseParams: DataLayerBaseParams) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventCategory.PAGE_LOAD,
    event_action: EventCategory.PAGE_LOAD,
    event_category: EventCategory.PAGE_LOAD,
    event: Event.PURCHASE,
    event_name: EventName.ORDER_COMPLETE
  };
  return getDataLayer({ baseParams, flowParams });
};

export const getRrpProductDetailsAddToCartDataLayer = ({
  baseParams,
  eventLabel
}: {
  baseParams: DataLayerBaseParams;
  eventLabel: EventLabel;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: eventLabel,
    event_action: EventActions.BUTTON_CLICK,
    event_category: EventCategory.CONTENT_CLICK,
    event: Event.ADD_TO_CART,
    event_name: EventName.ORDER_INITIATE
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};

export const rrpCheckoutDataLayer = ({
  baseParams
}: {
  baseParams: DataLayerBaseParams;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventLabel.CHECKOUT,
    event_action: EventActions.BUTTON_CLICK,
    event_category: EventCategory.CONTENT_CLICK,
    event: Event.CHECKOUT,
    event_name: EventName.CHECKOUT
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};

export const rrpCustomerRegistrationDataLayer = ({
  baseParams
}: {
  baseParams: DataLayerBaseParams;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventLabel.GOT_IT,
    event_action: EventActions.BUTTON_CLICK,
    event_category: EventCategory.CONTENT_CLICK,
    event: Event.RRP_CUSTOMER_REGISTRATION,
    event_name: EventName.RRP_CUSTOMER_REGISTRATION
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};

export const rrpReviewOrderDataLayer = ({
  baseParams
}: {
  baseParams: DataLayerBaseParams;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventLabel.REVIEW_ORDER,
    event_action: EventActions.BUTTON_CLICK,
    event_category: EventCategory.CONTENT_CLICK,
    event: Event.ORDER_FULFILMENT,
    event_name: EventName.ORDER_FULFILMENT
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};
export const rrpOrderSummaryProceedDataLayer = ({
  baseParams
}: {
  baseParams: DataLayerBaseParams;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventLabel.PROCEED,
    event_action: EventActions.BUTTON_CLICK,
    event_category: EventCategory.CONTENT_CLICK,
    event: Event.ORDER_SUMMARY,
    event_name: EventName.ORDER_SUMMARY
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};

export const rrpOrderConfirmationDataLayer = ({
  baseParams
}: {
  baseParams: DataLayerBaseParams;
}) => {
  const flowParams: DataLayerFlowParams = {
    event_label: EventLabel.ORDER_RECEIVED,
    event_action: null,
    event_category: EventCategory.PAGE_LOAD,
    event: Event.ORDER_CONFIRMATION,
    event_name: EventName.ORDER_CONFIRMATION
  };
  baseParams.rrpFlow = true;
  return getDataLayer({ baseParams, flowParams });
};
