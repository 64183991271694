/* istanbul ignore file */
import { REQUEST_STATUS } from '../constants';
import { accessoryActions } from '@detox/actions';
const {
  STORE_SELECTED_ACCESSORIES,
  CLEAR_SELECTED_ACCESSORIES,
  REMOVE_ACCESSORY
} = accessoryActions.ACCESSORY_ACTIONS;
export const initialState = {
  modified: false,
  status: REQUEST_STATUS.IDLE,
  selectedAccessories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_ACCESSORIES_LOADING':
    case 'REMOVE_ACCESSORIES_LOADING': {
      return {
        ...state,
        status: REQUEST_STATUS.PENDING,
        modified: false
      };
    }

    case 'ADD_ACCESSORIES_SUCCESS':
    case 'REMOVE_ACCESSORIES_SUCCESS': {
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        modified: true
      };
    }

    case 'ADD_ACCESSORIES_ERROR':
    case 'REMOVE_ACCESSORIES_ERROR': {
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        modified: false
      };
    }

    case 'RESET_MODIFY_ACCESSORIES': {
      return {
        ...state,
        modified: false,
        status: REQUEST_STATUS.IDLE
      };
    }

    case STORE_SELECTED_ACCESSORIES: {
      return {
        ...state,
        selectedAccessories: action.value
      };
    }

    case CLEAR_SELECTED_ACCESSORIES: {
      return {
        ...state,
        selectedAccessories: []
      };
    }
    case REMOVE_ACCESSORY: {
      const removeId = action.value;
      const selectedAccessoriesAfterRemove = state.selectedAccessories.reduce(
        (result, accessory) => {
          if (accessory.id !== removeId) {
            result.push(accessory);
          }
          return result;
        },
        []
      );
      return {
        ...state,
        selectedAccessories: selectedAccessoriesAfterRemove
      };
    }

    default: {
      return state;
    }
  }
};
