import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { Selector, Column, Text, Row, theme } from '@dls/web';
import { api } from '@detox/actions';
import styled from 'styled-components';
import { Service } from '@detox/actions/dist/types/types/service.types';

import { Product } from '../../types/product.types';
import { PlanState } from '../../types/plan.types';
import { Plan } from '../../types/order';
import createDescription from '../../helpers/create-description';
import { planTagsConfig } from '../../config/plan-group-config';
import { CI_ELIGIBLE_MONTHS } from '../../config';
import notifications from '../../config/notifications.js';
import { splitNumber, isTagged } from '../../helpers/common';

const { isServiceSuspended, isEligibleForRecontract } = api.mcss.helpers;
const { isCIEligibleForRecontract } = api.mcss.helpersService;

interface TProps {
  selectedPlan: Plan;
  plans: PlanState[];
  selectedProduct: Product;
  services: CustomerService[];
  onServiceSelection: (service: unknown) => void;
  ctaText: string;
}

interface CustomerService extends Service {
  [key: string]: unknown;
}

interface ProcessedServices {
  service: CustomerService;
  isReContactNotEligible: boolean;
  message: string;
  serviceNumber: string;
  offeringName: string;
  planDescription: string;
}

interface ProcessedServicesInputs {
  services: CustomerService[];
  selectedProduct: Product;
  plans: PlanState[];
}

const columnProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 6
};

const StyledRow = styled(Row)`
  margin: 0 -8px;
`;

const StyledDiv = styled.div`
  ${props =>
    props.isHeightNeeded &&
    `
    height: 10rem;
  `}
  @media (max-width: ${theme.breakpoints['xs']}) {
    height: auto;
  }
`;

const getServiceMessage = (service, isEligible, currentMessage): string => {
  let message = currentMessage;
  if (isServiceSuspended(service)) {
    message = notifications.ELIGIBILITY_SUSPENDED.text;
  } else if (!isEligible) {
    message = notifications.ELIGIBILITY_HAS_PENDING_ORDER.text;
  } else if (service.errorMsg) {
    message = notifications[service.errorMsg]?.text;
  }

  return message;
};

const getProcessedServices = ({
  services,
  plans,
  selectedProduct
}: ProcessedServicesInputs): ProcessedServices[] => {
  const hasDevice = Boolean(selectedProduct);

  const filteredServices = services
    ?.filter(service => Boolean(service.serviceId))
    .map(service => {
      let planDescription = '';
      let isCIEligible = true;
      let message = '';
      const contractPlan = plans.find(
        plan => plan.basePriceSchemaId === service.plan?.catalogItemID
      );
      const isSimOnlyPlan = isTagged(
        contractPlan?.tags,
        planTagsConfig['sim-only'].tagName
      );
      if (contractPlan && !isSimOnlyPlan) {
        planDescription = createDescription({
          data: contractPlan.data,
          talktime: contractPlan.talktime,
          sms: contractPlan.sms
        });
      }

      if (hasDevice) {
        isCIEligible = isCIEligibleForRecontract(service as never, {
          contractEligibleMonths: CI_ELIGIBLE_MONTHS
        });

        if (!isCIEligible) {
          message = notifications.ELIGIBILITY_NOT_CI_ELIGIBLE.text;
        }
      }

      // A generic check for alone re-contract eligibility.
      const isEligible = isEligibleForRecontract(service);
      // Block customers with paid orders but are pending for clearing
      const hasPaidUnclearedOrder =
        service.errorMsg === 'ELIGIBILITY_HAS_PENDING_PAYMENT_ORDER';

      message = getServiceMessage(service, isEligible, message);

      const isReContactNotEligible =
        isEligible &&
        !service.disabled &&
        isCIEligible &&
        !hasPaidUnclearedOrder;

      const serviceNumber = splitNumber(service.serviceId);

      return {
        service,
        isReContactNotEligible,
        message,
        serviceNumber,
        planDescription,
        offeringName: service.offeringName
      };
    }) // sort based on enabled services
    .sort((fService, nService) => {
      const firstServiceEligibility = fService.isReContactNotEligible ? -1 : 1;
      const firstAndNextServiceEligibility =
        fService.isReContactNotEligible === nService.isReContactNotEligible;

      return firstAndNextServiceEligibility ? 0 : firstServiceEligibility;
    });

  return filteredServices || [];
};

const ExistingServices: React.FC<TProps> = (props): ReactElement => {
  const {
    selectedProduct,
    plans = [],
    services = [],
    onServiceSelection
  } = props;
  const [selectedService, setSelectedService] = useState(-1);

  const filteredServices = useMemo(() => {
    return getProcessedServices({
      services,
      plans,
      selectedProduct
    });
  }, [services, plans, selectedProduct]);

  useEffect(() => {
    onServiceSelection(null);
  }, []);

  useEffect(() => {
    if (filteredServices[selectedService]?.message) {
      updateSelection(-1);
    }
  }, [selectedService, filteredServices, onServiceSelection]);

  const updateSelection = (servIndex: number) => {
    setSelectedService(servIndex);

    onServiceSelection(filteredServices[servIndex]?.service || null);
  };

  return (
    <Row>
      <Column xs={12} md={8} noGutter>
        <div className="fs-mask">
          <StyledRow>
            {filteredServices.map(
              (
                {
                  service,
                  isReContactNotEligible,
                  serviceNumber,
                  planDescription,
                  message
                },
                servIndex
              ) => {
                return (
                  <Column {...columnProps} key={service.serviceId}>
                    <StyledDiv isHeightNeeded={filteredServices.length > 1}>
                      <Selector
                        autoHeight={false}
                        alignment="row"
                        selected={
                          selectedService === servIndex &&
                          isReContactNotEligible &&
                          !message
                        }
                        disabled={!isReContactNotEligible || Boolean(message)}
                        onClick={() => {
                          updateSelection(servIndex);
                        }}
                        data-testid={`recontract-button-${
                          !isReContactNotEligible ? 'disabled' : 'enabled'
                        }`}
                      >
                        <Selector.Body
                          title={serviceNumber}
                          subtitle={service.offeringName}
                          align={'start'}
                        >
                          <Text type="body">{planDescription}</Text>
                        </Selector.Body>
                      </Selector>
                    </StyledDiv>
                    {message && (
                      <Text type="smallBody">
                        <span dangerouslySetInnerHTML={{ __html: message }} />
                      </Text>
                    )}
                  </Column>
                );
              }
            )}
          </StyledRow>
        </div>
      </Column>
    </Row>
  );
};

export default ExistingServices;
