import {
  applyMiddleware,
  compose,
  createStore as reduxCreateStore
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import isServer from '../helpers/is-server';
import rootReducer from '../reducers';

import eventTrackingMiddleware from '../middlewares/events';
import navigationMiddleware from '../middlewares/navigation';
import { persistStore, persistReducer } from 'redux-persist';
import { getStorage } from '../helpers/reduxHelper';

const isProduction =
  process.env.NODE_ENV === 'production' &&
  process.env.GATSBY_STAGING_ENV !== 'true';

const isUnitTest = process.env.NODE_ENV === 'test';
const initialState = {};

const middlewares = [thunk, navigationMiddleware, eventTrackingMiddleware];
let reducer = rootReducer;

const logger = createLogger({
  collapsed: true
});

// Create logger only during runtime
if (!isProduction && !isServer() && !isUnitTest) {
  middlewares.push(logger);
}

if (!isServer()) {
  // Persist reducers only during runtime

  // Blacklist user here because it will be persisted individually as a reducer
  const persistConfig = {
    key: 'root',
    storage: getStorage(),
    blacklist: [
      'user',
      'auth',
      'voucher',
      'documents',
      'productCatalog',
      'userPromotions'
    ]
  };
  reducer = persistReducer(persistConfig, rootReducer);
}

const appliedMiddlewares = compose(applyMiddleware(...middlewares));
export const store = reduxCreateStore(
  reducer,
  initialState,
  appliedMiddlewares
);

// persistor to be passed to PersistGate
const persistor = !isServer() && persistStore(store);

export default () => ({ store, persistor });
