import styled from 'styled-components';
import { FORM_FIELD_TYPES } from '@wec-core/form-engine';
import { trans as t } from '../../helpers/localisation';
import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { myInfoReadOnlyFields } from '../Checkout/myinfoHelper';

type ApiReturnType = [APP_TYPE_ANY | null, APP_TYPE_ANY | null, boolean];

const baseUrl = process.env.GATSBY_AEM_URL;

export const fetchAndRespond = async (
  api: (_: APP_TYPE_ANY) => APP_TYPE_ANY,
  options: APP_TYPE_ANY
): Promise<ApiReturnType> => {
  try {
    const res = await api(options);
    return [res, null, false];
  } catch (error) {
    return [null, error, false];
  }
};

export const StyledSingPassImg = styled.img`
  width: 165px;
`;

export const eRegReadOnlyFields = myInfoData => {
  const readOnlyFields = [...myInfoReadOnlyFields(myInfoData)];
  readOnlyFields.splice(2, 1);
  return readOnlyFields;
};

export const getSubmitButton = () => {
  return [
    {
      component: FORM_FIELD_TYPES.FORM_DIVIDER,
      name: 'personalSectionDivider'
    },
    {
      component: FORM_FIELD_TYPES.FORM_TITLE,
      name: 'termsTitle',
      title: t('CO_NN_TERMS')
    },
    {
      component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
      name: 'termsCheck',
      label: t('E_FORM_TERMS_1'),
      showError: true,
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_TERMS') }
        }
      ]
    },
    {
      component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
      name: 'infoCheck',
      label: t('E_FORM_TERMS_2'),
      showError: true,
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_TERMS') }
        }
      ]
    },
    {
      component: FORM_FIELD_TYPES.CHECKBOX_INPUT,
      name: 'consentCheck',
      label: t('E_FORM_TERMS_3'),
      showError: true,
      validations: [
        'string',
        {
          type: 'required',
          params: { message: t('CC_E_TERMS') }
        }
      ]
    },
    {
      component: FORM_FIELD_TYPES.FORM_SUBMIT_BUTTON,
      name: 'submitButton',
      label: t('CR_SUBMIT_CTA'),
      columnProps: {
        xs: 12,
        sm: 6,
        md: 6
      },
      sizing: 3,
      btnProps: {
        fullWidth: true
      }
    }
  ];
};

export const getTransactionList = (): Array<KeyValue> => [
  { label: t('E_FORM_TRANSACTION_TYPE_NEW'), value: 'NEW' },
  { label: t('E_FORM_TRANSACTION_TYPE_PORTIN'), value: 'PORTIN' }
];

export const getTransactionType = () => {
  return {
    component: FORM_FIELD_TYPES.BINARY_SELECTOR_INPUT,
    name: 'transactionType',
    label: t('E_FORM_TRANSACTION_TYPE_TITLE'),
    dataSource: 'transactionList',
    validations: [
      'string',
      {
        type: 'required',
        params: { message: t('E_FORM_TRANSACTION_TYPE_REQUIRED') }
      }
    ]
  };
};

export const eRegisterInitialValues = ({ email, contactNumber }) => {
  const emailValue = ('' + email).toLowerCase();

  return {
    transactionType: '',
    email: emailValue || '',
    contactNumber: contactNumber || '',
    termsCheck: '',
    infoCheck: '',
    consentCheck: ''
  };
};

export const formattedFormData = (values: KeyValue = {}) => {
  const {
    passValue,
    fullName,
    contactNumber,
    email,
    transactionType,
    mailingAddrBlock,
    mailingAddrStreet,
    mailingAddrFloor,
    mailingAddrUnit
  } = values;

  return {
    eventId: 'eSIMTrialBuy',
    eformType: 'eSIMTrialBuy',
    custId: passValue,
    custName: fullName,
    mobileNo: contactNumber,
    emailAddress: email,
    product: 'mobile',
    transactionType: transactionType,
    handset: '',
    installationAddress: '',
    remark: `Billing Address: ${mailingAddrBlock}, ${mailingAddrStreet}, ${mailingAddrFloor}, ${mailingAddrUnit}`
  };
};

export const submitSRB = async (
  data: APP_TYPE_ANY = {}
): Promise<ApiReturnType> => {
  const payload = formattedFormData(data);

  try {
    const res = await fetch(`${baseUrl}/api/srp/v1/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (!res.ok) {
      throw new Error('submit failed');
    }
    const resData = await res.json();

    return [resData, null, false];
  } catch (error) {
    return [null, error, false];
  }
};

export interface ERegisterReducerState {
  showRegForm: boolean;
  singpassCancel: boolean;
  apiLoading: boolean;
  toggleAckModal: boolean;
  customerMyInfo: KeyValue;
  addressData: KeyValue;
  modalData?: {
    title: APP_TYPE_ANY;
    message: APP_TYPE_ANY;
    clearState: boolean;
  };
}

export const EREG_STATE_ACTIONS = {
  API_LOADING: 'API_LOADING',
  RESET_LOADING: 'RESET_LOADING',
  CLEAR_AND_RESET: 'CLEAR_AND_RESET',
  HANDLE_CANCEL_ACK: 'HANDLE_CANCEL_ACK',
  SET_RETRIEVE_INITIATE: 'SET_RETRIEVE_INITIATE',
  SET_CUS_INFO_SUCCESS: 'SET_CUS_INFO_SUCCESS',
  SET_ADDRESS_DATA_SUCCESS: 'SET_ADDRESS_DATA_SUCCESS',
  SET_RETRIEVE_FAILURE: 'SET_RETRIEVE_FAILURE',
  SET_SHOW_REG_FORM: 'SET_SHOW_REG_FORM',
  SET_SINGPASS_: 'SET_SHOW_REG_FORM',
  SUBMIT_SRB_SUCCESS: 'SUBMIT_SRB_SUCCESS'
};

const defaultErrorModal = {
  title: t('GENERIC_ERROR_TITLE'),
  message: t('CO_CONTACT_ERROR_MSG'),
  clearState: true
};

export const eRegisterInitialState = {
  showRegForm: false,
  singpassCancel: false,
  apiLoading: false,
  toggleAckModal: false,
  customerMyInfo: null,
  addressData: null,
  modalData: defaultErrorModal
};

export const eRegisterStateReducer = (
  state = eRegisterInitialState,
  action: { type: string; payload?: APP_TYPE_ANY }
): ERegisterReducerState => {
  switch (action.type) {
    case EREG_STATE_ACTIONS.API_LOADING: {
      return {
        ...state,
        apiLoading: true
      };
    }

    case EREG_STATE_ACTIONS.SET_SHOW_REG_FORM: {
      return {
        ...state,
        showRegForm: action.payload
      };
    }

    case EREG_STATE_ACTIONS.RESET_LOADING: {
      return {
        ...state,
        apiLoading: false
      };
    }

    case EREG_STATE_ACTIONS.SET_CUS_INFO_SUCCESS: {
      return {
        ...state,
        apiLoading: false,
        customerMyInfo: action.payload
      };
    }

    case EREG_STATE_ACTIONS.SET_ADDRESS_DATA_SUCCESS: {
      return {
        ...state,
        apiLoading: false,
        addressData: action.payload
      };
    }

    case EREG_STATE_ACTIONS.SUBMIT_SRB_SUCCESS: {
      const { message = '' } = action.payload || {};
      const reqdata = message.match(/REQ-\d+/);
      const reqRef = reqdata ? reqdata[0] : '';

      return {
        ...state,
        apiLoading: false,
        toggleAckModal: true,
        modalData: {
          title: t('E_FORM_REG_SUCCESS_TITLE'),
          message: t('E_FORM_REG_SUCCESS_MSG', {
            reqRef
          }),
          clearState: false
        }
      };
    }

    case EREG_STATE_ACTIONS.SET_RETRIEVE_INITIATE: {
      return {
        ...state,
        ...eRegisterInitialState,
        apiLoading: true
      };
    }

    case EREG_STATE_ACTIONS.SET_RETRIEVE_FAILURE: {
      return {
        ...state,
        modalData: defaultErrorModal,
        toggleAckModal: true,
        apiLoading: false
      };
    }

    case EREG_STATE_ACTIONS.HANDLE_CANCEL_ACK: {
      return {
        ...state,
        singpassCancel: action.payload,
        showRegForm: false
      };
    }

    case EREG_STATE_ACTIONS.CLEAR_AND_RESET: {
      return eRegisterInitialState;
    }

    default:
      return eRegisterInitialState;
  }
};
