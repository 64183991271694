/**
 * Sorts an array of capacities object
 * @param {Array} capacities
 * @param {String} field name of property in object to be sorted
 */

const sortCapacity = (capacities, field) => {
  return capacities.sort((a, b) => {
    const first = field ? a[field] : a;
    const second = field ? b[field] : b;

    return (
      parseInt(first.split('GB')[0], 10) - parseInt(second.split('GB')[0], 10)
    );
  });
};

export default sortCapacity;
