import React from 'react';
import { Column, Row } from '@lux/components';
import { Text, TextLink, Modal, Button, Spacing } from '@dls/web';
import { Simcard } from '@dls/assets/dist/icons/web';
import { ColorContainer, IconWrapper, TextLinkWrapper } from './styles';
import useTranslate from '../../hooks/useTranslation';
import { getMobileShareLinesOfMainLine } from '../../helpers/chooseYourNumberHelper';
import { mobileShareFaqUrl } from '../../config/links';
import { APP_TYPE_ANY } from '../../types/common.types';

interface SupplementaryLinesConversionConfirmationModalProps {
  visible: boolean;
  onMobileShareToSimOnlyConversionConfirmation: () => void;
  onChooseAnotherPlan: () => void;
  onClose: () => void;
  selectedPlanName: string;
  selectedServiceState: APP_TYPE_ANY;
  user: APP_TYPE_ANY;
}

const SupplementaryLinesConversionConfirmationModal = ({
  visible,
  onMobileShareToSimOnlyConversionConfirmation,
  onChooseAnotherPlan,
  onClose,
  selectedServiceState,
  selectedPlanName,
  user
}: SupplementaryLinesConversionConfirmationModalProps) => {
  const { t } = useTranslate(null);

  const onFaqLinkClick = () => {
    window.open(mobileShareFaqUrl, '_blank');
  };

  return (
    <Modal
      wide={true}
      visible={visible}
      title={t('MS_CONVERSION_MODAL_TITLE')}
      onClose={onClose}
      data-testid="supplementary-lines-conversion-confirmation-modal"
    >
      <Modal.Content>
        <Text type="body">
          <span>
            {t('MS_CONVERSION_MODAL_DESCRIPTION_1', [
              selectedServiceState?.offeringName,
              selectedPlanName
            ])}
          </span>
          <TextLink
            inline
            onClick={onFaqLinkClick}
            data-testid="mobileshare-faq-link"
          >
            {t('MOBILE_SHARE')}
          </TextLink>
          <span>{t('MS_CONVERSION_MODAL_DESCRIPTION_2')}</span>
          <Text type="boldBody" tag="span">
            {getMobileShareLinesOfMainLine(user, selectedServiceState)}
          </Text>
          <span>{t('MS_CONVERSION_MODAL_DESCRIPTION_3')}</span>
        </Text>
        <ColorContainer type="secondary">
          <IconWrapper>
            <Simcard size={24} color={'#222222'} />
          </IconWrapper>
          <div>
            <Text type="boldBody">
              {t('MS_CONVERSION_DEFAULT_SIM_ONLY_TITLE')}
            </Text>
            <Text type="body">
              {t('MS_CONVERSION_DEFAULT_SIM_ONLY_DESCRIPTION')}
            </Text>
          </div>
        </ColorContainer>
        <Text type="smallBody">
          {t('MS_CONVERSION_DEFAULT_SIM_ONLY_DISCLAIMER')}
        </Text>
        <Spacing top={3}>
          <Row>
            <Column>
              <Button
                fullWidth
                onClick={onMobileShareToSimOnlyConversionConfirmation}
              >
                <Text type="boldBody">{t('PROCEED')}</Text>
              </Button>
              <TextLinkWrapper>
                <TextLink secondary onClick={onChooseAnotherPlan}>
                  {t('CHOOSE_ANOTHER_PLAN')}
                </TextLink>
              </TextLinkWrapper>
            </Column>
          </Row>
        </Spacing>
      </Modal.Content>
    </Modal>
  );
};
export default SupplementaryLinesConversionConfirmationModal;
