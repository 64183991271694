/**
 * Serialises and saves state to localStorage
 *
 * @param {String} keyName
 * @param {Object} keyValue
 */
export const setState = (keyName, keyValue) => {
  try {
    return window.localStorage.setItem(keyName, JSON.stringify(keyValue));
  } catch {}
};

/**
 * Retrieves and deserialises state from localStorage
 *
 * @param {String} keyName
 */
export const getState = keyName => {
  try {
    return JSON.parse(localStorage.getItem(keyName));
  } catch {}
};

/**
 * Deletes state from localStorage
 *
 * @param {String} keyName
 */
export const removeState = keyName => {
  return window.localStorage.removeItem(keyName);
};

export default {
  set: setState,
  get: getState,
  remove: removeState
};
