import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isZeroUpfrontCharges } from '../../components/OrderSummary/helper';
import { ACTION_TYPES } from '../../constants/actions';
import ORDER_CONSTANTS from '../../constants/order';
import { OrderSummaryState } from '../../reducers/orderSummary';
import { dataLayerPush } from '../../helpers/common';
import { rrpOrderSummaryProceedDataLayer } from '../../helpers/dataLayerHelpers';
import { RRPCartState } from '../../reducers/rrpCart';
import { Fulfilment } from '../../types/fulfilment.types';

type UsePaymentRedirectionOptions = {
  thankYouPageUrlSlug: string;
};

const usePaymentRedirection = (
  orderSummary: OrderSummaryState,
  { thankYouPageUrlSlug }: UsePaymentRedirectionOptions,
  rrpCart?: RRPCartState,
  fulfilmentState?: Fulfilment
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onePayUrl = orderSummary.paymentIntent?.redirect_url;
    if (orderSummary.requestPaymentSuccess && orderSummary?.refId) {
      if (isZeroUpfrontCharges(orderSummary?.quoteData)) {
        pushDataLayer();
        navigate(
          `/${thankYouPageUrlSlug}?pgStatus=${ORDER_CONSTANTS.STATUS.SUCCESS}&${ORDER_CONSTANTS.URL_PARAMS.PAYMENT_TYPE}=${ORDER_CONSTANTS.PAYMENT_TYPES.NO_PAYMENT}&${ORDER_CONSTANTS.URL_PARAMS.ZERO_UPFRONT}=true`
        );
      } else if (onePayUrl) {
        pushDataLayer();
        window.sessionStorage.setItem('det-onepay-ref-id', orderSummary?.refId);
        window.open(onePayUrl, '_self');
      } else {
        pushDataLayer();
        navigate(
          `/${thankYouPageUrlSlug}?pgStatus=${ORDER_CONSTANTS.STATUS.SUCCESS}&${ORDER_CONSTANTS.URL_PARAMS.PAYMENT_TYPE}=${ORDER_CONSTANTS.PAYMENT_TYPES.CASH}`
        );
      }
    }
    dispatch({ type: ACTION_TYPES.ORDER_SUMMARY.CLEAR_PAYMENT_INTENT });
  }, [
    orderSummary.paymentIntent?.redirect_url,
    orderSummary.requestPaymentSuccess,
    orderSummary?.refId
  ]);

  const pushDataLayer = () => {
    if (rrpCart && fulfilmentState) {
      dataLayerPush(
        rrpOrderSummaryProceedDataLayer({
          baseParams: {
            cartItems: rrpCart.addedItems,
            delivery: { type: fulfilmentState?.delivery?.selectedDeliveryMode },
            location: window.location,
            ippDetails: rrpCart.tempCartItem
          }
        })
      );
    }
  };
};

export default usePaymentRedirection;
