import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions, checkoutActions } from '@detox/actions';
import { KeyValue } from '../../types/common.types';

interface UserState {
  information: {
    anonymousUser: boolean;
    clientContext: KeyValue;
  };
}

interface RootState {
  user: UserState;
  checkout: KeyValue;
}

const { getUserInformation, resetUser } = userActions;
const { getCallerByContact } = checkoutActions;

const useUser = (skipCallerByContact = false): KeyValue => {
  const dispatch = useDispatch();
  const { userInformation, verificationData } = useSelector(
    (state: RootState) => {
      return {
        userInformation: state.user.information,
        verificationData: state.checkout?.verificationData
      };
    }
  );
  const hasUserInfo = Boolean(userInformation);
  const hasUserInfoContext = Boolean(userInformation?.clientContext);
  const isUserLoggedIn = Boolean(
    hasUserInfo && !userInformation?.anonymousUser
  );

  useEffect(() => {
    if (!hasUserInfo) {
      dispatch(getUserInformation());
    }
  }, [hasUserInfo]);

  useEffect(() => {
    if (
      hasUserInfo &&
      verificationData &&
      !hasUserInfoContext &&
      !skipCallerByContact
    ) {
      const { id: passId, passType } = verificationData;
      dispatch(getCallerByContact({ passType, passId }));
    }
  }, [hasUserInfo, hasUserInfoContext, verificationData, skipCallerByContact]);

  return { isUserLoggedIn, hasUserInfo };
};

export default useUser;
