import React from 'react';
import { Grid, Row, Column, Spacing, Text, Button } from '@dls/web';

const OutOfStock = props => {
  const { selectedProduct, onViewAvailableProduct } = props;
  return (
    <Grid>
      <Row>
        <Column sm={12} md={4} lg={4} xl={4}>
          <Text type="bannerTitle" tag="h2">
            Sorry, we're unable to sign you up at this time
          </Text>
          <Spacing top={1} bottom={1}>
            <Text type="smallBody">
              The {selectedProduct?.title}, {selectedProduct?.colour},{' '}
              {selectedProduct?.size} is no longer available and has been
              removed from your cart.
            </Text>
          </Spacing>
        </Column>
        <Column sm={false} xs={false} md={true} lg={true} xl={true} />
      </Row>
      <Row>
        <Column>
          <Button onClick={onViewAvailableProduct}>Choose another item</Button>
        </Column>
      </Row>
    </Grid>
  );
};

export default OutOfStock;
