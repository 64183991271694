import React from 'react';
import { Modal, Button, Text, Spacing, useDevice } from '@dls/web';
import { APP_TYPE_ANY } from '../../types/common.types';

interface Props {
  isModalOpen?: boolean;
  disableClose?: boolean;
  modaltitle?: string;
  modalItems?: APP_TYPE_ANY[];
  modalContent?: string;
  ctaText?: string;
  secondaryType?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const MyInfoEKYCModal: React.FC<Props> = props => {
  const {
    isModalOpen,
    disableClose,
    modaltitle,
    modalItems,
    modalContent,
    ctaText,
    secondaryType,
    onClose,
    onConfirm
  } = props;
  const { isMobile } = useDevice();

  return (
    <Modal
      data-testid={`myinfo-modal-${modaltitle}`}
      visible={isModalOpen}
      title={modaltitle}
      closable={disableClose}
      onClose={onClose}
      backdropClosable={false}
    >
      <Modal.Content>
        <Spacing bottom={4}>
          <Text type="smallBody">{modalContent}</Text>
          {modalItems && (
            <ul>
              {modalItems.map(msg => (
                <li dangerouslySetInnerHTML={{ __html: msg }} />
              ))}
            </ul>
          )}
        </Spacing>
        {secondaryType && (
          <Button
            fullWidth={isMobile}
            secondary
            data-testid="confirm-button"
            text={ctaText}
            onClick={() => {
              onConfirm();
            }}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default MyInfoEKYCModal;
