import styled, { css } from 'styled-components';
import { Column, Layout, Spacing, withTheme } from '@dls/web';

export const StyledStickyColumns = styled(Column)`
  align-content: center;
`;

export const StyledStickyButtonLayout = withTheme(styled(Layout.Inline)`
  ${({ coreTheme }) => css`
    justify-content: flex-end;
    & > * {
      width: 100%;
    }
    @media (min-width: ${coreTheme.brk_md}) {
      & > * {
        width: auto;
      }
    }
  `}
`);

export const StyledStickyProductLayout = styled(Layout.Inline)`
  justify-content: flex-start;
`;

export const StickyImageContainer = styled.div`
  min-width: 55px;
  min-height: 55px;
`;

export const StyledSpacing = styled(Spacing)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledCardContent = styled.div`
  padding: 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledTruncatedDiv = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const StyledDiv = styled.div`
  flex: 1;
`;

export const StyledStackSpacing = styled(Spacing)`
  height: 55px;
  display: flex;
  align-items: center;
`;

export const StyledBackgroundDiv = withTheme(styled.div`
  ${({ coreTheme }) => css`
    background-color: ${coreTheme.cl_white};
  `}
`);

export const StyledErrorButtonLayout = withTheme(styled(Layout.Inline)`
  ${({ coreTheme }) => css`
    justify-content: flex-start;
    & > * {
      width: 100%;
      margin-right: 8px;
      margin-bottom: 16px;
    }
    @media (min-width: ${coreTheme.brk_xs}) {
      & > * {
        width: auto;
      }
    }
  `}
`);
