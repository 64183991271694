import { useEffect, useRef } from 'react';

/**
 * like setInterval
 * setInterval does not work with hooks
 *
 * @param {Function} callback Function evaluated at specified intervals (in milliseconds)
 * @param {*} delay Interval in milliseconds
 */

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
