import React from 'react';
import { Modal, Button, Text, useDevice, Spacing } from '@dls/web';
import { useForm } from 'react-hook-form';
import { APP_TYPE_ANY } from '../../types/common.types';

interface Props {
  isModalOpen: boolean;
  disableClose: boolean;
  modaltitle: string;
  modalItems: APP_TYPE_ANY[];
  modalContent: string;
  ctaText: string;
  secondaryType: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const AddonModal: React.FC<Props> = props => {
  const {
    isModalOpen,
    disableClose,
    modaltitle,
    modalItems,
    modalContent,
    ctaText,
    secondaryType,
    onClose,
    onConfirm
  } = props;
  const { handleSubmit } = useForm();
  const { isMobile } = useDevice();
  const onSubmit = handleSubmit(data => {
    onClose();
    onConfirm();
  });

  return (
    <Modal
      data-testid={`addon-modal-${modaltitle}`}
      visible={isModalOpen}
      title={modaltitle}
      closable={disableClose}
      onClose={onClose}
    >
      <Modal.Content>
        <Spacing bottom={4}>
          <Text type="smallBody">{modalContent}</Text>
          {modalItems && (
            <ul>
              {modalItems.map((msg, index) => (
                <li
                  key={`addon-error-${index}`}
                  dangerouslySetInnerHTML={{ __html: msg }}
                />
              ))}
            </ul>
          )}
        </Spacing>
        {secondaryType && (
          <Button
            fullWidth={isMobile}
            secondary
            data-testid="confirm-button"
            text={ctaText}
            onClick={onSubmit}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AddonModal;
