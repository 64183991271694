import React, { ReactNode, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { Fulfilmentv2, LAYOUT } from '@common-modules/shopping-cart';
import {
  fulfillmentDeliveryActions,
  fulFillmentActions,
  PaymentMode,
  FulfilmentMode
} from '@detox/actions';

import { navigation } from '../../middlewares/navigation-constants';

import {
  confirmNewAddress,
  revertNewAddress
} from '../../reducers/fulfillment/addressInfo';

import {
  initData,
  setAddress,
  setConfirmAddress,
  setDeliveryTimeSlots,
  setPopstations,
  setPopstationTimeslots,
  setValidFloorForAddress,
  setExistingAddress,
  setPreSelectDeliveryData,
  setDeliveryMode,
  clearPreSelectDeliveryData
} from '../../reducers/fulfillment/delivery';
import { resetPopStationData } from '../../reducers/fulfillment/popstations';

import { FULFILLMENT, COMMON } from '../../constants';
import { POPSTATION_TIMESLOTS, DELIVERY_SLOTS } from '../../config/fulfilment';
import { ACTION_TYPES } from '../../constants/actions';

import useTranslate from '../../hooks/useTranslation';
import useUser from '../../hooks/useUser';
import { useErrorHandler } from '../../hooks/useErrorHandler';

import {
  getDeliveryTimeSlot,
  getPopstationTimeSlot,
  getDoorStepDeliveryTimeSlot,
  getSelectedPopStationTimeSlot,
  getRetrieveAddressIdParams,
  getAddressWithId,
  getFulfilmentParams,
  getSelectedPopstation,
  getAddressInfo,
  formatDate
} from '../Fulfilment/helpers';
import {
  RRPFulfilment,
  getRrpIppFulfilmentOptions
} from '../../helpers/fulfilment';
import { getProductBySku } from '../../helpers/rrp-product';
import {
  getFirstEntries,
  flattenNodes,
  dataLayerPush
} from '../../helpers/common';

import { APP_TYPE_ANY, KeyValue } from '../../types/common.types';
import { BaseAddress } from '../../types/registrationCheckout';
import {
  getLeadDays,
  getPreOrderFulfilment,
  getAEMFulfilmentById
} from '../../helpers/fulfilment.common';
import { RRPCartState } from '../../reducers/rrpCart';
import { rrpReviewOrderDataLayer } from '../../helpers/dataLayerHelpers';
import { getCatalogPath } from '../../helpers/rrpCart';
import { SkeletonType } from '../SkeletonLoader';
import { useLoader } from '../../hooks/useLoader';

const {
  FULFILMENT_ACTIONS,
  FULFILMENT_TYPES_AEM,
  REQUEST_STATUS
} = FULFILLMENT;

const RrpIppFulfilment = ({ data }: KeyValue): ReactNode => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const allRrpProduct = flattenNodes(data.allRrpProduct);
  const productVariantBySku = getProductBySku(allRrpProduct);
  const { isUserLoggedIn } = useUser();

  const {
    fulfilmentState,
    deliveryDataModel,
    cart,
    userInformation,
    user,
    productOrder,
    checkout,
    bridgeData,
    rrpCart,
    skeletonLoading
  } = useSelector((state: APP_TYPE_ANY) => ({
    fulfilmentState: state.fulfillment,
    deliveryDataModel: state.fulfillment?.delivery?.deliveryDataModel,
    cart: state.cart,
    userInformation: state.user?.information,
    user: state.user,
    productOrder: state.cart?.order?.productOrder,
    checkout: state.checkout,
    bridgeData: state?.bridge?.data,
    rrpCart: state.rrpCart as RRPCartState,
    skeletonLoading: state.fulfillment?.delivery?.skeletonLoading
  }));

  const { delivery, addressInfo, popstations } = fulfilmentState;
  const { appointmentSlots, deliveryDetails } = delivery;
  const { order, cartOrder } = cart;

  const hasCheckoutError =
    cart?.checkoutOrderError ||
    fulfilmentState?.allocateOrReserve?.status ===
      FULFILLMENT.REQUEST_STATUS.FAILED;

  const { fulfilmentList, leadDays = [] } = data.rrpFulfilment;
  const aemFulfilmentById = getAEMFulfilmentById(fulfilmentList, 'fulfilment');
  const accessoryIds = order?.accessories?.map(accessory => accessory.skuId);
  const preOrderFulfilment = getPreOrderFulfilment(accessoryIds, leadDays);

  const isPopstationLoading =
    fulfilmentState?.popstations?.status === REQUEST_STATUS.PENDING;
  const isPopstationTimeslotLoading =
    fulfilmentState?.popstations?.timeSlotsStatus === REQUEST_STATUS.PENDING;
  const showOverlayLoading =
    fulfilmentState.addressInfo?.isLoading ||
    fulfilmentState?.delivery?.isLoading;

  const [pickupType, setPickupType] = useState<string | undefined>();
  const { Loader } = useLoader({
    options: { type: SkeletonType.page, position: { top: 6 } },
    loadings: [showOverlayLoading],
    skeletonLoadings: [skeletonLoading]
  });
  const onCloseErrorPopUp = () => {
    dispatch({ type: ACTION_TYPES.DELIVERY.RESET_ERROR });
  };
  const isError = user?.error || delivery?.isError || popstations?.isError;
  const { renderError } = useErrorHandler({
    states: [
      {
        isError,
        redirectUrl: getCatalogPath()
      }
    ],
    onCloseErrorPopUp
  });

  const onConfirmNewAddress = (address: Partial<BaseAddress>) => {
    dispatch(confirmNewAddress(address));
  };

  const getProductsList = (products: KeyValue) => {
    const productList = products.map((item: KeyValue) => {
      let productVariantDetails;
      if (item.skuId in productVariantBySku) {
        productVariantDetails = productVariantBySku[item.skuId];
      }
      return {
        id: item.skuId,
        imageSrc:
          process.env.GATSBY_AEM_URL + productVariantDetails?.smallImage?.src,
        name: `${item?.name}`,
        description: productVariantDetails?.shippingLiner,
        quantity: '01',
        ribbon: productVariantDetails?.ribbonText
          ? `[${productVariantDetails?.ribbonText}]`
          : '',
        tags: productVariantDetails?.tags
      };
    });
    return productList;
  };

  useEffect(() => {
    if (popstations?.timeSlots?.length) {
      const popstationTimeslots = getPopstationTimeSlot(popstations.timeSlots);
      dispatch(
        setPopstationTimeslots(
          getFirstEntries(popstationTimeslots, POPSTATION_TIMESLOTS) as string[]
        )
      );
    } else if (popstations?.hasError) {
      dispatch(setPopstationTimeslots([]));
    }
  }, [popstations?.timeSlots]);

  useEffect(() => {
    if (isUserLoggedIn) {
      const { address, validFloors, savedAddress } = addressInfo;
      if (address && Object.keys(address).length > 0) {
        const addressWithId = getAddressWithId(address, savedAddress);
        dispatch(setConfirmAddress(addressWithId));
        dispatch(setValidFloorForAddress(validFloors));
      } else {
        dispatch(setConfirmAddress(null));
      }
    } else {
      dispatch(setConfirmAddress(null));
      dispatch(setValidFloorForAddress(null));
    }
  }, [addressInfo]);

  useEffect(() => {
    if (userInformation?.clientContext?.contact) {
      const {
        contactId,
        indentValue,
        indentType
      } = userInformation?.clientContext?.contact;
      dispatch(
        fulfillmentDeliveryActions.getDeliveryDetails({
          contactId,
          productOrderReferenceNumber:
            rrpCart?.ippOrderData?.productOrderReferenceNumber ||
            bridgeData?.productOrderReferenceNumber
        })
      );
      dispatch(
        fulFillmentActions.isEBillMethod({
          nric: indentValue,
          contactId,
          indentType
        })
      );
    }
  }, [userInformation?.clientContext?.contact]);

  const handleSearchPopstationTimeslot = async (value: string) => {
    const options = {
      slotType: RRPFulfilment.slotTypes.RRP_IPP,
      hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate),
      documentRequired: false,
      dealer: RRPFulfilment.dealerCode,
      outlet: value,
      leadDays: getLeadDays({
        firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
        fulfilmentData: aemFulfilmentById[FULFILMENT_TYPES_AEM.POP_STATION]
      })
    };

    dispatch(fulfillmentDeliveryActions.getSelfCollectionTimeSlots(options));
  };

  const slotType = () => {
    return order.vendorName === RRPFulfilment.vendor.SINGTEL
      ? RRPFulfilment.slotTypes.RRP_IPP
      : `${RRPFulfilment.slotTypes.DropShipping}-${deliveryDataModel.bags[0].items.list[0].id}`;
  };

  const getDeliverySlots = async (orderId: string) => {
    const options = {
      slotType: slotType(),
      dealerCode: RRPFulfilment.dealerCode,
      hasPrelaunchDevice: Boolean(preOrderFulfilment.firstDeliveryDate),
      leadDay: getLeadDays({
        firstDeliveryDate: preOrderFulfilment.firstDeliveryDate,
        fulfilmentData:
          aemFulfilmentById[FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY]
      }),
      paymentMode: RRPFulfilment.paymentMode.CASH as PaymentMode,
      documentRequired: false
    };
    dispatch(fulfillmentDeliveryActions.getLogisticAppointmentsSlots(options));
  };

  useEffect(() => {
    if (popstations?.data) {
      dispatch(setPopstations(popstations.data));
    }
  }, [popstations?.data]);

  useEffect(() => {
    if (appointmentSlots?.slots?.length > 0) {
      const deliverySlots = getDeliveryTimeSlot(appointmentSlots?.slots);
      const enabledDays = getFirstEntries(deliverySlots, DELIVERY_SLOTS);
      dispatch(setDeliveryTimeSlots(enabledDays));
    }
  }, [appointmentSlots]);

  const handleSearchPopstation = async (postcode, orderId) => {
    if (postcode) {
      dispatch(fulfillmentDeliveryActions.getPopstations(postcode));
    }
  };

  useEffect(() => {
    if (order?.accessories?.length) {
      const productsList = getProductsList(order.accessories);
      dispatch(
        initData({
          pickup: getRrpIppFulfilmentOptions({
            bag: productsList,
            vendor: order?.vendorName,
            fulfilmentData: data?.rrpFulfilment
          }),
          productList: { list: productsList, vendor: order?.vendorName }
        } as APP_TYPE_ANY)
      );
    }
  }, [order]);

  useEffect(() => {
    const { addresses, addAddressInfo, canAddNewAddress } = getAddressInfo({
      checkoutFlow: checkout.checkoutFlow,
      fulfilmentState,
      t,
      pickupType,
      fulfilmentList,
      isBuyingWithoutDevice: true,
      newAddressData: {
        floor: checkout?.checkoutFormData?.floorNumber,
        unitNumber: checkout?.checkoutFormData?.unitNumber
      },
      addressId: checkout?.newAddedAddressId,
      fulfilmentDataType: 'fulfilment'
    });

    dispatch(
      setExistingAddress({
        data: addresses,
        information: addAddressInfo,
        canAddNewAddress
      })
    );
  }, [fulfilmentState?.delivery?.deliveryDetails]);

  const onDeliverySubmit = async ({
    commonParams = {},
    deliverySelectedSlot,
    pickupType,
    address
  }) => {
    /**To Retain preselection and send addressId*/
    if ((address && !address?.addressId) || address?.isNewAddress) {
      address.addressId = await dispatch(
        fulfillmentDeliveryActions.retrieveAddressId(
          getRetrieveAddressIdParams(address),
          FULFILLMENT.FULFILMENT_TYPES_AEM.DOOR_STEP_DELIVERY as FulfilmentMode
        )
      );
    }
    const selectedFulfilmentMode = RRPFulfilment.deliveryMethodApi[pickupType];
    const deliveryTimeSlot = getDoorStepDeliveryTimeSlot({
      slots: appointmentSlots?.slots,
      deliverySelectedSlot,
      pickupType
    });

    const selectedAddressId =
      address?.addressId || deliveryDetails?.contactAddress?.addressId;

    const { selectedDate, selectedTime } = deliverySelectedSlot || {};
    dispatch(
      setPreSelectDeliveryData({
        selectedDeliveryMode: pickupType,
        address: selectedAddressId,
        timeslot: selectedDate && formatDate(selectedDate),
        selectedTime
      })
    );

    const fulfilmentRequestParams = getFulfilmentParams({
      ...commonParams,
      address,
      addressId: selectedAddressId,
      selectedTimeslot: deliveryTimeSlot,
      selectedFulfilmentMode,
      msisdn: cart?.cartOrder?.serviceId,
      isAllocateEquipmentRequired: false,
      isReserveEquipmentRequired: false,
      cartItem: cartOrder,
      reserveSlotType: slotType()
    });
    dispatch(
      fulFillmentActions.proceedToOrderSummary({
        ...fulfilmentRequestParams
      })
    );
  };

  const onPopstationSubmit = (
    commonParams = {},
    selectedTimeslot,
    address,
    selectedFulfilmentMode: string
  ) => {
    if (selectedTimeslot && address && user.information) {
      const fulfilmentRequestParams = getFulfilmentParams({
        ...commonParams,
        selectedTimeslot,
        address,
        addressId: '',
        selectedFulfilmentMode,
        cartItem: cartOrder,
        serviceId: cartOrder?.serviceId,
        selectedCollectAtStore: {
          collectionAddress: {
            city: COMMON.SINGAPORE,
            postcode: address?.PostCode,
            country: 'SGP',
            streetName: address?.StreetName,
            apartment: address?.HouseBlockNumber,
            countryCode: 'SGP',
            buildingName: address?.BuildingName,
            state: 'SNG'
          }
        }
      });
      dispatch(
        fulFillmentActions.proceedToOrderSummary(fulfilmentRequestParams)
      );
    }
  };

  useEffect(() => {
    if (fulfilmentState?.delivery?.submitSuccess) {
      dispatch({
        type: FULFILMENT_ACTIONS.RESET_FULFILMENT
      });
      dataLayerPush(
        rrpReviewOrderDataLayer({
          baseParams: {
            cartItems: rrpCart?.addedItems,
            delivery: { type: pickupType },
            location: window.location,
            ippDetails: rrpCart?.tempCartItem
          }
        })
      );
      navigate(`/${navigation.RRP_IPP_ORDER_SUMMARY}`);
    }
  }, [fulfilmentState?.delivery?.submitSuccess]);

  const callbackModel = {
    onProceed: async (fulfilmentPageData: KeyValue) => {
      const item =
        fulfilmentPageData[deliveryDataModel.bags[0]?.items?.list[0].id];
      const commonParams = {
        billingAccountId: order?.barId,
        customerId: userInformation?.clientContext?.customers?.[0]?.customerId,
        productOrderId: productOrder?.productOrderId,
        productOrderItemId: productOrder?.productOrderItemId,
        productOrderReferenceNumber: productOrder?.productOrderReferenceNumber,
        productId: productOrder?.productId,
        isBackOrder: false,
        isPreOrder: false,
        isPayChannelRequired: true,
        userInfo: userInformation,
        hasDocument: false,
        order,
        isRrpIppOrder: true,
        fulfillment: fulfilmentState
      };
      const pickupType = item.fulfilmentMethod;
      switch (pickupType) {
        case FULFILLMENT.FULFILMENT_TYPES.PARTNER_DELIVERY:
        case FULFILLMENT.FULFILMENT_TYPES.DOOR_STEP_DELIVERY: {
          onDeliverySubmit({
            commonParams,
            deliverySelectedSlot: {
              selectedDate: item?.selectedDate,
              selectedTime: item?.selectedTime
            },
            pickupType,
            address: fulfilmentPageData.deliveryAddress
          });
          break;
        }

        case FULFILLMENT.FULFILMENT_TYPES.POP_STATION: {
          const selectedPopStation = getSelectedPopstation(
            {
              popStation: { popStation: item.popStation }
            },
            popstations?.data
          );
          if (selectedPopStation && item?.date) {
            const selectedTimeslot = getSelectedPopStationTimeSlot(
              fulfilmentState?.popstations?.timeSlots,
              item?.date
            );

            dispatch(
              setPreSelectDeliveryData({
                selectedDeliveryMode: pickupType,
                address: selectedPopStation.KioskId,
                postCode: fulfilmentState.popstations.postcode,
                timeslot: selectedTimeslot.slotDate
              })
            );

            onPopstationSubmit(
              commonParams,
              selectedTimeslot,
              selectedPopStation,
              FULFILLMENT.FULFILMENT_TYPES_AEM.POP_STATION
            );
          }
          break;
        }
      }
    }
  };

  const onCloseAddressModal = () => {
    dispatch(revertNewAddress());
  };

  useEffect(() => {
    if (pickupType) {
      dispatch(setDeliveryMode(pickupType));
      if (
        delivery?.preselectDeliveryData?.selectedDeliveryMode !== pickupType
      ) {
        dispatch(clearPreSelectDeliveryData());
      }
      pickupType !== FULFILLMENT.FULFILMENT_TYPES.POP_STATION &&
        dispatch(resetPopStationData());
    }
  }, [pickupType]);

  const handlePickupchange = (orderId: string, pickupType: string) => {
    setPickupType(pickupType);
  };

  const handleRetrieveAddress = (postalCode: string) => {
    const address = deliveryDetails?.billingAccountDetails.map(
      billingAccountDetails => billingAccountDetails.physicalAddress
    );
    dispatch(setAddress(address));
    if (postalCode) {
      dispatch(fulfillmentDeliveryActions.getAddressInfo(postalCode, false));
    }
  };

  const deliveryOrderModelBag = deliveryDataModel?.bags?.[0];
  const hasFulfilmentData = deliveryOrderModelBag?.items?.list[0]?.id;

  return (
    <>
      {hasFulfilmentData && !hasCheckoutError && (
        <Fulfilmentv2
          orders={[
            {
              orderId: deliveryOrderModelBag?.items?.list[0]?.id,
              items: deliveryOrderModelBag?.items,
              methods: {
                pickupOptions: deliveryOrderModelBag?.pickup,
                data: {
                  deliverySlots: deliveryOrderModelBag?.deliverySlot,
                  popstationTimeslots:
                    deliveryOrderModelBag?.popstationTimeslots,
                  popStations: deliveryOrderModelBag?.popstations
                },
                callbacks: {
                  onPostalCodeSearch: (orderId: string, postalCode: string) => {
                    return handleSearchPopstation(postalCode, orderId);
                  },
                  getDeliverySlots,
                  getPopstationTimeslot: handleSearchPopstationTimeslot,
                  onPickupChange: handlePickupchange
                },
                configs: {
                  isPopstationLoading,
                  isPopstationTimeslotLoading,
                  preSelection: delivery?.preselectDeliveryData,
                  doneFetchingAddress:
                    fulfilmentState?.addressInfo?.status ===
                      REQUEST_STATUS.SUCCEEDED ||
                    fulfilmentState?.addressInfo?.status ===
                      REQUEST_STATUS.FAILED
                }
              }
            }
          ]}
          addressModel={{
            existingAddresses: {
              data: deliveryDataModel?.existingAddresses?.data || [],
              canAddNewAddress: true
            },
            onSearchAddress: handleRetrieveAddress,
            validFloors: deliveryDataModel?.validFloors || [],
            confirmAddress: deliveryDataModel?.confirmAddress,
            onConfirmNewAddress,
            onCloseAddressModal,
            configs: {
              doneFetchingAddress:
                fulfilmentState?.addressInfo?.status ===
                  FULFILLMENT.REQUEST_STATUS.SUCCEEDED ||
                fulfilmentState?.addressInfo?.status ===
                  FULFILLMENT.REQUEST_STATUS.FAILED
            }
          }}
          callbacks={callbackModel}
          layout={[
            LAYOUT.ITEMS,
            LAYOUT.NOTIFICATION,
            LAYOUT.METHODS,
            LAYOUT.DIVIDER
          ]}
        />
      )}
      {renderError()}
      <Loader />
    </>
  );
};

export default RrpIppFulfilment;
