// Used to determine if the user has selected a SIM Only plan and
// has not selected a device, accessories or received any free gifts.

import isSimOnlyPlan from '../helpers/is-sim-only-plan';

const isSimOnlyCard = (plan, device, accessories, freebies) => {
  return isSimOnlyPlan(plan) && !device && !accessories && !freebies;
};

export default isSimOnlyCard;
