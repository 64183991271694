import { PROMO_ERROR_CODE } from '../config/notifications';

/* istanbul ignore file */
export const ORDER_SUMMARY_CONSTANTS = {
  MOBILE: 'MOBILE',
  REMOVE_PROMO: 'CLEAR',
  INVALID_PROMO_CODE: 'INVALID_PROMO_CODE',
  CLEAR_FIELD_WITHOUT_API: [
    PROMO_ERROR_CODE.COUPON_INVALID,
    PROMO_ERROR_CODE.EMPTY
  ]
};
