/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@wec-core/form-engine';
import { navigate } from 'gatsby';
import useTranslate from '../../../hooks/useTranslation';
import { navigation } from '../../../middlewares/navigation-constants';
import {
  getFormDataMapping,
  getValidationSchema,
  FORM_MAPPING_ACTIONS,
  documentTypeValidation,
  documentSizeValidation,
  fileUploaded,
  uploadedFileDeleted,
  checkNricSilver
} from '../helper';
import {
  TObject,
  BillingPrefType,
  UploadedDocument,
  TFile,
  FormConfig
} from '../../../types/registrationCheckout';
import { getFormData } from './formData';
import {
  getReconFlowNonPerIniValues,
  reconInitialState
} from './recontractFlowState';
import { checkoutFlowReducer as reconFlowState } from '../checkoutFlowState';
import {
  fulFillmentActions,
  fulfillmentDeliveryActions,
  fulFillmentDocActions
} from '@detox/actions';
import { useDocumentUpload } from '../hooks/useDocumentUpload';
import { useErrorHandler } from '../../../hooks/useErrorHandler';
import { USER } from '../../../constants';
import { ListSkeletonLoader, SkeletonGrid } from '../../SkeletonLoader';
import { useDevice } from '@dls/web';

interface TProps {
  enableReinitialize: boolean;
  stackSpacing: number;
  onValuesChange?: (values: TObject) => void;
  onFormSubmit?: (values: TObject, navigateTo: string) => void;
  billingPrefData: BillingPrefType[];
  isCisFlow: boolean;
  idCardLinkClicked?: (hasNricFin?: boolean) => void;
  onGettingFormConfigs?: (formConfigs: FormConfig) => void;
  handleCheckoutHistoryState?: (needPushState?: boolean) => void;
  goToOrderSummary: () => void;
  isCISMyInfoFlow?: boolean;
}

export const ReconFlow: React.FC<TProps> = ({
  onValuesChange,
  onFormSubmit,
  billingPrefData,
  isCisFlow,
  idCardLinkClicked,
  onGettingFormConfigs,
  handleCheckoutHistoryState,
  goToOrderSummary,
  isCISMyInfoFlow,
  ...props
}): ReactElement => {
  const { t } = useTranslate();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(
    reconFlowState,
    reconInitialState
  );

  const {
    uploadDocument,
    deleteUploadedDocument,
    retrieveDocumentContent
  } = fulFillmentDocActions;

  const {
    verificationData,
    checkoutFormData = {},
    checkoutFormDocumentData,
    productOrderReferenceNumber,
    documentUpload,
    downloadedDocData,
    delivery,
    userInformation,
    user,
    hasSimCard,
    billingPreference,
    cartOrder = {},
    order,
    orderSummary
  } = useSelector((state: TObject) => ({
    verificationData: state.checkout?.verificationData,
    checkoutFormData: state.checkout?.checkoutFormData,
    checkoutFormDocumentData: state.checkout?.checkoutFormDocumentData,
    documentData: state.checkout?.documentData,
    productOrderReferenceNumber:
      state.order?.productOrder?.productOrderReferenceNumber,
    documentUpload: state.checkout?.documentUpload as UploadedDocument[],
    downloadedDocData: state.documents.documentData as UploadedDocument[],
    delivery: state.fulfillment?.delivery,
    billingPreference: state.fulfillment?.billingPreference,
    user: state.user,
    userInformation: state.user?.information,
    order: state.cart?.order,
    hasSimCard: state.cart?.order?.hasSim,
    cartOrder: state.cart?.cartOrder,
    fulfilmentState: state.fulfillment,
    productOrder: state.order?.productOrder,
    checkout: state.checkout,
    orderSummary: state.orderSummary,
    selectedPlan: state.plan?.selectedPlan,
    selectedProduct: state.product?.selectedProduct
  }));
  const nonPersonalInitialValues = getReconFlowNonPerIniValues(isCisFlow);
  const hasDocumentErrors = useRef<TObject>({});
  const { device, accessories = [] } = cartOrder;
  const { isShowSkeletonLoader } = orderSummary;
  const hasAccessories = accessories.length > 0;
  const simType = order?.newlyAddedSimDetails?.simDetails?.simType;
  const { renderError } = useErrorHandler({
    states: [delivery]
  });

  const { getNonPersonalGroup, formData } = useMemo(() => {
    return getFormData({
      t,
      checkoutFormDocumentData,
      device,
      billingPrefData,
      verificationData,
      isCisFlow,
      isCISMyInfoFlow,
      hasSimCard,
      hasAccessories
    });
  }, [device, billingPrefData, verificationData, isCisFlow]);

  const { fetchUploadedDocuments } = useDocumentUpload({
    dispatch,
    documentUpload,
    productOrderReferenceNumber,
    retrieveDocumentContent,
    checkoutFormData,
    formDispatch,
    checkoutFormDocumentData,
    downloadedDocData
  });

  const setOverAllFormState = (isEBill: boolean) => {
    const isNric =
      userInformation?.clientContext?.contact?.indentType ===
      USER.ID_TYPES.NRIC;
    const { groups, groupConfigs } = getNonPersonalGroup({
      isEBill,
      isNric,
      isNricSilver: checkNricSilver(
        userInformation?.clientContext?.contact?.dateOfBirth,
        isNric
      ),
      simType
    });
    if (typeof onGettingFormConfigs === 'function') {
      onGettingFormConfigs({
        hasUploadID:
          groupConfigs?.showNricFIN || groupConfigs.showStaffUploadDocument,
        hasBillingPref: groupConfigs?.showBillingPref,
        byPassFulfilment: groupConfigs?.byPassFulfilment,
        byPassDocUpload: groupConfigs?.byPassDocUpload
      });
    }
    if (groupConfigs.byPassDocUpload) {
      if (groupConfigs.byPassFulfilment) {
        return goToOrderSummary();
      }
      return navigate(`/${navigation.ORDER_FULFILMENT_PAGE}`, {
        replace: true
      });
    } else {
      fetchUploadedDocuments();
    }

    if (handleCheckoutHistoryState) {
      handleCheckoutHistoryState();
    }

    const payload = {
      formValues: checkoutFormData
        ? {
            ...nonPersonalInitialValues,
            ...checkoutFormData
          }
        : { ...nonPersonalInitialValues },
      formInputsMapping: {
        ...groups
      },
      formConfigs: groupConfigs
    };
    formDispatch({
      type: FORM_MAPPING_ACTIONS.SET_OVERALL_FORM_STATE,
      payload
    });
  };

  const fetchDeliveryDetails = () => {
    if (!delivery?.deliveryDetails) {
      const contact = userInformation?.clientContext?.contact || {};

      dispatch(
        fulfillmentDeliveryActions.getDeliveryDetails({
          contactId: contact?.contactId,
          productOrderReferenceNumber
        })
      );
    }
  };

  useEffect(() => {
    fetchDeliveryDetails();

    const contact = userInformation?.clientContext?.contact || {};
    const { contactId, indentValue, indentType } = contact;
    if (contactId) {
      if (billingPreference?.isOnPaperBill === undefined) {
        dispatch(
          fulFillmentActions.isEBillMethod({
            nric: indentValue,
            contactId,
            indentType
          })
        );
      }
    }
  }, [userInformation]);

  useEffect(() => {
    if (
      billingPreference?.isOnPaperBill !== undefined &&
      userInformation &&
      delivery?.deliveryDetails
    ) {
      setOverAllFormState(!billingPreference?.isOnPaperBill);

      if (billingPreference.isOnPaperBill) {
        formDispatch({
          type: FORM_MAPPING_ACTIONS.SET_FORM_VALUES,
          payload: {
            billingPreference: 1
          }
        });
      }
    }
  }, [
    billingPreference?.isOnPaperBill,
    userInformation,
    delivery?.deliveryDetails
  ]);

  const formattedFormFieldsData = getFormDataMapping(
    formState.formInputsMapping
  );

  const uploadFile = (fieldName, fieldsGroupName, docGroup, uploadedFile) => {
    return fileUploaded({
      uploadedFile,
      fieldName,
      fieldsGroupName,
      docGroup,
      hasDocumentErrors,
      productOrderReferenceNumber,
      uploadDocument,
      dispatch,
      formDispatch,
      t
    });
  };

  const deleteFile = (fileName, fieldName, fieldsGroupName, docGroup) => {
    return uploadedFileDeleted({
      fileName,
      fieldName,
      fieldsGroupName,
      docGroup,
      productOrderReferenceNumber,
      documentUpload,
      dispatch,
      formDispatch,
      deleteUploadedDocument
    });
  };

  const _idCardLinkClicked = () => {
    idCardLinkClicked(formState.formConfigs?.showNricFIN);
  };

  const callbacks = () => {
    const documentsGroup = formState.formInputsMapping['documents'];
    const staffGroup = formState.formInputsMapping['staffPass'];
    return {
      idCardLinkClicked: _idCardLinkClicked,
      documentTypeValidation: (file: File) => documentTypeValidation(file, t),
      documentSizeValidation: (file: File) => documentSizeValidation(file, t),
      nricFrontDropped: (file: TFile) =>
        uploadFile('nricFrontUpload', 'documents', documentsGroup, file),
      nricFrontDeleted: fileName =>
        deleteFile(fileName, 'nricFrontUpload', 'documents', documentsGroup),
      nricBackDropped: (file: TFile) =>
        uploadFile('nricBackUpload', 'documents', documentsGroup, file),
      nricBackDeleted: fileName =>
        deleteFile(fileName, 'nricBackUpload', 'documents', documentsGroup),
      staffPassDropped: (file: TFile) =>
        uploadFile('staffPassUpload', 'staffPass', staffGroup, file),
      staffPassDeleted: fileName =>
        deleteFile(fileName, 'staffPassUpload', 'staffPass', staffGroup)
    };
  };

  const _onValidate = _ => {
    return hasDocumentErrors.current;
  };

  if (
    delivery?.skeletonLoading ||
    delivery?.isLoading ||
    user?.loading ||
    isShowSkeletonLoader
  ) {
    return (
      <>
        <SkeletonGrid numberOfColumn={isMobile ? 1 : 2} numberOfRow={2} />
        <ListSkeletonLoader numberOfItem={3} />
      </>
    );
  }

  const _onFormSubmit = values => {
    const groupConfigs = formState.formConfigs;

    if (groupConfigs?.byPassFulfilment) {
      return goToOrderSummary();
    }
    onFormSubmit(values, `/${navigation.ORDER_FULFILMENT_PAGE}`);
  };

  return (
    <div className="fs-mask">
      {formattedFormFieldsData && formattedFormFieldsData.length > 0 && (
        <Form
          {...props}
          formFieldsConfig={formattedFormFieldsData}
          initialValues={formState.formValues}
          data={formData}
          callbacks={callbacks()}
          onValueChange={onValuesChange}
          onSubmit={_onFormSubmit}
          onValidate={_onValidate}
          validationSchema={getValidationSchema(t)}
        />
      )}
      {renderError()}
    </div>
  );
};

export default ReconFlow;
